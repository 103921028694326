import React, { memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import DropdownMenu from "../../layout/AppLayout/DropdownMenu";
import { useBluechipResources } from "../../hooks/useResources";
import useActions from "../../hooks/useActions";
import { openModalDialog } from "../../../actions/layoutActions";
import { isSpecDeleteDisabled } from "./SpecsPage";
import { isPOChangeDisabled } from "../PurchaseOrderDetail/utils";

// eslint-disable-next-line max-lines-per-function
export const SpecActions = ({
  dataComponent,
  onOpenDuplicateSpecs,
  onOpenBulkDeleteModal,
  onOpenCopySpecModal,
  onOpenIncompleteSpecModal,
  onOpenCompleteSpecModal,
  onOpenMoveSpecsModal,
  onOpenFlagAllowanceSpecs,
}) => {
  const { selectedIds = [] } = dataComponent;

  const actions = useActions({
    openModalDialog,
  });

  const onOpenBulkDeleteModalWithSelectedIds = useCallback(() => {
    onOpenBulkDeleteModal(selectedIds);
  }, [onOpenBulkDeleteModal, selectedIds]);
  const onOpenDuplicateSpecsModalWithSelectedIds = useCallback(() => {
    onOpenDuplicateSpecs(selectedIds);
  }, [onOpenDuplicateSpecs, selectedIds]);
  const onOpenIncompletesModalWithSelectedIds = useCallback(() => {
    onOpenIncompleteSpecModal(selectedIds);
  }, [onOpenIncompleteSpecModal, selectedIds]);
  const onOpenCompletesModalWithSelectedIds = useCallback(() => {
    onOpenCompleteSpecModal(selectedIds);
  }, [onOpenCompleteSpecModal, selectedIds]);
  const onOpenMoveSpecsModalWithSelectedIds = useCallback(() => {
    onOpenMoveSpecsModal(selectedIds);
  }, [onOpenMoveSpecsModal, selectedIds]);
  const onOpenFlagAllowanceSpecsModalWithSelectedIds = useCallback(() => {
    onOpenFlagAllowanceSpecs(selectedIds, true);
  }, [onOpenFlagAllowanceSpecs, selectedIds]);
  const onOpenRemoveFlagAllowanceSpecsModalWithSelectedIds = useCallback(() => {
    onOpenFlagAllowanceSpecs(selectedIds, false);
  }, [onOpenFlagAllowanceSpecs, selectedIds]);
  const onOpenSetForecastComment = useCallback(() => {
    actions.openModalDialog(
      "Set Forecast Comment",
      "SetForecastComment",
      {
        selectedIds,
      },
      false,
      false,
      {
        subtitle: `${pluralize("Spec", selectedIds.length, true)} Selected`,
      }
    );
  }, [actions, selectedIds]);
  const onOpenSetGLCodes = useCallback(() => {
    actions.openModalDialog(
      "Set GL Codes",
      "SetGLCodes",
      {
        selectedIds,
      },
      false,
      false,
      {
        subtitle: `${pluralize("Spec", selectedIds.length, true)} Selected`,
      }
    );
  }, [actions, selectedIds]);

  const selectedSpecs = useBluechipResources(
    dataComponent.model,
    ["purchaseOrder", "bidGroups"],
    selectedIds
  );

  const options = useMemo(() => {
    const disabled = selectedIds.length === 0;

    const isBulkChangeSpecDisabled =
      disabled ||
      selectedSpecs.some(spec => isPOChangeDisabled(spec.purchaseOrder));

    const isBulkDeleteSpecDisabled =
      isBulkChangeSpecDisabled ||
      selectedSpecs.some(spec => isSpecDeleteDisabled(spec));

    return [
      {
        content: "Duplicate Specs within Project",
        onClick: onOpenDuplicateSpecsModalWithSelectedIds,
        disabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Copy Specs into Another Project",
        onClick: onOpenCopySpecModal,
        disabled,
      },
      {
        content: "Move to Area",
        onClick: onOpenMoveSpecsModalWithSelectedIds,
        disabled: disabled,
        disableIfProjectClosed: true,
      },
      {
        content: disabled
          ? "Delete Specs"
          : `Delete ${selectedIds.length} Specs`,
        onClick: onOpenBulkDeleteModalWithSelectedIds,
        disabled: isBulkDeleteSpecDisabled,
        disableIfProjectClosed: true,
        useSeparator: true,
      },
      {
        content: "Incomplete Specs",
        onClick: onOpenIncompletesModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Complete Specs",
        onClick: onOpenCompletesModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Allowance Specs",
        onClick: onOpenFlagAllowanceSpecsModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Not Allowance Specs",
        onClick: onOpenRemoveFlagAllowanceSpecsModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
        useSeparator: true,
      },
      {
        content: "Set Forecast Comments",
        onClick: onOpenSetForecastComment,
        disabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Set GL Codes",
        onClick: onOpenSetGLCodes,
        disabled,
        disableIfProjectClosed: true,
      },
    ];
  }, [
    selectedIds.length,
    selectedSpecs,
    onOpenDuplicateSpecsModalWithSelectedIds,
    onOpenCopySpecModal,
    onOpenIncompletesModalWithSelectedIds,
    onOpenCompletesModalWithSelectedIds,
    onOpenFlagAllowanceSpecsModalWithSelectedIds,
    onOpenRemoveFlagAllowanceSpecsModalWithSelectedIds,
    onOpenMoveSpecsModalWithSelectedIds,
    onOpenBulkDeleteModalWithSelectedIds,
    onOpenSetForecastComment,
    onOpenSetGLCodes,
  ]);

  return <DropdownMenu buttonText="Actions" options={options} />;
};

SpecActions.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  onOpenBulkDeleteModal: PropTypes.func.isRequired,
  onOpenDuplicateSpecs: PropTypes.func.isRequired,
  onOpenCopySpecModal: PropTypes.func.isRequired,
  onOpenIncompleteSpecModal: PropTypes.func.isRequired,
  onOpenCompleteSpecModal: PropTypes.func.isRequired,
  onOpenMoveSpecsModal: PropTypes.func.isRequired,
  onOpenFlagAllowanceSpecs: PropTypes.func.isRequired,
};

export default memo(SpecActions);
