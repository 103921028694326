import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrid from "../../../forms/FormGrid";
import { FormikForm, ActionButtons } from "../../../forms";
import Joi from "joi-browser";
import BWModels from "benjaminwest-models";
import createClientContactFields from "./createClientContactFields";
import propTypes from "../../../../constants/propTypes";
import DuplicatePersonDialog from "../../../DuplicatePersonDialog";

const getValidationSchema = () =>
  BWModels.loadSchema("ClientContact").concat(
    Joi.object().keys({
      contact: BWModels.loadSchema("Contact").concat(
        Joi.object().keys({
          person: BWModels.loadSchema("Person"),
        })
      ),
    })
  );

const initialValues = {
  contact: {
    person: {
      firstName: undefined,
      lastName: undefined,
      phoneNumber: null,
      faxNumber: undefined,
      email: undefined,
    },
    status: "act",
    jobTitle: undefined,
  },
  canAccessClientPortal: false,
};

const EditClientContactForm = ({
  onSubmit,
  sendButtonText,
  clientContact,
  clientId,
  dataComponentId,
}) => (
  <FormikForm
    initialValues={clientContact || { clientId, ...initialValues }}
    onSubmit={onSubmit}
    enableReinitialize={true}
    validationSchema={getValidationSchema()}
    ignoreValidationFieldNames={["name", "contactId", "contact.fax"]}
  >
    {({ handleSubmit, values, ...formikProps }) => (
      <Fragment>
        <FormGrid
          values={values}
          fields={createClientContactFields}
          {...formikProps}
        />
        <DuplicatePersonDialog
          dataComponentId={dataComponentId}
          contactRelationId={clientId}
        />
        <ActionButtons
          isModal={true}
          onSend={handleSubmit}
          sendButtonText={sendButtonText}
          listeners={[dataComponentId]}
        />
      </Fragment>
    )}
  </FormikForm>
);

EditClientContactForm.propTypes = {
  clientContact: propTypes.clientContact,
  closeModalDialog: PropTypes.func,
  handleEditContact: PropTypes.func,
  dataComponentId: PropTypes.string.isRequired,
};

export default EditClientContactForm;
