import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";
import AddCircle from "@material-ui/icons/AddCircle";
import CheckCircle from "@material-ui/icons/CheckCircle";
import styled from "styled-components";

import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import propTypes from "../../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import { Paper } from "../../../../mui/core";
import { LoaderContext } from "../../../../ui/Loader";
import { useDeleteProcessRequests } from "../../../../hooks/useProcessRequest";
import { convertServerErrorToFormikErrors } from "../../../../../utils/formValidationUtils";
import withRenderOnPermissionActive from "../../../../hocs/withRenderOnPermissionActive";
import { BWGridAPI, GridHeader, GridTable } from "../../../../ui/BWGrid";
import AreaType from "../../../../../models/AreaType";

const CheckIcon = styled(({ isMultiple, ...props }) => (
  <CheckCircle {...props} />
))`
  display: flex;
  color: ${props => (props.isMultiple ? "#72b327" : "#D8D8D8")};
`;
const MultipleRender = ({ isMultiple }) => {
  return <CheckIcon isMultiple={isMultiple} />;
};
MultipleRender.propTypes = {
  isMultiple: PropTypes.bool,
};

export const areaTypeDataComponentId = "dataComponentAreaType";

export const columns = ["name", "isMultiple"];

export const columnOptions = {
  name: { title: "Name", filter: true },
  isMultiple: {
    title: "Multiple",
    filter: "select",
    filterOptions: {
      options: [
        { id: true, name: "True" },
        { id: false, name: "False" },
      ],
    },
    render: MultipleRender,
  },
};

const rowMenu = ({ handleDeleteAreaType, handleOpenEditModal }) => [
  {
    text: "Edit",
    onClick: handleOpenEditModal,
  },
  {
    text: "Delete",
    onClick: handleDeleteAreaType,
  },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Area Type",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

export const AreaTypeContainer = ({
  dataComponent,
  flattenedDataComponent,
  loading,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
}) => {
  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction(
        "The Area Type has been deleted successfully"
      );
      setReload(areaTypeDataComponentId, true);
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError["area_types"]);
    },
  });

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Create Area Type", "CreateAreaType", {}, true, true);
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    areaType => {
      openModalDialog(
        "Edit Area Type",
        "EditAreaType",
        { areaType },
        true,
        true
      );
    },
    [openModalDialog]
  );

  const handleDeleteAreaType = useCallback(
    areaType => {
      performDeleteRequest(areaTypeDataComponentId, areaType.id);
    },
    [performDeleteRequest]
  );

  return (
    <Paper>
      <LoaderContext.Provider value={loading}>
        <BWGridAPI
          dataComponent={flattenedDataComponent}
          model={AreaType}
          defaultSorting={[
            {
              columnName: "name",
              direction: "asc",
            },
          ]}
          apiRoute="area-types"
          includes={[]}
        >
          <GridHeader
            headerText={`${flattenedDataComponent.totalRows} Area Types`}
            actions={actions(handleOpenCreateModal)}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            rowMenu={rowMenu({
              handleDeleteAreaType,
              handleOpenEditModal,
            })}
          />
        </BWGridAPI>
      </LoaderContext.Provider>
    </Paper>
  );
};

AreaTypeContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    areaTypeDataComponentId,
    state
  );

  const dataComponent = getDataComponent(areaTypeDataComponentId, state);

  const requirements =
    getBluechipResourcesByType(areaTypeDataComponentId, state) || [];
  return {
    dataComponent,
    flattenedDataComponent,
    loading: _get(flattenedDataComponent, "loading") || !requirements,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(AreaTypeContainer),
  ["manage-area-types"]
);
