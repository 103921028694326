import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import get from "lodash.get";

import propTypes from "../../../../../constants/propTypes";
import Project from "../../../../../models/Project";
import ForecastByAreaSection from "./ForecastByAreaSection";
import { Title } from "../../../../ui/Typography/SectionTitle";
import RegularText from "../../../../ui/Typography/RegularText";
import currencyFormatter from "../../../../../utils/currencyFormatter";
import ProjectSummary from "../../../../../models/ProjectSummary";
import CardWithGraphic from "./ForecastByAreaSection/CardWithGraphic";
import PercentOfForecastCommittedForMerchandisePieChart from "./PercentOfForecastCommittedForMerchandisePieChart";
import ClientBudgetVsForecastBarChart from "./ClientBudgetVsForecastBarChart";

export const dataComponentId = "ProjectsDetailsDataComponentId";
export const summaryDataComponentId = "ProjectSummaryDataComponentId";
export const fundingsDataComponentId = "ProjectFundingsDataComponentId";

const format = value => currencyFormatter.format(value || 0);

import {
  ContentWrapper,
  GridContainer,
  GridItemLeft,
  GridItemCenter,
  GridItemRight,
  ItemPaperWrapper,
} from "./components";

import ProjectOverviewBarChart from "./ProjectOverviewBarChart";
import FundingRequests from "./FundingRequests/index";
import { ClientSection } from "./CientSection";
import { PurchasingAgentSection } from "./PurchasingAgentSection";
import { ProjectStatusCard } from "./ProjectStatusCard";
import { LoaderContext } from "../../../../ui/Loader";
import { getDefaultProjectCurrency } from "../../../../helpers";

export const getProjectCurrency = project => {
  const defaultProjectCurrency = getDefaultProjectCurrency(
    project.projectCurrencies
  );
  return get(defaultProjectCurrency, "currency.name");
};
export const calculateContingenciesTotal = projectSummary => {
  return (
    projectSummary.contingencies.reduce((accum, contingency) => {
      return accum + (contingency.value || 0);
    }, 0) +
    projectSummary.otherCosts?.reduce((accum, otherCost) => {
      return accum + (otherCost.value || 0);
    }, 0) +
    (projectSummary.salesTax || 0) +
    (projectSummary.totalFreightCost || 0) +
    (projectSummary.totalWarehousingCost || 0) +
    (projectSummary.totalVendorFreightCost || 0) +
    (projectSummary.totalInstallCost || 0)
  );
};
export const getContactAttribute = (entity, field) => {
  if (entity[field]) {
    return <RegularText>{entity[field]}</RegularText>;
  }
};
export const getHasAccounting = project => {
  return project.hasAccounting ? "Yes" : "No";
};

export const getForecastCommittedForMerchandiseData = projectSummary => {
  return [
    {
      name: "Committed",
      value:
        (projectSummary.committedPrice /
          (projectSummary.committedPrice + projectSummary.uncommittedPrice)) *
        100,
    },
    {
      name: "To  Be Committed",
      value:
        (projectSummary.uncommittedPrice /
          (projectSummary.committedPrice + projectSummary.uncommittedPrice)) *
        100,
    },
  ];
};
export const getProjectVarianceLabel = project => {
  switch (project.varianceType) {
    case "Merchandise Total": {
      return "Variance from Merchandise Total";
    }
    default:
      return "Variance from Project Forecast Total";
  }
};

export const getProjectVariance = (project, projectSummary) => {
  switch (project.varianceType) {
    case "Merchandise Total": {
      return projectSummary.forecastTotal - project.contractBudget;
    }
    default:
      return projectSummary.totalForecastAmount - project.contractBudget;
  }
};

const overviewItems = ({ project, projectSummary, projectFundings }) => [
  {
    label: "Currency Type",
    value: getProjectCurrency(project),
  },
  {
    label: "Client Budget",
    value: format(projectFundings.contractBudget),
  },
  {
    label: "Merchandise Forecast",
    value: format(projectSummary.total),
  },
  {
    label: "Total Project Forecast",
    value: format(projectSummary.totalForecastAmount),
  },
  {
    label: getProjectVarianceLabel(project),
    value: format(getProjectVariance(project, projectSummary)),
  },
  {
    label: "To Be Committed",
    value: format(projectSummary.uncommittedPrice),
  },
  {
    label: "Committed",
    value: format(projectSummary.committedPrice),
  },
  {
    label: "Tax, Freight, Contingency, etc.",
    value: format(calculateContingenciesTotal(projectSummary)),
  },
  {
    label: "Total Funding Received",
    value: format(projectFundings.totalFundsReceived),
  },
  {
    label: "Total Expenditures",
    value: format(project.expenditures),
  },
  {
    label: "Bank Balance",
    value: format(
      projectFundings.totalFundsReceived - projectFundings.expenditures
    ),
  },
  {
    label: "Cash to Complete",
    value: format(
      projectSummary.forecastTotal - projectFundings.totalFundsReceived
    ),
  },
  {
    label: "Project Accounting",
    value: getHasAccounting(project),
  },
  {
    label: "Change Orders to Date",
    value: projectSummary.projectStatus?.totalBilledChangeOrdersToDate,
  },
];

const overviewGraphItems = ({ projectSummary, projectFundings }) => [
  {
    label: "Client Budget",
    value: projectFundings.contractBudget,
  },
  {
    label: "Project Forecast",
    value: projectSummary.totalForecastAmount,
  },
  {
    label: "To Be Committed",
    value: projectSummary.uncommittedPrice,
  },
  {
    label: "Committed",
    value: projectSummary.committedPrice,
  },
  {
    label: "Total Funding Received",
    value: projectFundings.totalFundsReceived,
  },
  {
    label: "Bank Balance",
    value: projectFundings.totalFundsReceived - projectFundings.expenditures,
  },
  {
    label: "Cash to Complete",
    value:
      projectSummary.totalForecastAmount - projectFundings.totalFundsReceived,
  },
];

const clientBudgetVsForecastData = (
  project,
  projectSummary,
  projectFundings
) => [
  {
    label: "Client Budget",
    value: projectFundings.contractBudget,
  },
  {
    label: "Forecast",
    value: projectSummary.totalForecastAmount,
  },
  {
    label: "Total Funding Received",
    value: projectFundings.totalFundsReceived,
  },
  {
    label: "Cash to Complete",
    value: projectSummary.forecastTotal - projectFundings.totalFundsReceived,
  },
];

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity,max-lines-per-function
const ProjectOverviewContainer = props => {
  const {
    projectId,
    project,
    projectSummary,
    projectFundings,
    initDataComponent,
    performFindRequest,
    loading,
  } = props;

  useEffect(() => {
    initDataComponent(
      dataComponentId,
      Project,
      [
        "property.[entity.[client],location]",
        "projectMemberUsers.user.person",
        "projectMemberContacts.contact.person",
        "areas.[areaType,areaRooms]",
        "remitAddresses",
        "projectLead.person",
        "projectManager",
        "designer",
        "generalContractor",
        "otherConsultant",
        "office.location",
        "notesAuthor",
        "workScopes",
      ],
      "projects"
    );
    performFindRequest(dataComponentId, projectId);
    initDataComponent(
      summaryDataComponentId,
      ProjectSummary,
      [],
      "projects/summary"
    );
    performFindRequest(summaryDataComponentId, projectId);
    initDataComponent(
      fundingsDataComponentId,
      Project,
      [],
      "project-with-fundings"
    );
    performFindRequest(fundingsDataComponentId, projectId);
  }, [initDataComponent, performFindRequest, projectId]);

  const { property = {} } = project;
  const { entity = {} } = property;
  const { client = {} } = entity;
  const { projectMemberContacts = [] } = project;
  const { projectLead } = project;

  return (
    <LoaderContext.Provider value={{ loading }}>
      <ContentWrapper>
        <GridContainer container>
          <GridItemLeft item xs={12} sm={12} md={12} lg={4}>
            <ClientSection
              client={client}
              property={property}
              projectMemberContacts={projectMemberContacts}
            />
          </GridItemLeft>
          <GridItemCenter item xs={12} sm={12} md={12} lg={4}>
            <PurchasingAgentSection
              project={project}
              projectLead={projectLead}
            />
          </GridItemCenter>
          <GridItemRight item xs={12} sm={12} md={12} lg={4}>
            <ProjectStatusCard projectSummary={projectSummary} />
          </GridItemRight>
        </GridContainer>
        <GridContainer container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CardWithGraphic
              title="Overview"
              disableFilter
              items={overviewItems({
                project,
                projectSummary,
                projectFundings,
              })}
              graphItems={overviewGraphItems({
                project,
                projectSummary,
                projectFundings,
              })}
            >
              <ProjectOverviewBarChart
                data={overviewGraphItems({
                  project,
                  projectSummary,
                  projectFundings,
                })}
              />
            </CardWithGraphic>
          </Grid>
        </GridContainer>
        <GridContainer container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FundingRequests />
          </Grid>
        </GridContainer>
        <GridContainer container>
          <GridItemLeft item xs={12} sm={12} md={12} lg={6}>
            <ItemPaperWrapper>
              <Title>% of Forecast Committed for Merchandise</Title>
              <PercentOfForecastCommittedForMerchandisePieChart
                data={getForecastCommittedForMerchandiseData(projectSummary)}
              />
            </ItemPaperWrapper>
          </GridItemLeft>
          <GridItemRight item xs={12} sm={12} md={12} lg={6}>
            <ItemPaperWrapper>
              <Title>Client Budget vs. Forecast</Title>
              <ClientBudgetVsForecastBarChart
                data={clientBudgetVsForecastData(
                  project,
                  projectSummary,
                  projectFundings
                )}
              />
            </ItemPaperWrapper>
          </GridItemRight>
        </GridContainer>
        <GridContainer container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ForecastByAreaSection />
          </Grid>
        </GridContainer>
      </ContentWrapper>
    </LoaderContext.Provider>
  );
};

ProjectOverviewContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: propTypes.project.isRequired,
  projectSummary: propTypes.projectSummary,
  projectFundings: propTypes.project,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ProjectOverviewContainer.defaultProps = {
  projectId: "",
  project: { office: {} },
  projectSummary: { contingencies: [] },
  projectFundings: {},
  initDataComponent: () => {},
  performFindRequest: () => {},
};
export default ProjectOverviewContainer;
