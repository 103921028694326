import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GetApp from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ButtonText, StyledButton } from "./components";
import styled from "styled-components";

const StyledCircularProgress = styled(CircularProgress)`
  color: rgba(0, 0, 0, 0.26);
`;

export const DownloadPDFButton = ({
  label = "Download PDF",
  handleDownloadPDF,
  loading,
}) => {
  const onDownloadPDF = useCallback(() => {
    handleDownloadPDF();
  }, [handleDownloadPDF]);

  return (
    <StyledButton onClick={onDownloadPDF} tabIndex={-1} disabled={loading}>
      {loading ? <StyledCircularProgress size={24} /> : <GetApp />}
      <ButtonText>{label}</ButtonText>
    </StyledButton>
  );
};

DownloadPDFButton.propTypes = {
  label: PropTypes.string,
  handleDownloadPDF: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    loading: state.reports.loaders["purchase-order-detail-pdf-false"],
  };
};

export default connect(mapStateToProps)(DownloadPDFButton);
