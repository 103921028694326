import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import VendorCreatePage from "./VendorCreatePage";
import { performCreateRequest } from "../../../actions/dataComponentActions";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../utils/formValidationUtils";
import {
  getCreatedId,
  processCreateRequestStatus,
} from "../../../utils/dataComponentUtils";
import { initializeVendorDependencies } from "../../../actions/vendorActions";
import { showSnackNotificationAction } from "../../../actions/layoutActions";

const dataComponentId = "VendorCreate";

export const parseVendorName = name =>
  name
    ? name
        .replaceAll(/\s\s+/g, " ")
        .replaceAll(/[“|”]|``/g, '"')
        .replaceAll(/[`|‘|’]/g, "'")
        /* eslint-disable no-useless-escape */
        .replaceAll(/[^\[\&|\s|\w|\d|.|,|(|)|'|"\]]/g, "-") // To allow a special character, add it to the list. For example: .,() are allowed through the list
    : name;

export const handleVendorCreateError = (
  showSnackNotificationAction,
  formikActions
) => error => {
  const { title } = error.data.errors.find(({ global }) => global);
  showSnackNotificationAction(title);
  handleRequestError(error, formikActions);
};

export class VendorCreateContainer extends Component {
  componentDidMount() {
    const { initializeVendorDependencies } = this.props;

    initializeVendorDependencies(dataComponentId);
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, createdId } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => this.props.push(`/vendors/${createdId}`),
      onError: handleVendorCreateError(
        this.props.showSnackNotificationAction,
        this.state?.formikActions
      ),
    });
  }

  createVendor = ({ name, poName, w9Name, ...vendor }, formikActions) => {
    const { performCreateRequest } = this.props;

    performCreateRequest(dataComponentId, {
      ...vendor,
      name: parseVendorName(name),
      poName: parseVendorName(poName),
      w9Name: parseVendorName(w9Name),
    });
    this.setState({ formikActions });
  };

  goBack = () => {
    this.props.push("/vendors");
  };

  render() {
    const { vendorCategories, dataComponent } = this.props;

    return (
      <VendorCreatePage
        goBack={this.goBack}
        createVendor={this.createVendor}
        vendorCategories={vendorCategories}
        dataComponentId={dataComponent.dataComponentId}
      />
    );
  }
}

VendorCreateContainer.propTypes = {
  push: PropTypes.func,
  createdId: PropTypes.string,
  remitAddresses: PropTypes.array,
  dataComponent: PropTypes.object,
  vendorCategories: PropTypes.object,
  performCreateRequest: PropTypes.func.isRequired,
  initializeVendorDependencies: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const createdId = getCreatedId(dataComponent);
  return {
    dataComponent,
    createdId,
  };
};

const mapDispatchToProps = {
  push,
  performCreateRequest,
  initializeVendorDependencies,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorCreateContainer);
