import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import MUIPaper from "../../../mui/core/Paper";
import Project from "../../../../models/Project";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import GridAPI from "../../../ui/Grid/GridAPI";
import { RowMenuProvider } from "../../../ui/Grid/utilComponents";
import {
  FavoriteTypeProvider,
  StatusTypeProvider,
  BillingStatusProvider,
  ScopeTypeProvider,
} from "../../Projects/dataTypeProviders";
import { StyledCell } from "../../../ui/Grid/utilComponents/tableComponents/Cell";
import PagingPaneWithStatusSwitch from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { statusFilter } from "../../Projects/Grid";
import {
  buildTextTypeProvider,
  buildStaticSelectTypeProvider,
} from "../../../ui/Grid/filterDataTypeProviders";
import get from "lodash.get";

const Paper = styled(MUIPaper)`
  ${StyledCell} {
    &:first-child {
      padding-left: 8px;
    }
  }
`;

export const getCellValueNeedsFollowUp = property =>
  function CellValueNeedsFollowUp(props) {
    const value = get(props, property);
    const needsFolowUp = get(props, "needsFollowUp");
    return needsFolowUp ? (
      <span style={{ color: "#ff1744" }}>{value}</span>
    ) : (
      value
    );
  };

const Grid = ({
  selectedStatuses,
  userId,
  dataComponent,
  getRowMenu,
  onRowClick,
}) => {
  const rowProps = useMemo(
    () => ({
      onClick: onRowClick,
      getRowTitle: ({ name }) => `Press to go to ${name} details`,
    }),
    [onRowClick]
  );

  const projectSchema = BWModels.loadSchema("Project");
  const statuses = projectSchema.__utils.statuses;

  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider(["name"]),
      buildStaticSelectTypeProvider(["status"]),
    ],
    []
  );

  return (
    <Paper noBorder>
      <GridAPI
        apiRoute="projects"
        model={Project}
        includes={[
          "property.entity.client.scope",
          "office.scope",
          "workScopes",
          "projectSubWorkScopes.workScopeSubWorkScope.[workScope,subWorkScope]",
          "projectType",
        ]}
        defaultSorting={[
          { columnName: "isFavorite", direction: "asc" },
          { columnName: "number", direction: "desc" },
        ]}
        apiFilters={{
          rootFilters: {
            $where: {
              status: {
                $in: selectedStatuses,
              },
              $or: [
                { "projectLead.id": userId },
                { "projectMemberUsers.userId": userId },
              ],
              isFavorited: true,
            },
          },
          params: { selectNeedsFollowUp: true },
        }}
        dataComponent={dataComponent}
        rowProps={rowProps}
        columns={[
          {
            name: "isFavorite",
            title: "Fav.",
          },
          {
            type: "rowMenu",
            getCellValue: getRowMenu,
            width: "64px",
          },
          {
            name: "number",
            title: "Number",
            bold: true,
            filter: true,
            fill: true,
            getCellValue: getCellValueNeedsFollowUp("number"),
          },
          {
            name: "name",
            maxWidth: "20vw",
            title: "Project Name",
            filter: true,
            getCellValue: getCellValueNeedsFollowUp("name"),
          },
          {
            name: "status",
            filter: true,
            filterOptions: {
              operator: statusFilter,
              options: statuses,
            },
          },
          {
            name: "projectTypeName",
            title: "Project Type",
          },
          {
            name: "billingFlag",
            title: "Billing",
          },
          {
            name: "workScopes.id",
            title: "Scope",
          },
        ]}
        columnExtensions={[
          { columnName: "workScopes.id", sortingEnabled: false },
        ]}
        tableComponents={{
          GridHeader: useHeaderComponent({
            headerText: `My Projects (${dataComponent.totalRows})`,
          }),
          PagingPanelTemplate: PagingPaneWithStatusSwitch,
        }}
        dataTypeProviders={[
          StatusTypeProvider,
          FavoriteTypeProvider,
          BillingStatusProvider,
          RowMenuProvider,
          ScopeTypeProvider,
        ]}
        filteringDataTypeProviders={filteringDataTypeProviders}
      />
    </Paper>
  );
};

Grid.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string),
  getRowMenu: PropTypes.func,
  onRowClick: PropTypes.func,
  userId: PropTypes.string,
};

export default Grid;
