import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import ConsultingCompanyContactForm from "./ConsultingCompanyContactForm";
import { handleCreateContactError } from "../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";

const dataComponentId = "ConsultingCompanyContacts";

const initialValues = {
  contact: {
    person: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      faxNumber: "",
      email: "",
    },
    jobTitle: "",
  },
};

export class CreateConsultingCompanyContactContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, showSnackNotificationAction } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateRequestSuccess,
      onError: response =>
        handleCreateContactError(
          this.state.formikActions,
          showSnackNotificationAction
        )(response),
    });
  }

  handleCreateRequestSuccess = () => {
    const { closeModalDialog, setReload } = this.props;

    closeModalDialog();
    setReload(dataComponentId, true);
  };

  handleCreateContact = (consultingCompanyContact, formikActions) => {
    const { performCreateRequest } = this.props;

    performCreateRequest(dataComponentId, consultingCompanyContact);
    this.setState({ formikActions });
  };

  render() {
    const { consultingCompanyId } = this.props;

    return (
      <ConsultingCompanyContactForm
        sendButtonText="Create Contact"
        onSubmit={this.handleCreateContact}
        consultingCompanyContact={{ ...initialValues, consultingCompanyId }}
        dataComponentId={dataComponentId}
      />
    );
  }
}

CreateConsultingCompanyContactContainer.propTypes = {
  closeModalDialog: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  consultingCompanyId: PropTypes.string,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  setReload,
  closeModalDialog,
  performCreateRequest,
  showSnackNotificationAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateConsultingCompanyContactContainer)
);
