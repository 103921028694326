import React, { Fragment } from "react";
import startCase from "lodash/startCase";
import lowerFirst from "lodash/lowerFirst";
import get from "lodash.get";

// eslint-disable-next-line no-unused-vars
import InvoiceData from "./InvoiceData";
import { currencyToNumber } from "../../../components/inputs/utils";
import Price from "../../../components/ui/Price";
import { roundInvoiceVal } from "./DataRow";

export default class Validator {
  /**
   *
   * @param {InvoiceData} invoiceData - The invoice data object
   */
  constructor(invoiceData) {
    this.invoiceData = invoiceData;
  }

  validate(obj, row) {
    const { field } = row;

    const editedField = this.getEditedField(row, obj);
    const value = currencyToNumber(get(obj, editedField, 0));

    switch (field) {
      case "tax":
        return this.taxValidator({ value, field, obj, row });
      case "discount":
        return this.discountValidator({ value, field, obj, row });
      case "accruedUseTax":
        return {
          valid: true,
          helperText: " ",
        };
      default:
        return this.defaultValidator({ value, field, obj, row });
    }
  }

  discountValidator({ value, field, obj }) {
    const editedColumn = obj[`${field}DepositsPaid`] ? "deposits" : "assets";
    const { max } = this.getLimits({ field, obj, editedColumn });
    return this.genValidatorResult(
      value > max,
      <Fragment>
        {startCase(field)} amount cannot exceed{" "}
        <Price number={max} currency={this.currency} />
      </Fragment>
    );
  }

  /**
   *
   * @param {"merchandise" | "overage" | "tax" | "freightWarehousing" | "install" | "otherCost"} field
   * @param {number} value
   */
  defaultValidator({ value, field, obj }) {
    const editedColumn = obj[`${field}DepositsPaid`] ? "deposits" : "assets";
    const { min, max } = this.getLimits({ field, obj, editedColumn });
    return this.genValidatorResult(
      value !== 0 && !(value >= min && value <= max),
      <Fragment>
        {startCase(field)} amount must be between{" "}
        <Price number={min} currency={this.currency} /> and{" "}
        <Price number={max} currency={this.currency} />
      </Fragment>
    );
  }

  taxValidator({ value, obj }) {
    const column = obj["taxDepositsPaid"] ? "deposits" : "assets";

    const salesTaxTolerancePercent = get(
      this,
      "invoiceData.invoice.purchaseOrder.project.salesTaxTolerancePercent"
    );
    const salesTaxPercent = get(
      this,
      "invoiceData.invoice.purchaseOrder.project.salesTaxPercent"
    );

    const maxTaxable =
      this.invoiceData[`total${startCase(column)}Taxable`] *
        (salesTaxTolerancePercent || salesTaxPercent) -
      this.invoiceData.tax[`${lowerFirst(startCase(column))}ToDate`];

    return this.genValidatorResult(
      value > Number((Math.ceil(maxTaxable * 100) / 100).toFixed(2)),
      <Fragment>
        Tax {column} exceeds max value{" "}
        <Price number={maxTaxable} currency={this.currency} />
      </Fragment>
    );
  }

  get currency() {
    return get(
      this,
      "invoiceData.invoice.purchaseOrder.projectCurrency.currency"
    );
  }

  getEditedField(row, obj) {
    const { field } = row;
    if (typeof obj[`${field}DepositsPaid`] === "undefined") {
      return `${field}AssetsPaid`;
    }
    return `${field}DepositsPaid`;
  }

  /**
   *
   * @param {{field: "merchandise" | "overage" | "tax" | "freightWarehousing" | "install" | "otherCost"}} param0
   * @returns
   */
  getLimits({ field, editedColumn }) {
    const notProcessedColumn =
      editedColumn == "deposits"
        ? "notProcessedDepositsPaid"
        : "notProcessedAssetsPaid";
    const notProcessed = this.invoiceData[field][notProcessedColumn];
    if (notProcessed < 0) {
      const initialNotProcessed =
        this.invoiceData[field].rawNotProcessed +
        this.invoiceData[field][`${editedColumn}Paid`];
      return {
        min: roundInvoiceVal(
          Math.min(
            -1 *
              Math.min(
                this.invoiceData[field].depositsToDate,
                Math.max(
                  Math.abs(initialNotProcessed),
                  Math.abs(this.invoiceData[field].assetsPaid)
                )
              ),
            initialNotProcessed
          )
        ),
        max: 0,
      };
    }
    const min = this.getMinimumForPositiveNotProcessed({
      field,
      editedColumn,
    });
    const max = notProcessed;
    return {
      min: roundInvoiceVal(Math.min(min, max)),
      max: roundInvoiceVal(Math.max(min, max)),
    };
  }

  /**
   *
   * @param {"merchandise" | "overage" | "tax" | "freightWarehousing" | "install" | "otherCost"} field
   */
  getMinimumForPositiveNotProcessed({ field, editedColumn }) {
    /**
     * Assume assetsPaid is being updated, then depositsPaid if it's pair
     */
    const pairColumn = editedColumn == "deposits" ? "assets" : "deposits";

    const pairColumnPaid = this.invoiceData[field][`${pairColumn}Paid`];
    const prevPaid =
      this.invoiceData[field][`${editedColumn}ToDate`] -
      this.invoiceData[field][`${editedColumn}Paid`];

    /**
     * Minimum assetsPaid is the minimum between depositsPaid and prevPaid
     */
    if (pairColumnPaid !== 0 && prevPaid !== 0) {
      return -1 * Math.max(Math.abs(pairColumnPaid), Math.abs(prevPaid));
    }

    /**
     * If prevPaid is filled, then let's use it as minimum
     */
    return prevPaid !== 0 ? -1 * prevPaid : -1 * pairColumnPaid;
  }

  genValidatorResult(isInvalid, message) {
    if (isInvalid) {
      return {
        valid: false,
        helperText: message,
      };
    }
    return {
      valid: true,
      helperText: " ",
    };
  }
}
