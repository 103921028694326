import React from "react";
import _set from "lodash/set";

import { inputs } from "../../../../inputs/inputConfigs";
import { AddCell, StyleDelete, ActionWrapper } from "./components";
import { isValidEmail } from "../../../Notes/NoteCreate/EmailInput";
import get from "lodash.get";

export const rowInputs = {
  "contact.person.firstName": {
    ...inputs.firstName,
    name: "contact.person.firstName",
    required: true,
  },
  "contact.person.lastName": {
    ...inputs.lastName,
    name: "contact.person.lastName",
    required: true,
  },
  "contact.jobTitle": { ...inputs.contactRole, required: true },
  "contact.person.email": { ...inputs.email, name: "contact.person.email" },
  "contact.person.phoneNumber": {
    ...inputs.phone,
    name: "contact.person.phoneNumber",
    required: true,
  },
};

export const columns = [
  "contact.person.firstName",
  "contact.person.lastName",
  "contact.jobTitle",
  "contact.person.email",
  "contact.person.phoneNumber",
  "actions",
];

export const newVendorContact = {
  contact: {
    person: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    jobTitle: "",
  },
};

export const renderColumnFactory = title => {
  return (row, column, data) => {
    if (!data) return;
    const { rowId } = data;
    if (rowId > 0) return get(row, column);
    return get(row, column) || <AddCell>{`Add ${title}`}</AddCell>;
  };
};

export const getActionRender = (isAdding, handleDelete, labeledText) => (
  row,
  _,
  rowOption
) => {
  if (!rowOption || labeledText) return;
  const { rowId } = rowOption;
  const isCreateRow = rowId === 0 && isAdding;
  const showDeleteButton = rowId !== 0;

  return (
    row &&
    showDeleteButton && (
      <ActionWrapper>
        <StyleDelete onClick={() => handleDelete(isCreateRow, rowId)} />
      </ActionWrapper>
    )
  );
};

export const getHandleDelete = (
  setIsAdding,
  formik,
  values,
  isAdding,
  handleRemoveContact,
  handleSubmit
) => (isCreateRow, rowId) => {
  if (isCreateRow) return setIsAdding(false);
  const currentIndex = isAdding ? rowId - 1 : rowId;
  handleRemoveContact(currentIndex, formik, values);
  handleSubmit();
};

const beforeSave = ({ columnName, showSnackNotificationAction }) => value => {
  if (columnName.includes("email") && isValidEmail(value || "")) {
    showSnackNotificationAction(`"email" must be a valid email`);
  }
  return value;
};

export const getEditableOptions = ({
  columnName,
  rows,
  handleSubmit,
  hasEmptyValues,
  showSnackNotificationAction,
}) => ({
  beforeSave: beforeSave({ columnName, showSnackNotificationAction }),
  afterSave: (value, { rowId }) => {
    const { contact, ...row } = rows[rowId];
    const updatedRow = {
      ...row,
      contact: { ...contact },
    };
    _set(updatedRow, columnName, value);
    if (updatedRow.id) {
      handleSubmit();
      return;
    }
    if (
      columnName.includes("phoneNumber") ||
      !hasEmptyValues(updatedRow.contact)
    ) {
      handleSubmit();
    }
  },
});

export const columnsOptions = (
  handleSubmit,
  handleRemoveContact,
  hasEmptyValues,
  {
    isAdding,
    setIsAdding,
    formik,
    values,
    rows,
    showSnackNotificationAction,
    labeledText,
  }
) => {
  const handleDelete = getHandleDelete(
    setIsAdding,
    formik,
    values,
    isAdding,
    handleRemoveContact,
    handleSubmit
  );

  return {
    "contact.person.firstName": {
      sortingEnabled: false,
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "contact.person.firstName",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      render: renderColumnFactory("First Name"),
      width: "150px",
      title: "First Name *",
    },
    "contact.person.lastName": {
      sortingEnabled: false,
      editableOptions: getEditableOptions({
        columnName: "contact.person.lastName",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      title: "Last Name *",
      render: renderColumnFactory("Last Name"),
      width: "100px",
      editable: true,
    },
    "contact.jobTitle": {
      sortingEnabled: false,
      title: "Job Title *",
      render: renderColumnFactory("Job Title"),
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "contact.jobTitle",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      width: "100px",
    },
    "contact.person.email": {
      sortingEnabled: false,
      render: renderColumnFactory("Email"),
      title: "Email *",
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "contact.person.email",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      width: "100px",
    },
    "contact.person.phoneNumber": {
      sortingEnabled: false,
      title: "Phone Number *",
      render: renderColumnFactory("phone"),
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "contact.person.phoneNumber",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      width: "150px",
    },
    actions: {
      width: "100px",
      title: " ",
      sortingEnabled: false,
      render: getActionRender(isAdding, handleDelete, labeledText),
    },
  };
};

export const getInputName = isAdding => (tableRow, column) => {
  if (!column) return;
  const defaultAttribute = `data[${tableRow.rowId}].${column.name}`;
  if (!isAdding) return defaultAttribute;

  return tableRow.rowId === 0
    ? `newVendorContact.${column.name}`
    : `data[${tableRow.rowId - 1}].${column.name}`;
};
