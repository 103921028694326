import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import FormGrid from "../../../../components/forms/FormGrid";
import createVendorContactFields from "./createVendorContactFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import styled from "styled-components";
import { inputs } from "../../../inputs/inputConfigs";
import { Field } from "formik";
import InputWrapper from "../../../inputs/InputWrapper";
import {
  getErrorMessage,
  hasError,
} from "../../../../utils/formValidationUtils";

const FieldContainer = styled.div({});
const Row = styled.div`
  display: flex;
  padding: 0 24px;

  ${FieldContainer} {
    margin-left: 0;
    margin-bottom: 24px;
  }
`;

const CheckboxRow = ({ input, label }) => {
  return (
    <Row>
      <FieldContainer>
        <Field name={input.name}>
          {({ field, form }) => (
            <InputWrapper
              {...input}
              {...field}
              label={label || input.label}
              hasError={hasError(input.name, form)}
              errorMessage={getErrorMessage(input.name, form)}
            />
          )}
        </Field>
      </FieldContainer>
    </Row>
  );
};

export default class CreateVendorContactForm extends Component {
  static propTypes = {
    createVendorContact: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    validationSchema: PropTypes.object.isRequired,
  };

  vendorContactInitialValues = {
    contact: {
      person: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        faxNumber: "",
        email: "",
      },
      status: "inact",
      jobTitle: "",
    },
    canAccessVendorPortal: false,
    contactStatus: "",
  };

  handleCancel = () => {
    this.props.closeModalDialog();
  };

  render() {
    const { createVendorContact, validationSchema } = this.props;
    const { canAccessVendorPortal, status } = inputs;

    return (
      <FormikForm
        initialValues={this.vendorContactInitialValues}
        onSubmit={createVendorContact}
        validationSchema={validationSchema}
        ignoreValidationFieldNames={["name", "contactId", "vendorId"]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createVendorContactFields()}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <CheckboxRow
              input={canAccessVendorPortal}
              label={canAccessVendorPortal.label}
            />
            <CheckboxRow
              input={{ ...status, name: `contact.${status.name}` }}
            />
            <ActionButtons
              onSend={handleSubmit}
              sendButtonText="CREATE CONTACT"
              onCancel={this.handleCancel}
              isModal={true}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}
