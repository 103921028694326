import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import propTypes from "../../../../../constants/propTypes";
import { BWGridLocal, GridRow, GridTable } from "../../../../ui/BWGrid";
import Loader from "../../../../ui/Loader";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import {
  HeaderWrapper,
  TitleWrapper,
  Wrapper,
  HeaderLeft,
  PreviousPOWarning,
  WarningSymbol,
} from "../BidGroupTable/components";
import {
  SummaryLabelWrapper,
  SummaryValueWrapper,
  SummaryWrapper,
} from "../BidGroupTable/Summary";
import { ActionButtons, FormGrid } from "../../../../forms";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import EditableColumn, {
  StyledEdit,
} from "../../../../ui/BWGrid/columns/EditableColumn";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCell";
import FormikForm from "../../../../forms/FormikForm";
import {
  handleAddToPurchaseOrder,
  generateGridRows,
  calculateTotal,
} from "./CreateBidGroupPurchaseOrderFunctions";
import { COM_TYPE } from "../bidGroupVendorSpecUtils";
import { getFields } from "./fields";

const rowInputs = {
  baseQuantity: {
    InputComponent: TextInputWithError,
    type: "number",
    name: "baseQuantity",
    fullWidth: true,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
  atticStock: {
    InputComponent: TextInputWithError,
    type: "number",
    name: "atticStock",
    fullWidth: true,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
};
export const getInputName = (tableRow, column) => {
  if (!column) return;
  return `data[${tableRow.rowId - 1}].${column.name}`;
};
export const isEditable = column => {
  const columns = ["baseQuantity", "atticStock"];
  return columns.includes(column.name);
};
export const getQuantityEditColumn = (row, prop) => {
  return <EditableColumn>{row[prop]}</EditableColumn>;
};

const showCustomNumber = specIdsWithPreviousPO =>
  function CustomNumber(spec) {
    const symbol = specIdsWithPreviousPO.includes(spec.specId) ? "*" : "";
    return (
      <span>
        <WarningSymbol>{symbol}</WarningSymbol> {spec.customNumber}
      </span>
    );
  };

const columns = [
  "customNumber",
  "description",
  "unitOfMeasure.name",
  "baseQuantity",
  "atticStock",
  "price",
  "extendedPrice",
];
export const columnOptions = (
  gridRows,
  setGridRows,
  specIdsWithPreviousPO,
  currencyFormatter,
  bidGroupVendorSpecs
) => ({
  customNumber: {
    title: "Number",
    bold: true,
    filter: true,
    render: showCustomNumber(specIdsWithPreviousPO),
  },
  description: {
    filter: true,
    fill: true,
    width: "280px",
  },
  "unitOfMeasure.name": { title: "UOM", bold: true, width: "50px" },
  baseQuantity: {
    title: "Base",
    width: "50px",
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];

        row.baseQuantity = newValue;
        row.extendedPrice = (newValue + row.atticStock) * row[row.price];

        const bidGroupVendorSpec = bidGroupVendorSpecs.find(
          bidGroupVendorSpec => bidGroupVendorSpec.id === row.id
        );

        bidGroupVendorSpec.sampleQuantity = newValue;
        bidGroupVendorSpec.spec.baseQuantity = newValue;

        setGridRows(rows);
      },
    },
    render: row => {
      return getQuantityEditColumn(row, "baseQuantity");
    },
  },
  atticStock: {
    title: "Attic",
    width: "50px",
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];
        row.atticStock = newValue;
        row.extendedPrice = (newValue + row.baseQuantity) * row[row.price];

        const bidGroupVendorSpec = bidGroupVendorSpecs.find(
          bidGroupVendorSpec => bidGroupVendorSpec.id === row.id
        );

        bidGroupVendorSpec.spec.atticStock = newValue;

        setGridRows(rows);
      },
    },
    render: row => {
      return getQuantityEditColumn(row, "atticStock");
    },
  },
  price: {
    title: "Price",
    wordWrapEnabled: true,
    render: row => {
      if (row.type === COM_TYPE) {
        return;
      }
      return currencyFormatter.format(row.price);
    },
  },
  extendedPrice: {
    title: "Ext. Price",
    wordWrapEnabled: true,
    render: row => {
      if (row.type === COM_TYPE) {
        return;
      }
      return currencyFormatter.format(row.price * row.quantity);
    },
  },
});

const CreateBidGroupPurchaseOrderContainer = ({
  projectId,
  bidGroupVendor,
  bidGroupVendorSpecs,
  onCreateBidGroupPurchaseOrder,
  dataComponentId,
  performCreateRequest,
  setSnackMessage,
  onAddToPurchaseOrder,
}) => {
  const [total, setTotal] = useState(0);
  const [gridRows, setGridRows] = useState([]);
  const currency = bidGroupVendor?.quoteProjectCurrency?.currency;
  const quoteProjectCurrencyId = bidGroupVendor?.quoteProjectCurrencyId;
  const currencyFormatter = useCurrencyFormatter(currency);

  useEffect(() => {
    const rows = generateGridRows(bidGroupVendorSpecs);

    setGridRows(rows);
  }, [bidGroupVendorSpecs]);

  useEffect(() => {
    const pricingTotal = calculateTotal(gridRows);

    setTotal(pricingTotal);
  }, [gridRows, bidGroupVendorSpecs]);
  const tableComponent = useMemo(
    () => ({
      CellComponent: editableCellCreator(rowInputs, getInputName, column =>
        isEditable(column)
      ),
    }),
    []
  );

  const specsWithPreviousPO = bidGroupVendorSpecs.filter(
    bidGroupVendorSpec => bidGroupVendorSpec.spec.purchaseOrderId
  );

  return (
    <FormikForm
      initialValues={{ purchaseOrderId: "0" }}
      onSubmit={() => {}}
      ignoreCache
    >
      {formikProps => (
        <React.Fragment>
          <BWGridLocal
            id={`create-bid-group-purchase-order-${bidGroupVendor.id}`}
            sorting={[{ columnName: "spec.customNumber", direction: "asc" }]}
            rows={gridRows}
            gridConfig={{ pageSize: -1, totalRows: bidGroupVendorSpecs.length }}
            isLoading={false}
            tableComponents={tableComponent}
            noBorder
          >
            <GridRow>
              <HeaderWrapper>
                <Wrapper>
                  <HeaderLeft>
                    <Loader height="30px" width="350px">
                      <TitleWrapper>
                        {`${bidGroupVendorSpecs.length} ${pluralize(
                          "Spec",
                          bidGroupVendorSpecs.length
                        )} Selected`}
                        <PreviousPOWarning>
                          {specsWithPreviousPO.length > 0
                            ? "* The specs already assigned to a PO will get moved into the new one."
                            : ""}
                        </PreviousPOWarning>
                      </TitleWrapper>
                    </Loader>
                  </HeaderLeft>
                </Wrapper>
              </HeaderWrapper>
            </GridRow>
            <GridTable
              columns={columns}
              columnOptions={columnOptions(
                gridRows,
                setGridRows,
                specsWithPreviousPO.map(spec => spec.specId),
                currencyFormatter,
                bidGroupVendorSpecs
              )}
            />
            <GridRow showPagingPanel={false} />
            <GridRow>
              <SummaryWrapper>
                <SummaryLabelWrapper>Total:</SummaryLabelWrapper>
                <SummaryValueWrapper>
                  {currencyFormatter.format(total)}
                </SummaryValueWrapper>
              </SummaryWrapper>
            </GridRow>
          </BWGridLocal>
          <FormGrid
            fields={getFields(projectId, quoteProjectCurrencyId)}
            values={formikProps.values}
            errors={formikProps.errors}
            {...formikProps}
          />
          <ActionButtons
            isModal={true}
            listeners={[dataComponentId]}
            sendButtonText={"Add To Purchase Order"}
            onSend={() =>
              handleAddToPurchaseOrder(
                bidGroupVendor,
                bidGroupVendorSpecs,
                gridRows,
                onCreateBidGroupPurchaseOrder,
                dataComponentId,
                performCreateRequest,
                setSnackMessage,
                formikProps.values.purchaseOrderId,
                onAddToPurchaseOrder
              )
            }
          />
        </React.Fragment>
      )}
    </FormikForm>
  );
};

CreateBidGroupPurchaseOrderContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  bidGroupVendor: propTypes.bidGroupVendor.isRequired,
  bidGroupVendorSpecs: PropTypes.arrayOf(propTypes.spec),
  onCreateBidGroupPurchaseOrder: PropTypes.func.isRequired,
  index: PropTypes.string,
  dataComponentId: PropTypes.string.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  setSnackMessage: PropTypes.func.isRequired,
  onAddToPurchaseOrder: PropTypes.func.isRequired,
};

export default CreateBidGroupPurchaseOrderContainer;
