import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../../forms";
import { ActionButtons } from "../../../../../forms/ActionButtons";
import { inputs } from "../../../../../inputs/inputConfigs";
import { areaTypeDataComponentId } from "../AreaTypeContainer";
import CheckboxWithError from "../../../../../inputs/CheckboxWithError";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.name,
          grid: { xs: 12 },
        },
        {
          input: {
            name: "isMultiple",
            InputComponent: CheckboxWithError,
            label: "Multiple",
            disabled: true,
            checkValues: {
              check: true,
              uncheck: false,
            },
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const EditAreaType = ({
  areaType,
  handleEditAreaType,
  closeModalDialog,
}) => {
  return (
    <FormikForm
      initialValues={{
        name: areaType.name,
        isMultiple: areaType.isMultiple,
      }}
      onSubmit={handleEditAreaType}
      validationSchema={BWModels.loadSchema("AreaType")}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Save"
            listeners={[areaTypeDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
