import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import Draggable from "react-draggable";

import ModalTitle from "./ModalTitle";
import ModalTabs from "./ModalTabs";
import withModalState from "./withModalState";
import propTypes from "../../../../constants/propTypes";
import withPagination from "../../../../withPagination";
import CreateClientContactLocal from "../../../pages/ClientCreate/CreateClientContactLocal";
import CreateVendorContactLocal from "../../../pages/VendorCreate/CreateVendorContactLocal";
import CreateRemitAddressLocal from "../../../pages/VendorCreate/CreateRemitAddressLocal";
import CreateAreaLocal from "../../../pages/ProjectCreate/AreasSection/CreateAreaLocal";
import CreateUserContainer from "../../../pages/Users/CreateUser";
import UserSettings from "../../../pages/Users/UserSettings";
import EditClientContact from "../../../pages/ClientContacts/EditClientContact";
import DeleteClientContact from "../../../pages/ClientContacts/DeleteClientContact";
import CreateClientContact from "../../../pages/ClientContacts/CreateClientContact";
import CreateVendorContact from "../../../pages/VendorContacts/CreateVendorContact";
import EditVendorContact from "../../../pages/VendorContacts/EditVendorContact";
import CreateRemitAddressContainer from "../../../pages/RemitAddresses/CreateRemitAddress";
import EditRemitAddressContainer from "../../../pages/RemitAddresses/EditRemitAddress";
import DeleteRemitAddress from "../../../pages/RemitAddresses/DeleteRemitAddress";
import CreateEntityContainer from "../../../pages/Entities/CreateEntity";
import EditConsultingCompanyContact from "../../../pages/ConsultingCompanyContacts/EditConsultingCompanyContact";
import DeleteConsultingCompanyContact from "../../../pages/ConsultingCompanyContacts/DeleteConsultingCompanyContact";
import CreateConsultingCompanyContact from "../../../pages/ConsultingCompanyContacts/CreateConsultingCompanyContact";
import CreateConsultingCompany from "../../../pages/ConsultingCompanies/CreateConsultingCompany";
import EditConsultingCompany from "../../../pages/ConsultingCompanies/EditConsultingCompany";
import DeleteConsultingCompany from "../../../pages/ConsultingCompanies/DeleteConsultingCompany";
import EditSpecDetailDescription from "../../../pages/SpecDetail/EditSpecDetailDescription";
import EditSpecPricingContainer from "../../../pages/SpecDetail/EditSpecPricing";
import EditEntityContainer from "../../../pages/Entities/EditEntity/EditEntityContainer";
import COMLibrary from "../../../pages/ProjectDetail/COMLibrary";
import CreateEditContingency from "../../../pages/ProjectCreate/ContingenciesSection/CreateEditContingency";
import DeleteContingency from "../../../pages/ProjectCreate/ContingenciesSection/DeleteContingency";
import DeleteArea from "../../../pages/ProjectCreate/AreasSection/DeleteArea";
import CreateSpec from "../../../pages/Specs/CreateSpec";
import PARBasedQuantityCalculation from "../../../pages/SpecDetail/PARBasedQuantityCalculation";
import CaseBasedQuantityCalculation from "../../../pages/SpecDetail/CaseBasedQuantityCalculation";
import BulkEditSpecDetailsModal from "../../../pages/SpecDetail/BulkEditSpecDetailsModal";
import EditSpecDetailCOM from "../../../pages/SpecDetail/SpecDetailCOM/EditSpecDetailCOM";
import DeleteProperty from "../../../pages/Properties/DeleteProperty";
import DeleteSpecDetail from "../../../pages/SpecDetail/DeleteSpecDetail";
import DeleteVendorContact from "../../../pages/VendorContacts/DeleteVendorContact";
import DeleteSpecDetailCOM from "../../../pages/SpecDetail/DeleteSpecDetailCOM";
import DeleteEntity from "../../../pages/Entities/DeleteEntity";
import DeleteVendor from "../../../pages/Vendors/DeleteVendor";
import CreatePurchaseOrder from "../../../pages/Quoting/CreatePurchaseOrder";
import ReportsContainer from "../../../pages/Reports/ReportsContainer";
import CreateShipment from "../../../pages/ProjectDetail/Shipments/CreateShipment";
import BulkEditShipment from "../../../pages/ProjectDetail/Shipments/BulkEditShipment";
import EditShipment from "../../../pages/ProjectDetail/Shipments/EditShipment";
import DeleteClient from "../../../pages/Clients/DeleteClient";
import DeleteShipment from "../../../pages/ProjectDetail/Shipments/DeleteShipment";
import AddTrackingNote from "../../../pages/ProjectDetail/Shipments/AddTrackingNote";
import CreateFee from "../../../pages/ProjectDetail/Accounting/Fees/CreateFee";
import AddSpecFile from "../../../pages/SpecDetail/AdditionalInformation/SpecFiles/AddSpecFile";
import AddPOFile from "../../../pages/PurchaseOrderDetail/ShippingContactSection/PurchaseOrderFiles/AddPOFile";
import ApprovalManager from "../../../pages/PurchaseOrderDetail/HeaderSection/ApprovalManager";
import QuickEditSpec from "../../../pages/PurchaseOrderDetail/Specs/QuickEditSpec";
import CreateGLCode from "../../../pages/ClientDetail/GLCodes/CreateGLCode";
import EditGLCode from "../../../pages/ClientDetail/GLCodes/EditGLCode";
import DeleteGLCode from "../../../pages/ClientDetail/GLCodes/DeleteGLCode";
import ArchiveGLCode from "../../../pages/ClientDetail/GLCodes/ArchiveGLCode";
import BulkDeleteGLCode from "../../../pages/ClientDetail/GLCodes/BulkDeleteGLCode";
import ImportFromFile from "../../../pages/ClientDetail/GLCodes/ImportFromFile";
import Notes from "../../../pages/Notes";
import CreateUserRole from "../../../pages/Settings/CreateUserRole";
import EditUserRole from "../../../pages/Settings/EditUserRole";
import DeleteUserRole from "../../../pages/Settings/DeleteUserRole";
import CreateInvoice from "../../../pages/ProjectDetail/Accounting/Invoices/CreateInvoice";
import CreateEditAddress from "../../../pages/ProjectCreate/ContractSection/ProjectAddressGrid/CreateEditAddress";
import DeleteAddress from "../../../pages/ProjectCreate/ContractSection/ProjectAddressGrid/DeleteAddress";
import VoidFee from "../../../pages/ProjectDetail/Accounting/Fees/VoidFee";
import CreateFlag from "../../../pages/Settings/CreateFlag";
import EditFlag from "../../../pages/Settings/EditFlag";
import DeleteFlag from "../../../pages/Settings/DeleteFlag";
import CreateOffice from "../../../pages/Settings/CreateOffice";
import EditOffice from "../../../pages/Settings/EditOffice";
import DeleteOffice from "../../../pages/Settings/DeleteOffice";
import CreateBrand from "../../../pages/Settings/CreateBrand";
import EditBrand from "../../../pages/Settings/EditBrand";
import DeleteBrand from "../../../pages/Settings/DeleteBrand";
import EditProjectDateColumn from "../../../pages/Accounting/Projects/EditProjectDateColumn";
import EditBankAccount from "../../../pages/Accounting/Projects/EditBankAccount";
import CreateDiscount from "../../../pages/VendorDetail/Discounts/CreateDiscount";
import CreateVendorDiscountContainer from "../../../pages/VendorDetail/Discounts/CreateDiscount/CreateVendorDiscountContainer";
import EditDiscount from "../../../pages/VendorDetail/Discounts/EditDiscount";
import DeleteDiscount from "../../../pages/VendorDetail/Discounts/DeleteDiscount";
import ImportSpecs from "../../../pages/Specs/ImportSpecs";
import CopySpecs from "../../../pages/Specs/CopySpecs";
import MoveSpecs from "../../../pages/Specs/MoveSpecs";
import BulkDeleteSpecs from "../../../pages/Specs/BulkDeleteSpecs";
import DuplicateSpecs from "../../../pages/Specs/DuplicateSpecs";
import CreateEditRequirement from "../../../pages/SpecDetail/RequirementsSection/CreateEditRequirement";
import DeleteRequirement from "../../../pages/SpecDetail/RequirementsSection/DeleteRequirement";
import ReviewSubmittals from "../../../pages/SpecDetail/RequirementsSection/ReviewSubmittals";
import DeleteSpec from "../../../pages/SpecDetail/DeleteSpec";
import DuplicateSpec from "../../../pages/SpecDetail/DuplicateSpec";
import CreateTemplate from "../../../pages/Settings/Templates/CreateTemplate";
import StandardDelete from "../../../ui/StandardDelete";
import StandardBulkDeleteConfirmation from "../../../ui/StandardBulkDeleteConfirmation";
import RevisionActivities from "../../../pages/PurchaseOrderDetail/IssuedHistory/RevisionActivities";
import AddSpecToPO from "../../../pages/PurchaseOrderDetail/Specs/AddSpecToPO";
import CancelPO from "../../../pages/Quoting/PurchaseOrderList/CancelPO";
import CreateTerm from "../../../pages/Settings/Terms/GlobalTerms/CreateTerm";
import EditTerm from "../../../pages/Settings/Terms/GlobalTerms/EditTerm";
import SubmittalsManager from "../../../pages/SubmittalsManager";
import CreateFundingType from "../../../pages/ProjectDetail/Accounting/Funding/CreateFundingType";
import CreateFunding from "../../../pages/ProjectDetail/Accounting/Funding/CreateFunding";
import CreateBatchFunding from "../../../pages/ProjectDetail/Accounting/Funding/CreateBatchFunding";
import CreateFundingForm from "../../../pages/ProjectDetail/Accounting/Funding/CreateFunding/FundingForm";
import FundingPaidConfirmation from "../../../pages/ProjectDetail/Accounting/Funding/FundingPaidConfirmation";
import ManageBidGroups from "../../../pages/Quoting/BidGroupList/ManageBidGroups";
import CreateBidGroup from "../../../pages/Quoting/BidGroupList/CreateBidGroup";
import DeleteBidGroupVendor from "../../../pages/BidGroupDetail/DeleteBidGroupVendor";
import AddVendor from "../../../pages/BidGroupDetail/AddVendor";
import AddVendorSpec from "../../../pages/BidGroupDetail/AddVendorSpec";
import AddressBookCreateContactContainer from "../../../pages/AddressBook/CreateContactContainer";
import AddressBookDeleteContactContainer from "../../../pages/AddressBook/DeleteAddressBookContactContainer";
import CreateShipToSiteContainer from "../../../pages/VendorDetail/ShipToSites/CreateShipToSiteContainer";
import DeleteShipToSiteContainer from "../../../pages/VendorDetail/ShipToSites/DeleteShipToSiteContainer";
import ShipToSitesCreateContacts from "../../../pages/VendorDetail/ShipToSites/CreateContacts";
import SelectShipToSite from "../../../pages/PurchaseOrderDetail/ShippingContactSection/ShipToSite/SelectShipToSite";
import VendorCommentsContainer from "../../../pages/VendorContacts/VendorComments";
import MenuModal from "../../../menus/MenuModal";
import EditPreview from "../../../pages/SpecDetail/EditPreview";
import CreateBidGroupPurchaseOrderContainer from "../../../pages/BidGroupDetail/Quotes/PurchaseOrders/CreateBidGroupPurchaseOrderContainer";
import QuoteEdit from "../../../pages/BidGroupDetail/Quotes/BidGroupTable/QuoteEdit";
import CreateAlternateQuoteContainer from "../../../pages/BidGroupDetail/Quotes/AlternateQuotes/CreateAlternateQuoteContainer";
import QuotesNotesContainer from "../../../pages/BidGroupDetail/Quotes/Notes/QuoteNotesContainer";
import CreateDiscountType from "../../../pages/VendorDetail/Discounts/CreateDiscount/CreateDiscountType";
import TransferProjectContainer from "../../../pages/Projects/TransferProject/TransferProjectContainer";
import ProjectNoteModal from "../../../pages/ProjectDetail/Overview/ProjectNoteSection/ProjectNoteModal";
import InactivityDialog from "../InactivityDialog";
import VendorEmailPreview from "../../../pages/BidGroupDetail/Vendors/VendorEmailPreview";
import EditDateModal from "../../../pages/BidGroupDetail/EditDateModal";
import SelectVendorContactContainer from "../../../pages/BidGroupDetail/SelectVendorContacts/SelectVendorContactContainer";
import AccountSettings from "../../../layout/AppLayout/AccountSettings";
import AwardSpecContainer from "../../../pages/BidGroupDetail/Quotes/AwardSpec/AwardSpecContainer";
import UnawardSpecContainer from "../../../pages/BidGroupDetail/Quotes/AwardSpec/UnawardSpecContainer";
import AccountPictureSettings from "../../../layout/AppLayout/AccountPictureSettings";
import ImportOptions from "../../../pages/Specs/ImportSpecs/ImportOptions";
import DuplicateProject from "../../../pages/Projects/DuplicateProject";
import DeleteContract from "../../../pages/ProjectCreate/ContractSection/ContractFilesGrid/DeleteContract";
import FlagUnflagContract from "../../../pages/ProjectCreate/ContractSection/ContractFilesGrid/FlagUnflagContract";
import QuotingFilter from "../../../pages/Quoting/QuotingFilter";
import BulkQuoteUpload from "../../../pages/Quoting/BulkQuoteUpload";
import IncompleteSpec from "../../../pages/SpecDetail/IncompleteSpec";
import IncompleteSpecs from "../../../pages/Specs/IncompleteSpecs";
import CompleteSpec from "../../../pages/SpecDetail/CompleteSpec";
import CompleteSpecs from "../../../pages/Specs/CompleteSpecs";
import AnalysisAlternateQuotesModal from "../../../pages/BidGroupDetail/Analysis/AlternateQuotesModal/AnalysisAlternateQuotesModal";
import ManageShipmentsModal from "../../../pages/ProjectDetail/Shipments/ManageShipments/ManageShipmentsModal";
import CreatePaymentTerm from "../../../pages/Settings/PaymentTerms/CreatePaymentTerm";
import EditPaymentTerm from "../../../pages/Settings/PaymentTerms/EditPaymentTerm";
import DeletePaymentTerm from "../../../pages/Settings/PaymentTerms/DeletePaymentTerm";
import CreateTeam from "../../../pages/Settings/Teams/CreateTeam";
import EditTeam from "../../../pages/Settings/Teams/EditTeam";
import EditMultipleShipmentsModal from "../../../pages/ProjectDetail/Shipments/EditMultipleShipments/EditMultipleShipmentsModal";
import VoidInvoice from "../../../pages/ProjectDetail/Accounting/Invoices/VoidInvoice";
import DeleteInvoice from "../../../pages/ProjectDetail/Accounting/Invoices/DeleteInvoice";
import EditMultipleShipmentsAllModal from "../../../pages/ProjectDetail/Shipments/EditMultipleShipments/EditMultipleShipmentsAllModal";
import EditMultipleSpecsModal from "../../../pages/PurchaseOrderDetail/Specs/EditMultipleSpecsModal/EditMultipleSpecsModal";
import DirtyFormDialog from "../../../forms/DirtyFormDialog";
import SetStatusComment from "../../../../components/pages/ProjectDetail/Shipments/SetStatusComment";
import SetStatusCommentAll from "../../../../components/pages/ProjectDetail/Shipments/SetStatusCommentAll";
import GeneralConfirmation from "../../../ui/GeneralConfirmation";
import CreateReportTemplate from "../../../pages/Settings/ReportTemplates/CreateReportTemplate";
import GenerateCustomReport from "../../../pages/Settings/ReportTemplates/GenerateCustomReport";
import CreateRequirementType from "../../../pages/Settings/RequirementType/CreateRequirementType";
import EditRequirementType from "../../../pages/Settings/RequirementType/EditRequirementType";
import DirtyAutoSaveFormDialog from "../../../forms/DirtyAutoSaveFormPrompt/DirtyAutoSaveFormDialog";
import BillFee from "../../../../components/pages/Billing/Available/BillFee";
import FeePayments from "../../../../components/pages/Billing/Received/ProjectFees/FeePayments";
import CreateUnitOfMeasure from "../../../pages/Settings/UnitOfMeasure/CreateUnitOfMeasure";
import EditUnitOfMeasure from "../../../pages/Settings/UnitOfMeasure/EditUnitOfMeasure";
import ImportShippingPayments from "../../../pages/Billing/Shipping/ImportShippingPayments";
import BulkUpdateTerms from "../../../pages/Settings/Terms/GlobalTerms/BulkUpdateTerms";
import SendRFQ from "../../../pages/BidGroupDetail/Vendors/SendRFQ";
import DownloadQuoteFiles from "../../../pages/BidGroupDetail/Quotes/BidGroupTable/DownloadQuoteFiles";
import SaveQueryReport from "../../../pages/Settings/QueryReports/SaveQueryReport";
import ImportSpecsPDF from "../../../pages/Specs/ImportSpecsPDF";
import CancelIssueDialog from "../../../pages/PurchaseOrderDetail/HeaderSection/CancelIssueDialog";
import EditFeeSchedule from "../../../pages/ProjectCreate/ContractSection/FeeScheduleAction/EditFeeSchedule";
import UploadFiles from "../../../pages/SpecDetail/RequirementsSection/UploadFiles";
import CancelChangeOrder from "../../../pages/PurchaseOrderDetail/HeaderSection/CancelChangeOrder";
import MarkBilledModal from "../../../pages/Billing/Shipping/ShippingPayments/MarkBilledModal";
import { DesignerConfirmationModal } from "../../../pages/ProjectCreate/TeamSection/DesignerConfirmationModal/DesignerConfirmationModal";
import ExportShippingPaymentsModal from "../../../pages/Billing/Shipping/ExportShippingPaymentsModal";
import ReloadModal from "../../../ui/ReloadModal";
import BulkDeletePOSpecs from "../../../pages/PurchaseOrderDetail/Specs/BulkDeletePOSpecs";
import SetForecastComment from "../../../pages/Specs/SetForecastComment";
import SetGLCodes from "../../../pages/Specs/SetGLCodes";
import UpdateFunding from "../../../pages/ProjectDetail/Accounting/Funding/UpdateFunding";
import CreateProjectService from "../../../pages/Settings/ProjectService/CreateProjectService";
import EditProjectService from "../../../pages/Settings/ProjectService/EditProjectService";
import CreateProjectType from "../../../pages/Settings/ProjectType/CreateProjectType";
import EditProjectType from "../../../pages/Settings/ProjectType/EditProjectType";
import CreateVendorProduct from "../../../pages/Settings/VendorProducts/CreateVendorProduct";
import EditVendorProduct from "../../../pages/Settings/VendorProducts/EditVendorProduct";
import CreateVendorCertification from "../../../pages/Settings/VendorCertifications/CreateVendorCertification";
import EditVendorCertification from "../../../pages/Settings/VendorCertifications/EditVendorCertification";
import EditScope from "../../../pages/Settings/Administrative/Scopes/EditScope";
import DownloadPurchaseOrderPDF from "../../../pages/PurchaseOrderDetail/DownloadPurchaseOrderPDF";
import FlagAllowanceSpecs from "../../../pages/Specs/FlagAllowanceSpecs";
import FlagAllowanceSpec from "../../../pages/Specs/FlagAllowanceSpec";
import CreateAreaType from "../../../pages/Settings/ProjectDetail/AreaType/CreateAreaType";
import EditAreaType from "../../../pages/Settings/ProjectDetail/AreaType/EditAreaType";
import { AffectedRecordsDialog } from "../../../DuplicatePersonDialog/AffectedRecordsDialog";

export const BaseOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
`;

const DefaultOverlay = styled(BaseOverlay)`
  align-items: start;
  padding-top: calc(8vh);
`;

export const WideOverlay = styled(BaseOverlay)`
  align-items: center;
`;

export const Wrapper = styled.div`
  display: inline-block;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.18);
`;

export const BaseContent = styled.div`
  background-color: rgb(255, 255, 255);
`;

export const DefaultContent = styled(BaseContent)`
  width: ${({ width }) => (width ? width : "560px")};
  overflow-y: ${({ scrollDisabled }) => (scrollDisabled ? "none" : "auto")};
  max-height: 75vh;
  @media (max-width: 768px) {
    width: calc(100vw - 48px) !important;
  }
  @media (max-width: 600px) {
    width: calc(100vw) !important;
  }
  @media (max-width: 190px) {
    width: calc(100vw - 5px) !important;
  }
`;

export const WideContent = styled(BaseContent)`
  width: calc(100vw - 48px);
  height: ${({ height }) => (height ? height : "calc(100vh - 48px)")};
  @media (max-width: 768px) {
    width: calc(100vw - 48px) !important;
  }
  @media (max-width: 600px) {
    width: calc(100vw) !important;
  }
  @media (max-width: 1024px) {
    overflow: auto;
  }
`;

const elementTypes = {
  BulkQuoteUpload,
  CreateUserContainer,
  EditClientContact,
  CreateClientContact,
  EditConsultingCompanyContact,
  DeleteConsultingCompanyContact,
  CreateConsultingCompanyContact,
  CreateVendorContact,
  EditVendorContact,
  CreateRemitAddressContainer,
  EditRemitAddressContainer,
  CreateConsultingCompany,
  EditConsultingCompany,
  DeleteConsultingCompany,
  DeleteRemitAddress,
  CreateEntityContainer,
  EditSpecDetailDescription,
  CreateRemitAddressLocal,
  CreateClientContactLocal,
  CreateVendorContactLocal,
  ReportsContainer,
  PARBasedQuantityCalculation,
  CaseBasedQuantityCalculation,
  EditSpecPricingContainer,
  CreatePurchaseOrder,
  CreateAreaLocal,
  MenuModal,
  COMLibrary,
  CreateSpec,
  EditShipment,
  DeleteShipment,
  CreateShipment,
  BulkEditShipment,
  DeleteProperty,
  DeleteSpecDetail,
  DeleteVendorContact,
  DeleteClientContact,
  UserSettings,
  DeleteEntity,
  DeleteVendor,
  EditEntityContainer,
  DeleteSpecDetailCOM,
  DeleteClient,
  EditSpecDetailCOM,
  Notes,
  AddTrackingNote,
  AddSpecFile,
  AddPOFile,
  CreateEditContingency,
  DeleteContingency,
  DeleteArea,
  ApprovalManager,
  CreateGLCode,
  EditGLCode,
  DeleteGLCode,
  ArchiveGLCode,
  BulkDeleteGLCode,
  ImportFromFile,
  CreateFee,
  CreateUserRole,
  EditUserRole,
  DeleteUserRole,
  CreateInvoice,
  CreateEditAddress,
  DeleteAddress,
  VoidFee,
  CreateFlag,
  EditFlag,
  DeleteFlag,
  CreateBrand,
  EditBrand,
  DeleteBrand,
  EditProjectDateColumn,
  EditBankAccount,
  CreateDiscount,
  EditDiscount,
  DeleteDiscount,
  ImportSpecs,
  BulkDeleteSpecs,
  DuplicateSpecs,
  CreateEditRequirement,
  DeleteRequirement,
  ReviewSubmittals,
  CopySpecs,
  MoveSpecs,
  DeleteSpec,
  DuplicateSpec,
  StandardDelete,
  CreateTemplate,
  StandardBulkDeleteConfirmation,
  QuickEditSpec,
  RevisionActivities,
  CancelPO,
  CreateTerm,
  EditTerm,
  EditOffice,
  CreateOffice,
  DeleteOffice,
  SubmittalsManager,
  CreateFundingType,
  CreateFunding,
  FundingPaidConfirmation,
  CreateFundingForm,
  ManageBidGroups,
  CreateBidGroup,
  CreateBatchFunding,
  DeleteBidGroupVendor,
  AddVendor,
  AddVendorSpec,
  AddressBookCreateContactContainer,
  AddressBookDeleteContactContainer,
  CreateShipToSiteContainer,
  DeleteShipToSiteContainer,
  VendorCommentsContainer,
  BulkEditSpecDetailsModal,
  EditPreview,
  SelectShipToSite,
  QuoteEdit,
  ShipToSitesCreateContacts,
  CreateBidGroupPurchaseOrderContainer,
  CreateDiscountType,
  CreateAlternateQuoteContainer,
  QuotesNotesContainer,
  CreateVendorDiscountContainer,
  TransferProjectContainer,
  InactivityDialog,
  ProjectNoteModal,
  VendorEmailPreview,
  SelectVendorContactContainer,
  AccountSettings,
  AwardSpecContainer,
  UnawardSpecContainer,
  AccountPictureSettings,
  ImportOptions,
  EditDateModal,
  DuplicateProject,
  DeleteContract,
  FlagUnflagContract,
  QuotingFilter,
  AddSpecToPO,
  IncompleteSpec,
  IncompleteSpecs,
  CompleteSpec,
  CompleteSpecs,
  AnalysisAlternateQuotesModal,
  ManageShipmentsModal,
  CreatePaymentTerm,
  EditPaymentTerm,
  DeletePaymentTerm,
  CreateTeam,
  EditTeam,
  EditMultipleShipmentsModal,
  VoidInvoice,
  DeleteInvoice,
  EditMultipleShipmentsAllModal,
  EditMultipleSpecsModal,
  DirtyFormDialog,
  SetStatusComment,
  SetStatusCommentAll,
  GeneralConfirmation,
  CreateReportTemplate,
  GenerateCustomReport,
  CreateRequirementType,
  EditRequirementType,
  DirtyAutoSaveFormDialog,
  BillFee,
  FeePayments,
  CreateUnitOfMeasure,
  EditUnitOfMeasure,
  ImportShippingPayments,
  BulkUpdateTerms,
  SendRFQ,
  DownloadQuoteFiles,
  SaveQueryReport,
  ImportSpecsPDF,
  CancelIssueDialog,
  EditFeeSchedule,
  UploadFiles,
  CancelChangeOrder,
  MarkBilledModal,
  DesignerConfirmationModal,
  ExportShippingPaymentsModal,
  ReloadModal,
  BulkDeletePOSpecs,
  SetForecastComment,
  SetGLCodes,
  UpdateFunding,
  CreateProjectService,
  EditProjectService,
  CreateProjectType,
  EditProjectType,
  CreateVendorProduct,
  EditVendorProduct,
  CreateVendorCertification,
  EditVendorCertification,
  EditScope,
  DownloadPurchaseOrderPDF,
  FlagAllowanceSpecs,
  FlagAllowanceSpec,
  CreateAreaType,
  EditAreaType,
  AffectedRecordsDialog,
};

const defaultComponents = {
  default: { Overlay: DefaultOverlay, Content: DefaultContent },
  wide: { Overlay: WideOverlay, Content: WideContent },
};

export class ModalDialog extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen && prevProps.isOpen) {
      this.props.setModalState({});
    }
  }

  render() {
    if (!this.props.isOpen) return null;
    const {
      scrollDisabled,
      tabs,
      title,
      subtitle,
      secondSubtitle,
      isShowCloseIcon,
      bodyType,
      onTabChange,
      layoutType,
      elementTypeID,
      elementProperties,
      closeModalDialog,
      components,
      width,
      height,
    } = this.props;
    const BodyDynamicComponent = elementTypes[elementTypeID];
    const { Overlay, Content } = {
      ...defaultComponents[layoutType],
      ...components,
    };

    const titleProps = {
      title,
      subtitle,
      secondSubtitle,
      isShowCloseIcon,
      closeModalDialog,
    };

    const isDraggable = elementTypeID === "CreateUnitOfMeasure";

    return (
      <Overlay>
        <Draggable handle={".draggableHandle"} disabled={!isDraggable}>
          <Wrapper>
            <ModalTitle
              {...titleProps}
              width={width}
              isDraggable={isDraggable}
            />
            <ModalTabs
              tabs={tabs}
              onTabChange={onTabChange}
              elementTypeID={elementTypeID}
            />
            <Content
              containerType={bodyType}
              scrollDisabled={scrollDisabled}
              width={width}
              height={height}
            >
              {elementTypes[elementTypeID] && (
                <BodyDynamicComponent
                  {...elementProperties}
                  closeModalDialog={closeModalDialog}
                />
              )}
            </Content>
          </Wrapper>
        </Draggable>
      </Overlay>
    );
  }
}

ModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isShowCloseIcon: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.oneOf([false]),
    PropTypes.string,
    PropTypes.array,
  ]),
  subtitle: PropTypes.string,
  secondSubtitle: PropTypes.string,
  closeModalDialog: PropTypes.func,
  onTabChange: PropTypes.func,
  elementTypeID: PropTypes.string,
  elementProperties: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object),
  layoutType: PropTypes.oneOf(Object.keys(defaultComponents)),
  components: PropTypes.shape({
    Overlay: propTypes.component,
    Content: propTypes.component,
  }),
};

ModalDialog.defaultProps = {
  layoutType: "default",
  components: {},
};

export default withModalState(withRouter(withPagination(ModalDialog)));
