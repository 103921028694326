import { inputs } from "../../../inputs/inputConfigs";

const path = "contact";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.firstName,
            required: true,
            name: "contact.person.firstName",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.lastName,
            required: true,
            name: "contact.person.lastName",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.phone,
            label: "Phone Number",
            required: true,
            name: "contact.person.phoneNumber",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Fax",
            name: "contact.person.faxNumber",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Cell Number",
            name: "contact.person.cellNumber",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Work Number",
            name: "contact.person.workNumber",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.contactRole,
            required: true,
            name: `${path}.${inputs.contactRole.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.email,
            required: true,
            name: "contact.person.email",
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.canAccessClientPortal,
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.status,
            name: `${path}.${inputs.status.name}`,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
};
