import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { EditAreaType } from "./EditAreaType";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../../utils/formValidationUtils";
import propTypes from "../../../../../../constants/propTypes";
import { areaTypeDataComponentId } from "../AreaTypeContainer";

const EditAreaTypeContainer = ({
  areaType,
  dataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Area Type saved successfully.");
      setReload(areaTypeDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleEditAreaType = values => {
    performUpdateRequest(areaTypeDataComponentId, areaType.id, values);
  };

  return (
    <EditAreaType
      areaType={areaType}
      handleEditAreaType={handleEditAreaType}
      closeModalDialog={closeModalDialog}
    />
  );
};

EditAreaTypeContainer.propTypes = {
  areaType: propTypes.areaType.isRequired,
  dataComponent: propTypes.dataComponent,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(areaTypeDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAreaTypeContainer);
