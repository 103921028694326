import _get from "lodash/get";
export const SPEC_TYPE = "SPEC";
export const COM_TYPE = "COM";

const defaulParser = value => value / 100 || 0;

/* eslint-disable */
const createRow = ({
  bidGroupVendorSpec,
  spec,
  quote,
  quoteItem,
  allInclusive,
  sampleQuantity,
  productionQuantity,
  rowType,
  hasAlternate,
  parentQuoteItem,
  valueParser = defaulParser,
}) => {
  return {
    id: quoteItem.id,
    quoteId: quote.id,
    quote: quote,
    bidGroupVendorSpecId: bidGroupVendorSpec.id,
    bidGroupVendorSpecQuoteId: quote.id,
    sequenceIndex: quote.sequenceIndex,
    specId: spec.id,
    spec: spec,
    type: rowType,
    allInclusive: allInclusive,
    sampleQuantity: sampleQuantity,
    samplePricing: quoteItem.samplePricing / 100,
    samplePricing: valueParser(quoteItem.samplePricing),
    extendedSamplePricing: valueParser(quoteItem.extendedSamplePricing),
    extendedSamplePricing: valueParser(quoteItem.extendedSamplePricing),
    productionQuantity: productionQuantity,
    productionPricing: valueParser(quoteItem.productionPricing),
    productionPricing: valueParser(quoteItem.productionPricing),
    extendedProductionPricing:
    valueParser(productionQuantity * quoteItem.productionPricing),
    extendedProductionPricing:
    valueParser(productionQuantity * quoteItem.productionPricing),
    notes: quote.notes,
    parentSpecId: bidGroupVendorSpec.spec.id,
    parentSpec: bidGroupVendorSpec.spec,
    hasAlternate: hasAlternate,
    overagePercent: quoteItem.overagePercent,
    awardedSamplePricing: spec.awardedSamplePricing / 100,
    awardedProductionPricing: spec.awardedProductionPricing / 100,
    awardedSampleQuantity: spec.awardedSampleQuantity,
    awardedProductionQuantity: spec.awardedProductionQuantity,
    awardedVendorName: spec.awardedVendorName,
    parentQuoteItem: parentQuoteItem,
  };
};
/* eslint-enable */

const findQuoteItem = (quoteItems, spec) => {
  return (
    quoteItems.find(quoteItem => {
      const { specId = {} } = quoteItem;

      return specId.toString() === spec.id;
    }) || {}
  );
};

export const spreadBidGroupSpecsWithComs = bidGroupSpecs => {
  const rows = [];

  bidGroupSpecs.sort((row1, row2) => {
    return row1.customNumber.localeCompare(row2.customNumber, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
  bidGroupSpecs.map(spec => {
    const { specDetails = [] } = spec;

    const parentItem = {
      id: spec.id,
      customNumber: spec.customNumber,
      description: spec.description,
      specCategory: _get(spec, "specCategory.name", ""),
      unitOfMeasure: _get(spec, "unitOfMeasure.name", ""),
      type: SPEC_TYPE,
      sampleQuantity: spec.sampleQuantity,
      totalQuantity: spec.totalQuantityWithoutOverage,
      vendorName: _get(spec, "vendor.name", "-"),
      isTaxExempt: _get(spec, "isTaxExempt"),
    };

    let lastCOM = null;
    rows.push(parentItem);

    specDetails.map(specDetail => {
      const { specDetailComs = [] } = specDetail;

      specDetailComs.sort((row1, row2) => {
        return row1.spec.customNumber.localeCompare(
          row2.spec.customNumber,
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      });
      specDetailComs.map(specDetailCom => {
        const com = {
          id: specDetailCom.spec.id,
          customNumber: specDetailCom.spec.customNumber,
          description: specDetailCom.spec.description,
          specCategory: specDetailCom.spec.specCategory?.name,
          unitOfMeasure: specDetailCom.spec.unitOfMeasure?.name,
          type: COM_TYPE,
          sampleQuantity: undefined,
          totalQuantity: specDetailCom.spec.totalQuantityWithoutOverage,
          vendorName: _get(specDetailCom.spec, "vendor.name", "-"),
          isTaxExempt: _get(specDetailCom, "spec.isTaxExempt"),
        };

        lastCOM = com;
        rows.push(com);
      });
    });

    if (lastCOM) {
      parentItem.isParent = true;
      lastCOM.isLastCOM = true;
    }
  });

  return rows;
};
export const spreadBidGroupVendorSpecsWithComs = (
  bidGroupVendorSpecs,
  valueParser
) => {
  const rows = [];

  bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    const { quotes = [] } = bidGroupVendorSpec;

    const bidGroupVendorSpecQuotes = quotes.filter(
      quote => quote.bidGroupVendorSpecId === bidGroupVendorSpec.id
    );

    const hasAlternate = quotes.length > 1;

    bidGroupVendorSpecQuotes.map(quote => {
      const { quoteItems = [] } = quote;

      const { specDetails = [] } = bidGroupVendorSpec.spec;

      const relatedCOMS = [];
      specDetails.map(specDetail => {
        const { specDetailComs = [] } = specDetail;

        specDetailComs.map(specDetailCom => {
          const quoteItem = findQuoteItem(quoteItems, specDetailCom.spec);

          const parentQuoteItem = findQuoteItem(
            quoteItems,
            bidGroupVendorSpec.spec
          );

          const com = createRow({
            bidGroupVendorSpec,
            spec: specDetailCom.spec,
            quote,
            quoteItem,
            allInclusive: false,
            sampleQuantity: quoteItem.sampleQuantity,
            productionQuantity: quoteItem.productionQuantity,
            rowType: COM_TYPE,
            parentQuoteItem,
            valueParser,
          });

          relatedCOMS.push(com);
        });
      });
      const quoteItem = findQuoteItem(quoteItems, bidGroupVendorSpec.spec);

      const row = createRow({
        bidGroupVendorSpec,
        spec: bidGroupVendorSpec.spec,
        quote,
        quoteItem,
        allInclusive: false,
        sampleQuantity: quoteItem.sampleQuantity,
        productionQuantity: quoteItem.productionQuantity,
        rowType: SPEC_TYPE,
        hasAlternate,
        valueParser,
      });
      row.rowIndex = rows.length;
      row.coms = relatedCOMS;

      rows.push(row);
      let lastCOM = null;

      specDetails.map(specDetail => {
        const { specDetailComs = [] } = specDetail;

        specDetailComs.sort((row1, row2) => {
          return row1.spec.customNumber.localeCompare(
            row2.spec.customNumber,
            undefined,
            {
              numeric: true,
              sensitivity: "base",
            }
          );
        });
        specDetailComs.map(specDetailCom => {
          const quoteItem = findQuoteItem(quoteItems, specDetailCom.spec);
          const parentQuoteItem = findQuoteItem(
            quoteItems,
            bidGroupVendorSpec.spec
          );

          const row = createRow({
            bidGroupVendorSpec,
            spec: specDetailCom.spec,
            quote,
            quoteItem,
            allInclusive: false,
            sampleQuantity: quoteItem.sampleQuantity,
            productionQuantity: quoteItem.productionQuantity,
            rowType: COM_TYPE,
            parentQuoteItem,
            valueParser,
          });
          row.rowIndex = rows.length;

          lastCOM = row;
          rows.push(row);
        });
      });

      if (lastCOM) {
        row.isParent = true;
        lastCOM.isLastCOM = true;
      }
    });
  });

  return rows;
};

export const filterAnalysisRows = rows => {
  const comSpecIds = rows.reduce((previous, current) => {
    if (current.type === COM_TYPE) previous.add(current.specId);
    return previous;
  }, new Set());
  const filteredRows = rows.filter(
    expandedBidGroupVendorSpecs =>
      !comSpecIds.has(expandedBidGroupVendorSpecs.specId) ||
      expandedBidGroupVendorSpecs.type === COM_TYPE
  );

  return filteredRows;
};
