import PropTypes from "prop-types";

const component = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ render: PropTypes.func.isRequired }),
  PropTypes.any,
]);

const file = PropTypes.shape({
  id: PropTypes.string,
  s3Key: PropTypes.string,
  filename: PropTypes.string,
});

const clientContact = PropTypes.shape({
  clientId: PropTypes.string,
  contactId: PropTypes.string,
  canAccessClientPortal: PropTypes.bool,
});

const contact = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
});

const projectContact = PropTypes.shape({
  id: PropTypes.string,
  projectId: PropTypes.string,
  memberType: PropTypes.string,
  contactId: PropTypes.string,
  contact,
});

const consultingCompany = PropTypes.shape({
  name: PropTypes.string,
  postalCode: PropTypes.string,
});

const consultingCompanyContact = PropTypes.shape({
  contactId: PropTypes.string,
  consultingCompanyId: PropTypes.string,
});

const report = PropTypes.shape({
  name: PropTypes.string,
  hasCSV: PropTypes.bool,
  hasPDF: PropTypes.bool,
  hasXLS: PropTypes.bool,
  supportsDateRange: PropTypes.bool,
});

const reportCategory = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
});

const client = PropTypes.shape({
  name: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  postalCode: PropTypes.string,
  notes: PropTypes.string,
  status: PropTypes.string,
  federalId: PropTypes.string,
  website: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  clientContacts: PropTypes.arrayOf(clientContact),
});

const vendorContact = PropTypes.shape({
  id: PropTypes.string,
  canAccessVendorPortal: PropTypes.bool,
  vendorId: PropTypes.string,
  contactId: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  contactStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
});

const vendor = PropTypes.shape({
  address: PropTypes.string,
  address2: PropTypes.string,
  categoryId: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  createdAt: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  postalCode: PropTypes.string,
  requiresApproval: PropTypes.bool,
  state: PropTypes.string,
  status: PropTypes.array,
  updatedAt: PropTypes.string,
  website: PropTypes.string,
});

const unitOfMeasure = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const projectService = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const projectType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const areaType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  isMultiple: PropTypes.bool,
});

const product = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const certification = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const spec = PropTypes.shape({
  id: PropTypes.string,
  customNumber: PropTypes.string,
  description: PropTypes.string,
  unitOfMeasure,
  baseQuantity: PropTypes.number,
  allowanceCents: PropTypes.number,
  clientBudgetCents: PropTypes.number,
  extPrice: PropTypes.number,
});

const relatedSpec = PropTypes.shape({
  customNumber: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  area: PropTypes.string,
  projectId: PropTypes.string,
});

const user = PropTypes.shape({
  createdAt: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  officeId: PropTypes.string,
  password: PropTypes.string,
  phoneNumber: PropTypes.string,
  teamId: PropTypes.string,
  updatedAt: PropTypes.string,
  userRoleId: PropTypes.string,
});

const specDetail = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  preview: file,
});

const specDetailCom = PropTypes.shape({
  specDetailId: PropTypes.string,
  specId: PropTypes.string,
  notes: PropTypes.string,
});

const purchaseOrder = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  estimatedDeliveryDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  shipmentsCount: PropTypes.number,
  shipmentsWithoutDelivery: PropTypes.number,
  estimatedShipDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  shipmentsNeedsFollowUp: PropTypes.number,
  vendor: vendor,
});

const bidGroup = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const bidGroupVendor = PropTypes.shape({
  vendorId: PropTypes.string,
  bidGroupId: PropTypes.string,
});

const bidGroupVendorSpec = PropTypes.shape({
  vendorId: PropTypes.string,
  specId: PropTypes.string,
});

const approvalFile = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.date,
});

const purchaseOrderContact = PropTypes.shape({ id: PropTypes.string });

const navigationTabs = PropTypes.arrayOf(
  PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    exact: PropTypes.bool,
    component: component,
    icon: PropTypes.PropTypes.node,
  })
);

const requestState = PropTypes.shape({
  list: PropTypes.shape({
    totalRows: PropTypes.number,
    totalPages: PropTypes.number,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        columnName: PropTypes.string,
        direction: PropTypes.string,
      })
    ),
    selection: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    rootFilters: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.object,
    rowIndex: PropTypes.arrayOf(PropTypes.string),
  }),
  update: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.object,
    rowIndex: PropTypes.arrayOf(PropTypes.string),
  }),
});

const dataComponent = PropTypes.shape({
  dataComponentId: PropTypes.string.isRequired,
  includes: PropTypes.arrayOf(PropTypes.string),
  apiRoute: PropTypes.string,
  requestState,
});

const filteringComponent = {
  onFiltersChange: PropTypes.func,
  columnOptions: PropTypes.shape({}),
  displayFilters: PropTypes.bool,
  operationBuilders: PropTypes.objectOf(PropTypes.func.isRequired),
};

const areaRoom = PropTypes.shape({
  areaRoomId: PropTypes.string,
  name: PropTypes.string,
  roomCount: PropTypes.number,
  perRoom: PropTypes.number,
  par: PropTypes.number,
});

const area = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,
});

const team = PropTypes.shape({
  name: PropTypes.string,
  users: PropTypes.arrayOf(user),
});

const note = PropTypes.shape({
  id: PropTypes.string,
  authorId: PropTypes.string,
  projectId: PropTypes.string,
  callWith: PropTypes.string,
  carrierName: PropTypes.string,
  detail: PropTypes.string,
  emailSentBy: PropTypes.string,
  emailSentTo: PropTypes.string,
  category: PropTypes.string,
  trackingNumber: PropTypes.string,
  subject: PropTypes.string,
  trackingType: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

const errorPayload = PropTypes.shape({
  data: PropTypes.shape({
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        source: PropTypes.string,
      })
    ),
  }),
});

const tableComponents = PropTypes.shape({
  data: PropTypes.shape({
    CellComponent: PropTypes.func,
    DetailRowComponent: PropTypes.func,
    HeaderCellComponent: PropTypes.func,
    HeaderRowComponent: PropTypes.func,
    RowComponent: PropTypes.func,
    SelectionCell: PropTypes.func,
    SelectionHeader: PropTypes.func,
    TableComponent: PropTypes.func,
  }),
});

const gridConfig = PropTypes.shape({
  dataComponentId: PropTypes.string,
  error: PropTypes.object,
  filters: PropTypes.object,
  includes: PropTypes.array,
  loading: PropTypes.bool,
  model: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  requestState: PropTypes.object,
  rootFilters: PropTypes.object,
  rowIndex: PropTypes.array,
  selectedIds: PropTypes.array,
  selection: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  sort: PropTypes.array,
  success: PropTypes.bool,
  totalPages: PropTypes.number,
  totalRows: PropTypes.number,
});

const property = PropTypes.shape({
  clientId: PropTypes.string,
  createdAt: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  updatedAt: PropTypes.string,
});

const entity = PropTypes.shape({
  clientId: PropTypes.string,
  createdAt: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  federalId: PropTypes.string,
  updatedAt: PropTypes.string,
});

const project = PropTypes.shape({
  id: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string,
  status: PropTypes.string,
  propertyId: PropTypes.string,
  clientId: PropTypes.string,
  entityId: PropTypes.string,
  officeId: PropTypes.string,
  teamId: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  type: PropTypes.string,
  fundingType: PropTypes.string,
  fundingDue: PropTypes.string,
  freightVendorId: PropTypes.string,
  projectLeadId: PropTypes.string,
});

const vendorCategory = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const shipment = PropTypes.shape({
  id: PropTypes.string,
  specId: PropTypes.string,
  quantity: PropTypes.number,
  trackingNumber: PropTypes.string,
  receivedBy: PropTypes.string,
  estimatedShipDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  estimatedDeliveryDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  actualDeliveryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actualShipDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  notes: PropTypes.string,
});

const location = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string,
  lat: PropTypes.number,
  long: PropTypes.number,
});

const tag = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const flag = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const office = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const brand = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const news = PropTypes.shape({
  id: PropTypes.string,
  headline: PropTypes.string,
  content: PropTypes.string,
});

const contingency = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number,
});

const requirement = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const modalDialog = PropTypes.shape({
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
  ]),
  elementTypeID: PropTypes.string,
  elementProperties: PropTypes.object,
  isShowCancelButton: PropTypes.bool,
  freezeBackground: PropTypes.bool,
});

const formik = PropTypes.shape({
  values: PropTypes.shape({}),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
});

const notesContext = PropTypes.shape({
  clientId: PropTypes.string,
  projectId: PropTypes.string,
});

const notesFilters = PropTypes.shape({
  category: PropTypes.string,
  search: PropTypes.string,
  checks: PropTypes.shape({
    myNotes: PropTypes.bool,
    myMentions: PropTypes.bool,
  }),
  timeBoundaries: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
});

const breadcrumbs = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    clickParams: PropTypes.shape({
      context: PropTypes.shape({
        clientId: PropTypes.string,
      }),
      filters: PropTypes.shape({}),
    }),
  })
);

const matchRouter = PropTypes.shape({
  url: PropTypes.string,
  path: PropTypes.string,
  params: PropTypes.shape({}),
});

const reportsLoader = PropTypes.objectOf(PropTypes.bool);

const projectUserStar = PropTypes.shape({
  projectId: PropTypes.string,
  userId: PropTypes.string,
});

const buttonAdditionalProps = PropTypes.shape({
  send: PropTypes.shape({}),
  cancel: PropTypes.shape({}),
  secondary: PropTypes.shape({}),
  danger: PropTypes.shape({}),
});

const projectForecast = PropTypes.shape({
  id: PropTypes.string,
  uncommittedPrice: PropTypes.number,
  committedPrice: PropTypes.number,
  total: PropTypes.number,
  totalSpec: PropTypes.number,
  salesTax: PropTypes.number,
  forecastTotal: PropTypes.number,
  contingencies: PropTypes.arrayOf(contingency),
});

const projectSummary = PropTypes.shape({
  id: PropTypes.string,
  uncommittedPrice: PropTypes.number,
  committedPrice: PropTypes.number,
  total: PropTypes.number,
  totalSpec: PropTypes.number,
  salesTax: PropTypes.number,
  forecastTotal: PropTypes.number,
});

const route = PropTypes.shape({
  path: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  routers: PropTypes.arrayOf(PropTypes.shape({})),
});

const glCode = PropTypes.shape({
  id: PropTypes.string,
  code: PropTypes.string,
  description: PropTypes.string,
  isArchived: PropTypes.bool,
});

const discount = PropTypes.shape({
  id: PropTypes.string,
  programId: PropTypes.string,
  vendorId: PropTypes.string,
  discountPercent: PropTypes.number,
});

const invoice = PropTypes.shape({
  id: PropTypes.string,
});

const remitAddress = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const projectAddress = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const feeSummary = PropTypes.shape({
  projectId: PropTypes.string,
});

const fee = PropTypes.shape({
  projectId: PropTypes.string,
  status: PropTypes.string,
  scope: PropTypes.string,
  billingTerm: PropTypes.string,
  serviceCategory: PropTypes.string,
  amount: PropTypes.number,
  revenueSharing: PropTypes.bool,
  billingDate: PropTypes.date,
});

const funding = PropTypes.shape({
  projectId: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  method: PropTypes.string,
});

const userRole = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  permissions: PropTypes.array,
});

const submittalFile = PropTypes.shape({
  id: PropTypes.string,
  receivedDescription: PropTypes.string,
  approvedDescription: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  file,
});

const contractFile = PropTypes.shape({
  id: PropTypes.string,
  uploadedAt: PropTypes.string,
  file,
});

const specCategory = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
});

const revision = PropTypes.shape({
  purchaseOrderId: PropTypes.string,
});

const currency = PropTypes.shape({
  name: PropTypes.string,
});

const projectCurrency = PropTypes.shape({
  currencyId: PropTypes.string,
  projectId: PropTypes.string,
  conversionRate: PropTypes.number,
});

const rfq = PropTypes.shape({
  userId: PropTypes.string,
  responseDate: PropTypes.string,
  deliveryDate: PropTypes.string,
});

const term = PropTypes.shape({
  relation: PropTypes.arrayOf(PropTypes.string),
  displayOrder: PropTypes.number,
  content: PropTypes.string,
});

const revisionActivities = PropTypes.shape({
  revisionId: PropTypes.string,
  reference: PropTypes.string,
  columnName: PropTypes.string,
  operation: PropTypes.string,
  previous: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  current: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  plainObject: PropTypes.shape({}),
});

const specRequirement = PropTypes.shape({
  id: PropTypes.string,
  requirement,
});

const queue = PropTypes.shape({
  id: PropTypes.string,
});

const shipToSite = PropTypes.shape({
  id: PropTypes.string,
});

const paymentTerm = PropTypes.shape({
  id: PropTypes.string,
});

const customReport = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  customReportDataOptions: PropTypes.array,
  userRoleIds: PropTypes.array,
  treeObject: PropTypes.object,
});

const shippingPayment = PropTypes.shape({
  id: PropTypes.string,
});

const scope = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export default {
  paymentTerm,
  revision,
  areaRoom,
  area,
  client,
  component,
  dataComponent,
  errorPayload,
  consultingCompany,
  consultingCompanyContact,
  filteringComponent,
  gridConfig,
  modalDialog,
  navigationTabs,
  project,
  property,
  purchaseOrder,
  bidGroup,
  relatedSpec,
  requestState,
  shipment,
  spec,
  specDetail,
  specDetailCom,
  clientContact,
  tableComponents,
  user,
  vendor,
  report,
  reportCategory,
  vendorContact,
  purchaseOrderContact,
  contact,
  location,
  note,
  formik,
  tag,
  news,
  contingency,
  notesContext,
  notesFilters,
  breadcrumbs,
  matchRouter,
  reportsLoader,
  projectUserStar,
  buttonAdditionalProps,
  projectForecast,
  projectSummary,
  route,
  approvalFile,
  glCode,
  invoice,
  remitAddress,
  projectAddress,
  fee,
  userRole,
  flag,
  brand,
  discount,
  requirement,
  file,
  specCategory,
  revisionActivities,
  currency,
  projectCurrency,
  term,
  team,
  entity,
  office,
  submittalFile,
  specRequirement,
  funding,
  feeSummary,
  vendorCategory,
  projectContact,
  bidGroupVendor,
  bidGroupVendorSpec,
  rfq,
  queue,
  shipToSite,
  contractFile,
  customReport,
  shippingPayment,
  unitOfMeasure,
  projectService,
  projectType,
  product,
  certification,
  scope,
  areaType,
};
