import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import BidGroupVendor from "../../../../models/BidGroupVendor";
import propTypes from "../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import AddVendorContact from "./AddVendorContact";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import VendorContact from "../../../../models/VendorContact";
export const dataComponentId = "VendorsGrid";
export const dataComponentBGVendorId = "BidGroupVendor";
export const dataComponentBGVendorSpecId = "BidGroupVendorSpec";
export const BGDataComponentId = "BidGroupDetailId";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload("VendorsGrid", true);
      closeModalDialog();
    },
  });
};

export const SelectVendorContactContainer = ({
  bidGroup,
  initDataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
  dataComponent,
  bidGroupVendor,
  vendorContacts,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload
    );
  }, [
    bidGroup,
    bidGroupVendor,
    closeModalDialog,
    setReload,
    dataComponent,
    prevDataComponent,
  ]);

  const selectedContacts = useMemo(() => {
    return _get(bidGroupVendor, "selectedContacts", []);
  }, [bidGroupVendor]);

  const handleUpdateBidGroupVendor = useCallback(
    selectedIds => {
      const selectedContactIds = [];
      selectedIds.forEach(selectedId => {
        const vendorContact = vendorContacts.find(
          vendorContact => vendorContact.id === selectedId
        );
        if (vendorContact) {
          selectedContactIds.push(vendorContact.contact.id);
        }
      });
      performUpdateRequest(dataComponentBGVendorId, bidGroupVendor.id, {
        selectedContacts: JSON.stringify(selectedContactIds),
      });
    },
    [bidGroupVendor, vendorContacts, performUpdateRequest]
  );

  useEffect(() => {
    initDataComponent(
      dataComponentBGVendorId,
      BidGroupVendor,
      ["bidGroup", "vendor", "specs"],
      "bid-group-vendors"
    );
  }, [initDataComponent]);

  const filteredContacts = _get(bidGroupVendor, "contacts", []).filter(
    ({ contactId, isActive }) =>
      isActive || selectedContacts.includes(contactId)
  );

  return (
    <AddVendorContact
      selectedContacts={selectedContacts}
      loading={loading}
      bidGroup={bidGroup}
      contacts={filteredContacts}
      onUpdateBidGroupVendor={handleUpdateBidGroupVendor}
      closeModalDialog={closeModalDialog}
    />
  );
};

SelectVendorContactContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  bidGroup: propTypes.bidGroup,
  bidGroupVendor: propTypes.bidGroupVendor,
  dataComponent: propTypes.dataComponent,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
  loading: PropTypes.bool,
};

SelectVendorContactContainer.defaultProps = {
  loading: false,
};

export const mapStateToProps = (state, { bidGroupVendor }) => {
  const vendorContacts = VendorContact.query(state.resources)
    .includes(["contact.person"])
    .where({
      vendorId: _get(bidGroupVendor, "vendor.id"),
    })
    .toObjects();

  return {
    dataComponent: getDataComponent(
      dataComponentBGVendorId,
      state,
      REQUEST_TYPES.UPDATE
    ),
    loading: getDataComponentFlattenedRequestState(
      dataComponentBGVendorId,
      state,
      REQUEST_TYPES.UPDATE
    ).loading,
    vendorContacts: vendorContacts || [],
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectVendorContactContainer);
