import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import _get from "lodash.get";

import FormGrid from "../../../../../forms/FormGrid";
import addressFields from "./addressFields";
import { FormikForm } from "../../../../../forms";
import propTypes from "../../../../../../constants/propTypes";
import CreateContactsContainer from "../../../../VendorDetail/ShipToSites/CreateContacts";
import { useModalStateContext } from "../../../../../layout/AppLayout/ModalDialog/withModalState";
import DuplicatePersonDialog from "../../../../../DuplicatePersonDialog";
import { dataComponentId } from "../../../../ProjectEdit/ProjectEditContainer";

const AddressForm = ({
  onSubmit,
  isEditing,
  address,
  submitText,
  hideContacts,
  descriptionField,
  showSnackNotificationAction,
}) => {
  const formikPropsRef = useRef(null);

  const fields = useMemo(() => addressFields(descriptionField), [
    descriptionField,
  ]);

  const { setModalState, modalState } = useModalStateContext();

  const addressContacts = useMemo(
    () => address.contacts?.map(contact => ({ contact })),
    [address.contacts]
  );

  useEffect(() => {
    setModalState({ contacts: addressContacts });
  }, [addressContacts, setModalState]);

  const handleSubmit = useCallback(
    (values, formik) => {
      onSubmit(
        {
          ...values,
          contacts: (modalState.contacts || []).map(({ contact }) => ({
            ...contact,
          })),
        },
        formik
      );
    },
    [modalState.contacts, onSubmit]
  );

  return (
    <Fragment>
      <FormikForm
        initialValues={address}
        onSubmit={handleSubmit}
        validationSchema={BWModels.loadSchema("ProjectAddress")}
        ignoreValidationFieldNames={["projectId"]}
      >
        {({ ...formikProps }) => {
          formikPropsRef.current = formikProps;
          return (
            <Fragment>
              <FormGrid fields={fields} {...formikProps} />
              <DuplicatePersonDialog
                dataComponentId={dataComponentId}
                contactRelationId={_get(address, "id")}
              />
            </Fragment>
          );
        }}
      </FormikForm>
      {!hideContacts && (
        <CreateContactsContainer
          vendorContacts={addressContacts}
          isEditing={isEditing}
          showSnackNotificationAction={showSnackNotificationAction}
          formikPropsRef={formikPropsRef}
          submitText={submitText}
          hideAddContact
        />
      )}
    </Fragment>
  );
};

AddressForm.defaultProps = { submitText: "Address" };

AddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  address: propTypes.projectAddress,
  isEditing: PropTypes.bool,
  descriptionField: PropTypes.bool,
  hideContacts: PropTypes.bool,
  submitText: PropTypes.string,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export default AddressForm;
