import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { WarningOutlined } from "@material-ui/icons";
import { useFormikContext } from "formik";
import get from "lodash.get";

import { inputs } from "../../inputs/inputConfigs";
import { getOptionsFromSchema } from "../../inputs/utils";
import TooltipIcon from "../../mui/core/TooltipIcon";

const Fill = styled.div`
  display: flex;
  flex: 1;
`;

export const NonTaxableLabel = ({ disabledTaxSpecCategory, isLabeled }) => {
  const { values } = useFormikContext();
  const isTaxExempt = get(values, "isTaxExempt");
  const disabled = disabledTaxSpecCategory || isLabeled;
  return (
    <div style={{ display: "flex" }}>
      {disabledTaxSpecCategory && (
        <TooltipIcon
          Icon={WarningOutlined}
          title="This setting can not be edited as this spec category's tax settings are controlled via the project settings."
        />
      )}
      <span
        style={{ position: "relative", left: isTaxExempt ? "16px" : "0px" }}
      >
        Non-Taxable Spec
      </span>
      {isTaxExempt && (
        <span
          style={{
            position: "relative",
            left: "36px",
            bottom: "24px",
            fontWeight: "bold",
            color: !disabled ? "#3f52b5" : "unset",
          }}
        >
          NT
        </span>
      )}
    </div>
  );
};
NonTaxableLabel.propTypes = {
  disabledTaxSpecCategory: PropTypes.bool,
  isLabeled: PropTypes.bool,
};

export default (options, isLabeled, specCategoryKey) => {
  const disabledTaxCheckCategories = getOptionsFromSchema(
    "SpecCategory",
    "nonManagedTaxExemptCategories"
  )();

  const fields = [
    {
      input: {
        ...inputs.isAllowance,
        disabled: isLabeled,
      },
      width: 161,
    },
    {
      input: {
        ...inputs.isPlaceholder,
        disabled: isLabeled,
      },
      width: 168,
    },
  ];

  const disabledTaxSpecCategory = disabledTaxCheckCategories.includes(
    specCategoryKey
  );
  !isLabeled &&
    fields.push({
      input: {
        ...inputs.moreActions,
        options,
      },
      width: 120,
      preventSubmit: true,
    });

  fields.push(
    {
      component: Fill,
    },
    {
      input: {
        ...inputs.nonTaxableCheck,
        disabled: isLabeled || disabledTaxSpecCategory,
        label: (
          <NonTaxableLabel
            disabledTaxSpecCategory={disabledTaxSpecCategory}
            isLabeled={isLabeled}
          />
        ),
      },
      width: "auto",
      direction: "left",
    }
  );

  return fields;
};
