import get from "lodash.get";

import { performDeleteRequest } from "../../../../../actions/dataComponentActions";
import { initBidGroupSpecDetail } from "../../../../../actions/quotingActions";
import useActions from "../../../../hooks/useActions";
import { bidGroupSpecDetailId } from "../../../Quoting/BidGroupList/BidGroupListContainer";
import { blueChipActions } from "../../../../../store/blueChipConfig";
import { showSnackNotificationAction } from "../../../../../actions/layoutActions";

export const useRemoveSpec = bidGroup => {
  const actions = useActions({
    initBidGroupSpecDetail,
    performDeleteRequest,
    showSnackNotificationAction,
  });

  return ({ id: specId, type }) => {
    actions.initBidGroupSpecDetail();
    const bidGroupSpecs = get(bidGroup, "bidGroupSpecs", []);
    const bidGroupSpec = bidGroupSpecs.find(
      bidGroupSpec => bidGroupSpec.specId === specId
    );

    if (!bidGroupSpec) {
      return actions.showSnackNotificationAction(
        type == "COM"
          ? "There was an error while trying to remove the COM spec. It might not be associated with this bid group directly."
          : "There was an error while trying to remove the spec."
      );
    }

    const bidGroupSpecId = get(bidGroupSpec, "id");
    actions.performDeleteRequest(bidGroupSpecDetailId, [bidGroupSpecId]);
    const specs = get(bidGroup, "specs", []);

    const data = {
      type: "bidGroups",
      id: bidGroup.id,
      relationships: {
        bidGroupSpecs: {
          data: [
            ...bidGroupSpecs
              .filter(bidGroupSpec => bidGroupSpec.specId !== specId)
              .map(({ id }) => ({
                type: "bidGroupSpecs",
                id,
              })),
          ],
        },
        specs: {
          data: [
            ...specs
              .filter(specs => specs.id !== specId)
              .map(({ id }) => ({
                type: "specs",
                id,
              })),
          ],
        },
      },
    };
    blueChipActions.updateResource(data);
  };
};
