import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AddCircle from "@material-ui/icons/AddCircle";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";

import {
  BWGridLocal,
  GridHeader,
  GridTable,
  withSelectedRows,
} from "../../../ui/BWGrid";
import BWTableRow from "../../../ui/BWGrid/gridInternalComponents/BWTableRow";
import SubmittalsTable from "./SubmittalsTable";
import TagText from "../../../ui/Tags/TagText";
import { ExpandedIcon } from "./components";
import propTypes from "../../../../constants/propTypes";
import { GridActions } from "./GridActions";
import UploadFiles from "../../SpecDetail/RequirementsSection/UploadFiles";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";
import { specsDataComponentId } from "./RequirementsGridContainer";
import { ShowBulkUploadSubmittalsContext } from "../ShowBulkUploadSubmittalsContext";

const StyledTableRow = styled(BWTableRow)`
  > td {
    ${({ row }) => row.__expanded__ && "border-bottom: none !important"};
  }
`;

const RowComponent = props => <StyledTableRow {...props} />;

export const getSubmittalsTable = (
  onApproveSubmittal,
  onDeleteSubmittal,
  onDeleteRequirement,
  onDisplayUploadFormClick,
  disabled
) => {
  const DetailRowComponent = props => (
    <SubmittalsTable
      {...props}
      onApproveSubmittal={onApproveSubmittal}
      onDeleteSubmittal={onDeleteSubmittal}
      onDeleteRequirement={onDeleteRequirement}
      onDisplayUploadFormClick={onDisplayUploadFormClick}
      disabled={props.disabled || disabled}
    />
  );
  return DetailRowComponent;
};

export const RequirementStatus = ({ submittalApprovedId }) => {
  const isApproved = submittalApprovedId !== null;
  let intent = "success";
  let tagText = "Approved";
  if (!isApproved) {
    intent = "none";
    tagText = "Pending";
  }

  return <TagText intent={intent}>{tagText}</TagText>;
};

RequirementStatus.propTypes = {
  submittalApprovedId: PropTypes.string,
};

export const renderRequirementStatus = row => {
  return <RequirementStatus submittalApprovedId={row.submittalApprovedId} />;
};

export const renderIsOpen = ({ id, __expanded__ }) =>
  id && <ExpandedIcon isExpanded={__expanded__} />;

const columns = [
  "requirement.name",
  "submittalFiles",
  "submittalApprovedId",
  "isOpen",
];
const columnOptions = {
  "requirement.name": {
    title: "Name",
    bold: true,
    sortingEnabled: false,
    fill: true,
    render: row =>
      `${_get(row, "requirement.name", "")} (${_get(
        row,
        "spec.customNumber",
        ""
      )})`,
  },
  submittalFiles: {
    title: "Files",
    sortingEnabled: false,
    render: row => _get(row, "submittalFiles.length", 0),
  },
  submittalApprovedId: {
    title: "Status",
    sortingEnabled: false,
    width: "110px",
    render: renderRequirementStatus,
  },
  isOpen: {
    title: " ",
    sortingEnabled: false,
    width: "57px",
    render: renderIsOpen,
  },
};

const Grid = ({
  specs,
  onApproveSubmittal,
  onDeleteSubmittal,
  onDeleteRequirement,
  onDisplayUploadSubmittalForm,
  onAddRequirementClick,
  onUpdateSpec,
  setTitle,
  setReload,
  disabled,
}) => {
  const { showBulkUploadSubmittals, setShowBulkUploadSubmittals } = useContext(
    ShowBulkUploadSubmittalsContext
  );
  const { selectedIds } = useWithSelectedRowContext();

  const specRequirements = useMemo(() => {
    const specRequirements = specs.reduce((specRequirements, spec) => {
      return [
        ...specRequirements,
        ...spec.specRequirements.map(specRequirement => ({
          ...specRequirement,
          spec,
        })),
      ];
    }, []);
    return _sortBy(specRequirements, "requirement.name");
  }, [specs]);

  const DetailRowComponent = useMemo(
    () =>
      getSubmittalsTable(
        onApproveSubmittal,
        onDeleteSubmittal,
        onDeleteRequirement,
        onDisplayUploadSubmittalForm,
        disabled
      ),
    [
      onApproveSubmittal,
      onDeleteSubmittal,
      onDeleteRequirement,
      onDisplayUploadSubmittalForm,
      disabled,
    ]
  );

  if (showBulkUploadSubmittals) {
    return (
      <UploadFiles
        selectedSpecRequirementIds={selectedIds}
        specRequirements={specRequirements}
        onUpdateSpec={onUpdateSpec}
        afterSave={() => {
          setReload(specsDataComponentId, true);
          setShowBulkUploadSubmittals(false);
        }}
      />
    );
  }

  return (
    <BWGridLocal
      rows={specRequirements}
      gridConfig={{ pageSize: 0, totalRows: specRequirements.length }}
      tableComponents={{
        DetailRowComponent,
        RowComponent,
      }}
      showSelectionColumn
      showSelectAll
    >
      <GridHeader
        subheaderText="Requirements"
        actions={[
          {
            text: "ADD REQUIREMENT",
            icon: <AddCircle />,
            handler: onAddRequirementClick,
            disabled,
            disableIfProjectClosed: false,
          },
        ]}
        headerOverride={
          <GridActions
            selectedRows={specRequirements.filter(({ id }) =>
              selectedIds.includes(id)
            )}
            setShowBulkUploadSubmittals={setShowBulkUploadSubmittals}
            setTitle={setTitle}
          />
        }
      />
      <GridTable columns={columns} columnOptions={columnOptions} />
    </BWGridLocal>
  );
};

Grid.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec).isRequired,
  onApproveSubmittal: PropTypes.func.isRequired,
  onDeleteSubmittal: PropTypes.func.isRequired,
  onDeleteRequirement: PropTypes.func.isRequired,
  onDisplayUploadSubmittalForm: PropTypes.func.isRequired,
  onAddRequirementClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onUpdateSpec: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
};

export default withSelectedRows(Grid);
