import omit from "lodash/omit";

import {
  processCreateRequestStatus,
  processDeleteRequestStatus,
  processUpdateRequestStatus,
} from "../../../utils/dataComponentUtils";
import { handleSuccess, handleError } from "./AddressBookRequestHandlers";

export const onCreateContact = (
  addressBookId,
  contact,
  dataComponentId,
  performCreateRequest
) => {
  const { scopeId } = contact;
  delete contact.scopeId;
  const addressBookContact = {
    addressBookId,
    contact,
    scopeId,
  };
  performCreateRequest(dataComponentId, addressBookContact);
};
export const onUpdateContact = (
  contact,
  dataComponentId,
  performUpdateRequest
) => {
  delete contact.scopeId;
  performUpdateRequest(
    dataComponentId,
    contact.id,
    omit(contact, [
      "cellNumber",
      "email",
      "faxNumber",
      "firstName",
      "lastName",
      "name",
      "phoneNumber",
      "workNumber",
    ])
  );
};
export const onDeleteContact = (
  addressBookContactId,
  dataComponentId,
  performDeleteRequest
) => {
  performDeleteRequest(dataComponentId, addressBookContactId);
};
const processSaveRequest = (
  preDataComponent,
  dataComponent,
  addressBookDataComponentId,
  addressBookId,
  { fetchData, showSnackNotificationAction, closeModalDialog }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        addressBookDataComponentId,
        addressBookId,
        showSnackNotificationAction,
        "The contact has been saved successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        addressBookDataComponentId,
        addressBookId,
        showSnackNotificationAction,
        "The contact has been updated successfully",
        closeModalDialog
      );
    },
    onError: response =>
      handleError(response.data, showSnackNotificationAction),
  });
  processDeleteRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        addressBookDataComponentId,
        addressBookId,
        showSnackNotificationAction,
        "The contact has been deleted successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processRequests = (
  preDataComponent,
  dataComponent,
  addressBookDataComponentId,
  addressBookId,
  callbacks
) => {
  processSaveRequest(
    preDataComponent,
    dataComponent,
    addressBookDataComponentId,
    addressBookId,
    callbacks
  );
};
