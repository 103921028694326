import {
  performRetrieveListRequest,
  performUpdateRequest,
  performDeleteRequest,
  initDataComponent,
  setReload,
} from "./dataComponentActions";
import { getDataComponent } from "../reducers/dataComponentReducer";
import { closeModalDialog, openModalDialog } from "./layoutActions";
import pluralize from "pluralize";
import PurchaseOrder from "../models/PurchaseOrder";
import BidGroup from "../models/BidGroup";
import Spec from "../models/Spec";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import {
  bidGroupSpecDetailId,
  bidGroupSpecUpdateId,
} from "../components/pages/Quoting/BidGroupList/BidGroupListContainer";
import SpecCategory from "../models/SpecCategory";
import Vendor from "../models/Vendor";
import { fetchPurchaseOrder } from "./purchaseOrdersActions";
import Area from "../models/Area";
import BWInfiniteScroll from "../components/ui/BWInfiniteScroll";
import { specUpdateId } from "../components/pages/Quoting/PurchaseOrderList/SpecsGrid";
import { unassignedSecUpdateId } from "../components/pages/Quoting/QuotingActions";
import { dataComponentId as purchaseOrderUnassignedDataComponentId } from "../components/pages/Quoting/UnassignedSpecs/UnassignedOnPO/UnassignedSpecsContainer";

export const quotingCategoriesDCId = "Quoting-categoriesList";
export const quotingPODCId = "Quoting-PurchaseOrderList";
export const quotingBidGroupsDCId = "Quoting-BidGroupsList";
export const vendorsDataComponentId = "VendorsComponentId";
export const areasDataComponentId = "AreasComponentId";
export const allPurchaseOrdersDataComponentId = "all-purchase-orders-list";
export const allBidGroupsDataComponentId = "all-bid-groups-list";
export const bidGroupAreasDataComponentId = "bg-areas-list";
export const bidGroupVendorsDataComponentId = "bg-vendors-list";

export function initQuotingCategoriesDC() {
  return initDataComponent(
    quotingCategoriesDCId,
    SpecCategory,
    [],
    "spec-categories",
    true
  );
}

export function initQuotingPurchaseOrderDC() {
  return initDataComponent(
    quotingPODCId,
    PurchaseOrder,
    ["projectCurrency.currency", "vendor"],
    "purchase-orders",
    true,
    "v2"
  );
}

export function initSpecDetail() {
  return dispatch => {
    dispatch(initDataComponent(specUpdateId, Spec, [], "specs", true));
    dispatch(initDataComponent(unassignedSecUpdateId, Spec, [], "specs", true));
  };
}

export function initQuotingFiltersByProject(projectId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        vendorsDataComponentId,
        Vendor,
        ["purchaseOrders", "bidGroupVendors.bidGroup"],
        "vendors",
        false,
        "v2"
      )
    );

    dispatch(
      performRetrieveListRequest(vendorsDataComponentId, {
        rootFilters: {
          $where: {
            "specs.projectId": projectId,
          },
        },
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
        fields: ["vendors.id", "name", "scopeId"],
      })
    );

    dispatch(
      initDataComponent(areasDataComponentId, Area, [], "areas", false, "v2")
    );

    dispatch(
      performRetrieveListRequest(areasDataComponentId, {
        rootFilters: {
          $where: {
            projectId,
          },
        },
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
        fields: ["areas.id", "name"],
      })
    );
  };
}

const ignoredStatuses = ["Canceled"];
export function fetchPurchaseOrders(projectId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        allPurchaseOrdersDataComponentId,
        PurchaseOrder,
        [],
        "purchase-orders",
        true
      )
    );
    dispatch(
      performRetrieveListRequest(allPurchaseOrdersDataComponentId, {
        rootFilters: {
          $where: {
            $and: {
              projectId,
              $or: [
                {
                  status: { $notIn: [...ignoredStatuses, "Issued"] },
                },
                {
                  $and: {
                    revisionStatus: {
                      $exists: true,
                    },
                    status: "Issued",
                  },
                },
              ],
            },
          },
        },
        fields: ["purchase_orders.id", "number", "projectCurrencyId"],
        pageSize: -1,
        sort: [{ columnName: "sequenceIndex", direction: "asc" }],
        params: { omitDefaultModifier: true, modifiers: ["withBasicFields"] },
      })
    );
  };
}

export function refreshPurchaseOrderList(projectId, additionalFilters = {}) {
  return function loadPage(pageNumber) {
    return dispatch => {
      dispatch(initQuotingPurchaseOrderDC());
      dispatch(
        performRetrieveListRequest(quotingPODCId, {
          rootFilters: {
            $where: {
              projectId,
              status: { $notIn: ignoredStatuses },
              ...additionalFilters,
            },
          },
          pageSize: BWInfiniteScroll.pageSize,
          pageNumber,
          sort: [{ columnName: "id", direction: "asc" }],
          params: {
            omitDefaultModifier: true,
            modifiers: [
              "withBasicFields",
              "withNeedsFollowUp",
              "withCancellable",
              "withShipmentsData",
              "withRevised",
            ],
          },
        })
      );
    };
  };
}

const reloadUnassignedSpecs = (newPoId, dispatch) =>
  newPoId === null
    ? dispatch(setReload(purchaseOrderUnassignedDataComponentId, true))
    : null;

export function updateSpecs(
  dataComponent,
  newPoId = null,
  selectedIds = [],
  currentPO,
  projectId
) {
  const { dataComponentId } = dataComponent;
  let revisionIsActive = !!currentPO.revisionStatus;
  const itemsToUpdate = selectedIds
    ? selectedIds.map(id => ({
        id,
        purchaseOrderId: newPoId,
      }))
    : [];
  return dispatch => {
    dispatch(
      performUpdateRequest(
        dataComponentId,
        itemsToUpdate,
        revisionIsActive && {
          update_po_revision: true,
        }
      )
    );
    reloadUnassignedSpecs(newPoId, dispatch);
    if (currentPO && projectId)
      dispatch(fetchPurchaseOrder(currentPO.id, projectId));
  };
}

const dataComponentIsEmpty = dataComponent => {
  const stateList = dataComponent.requestState[REQUEST_TYPES.LIST];
  return stateList.selectedIds.length === stateList.totalRows;
};

export function handleSuccessCreatePO(
  originDataComponentId,
  projectId,
  originPOId
) {
  return async (dispatch, getState) => {
    const dataComponent = getDataComponent(originDataComponentId, getState());

    dispatch(closeModalDialog());
    dispatch(refreshPurchaseOrderList(projectId)(0));
    dispatch(fetchPurchaseOrders(projectId));

    if (originPOId && dataComponentIsEmpty(dataComponent)) {
      dispatch(performDeleteRequest(quotingPODCId, originPOId));
    } else {
      dispatch(setReload(originDataComponentId, true));
    }
  };
}

export function openCreatePurchaseOrderModal(
  selectedIds,
  projectId,
  originPOId,
  dataComponentId,
  scopeId
) {
  return dispatch => {
    const specTitle = pluralize("Spec", selectedIds.length, true);
    dispatch(
      openModalDialog(
        ["Create Purchase Order", `${specTitle} Selected`],
        "CreatePurchaseOrder",
        {
          specs: selectedIds.map(id => ({ id })),
          projectId,
          originDataComponentId: dataComponentId || quotingPODCId,
          originPOId,
          scopeId,
        },
        true
      )
    );
  };
}

export function initBidGroupsDC() {
  return initDataComponent(
    quotingBidGroupsDCId,
    BidGroup,
    [
      "bidGroupVendors.vendor",
      "bidGroupSpecs.spec(withAwardedVendorQuoteItem)",
    ],
    "bid-groups",
    true,
    "v2"
  );
}
export function initBidGroupSpecDetail() {
  return dispatch => {
    dispatch(
      initDataComponent(
        bidGroupSpecDetailId,
        Spec,
        [],
        "bid-group-specs",
        true,
        "v2"
      )
    );
    dispatch(
      initDataComponent(
        bidGroupSpecUpdateId,
        Spec,
        ["area", "project", "specCategory"],
        "specs",
        true
      )
    );
  };
}

export function refreshCategoriesList(projectId) {
  return performRetrieveListRequest(quotingCategoriesDCId, {
    rootFilters: {
      $where: {
        "specs.projectId": projectId,
      },
    },
    pageSize: -1,
    sort: [{ columnName: "name", direction: "asc" }],
    fields: ["spec_categories.id", "name", "isActive"],
  });
}

export const getBGAreasDataComponentId = bidGroupId =>
  `${bidGroupAreasDataComponentId}-${bidGroupId}`;
export function refreshAreasList(bidGroupId) {
  return dispatch => {
    const dataComponentId = getBGAreasDataComponentId(bidGroupId);
    dispatch(initDataComponent(dataComponentId, Area, [], "areas", true, "v2"));
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: {
            "specs.bidGroups.id": bidGroupId,
          },
        },
        pageSize: -1,
        sort: [{ columnName: "name", direction: "asc" }],
        fields: ["areas.id", "name"],
      })
    );
  };
}

export const getBGVendorsDataComponentId = bidGroupId =>
  `${bidGroupVendorsDataComponentId}-${bidGroupId}`;
export function refreshVendorsList(bidGroupId) {
  const dataComponentId = getBGVendorsDataComponentId(bidGroupId);
  return dispatch => {
    dispatch(
      initDataComponent(dataComponentId, Vendor, [], "vendors", true, "v2")
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: {
            "specs.bidGroups.id": bidGroupId,
          },
        },
        pageSize: -1,
        sort: [{ columnName: "name", direction: "asc" }],
        fields: ["vendors.id", "name", "scopeId"],
      })
    );
  };
}

export function refreshBidGroupsList(projectId, additionalFilters = {}) {
  return function loadPage(pageNumber) {
    return performRetrieveListRequest(quotingBidGroupsDCId, {
      rootFilters: {
        $where: {
          projectId,
          ...additionalFilters,
        },
      },
      pageSize: BWInfiniteScroll.pageSize,
      pageNumber,
      sort: [{ columnName: "sequenceIndex", direction: "asc" }],
    });
  };
}

export function fetchAllBidGroups(projectId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        allBidGroupsDataComponentId,
        BidGroup,
        ["bidGroupSpecs"],
        "bid-groups",
        true
      )
    );
    dispatch(
      performRetrieveListRequest(allBidGroupsDataComponentId, {
        rootFilters: {
          $where: {
            projectId,
          },
        },
        fields: [
          "bid_groups.id",
          "name",
          "specsCount",
          "selectedProjectCurrencyId",
          "sequenceIndex",
        ],
        pageSize: -1,
        sort: [{ columnName: "sequenceIndex", direction: "asc" }],
      })
    );
  };
}
