import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import Paper from "../../../mui/core/Paper";
import { ActionButtons } from "../../../../components/forms";
import propTypes from "../../../../constants/propTypes";
import * as RequestTypes from "../../../../constants/RequestTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

import { dataComponentId } from "../SpecsContainer";

const Title = styled.div`
  padding: 24px 24px 24px 24px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 0px;

  ${Paper} {
    box-shadow: none;
    overflow-y: auto;
    max-height: 300px;
  }
`;

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  showSnackNotificationAction
) => () => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: ({ data: { errors } }) => {
      errors.forEach(error => {
        if (error.global) {
          showSnackNotificationAction(error.title);
        }
      });
    },
  });
};

export const CompleteSpecsConfirmationContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
  specs,
  showSnackNotificationAction,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      showSnackNotificationAction
    )
  );

  const handleCompleteSpec = useCallback(() => {
    const updatedSpecs = specs.map(spec => ({
      id: spec.id,
      isIncomplete: false,
    }));
    performUpdateRequest(dataComponentId, updatedSpecs);
  }, [performUpdateRequest, specs]);

  return (
    <StyledPaper>
      <Title>
        Are you sure you want to remove the incomplete flag for {specs.length}{" "}
        Specs?
      </Title>
      <ActionButtons
        onSend={handleCompleteSpec}
        sendButtonText="Remove Incomplete Flag"
        isModal
        listeners={[dataComponent.dataComponentId]}
      />
    </StyledPaper>
  );
};

CompleteSpecsConfirmationContainer.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec),
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  showSnackNotificationAction,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);

  const requestState = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const selectedIds = dataComponent.requestState[RequestTypes.LIST].selectedIds;
  const specs = getBluechipResources(requestState, state, selectedIds) || [];
  return {
    dataComponent,
    selectedIds,
    specs,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteSpecsConfirmationContainer);
