import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import BWModels from "benjaminwest-models";
import get from "lodash.get";

import { BWGridLocal, GridHeader, GridTable } from "../../../../ui/BWGrid";
import propTypes from "../../../../../constants/propTypes";
import { getUniqRelationFromVendors } from "../BidGroupVendors";

import { withSelectedRows } from "../../../../ui/BWGrid";
import { useWithSelectedRowContext } from "../../../../ui/BWGrid/withSelectedRows";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCellAPI";
import {
  COM_TYPE,
  spreadBidGroupSpecsWithComs,
} from "../../Quotes/bidGroupVendorSpecUtils";
import EditableColumn from "../../../../ui/BWGrid/columns/EditableColumn";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import { BarLabel } from "../../../ProjectDetail/Forecast/components";
import { Switch } from "../../../../mui/core";
import {
  Indicator,
  CustomNumber,
  VendorName,
  Description,
  Category,
} from "../tableComponents";
import { validateWithJoiSchema } from "../../../../../utils/formValidationUtils";
import withFloatInputValidator from "../../../../inputs/FloatInput/withFloatInputValidator";
import EditColumn from "../EditColumn";
import CurrencyInput from "../../../../inputs/CurrencyInput";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";
import { useRemoveSpec } from "./useRemoveSpec";

export const getRowMenu = props => row => {
  const { onOpenQuickEditModal, removeSpec, bidGroup } = props;

  return [
    { text: "Quick Edit", onClick: () => onOpenQuickEditModal(row) },
    {
      text: "Remove Spec",
      onClick: removeSpec,
      disabled: !get(bidGroup, "specs", []).find(spec => spec.id == row.id),
    },
  ];
};
export const getColumns = enableSampleQuantity => {
  if (enableSampleQuantity) {
    return [
      "indicator",
      "customNumber",
      "vendorName",
      "description",
      "specCategory",
      "unitOfMeasure",
      "totalQuantity",
      "sampleQuantity",
    ];
  } else {
    return [
      "indicator",
      "customNumber",
      "vendorName",
      "description",
      "specCategory",
      "unitOfMeasure",
      "totalQuantity",
    ];
  }
};

const getSampleQuantityEditColumn = row => {
  if (typeof row.sampleQuantity == "number" && row.sampleQuantity >= 0) {
    return (
      <EditableColumn placeholder="Sample Qty">
        {row.sampleQuantity}
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder="Sample Qty" />;
};

export const validateFloatInput = ({ sampleQuantity }) => {
  const { sampleQuantity: sampleQuantityError } = validateWithJoiSchema(
    { sampleQuantity },
    BWModels.loadSchema("Spec")
  );

  return {
    valid: !sampleQuantityError,
    helperText: sampleQuantityError,
  };
};

export const getProductionQuantityEditColumn = (
  spec,
  specs,
  onOpenQuickEditModal
) => {
  if (!spec || spec.type === COM_TYPE) {
    return;
  }
  return (
    <EditColumn
      onClick={evt => {
        onOpenQuickEditModal(spec, specs);
        evt.preventDefault();
        evt.stopPropagation();
      }}
      label={spec.totalQuantity}
    />
  );
};

export const getColumnOptions = ({
  categories,
  specs,
  onOpenQuickEditModal,
  readOnly,
}) => ({
  indicator: {
    title: " ",
    width: "10px",
    sortingEnabled: false,
    render: Indicator,
  },
  customNumber: {
    title: "Spec Number",
    filter: true,
    bold: true,
    sortingEnabled: false,
    render: CustomNumber,
  },
  vendorName: {
    title: "Vendor Name",
    width: "400px",
    filter: true,
    render: VendorName,
  },
  description: {
    title: "Description",
    filter: true,
    width: "300px",
    sortingEnabled: false,
    render: Description,
  },
  specCategory: {
    title: "Type",
    fullWidth: true,
    filter: "select",
    filterOptions: {
      options: categories.map(({ name }) => ({ id: name, name })),
    },
    width: "140px",
    sortingEnabled: false,
    render: Category,
  },
  unitOfMeasure: {
    title: "UOM",
    filter: false,
    sortingEnabled: false,
  },
  totalQuantity: {
    title: "Qty",
    filter: false,
    sortingEnabled: false,
    editable: true,
    render: row =>
      getProductionQuantityEditColumn(row, specs, onOpenQuickEditModal),
  },
  sampleQuantity: {
    title: "Quantity for Sample Pricing",
    filter: false,
    sortingEnabled: false,
    editable: true,
    align: "right",
    render: row => {
      if (readOnly) {
        return row.sampleQuantity;
      }
      if (row.type === COM_TYPE) {
        return;
      }

      return getSampleQuantityEditColumn(row);
    },
  },
});

export const BidGroupSpecs = props => {
  const {
    bidGroup,
    loading,
    bidGroupSpecsDataComponentId,
    onOpenQuickEditModal,
    onToggleEnableSampleQuantity,
    onOpenSpecDetailsPage,
  } = props;
  const [columns, setColumns] = useState([
    "indicator",
    "customNumber",
    "vendorName",
    "description",
    "specCategory",
    "unitOfMeasure",
    "totalQuantity",
  ]);
  const [gridRows, setGridRows] = useState([]);
  const [enableSampleQuantity, setEnableSampleQuantity] = useState(false);

  const removeSpec = useRemoveSpec(bidGroup);

  const { rows, allRows } = useWithSelectedRowContext();

  const { specs = [] } = bidGroup;

  const isProjectClosed = useIsProjectClosed();

  useEffect(() => {
    const { specs = [] } = bidGroup;
    const rows = spreadBidGroupSpecsWithComs(specs);

    setEnableSampleQuantity(!!bidGroup.enableSampleQuantity);
    setGridRows(rows);
  }, [bidGroup]);

  useEffect(() => {
    const columns = getColumns(enableSampleQuantity);

    setColumns(columns);
  }, [enableSampleQuantity]);
  const categories =
    bidGroup &&
    getUniqRelationFromVendors("specCategoryId", "specCategory")(specs);

  const headerText = pluralize("Item", rows.length, true);

  const gridConfig = useMemo(() => ({ pageSize: 10, totalRows: rows.length }), [
    rows,
  ]);

  const columnOptions = useMemo(
    () =>
      getColumnOptions({
        categories,
        specs,
        onOpenQuickEditModal,
        readOnly: isProjectClosed,
      }),
    [categories, isProjectClosed, onOpenQuickEditModal, specs]
  );
  const rowInputs = {
    sampleQuantity: {
      InputComponent: TextInputWithError,
      name: "sampleQuantity",
      fullWidth: true,
      InputProps: {
        inputComponent: CurrencyInput,
      },
      inputProps: {
        maskProps: { prefix: "" },
      },
    },
  };
  const rowMenu = useMemo(
    () =>
      getRowMenu({
        onOpenQuickEditModal: spec => onOpenQuickEditModal(spec, allRows),
        removeSpec,
        bidGroup,
      }),
    [allRows, bidGroup, onOpenQuickEditModal, removeSpec]
  );
  const tableComponent = useMemo(
    () => ({
      CellComponent: withFloatInputValidator(
        editableCellCreator(
          rowInputs,
          bidGroupSpecsDataComponentId,
          {},
          (column, tableRow) => isEditable(column, tableRow)
        ),
        validateFloatInput
      ),
    }),
    [bidGroupSpecsDataComponentId, rowInputs]
  );
  const isEditable = (column, tableRow) => {
    const editableColumnsForSpec = ["sampleQuantity"];

    if (tableRow.row.type === COM_TYPE) {
      return false;
    }

    return editableColumnsForSpec.includes(column.name);
  };
  const toggleSampleQuantity = () => {
    const sampleQuantityState = !enableSampleQuantity;

    onToggleEnableSampleQuantity(sampleQuantityState);

    setEnableSampleQuantity(prev => !prev);
  };

  return (
    <BWGridLocal
      id="bidgroup-specs"
      rows={gridRows}
      gridConfig={gridConfig}
      emptyStateText="No Specs"
      revisionReference="specs"
      isLoading={loading}
      tableComponents={tableComponent}
      editableNavigationDirection="horizontal"
    >
      <GridHeader headerText={headerText}>
        <BarLabel>Include Sample Pricing</BarLabel>
        <Switch
          checked={Boolean(enableSampleQuantity)}
          color="primary"
          onChange={toggleSampleQuantity}
          disabled={isProjectClosed}
        />
      </GridHeader>
      <GridTable
        columns={columns}
        columnOptions={columnOptions}
        rowMenu={rowMenu}
        onClick={onOpenSpecDetailsPage}
      />
    </BWGridLocal>
  );
};

BidGroupSpecs.propTypes = {
  bidGroup: propTypes.bidGroup,
  loading: PropTypes.bool,
  bidGroupSpecsDataComponentId: PropTypes.string.isRequired,
  onOpenQuickEditModal: PropTypes.func,
  onOpenDeleteModal: PropTypes.func,
  onOpenAddVendor: PropTypes.func,
  onOpenAddVendorSpec: PropTypes.func,
  onToggleEnableSampleQuantity: PropTypes.func.isRequired,
  onSetSortedBidGroups: PropTypes.func,
  onOpenSpecDetailsPage: PropTypes.func,
};

export default withSelectedRows(BidGroupSpecs);
