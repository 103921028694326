import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import propTypes from "../../../../constants/propTypes";
import { Paper } from "../../../mui/core";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import Project from "../../../../models/Project";
import { Content } from "../../../layout/Content";
import BulkActions from "../BulkActions";
import theme from "../../../ui/Tags/theme";

import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import User from "../../../../models/User";
import { usersDataComponentId } from "./index";
import {
  getColumnOptions,
  columns,
  getTableComponents,
  rowMenu,
} from "./gridProps";
import { useUserRoleCan } from "../../../hooks/useUserRole";

const getEditableColumns = (
  canEditPurchasingAndAccountingCloseDates,
  canEditSoftCloseDate
) => {
  const editableColumns = [];
  if (canEditPurchasingAndAccountingCloseDates) {
    editableColumns.push("purchasingCloseDate", "accountingCloseDate");
  }

  if (canEditSoftCloseDate) {
    editableColumns.push("softCloseDate");
  }

  return editableColumns;
};

export const ClosingSchedule = props => {
  const {
    initDataComponent,
    performRetrieveListRequest,
    dataComponent,
    generateAndDownloadReport,
    setAutoSaveComponentId,
    users,
    push,
    pushWithReturnUrl,
  } = props;

  const handleOpenProjectDetails = useCallback(
    project => {
      push(
        `/clients/${project.property.entity.clientId}/projects/${project.id}`
      );
    },
    [push]
  );

  const handleOpenProjectSettings = useCallback(
    project => {
      pushWithReturnUrl(
        `/clients/${project.property.entity.clientId}/projects/${project.id}/settings`,
        "Closing Schedule"
      );
    },
    [pushWithReturnUrl]
  );

  const handleExport = () => {
    generateAndDownloadReport({
      format: "xls",
      templateName: "accounting-closing-schedule",
    });
  };

  useEffect(() => {
    initDataComponent(usersDataComponentId, User, [], "users");
    performRetrieveListRequest(usersDataComponentId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fields: ["id", "firstName", "lastName", "name"],
    });
  }, [initDataComponent, performRetrieveListRequest]);
  useEffect(() => {
    setAutoSaveComponentId(dataComponent.dataComponentId);
  }, [dataComponent.dataComponentId, setAutoSaveComponentId]);

  const currencyFormatter = useCurrencyFormatter();

  const canEditPurchasingAndAccountingCloseDates = useUserRoleCan(
    "edit-purchasing-and-accounting-close-dates"
  );
  const canEditSoftCloseDate = useUserRoleCan("edit-soft-close-date");

  const tableComponents = useMemo(
    () =>
      getTableComponents(
        dataComponent.dataComponentId,
        true,
        getEditableColumns(
          canEditPurchasingAndAccountingCloseDates,
          canEditSoftCloseDate
        )
      ),
    [
      canEditPurchasingAndAccountingCloseDates,
      canEditSoftCloseDate,
      dataComponent.dataComponentId,
    ]
  );

  return (
    <ThemeProvider theme={{ tag: theme.small }}>
      <Content>
        <Paper>
          <BWGridAPI
            dataComponent={dataComponent}
            model={Project}
            includes={[
              "projectLead.person",
              "projectMemberUsers.user.person",
              "property.entity.client",
            ]}
            apiRoute="projects"
            apiFilters={{
              rootFilters: {
                $where: {
                  status: "In Closing",
                },
              },
              params: {
                modifiers: ["withAccountingTotals"],
              },
            }}
            defaultSorting={[
              {
                columnName: "accountingCloseDate",
                direction: "asc",
              },
              { columnName: "number", direction: "asc" },
            ]}
            tableComponents={tableComponents}
            alwaysDisplayDetailRow
          >
            <GridHeader
              headerText={`${dataComponent.totalRows} Projects`}
              headerOverride={<BulkActions handleExport={handleExport} />}
            />
            <GridTable
              columns={columns}
              columnOptions={getColumnOptions(currencyFormatter, users)}
              rowMenu={rowMenu({
                onOpenProjectSettings: handleOpenProjectSettings,
                onOpenProjectDetails: handleOpenProjectDetails,
              })}
            />
          </BWGridAPI>
        </Paper>
      </Content>
    </ThemeProvider>
  );
};

ClosingSchedule.propTypes = {
  generateAndDownloadReport: PropTypes.func,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  openModalDialog: PropTypes.func,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(propTypes.user),
  push: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func.isRequired,
};

export default ClosingSchedule;
