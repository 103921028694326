import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";
import { useSelectedRowAPIContext } from "../../../ui/BWGrid/withSelectedRowsAPI";
import useActions from "../../../hooks/useActions";
import { openModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import { performUpdateRequest } from "../../../../actions/dataComponentActions";
import { isPOChangeDisabled } from "../utils";
import { dataComponentId as poDataComponentId } from "../connect";

export const GridActions = ({ dataComponentId, purchaseOrder }) => {
  const { selectedRows } = useSelectedRowAPIContext();
  const actions = useActions({ openModalDialog, performUpdateRequest });

  const handleDeleteClick = () => {
    actions.openModalDialog(
      `Remove Spec(s) - ${pluralize("spec", selectedRows.length, true)}`,
      "BulkDeletePOSpecs",
      {
        specs: selectedRows,
        purchaseOrder,
        dataComponentId,
      },
      false,
      false,
      {
        width: "650px",
      }
    );
  };

  const handleMarkAllTaxable = () => {
    actions.openModalDialog("Mark All Taxable", "GeneralConfirmation", {
      title:
        "All the specs within the PO that can have individual taxable status control will be updated.",
      sendButtonText: "Confirm",
      cancelButtonText: "Cancel",
      listeners: [poDataComponentId],
      onSubmit: () => {
        actions.performUpdateRequest(poDataComponentId, purchaseOrder.id, {
          markAllTaxable: true,
        });
      },
    });
  };

  const handleMarkAllNonTaxable = () => {
    actions.openModalDialog("Mark All Non-Taxable", "GeneralConfirmation", {
      title:
        "All the specs within the PO that can have individual taxable status control will be updated.",
      sendButtonText: "Confirm",
      cancelButtonText: "Cancel",
      listeners: [poDataComponentId],
      onSubmit: () => {
        actions.performUpdateRequest(poDataComponentId, purchaseOrder.id, {
          markAllTaxable: false,
        });
      },
    });
  };

  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: `Remove ${pluralize("Spec", selectedRows.length)}`,
          onClick: handleDeleteClick,
          disabled: !selectedRows.length,
          disableIfProjectClosed: true,
        },
        {
          content: "Mark All Taxable",
          onClick: handleMarkAllTaxable,
          disabled: isPOChangeDisabled(purchaseOrder),
          disableIfProjectClosed: true,
        },
        {
          content: "Mark All Non-Taxable",
          onClick: handleMarkAllNonTaxable,
          disabled: isPOChangeDisabled(purchaseOrder),
          disableIfProjectClosed: true,
        },
      ]}
    />
  );
};
GridActions.propTypes = {
  dataComponentId: PropTypes.string.isRequired,
  purchaseOrder: propTypes.purchaseOrder.isRequired,
};
