import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import { inputs } from "../../../../inputs/inputConfigs";

import { StyledEdit } from "../../../Quoting/PurchaseOrderList/components";
import BWGrid from "../../../../ui/BWGrid/BWGrid";
import GridTable from "../../../../ui/BWGrid/GridTable";
import propTypes from "../../../../../constants/propTypes";
import { editableColumns } from "./Column";
import getRows from "./getRows";
import editableCellCreator, { StyledBWCell } from "./editableCellCreator";
import withFloatInputValidator from "../../../../inputs/FloatInput/withFloatInputValidator";
import { getColumnOptions } from "./columnOptions";
import InvoiceData from "../../../../../models/Invoice/InvoiceData";

const rowInputs = currency => ({
  depositsPaid: {
    ...inputs.price,
    name: "depositsPaid",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "70px",
    },
    inputProps: {
      maskProps: { allowNegative: true },
      currency,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
  assetsPaid: {
    ...inputs.price,
    name: "assetsPaid",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "70px",
    },
    inputProps: {
      maskProps: { allowNegative: true },
      currency,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
});

const columns = [
  "title",
  "assetsPaid",
  "assetsToDate",
  "depositsPaid",
  "depositsToDate",
  "net",
  "notProcessed",
];

const gridConfig = {
  totalRows: 0,
  pageSize: 0,
};

const PricingSectionPage = ({
  invoice,
  readOnly,
  setCurrentCellFunc,
  pageSectionRef,
}) => {
  const invoiceData = useMemo(() => new InvoiceData(invoice), [invoice]);
  const rows = useMemo(() => getRows(invoiceData), [invoiceData]);
  const columnOptions = useMemo(() => getColumnOptions(!readOnly, invoice), [
    invoice,
    readOnly,
  ]);

  const openNextFallback = useMemo(
    () => () => {
      setTimeout(() => {
        pageSectionRef.current.parentElement.nextElementSibling
          .querySelector("textarea")
          .focus();
      }, 0);
    },
    [pageSectionRef]
  );

  const openPrevFallback = useMemo(
    () => () => {
      setTimeout(() => {
        const prevInput = pageSectionRef.current.querySelectorAll("input")[2];
        if (prevInput) {
          return prevInput.focus();
        }
        [
          ...pageSectionRef.current.parentElement.previousElementSibling
            .querySelector("table")
            .querySelector("tbody")
            .querySelectorAll("div"),
        ]
          .at(-1)
          .click();
      }, 0);
    },
    [pageSectionRef]
  );

  const tableComponents = useMemo(
    () => ({
      CellComponent: readOnly
        ? StyledBWCell
        : withFloatInputValidator(
            editableCellCreator(
              rowInputs(invoice.purchaseOrder?.projectCurrency),
              ({ rowId }, { name }) => editableColumns[rowId][name],
              openNextFallback,
              openPrevFallback
            ),
            invoiceData.validate.bind(invoiceData)
          ),
    }),
    [invoice, invoiceData, openNextFallback, openPrevFallback, readOnly]
  );

  return (
    <BWGrid
      rows={rows}
      gridConfig={gridConfig}
      tableComponents={tableComponents}
      setCurrentCellFunc={setCurrentCellFunc}
    >
      <GridTable columns={columns} columnOptions={columnOptions} />
    </BWGrid>
  );
};

PricingSectionPage.propTypes = {
  invoice: propTypes.invoice,
  readOnly: PropTypes.bool,
  formikProps: PropTypes.object,
  setCurrentCellFunc: PropTypes.func.isRequired,
  pageSectionRef: PropTypes.object.isRequired,
};

export default memo(PricingSectionPage);
