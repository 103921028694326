import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../components/forms/FormGrid";
import createUserFields, { getNonInternalRoleIds } from "./createUserFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import DuplicatePersonDialog from "../../../DuplicatePersonDialog";

export const getCustomValidator = userRoles => ({
  validate(values, options) {
    return BWModels.loadSchema("User")
      .append(
        getNonInternalRoleIds(userRoles).includes(values.userRoleId)
          ? { person: BWModels.loadSchema("Person") }
          : {
              person: BWModels.loadSchema("Person"),
              officeId: BWModels.Joi.selectFk().required(),
            }
      )
      .validate(values, options);
  },
});

const initialValues = {
  officeId: "",
  userRoleId: "",
  teamId: "",
  person: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
};

const CreateUserForm = ({ createUser, userRoles, dataComponentId }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={createUser}
      validationSchema={getCustomValidator(userRoles)}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={createUserFields(
              null,
              null,
              null,
              {
                values,
                ...formikProps,
              },
              userRoles
            )}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <DuplicatePersonDialog dataComponentId={dataComponentId} isUserForm />
          <ActionButtons
            onSend={handleSubmit}
            sendButtonText="CREATE USER"
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

CreateUserForm.propTypes = {
  createUser: PropTypes.func,
  closeModalDialog: PropTypes.func,
  dataComponentId: PropTypes.string.isRequired,
  userRoles: PropTypes.array,
};

export default CreateUserForm;
