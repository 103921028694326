import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../forms/FormGrid";
import createContactFields from "./createVendorContactFields";
import { inputs } from "../../../../inputs/inputConfigs";
import { FormikForm, ActionButtons } from "../../../../forms";

import Joi from "joi-browser";
import CommentsRow from "../CommentsRow";
import CheckboxRow from "../CheckboxRow";
import commentsInput from "../CommentsRowInput";

import { vendorContactsDataComponentId } from "../../VendorContactsContainer";
import DuplicatePersonDialog from "../../../../DuplicatePersonDialog";

const getValidationSchema = () =>
  BWModels.loadSchema("VendorContact").concat(
    Joi.object().keys({
      contact: BWModels.loadSchema("Contact"),
    })
  );

export default class CreateVendorContactForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    vendor: PropTypes.object.isRequired,
    ignoreAddressBook: PropTypes.bool,
    isEdit: PropTypes.bool,
    toggleCreateNewContact: PropTypes.func,
    formikPropsRef: PropTypes.shape({}),
    dataComponentId: PropTypes.string,
  };

  contactInitialValues = {
    contact: {
      person: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        faxNumber: "",
        email: "",
      },
      jobTitle: "",
      status: "act",
    },
    canAccessVendorPortal: false,
    isActive: true,
    contactStatus: "",
  };

  get formPatchProps() {
    const { initialValues, id } = this.props;
    if (!id) return {};

    return {
      enableReinitialize: true,
      initialValues,
    };
  }

  render() {
    const {
      onSubmit,
      vendor,
      toggleCreateNewContact,
      ignoreAddressBook,
      isEdit,
      formikPropsRef,
      dataComponentId,
    } = this.props;
    const { canAccessVendorPortal, contactIsActiveForVendor } = inputs;

    return (
      <FormikForm
        initialValues={{ vendorId: vendor.id, ...this.contactInitialValues }}
        onSubmit={onSubmit}
        validationSchema={getValidationSchema()}
        ignoreValidationFieldNames={["name", "contactId"]}
        {...this.formPatchProps}
      >
        {({ handleSubmit, values, ...formikProps }) => {
          formikPropsRef.current = formikProps;
          return (
            <Fragment>
              <FormGrid
                fields={createContactFields(vendor)}
                values={values}
                {...formikProps}
              />
              <CheckboxRow
                isFirst
                input={canAccessVendorPortal}
                label={canAccessVendorPortal.label}
              />
              <CheckboxRow
                input={contactIsActiveForVendor}
                label={contactIsActiveForVendor.label}
              />
              <CommentsRow input={commentsInput} />
              <DuplicatePersonDialog
                dataComponentId={dataComponentId}
                contactRelationId={vendor.id}
              />
              <ActionButtons
                hasThreeOption={!ignoreAddressBook}
                isModal={true}
                onSend={handleSubmit}
                sendButtonText={isEdit ? "Save" : "Create Contact"}
                secondaryButtonText={
                  ignoreAddressBook ? undefined : "Use Address Book"
                }
                onSecondary={
                  ignoreAddressBook ? undefined : toggleCreateNewContact
                }
                listeners={[vendorContactsDataComponentId]}
              />
            </Fragment>
          );
        }}
      </FormikForm>
    );
  }
}
