import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import { createSelector } from "reselect";

import { showSnackNotificationAction } from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performDeleteRequest,
  performFindRequest,
} from "../../../../../actions/dataComponentActions";
import propTypes from "../../../../../constants/propTypes";
import ApprovalManager from "./ApprovalManager";
import ApprovalFile from "../../../../../models/ApprovalFile";
import {
  approvalFileDataComponentID,
  uploadApprovalFile,
} from "../../../../../actions/purchaseOrdersActions";
import {
  processCreateRequestStatus,
  processDeleteRequestStatus,
} from "../../../../../utils/dataComponentUtils";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";

const poDataComponentId = "PODetail";

export const processRequestStatus = (
  prevDataComponent,
  dataComponent,
  showSnackNotificationAction,
  performFindRequest,
  purchaseOrder
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      performFindRequest(
        poDataComponentId,
        purchaseOrder.id,
        {},
        {
          modifiers: ["withName"],
        }
      );
    },
    onError: ({ response, data }) => {
      if (response.status === -1) {
        return showSnackNotificationAction("Failed request");
      }
      data.errors.map(error => {
        if (error.global) {
          showSnackNotificationAction(error.title);
        }
      });
    },
  });

  processDeleteRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      performFindRequest(
        poDataComponentId,
        purchaseOrder.id,
        {},
        {
          modifiers: ["withName"],
        }
      );
      return showSnackNotificationAction(
        "The approval file has been deleted successfully"
      );
    },
    onError: ({ response, data }) => {
      if (response.status === -1) {
        return showSnackNotificationAction("Failed request");
      }
      data.errors.map(error => {
        if (error.global) {
          showSnackNotificationAction(error.title);
        }
      });
    },
  });
};

const ApprovalManagerContainer = ({
  purchaseOrder,
  approvalFiles,
  initDataComponent,
  performDeleteRequest,
  performFindRequest,
  showSnackNotificationAction,
  uploadApprovalFile,
  dataComponent,
  fileKey,
  isDeleting,
  deletableVersions,
}) => {
  const [prevDataComponent, setDataComponent] = useState(null);
  useEffect(() => {
    setDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    initDataComponent(
      approvalFileDataComponentID,
      ApprovalFile,
      ["file", "user.person", "purchaseOrder", "revision"],
      "approval-files"
    );
  }, [initDataComponent]);

  useEffect(() => {
    processRequestStatus(
      prevDataComponent,
      dataComponent,
      showSnackNotificationAction,
      performFindRequest,
      purchaseOrder
    );
  }, [
    prevDataComponent,
    dataComponent,
    showSnackNotificationAction,
    performFindRequest,
    purchaseOrder,
  ]);

  const handleUploadFile = useCallback(
    file => uploadApprovalFile(purchaseOrder, fileKey, file),
    [uploadApprovalFile, fileKey, purchaseOrder]
  );
  const handleDeleteFile = useCallback(
    approvalId => performDeleteRequest(approvalFileDataComponentID, approvalId),
    [performDeleteRequest]
  );

  return (
    <ApprovalManager
      onUploadApproval={handleUploadFile}
      onDeleteApproval={handleDeleteFile}
      isDeleting={isDeleting}
      files={approvalFiles}
      deletableVersions={deletableVersions}
    />
  );
};

ApprovalManagerContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  uploadApprovalFile: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  purchaseOrderId: PropTypes.string,
  purchaseOrder: propTypes.purchaseOrder,
  dataComponent: propTypes.dataComponent,
  fileKey: PropTypes.string,
  isDeleting: PropTypes.bool,
  approvalFiles: PropTypes.arrayOf(propTypes.approvalFile),
  deletableVersions: PropTypes.arrayOf(PropTypes.string),
};

export const makeMapStateToProps = () => {
  const deletableVersionsSelector = createSelector(
    ({ approvalFiles }) => approvalFiles,
    approvalFiles => {
      const filesByVersion = approvalFiles.reduce((accum, file) => {
        if (!file.revision) return accum;
        const version = file.revision.version;
        if (!accum[version]) {
          accum[version] = 0;
        }
        accum[version]++;
        return accum;
      }, {});
      return Object.keys(filesByVersion).filter(
        version => filesByVersion[version] > 1
      );
    }
  );

  return (state, { purchaseOrderId }) => {
    const poDataComponent = getDataComponentFlattenedRequestState(
      poDataComponentId,
      state,
      REQUEST_TYPES.FIND
    );

    const purchaseOrder = getBluechipResourceById(
      poDataComponent,
      state,
      purchaseOrderId
    );

    const fileKey = `projects/${purchaseOrder.projectId}/purchase-orders/${purchaseOrder.id}/approvals`;

    const dataComponent = getDataComponent(approvalFileDataComponentID, state);

    const approvalFiles = _sortBy(
      (purchaseOrder.approvalFiles || []).map(file => file),
      ["createdAt"]
    ).reverse();

    return {
      fileKey,
      dataComponent,
      isDeleting: _get(
        dataComponent,
        `requestState.${REQUEST_TYPES.DELETE}.loading`
      ),
      deletableVersions: deletableVersionsSelector({ approvalFiles }),
      approvalFiles: approvalFiles.length > 0 ? approvalFiles : [{}],
      purchaseOrder,
    };
  };
};

const mapDispatchToProps = {
  initDataComponent,
  showSnackNotificationAction,
  uploadApprovalFile,
  performDeleteRequest,
  performFindRequest,
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ApprovalManagerContainer);
