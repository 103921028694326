import React, { useMemo } from "react";
import moment from "moment";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import _get from "lodash/get";

import _uniqBy from "lodash/uniqBy";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";
import DatePickerWithError from "../../../inputs/DatePickerWithError";

import { editableCellCreator } from "../../../ui/BWGrid/EditableLocalCell";

import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";
import { TableWrapper } from "./components";
import propTypes from "../../../../constants/propTypes";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";
import { DescriptionColumn } from "../../Specs/DescriptionColumn";

export const getUniqRelation = (uniqId, relation) => bidGroupVendors => {
  const filtered = _uniqBy(bidGroupVendors, uniqId);
  return filtered.reduce((result, vendor) => {
    const item = vendor[relation];
    if (!item) return result;
    return [...result, item];
  }, []);
};

const columns = [
  "customNumber",
  "description",
  "specCategoryId",
  "shipDate",
  "deliveryDate",
];
const pageNumber = 0;

export const getDateLabel = rowDate => {
  if (!rowDate) return "--";
  return moment(rowDate).format(PRIMARY_DATE_FORMAT);
};

export const isValidRow = (columnOptions, selectedIds) => (
  column,
  rowOptions
) => {
  return (
    columnOptions[column.name].editable &&
    selectedIds.includes(rowOptions.row.id)
  );
};

const getEditableOptionsFor = (columnName, updatedAttributes, setField) => {
  return {
    formatValue: row => {
      const attributes = updatedAttributes.find(
        ({ specId }) => specId === row.id
      );
      if (attributes) {
        return attributes[columnName];
      }
    },
    onChange: (value, { columnName, row }) => {
      setField(value, columnName, row);
    },
  };
};

const getColumnOptions = ({
  bidGroupVendor,
  categories,
  selectedIds,
  setField,
  updatedAttributes,
}) => ({
  customNumber: {
    title: "ID",
    filter: true,
    bold: true,
  },
  description: {
    title: "Description",
    filter: true,
    width: "300px",
    render: DescriptionColumn,
  },
  specCategoryId: {
    title: "Type",
    fullWidth: true,
    filter: "select",
    filterOptions: {
      options: categories,
    },
    width: "200px",
    render: row => row && _get(row, "specCategory.name"),
  },
  shipDate: {
    title: "Ship Date",
    filter: false,
    sortingEnabled: false,
    editable: false,
    render: row => {
      if (selectedIds.includes(row.id)) {
        return getDateLabel(_get(bidGroupVendor, `shipDate`));
      }
    },
    editableOptions: getEditableOptionsFor(
      "shipDate",
      updatedAttributes,
      setField
    ),
  },
  deliveryDate: {
    render: row => {
      if (selectedIds.includes(row.id)) {
        return getDateLabel(_get(bidGroupVendor, `deliveryDate`));
      }
    },
    title: "Delivery Date",
    filter: false,
    editable: false,
    sortingEnabled: false,
    editableOptions: getEditableOptionsFor(
      "deliveryDate",
      updatedAttributes,
      setField
    ),
  },
});
const sorting = [{ columnName: "customNumber", direction: "asc" }];

const getDefaultSelection = (rows, selectedSpecs) => () => {
  const defaultSelection = rows.reduce((result, spec, index) => {
    if (selectedSpecs.includes(spec.id)) return [...result, index];
    return result;
  }, []);

  return {
    [pageNumber]: defaultSelection,
  };
};

const rowInputs = {
  shipDate: {
    InputComponent: DatePickerWithError,
    name: "shipDate",
    disablePast: true,
    fullWidth: true,
    isApplyOnChange: true,
    FloatInputProps: {
      height: "70px",
    },
  },
  deliveryDate: {
    InputComponent: DatePickerWithError,
    name: "deliveryDate",
    disablePast: true,
    fullWidth: true,
    isApplyOnChange: true,
    FloatInputProps: {
      height: "70px",
    },
  },
};

const SpecsList = ({
  bidGroup,
  bidGroupVendor,
  selectedSpecs,
  loading,
  setField,
  updatedAttributes,
}) => {
  const { selection, rows, selectedIds } = useWithSelectedRowContext();
  const specs = useMemo(() => _get(bidGroup, "specs", []), [bidGroup]);
  const selectedCount = (selection[pageNumber] || []).length;
  const title = pluralize("Spec", selectedCount, true);
  const categories = getUniqRelation("specCategoryId", "specCategory")(specs);

  const defaultSelection = useMemo(getDefaultSelection(rows, selectedSpecs), [
    rows,
    selectedSpecs,
  ]);
  const columnOptions = getColumnOptions({
    bidGroupVendor,
    categories,
    selectedIds,
    setField,
    updatedAttributes,
  });

  const tableComponent = useMemo(() => {
    return {
      CellComponent: editableCellCreator(
        rowInputs,
        isValidRow(columnOptions, selectedIds),
        {}
      ),
    };
  }, [columnOptions, selectedIds]);

  return (
    <TableWrapper>
      <BWGridLocal
        noBorder
        id="bidgroup-selected-specs"
        sorting={sorting}
        rows={specs}
        gridConfig={{ pageSize: 0 }}
        tableComponents={tableComponent}
        emptyStateText="No Specs"
        revisionReference="specs"
        showSelectionColumn
        showSelectAll
        isLoading={loading}
        defaultSelection={defaultSelection}
      >
        <GridHeader headerText={`${title} Selected`} />
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridLocal>
    </TableWrapper>
  );
};

SpecsList.propTypes = {
  bidGroup: propTypes.bidGroup,
  bidGroupVendor: propTypes.bidGroupVendor,
  selectedSpecs: PropTypes.arrayOf(PropTypes.string),
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  setField: PropTypes.func,
  updatedAttributes: PropTypes.arrayOf(
    PropTypes.shape({ shipDate: PropTypes.date, deliveryDate: PropTypes.date })
  ),
  loading: PropTypes.bool,
};

export default SpecsList;
