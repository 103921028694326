import React, { useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";

import { GridLocalSection } from "../../../../layout/GridLocalSection";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import Paper from "../../../../mui/core/Paper";
import { ActionButtons } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import RegularText from "../../../../ui/Typography/RegularText";

const Title = styled.div`
  padding: 24px 24px 0px 24px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 0px;

  ${Paper} {
    box-shadow: none;
    overflow-y: auto;
  }
`;

const renderCustomNumber = spec => {
  const style = spec.totalQuantityShipped
    ? { color: "red", fontStyle: "italic" }
    : {};
  return (
    <span style={style}>
      {spec.totalQuantityShipped ? " *" : ""}
      {spec.customNumber}
    </span>
  );
};

const BulkDeletePOSpecs = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
  purchaseOrder,
  specs,
  showSnackNotificationAction,
}) => {
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponent.dataComponentId, true);
    },
    onError: ({ data: { errors } }) => {
      errors.forEach(error => {
        if (error.global) {
          showSnackNotificationAction(error.title);
        }
      });
    },
  });

  const handleDeleteList = useCallback(() => {
    const revisionIsActive = Boolean(purchaseOrder.revisionStatus);
    performUpdateRequest(
      dataComponent.dataComponentId,
      specs.map(({ id }) => ({ id, purchaseOrderId: null })),
      revisionIsActive && {
        update_po_revision: true,
      }
    );
  }, [
    dataComponent.dataComponentId,
    performUpdateRequest,
    purchaseOrder.revisionStatus,
    specs,
  ]);

  const isDeleteDisabled = specs.some(
    ({ totalQuantityShipped }) => totalQuantityShipped
  );

  return (
    <StyledPaper>
      <Title>Do you want to remove the following specs?</Title>
      <GridLocalSection
        id="delete-bulk-specs"
        columnsOptions={{
          customNumber: {
            bold: true,
            title: "Spec Number",
            render: renderCustomNumber,
          },
          "specCategory.name": { title: "Category" },
          "area.name": { title: "Area", fill: true },
        }}
        rows={specs}
        columns={[
          "customNumber",
          "description",
          "specCategory.name",
          "area.name",
        ]}
        gridConfig={{ pageSize: 0, totalRows: specs.length }}
        noBorder
      />
      {isDeleteDisabled && (
        <RegularText
          style={{
            color: "red",
            fontStyle: "italic",
            padding: "0 24px 24px 24px",
          }}
        >
          * Spec cannot be removed from purchase order as it has shipments.
        </RegularText>
      )}
      <ActionButtons
        onSend={handleDeleteList}
        sendButtonText="Confirm"
        additionalProps={{
          send: {
            disabled: isDeleteDisabled,
            isLoading: get(dataComponent, "requestState.update.loading"),
          },
        }}
        isModal
      />
    </StyledPaper>
  );
};

BulkDeletePOSpecs.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  specs: PropTypes.arrayOf(propTypes.spec),
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeletePOSpecs);
