import {
  initDataComponent,
  performRetrieveListRequest,
} from "./dataComponentActions";
import Contact from "../models/Contact";

export const addressBookContactsDataComponentId =
  "VendorAddressBookContactsDataComponentId";

export function initAddressBookDatacomponent() {
  return dispatch => {
    dispatch(
      initDataComponent(
        addressBookContactsDataComponentId,
        Contact,
        [],
        "contacts",
        undefined,
        "v2"
      )
    );
  };
}

export function retrieveAddressVendorBookContacts(addressBookId, vendorId) {
  return dispatch => {
    dispatch(
      performRetrieveListRequest(addressBookContactsDataComponentId, {
        rootFilters: {
          $where: {
            "addressBook.id": addressBookId,
            status: {
              $notEqual: "inact",
            },
          },
        },
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
        params: { omitInVendor: vendorId, modifiers: ["withPersonProps"] },
      })
    );
  };
}
