import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import memoizeOne from "memoize-one";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";
import _noop from "lodash/noop";

import SpecDetailPage from "./SpecDetailPage";
import { LoaderContext } from "../../ui/Loader";
import addSpecDetailMenuItems, {
  openCOM,
  openDescription,
  openPARBasedQuantityCalculation,
  openCaseBasedQuantityCalculation,
  openAddDetailModal,
  openPreview,
} from "./addSpecDetailMenuItems";

import propTypes from "../../../constants/propTypes";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
  processDeleteRequestStatus,
} from "../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../utils/formValidationUtils";
import withPORevision, { LabeledTextContext } from "../../../withPORevision";
import { mapDispatchToProps, mapStateToProps, PageContext } from "./selectors";
import { closeModalDialog } from "../../../actions/layoutActions";
import { addSpecDetailsComponentId } from "../../../actions/specDetailActions";
import { isPOChangeDisabled } from "../PurchaseOrderDetail/utils";
import { getQuantityErrorMessage } from "../Specs/SpecFormikForm";
import {
  getIsProjectClosed,
  ProjectContext,
} from "../../hooks/useIsProjectClosed";
import { generateValues } from "../Notes/NoteCreate/AssignToSelect/AssignToSelectContainer";

export const SpecDetailContext = createContext({ onUpdateSpec: _noop });

const dataComponentSettings = "specDetailsSettings";
export const dataComponentId = "SpecDetail";
export const specDetailsChildrenDataComponentId =
  "SpecDetail-SpecDetailsChildren";
export const specDetailComDataComponentId = "COMLibrary-specDetailCom";

export const generateCallbacks = (
  performFindRequest,
  specId,
  state,
  params
) => ({
  onSuccess: () => performFindRequest(dataComponentId, specId, {}, params),
  onError: error =>
    state.formikActions && handleRequestError(error, state.formikActions),
});
export class SpecDetailContainer extends Component {
  state = {};

  static contextType = ProjectContext;

  getParams() {
    const { history } = this.props;
    const { navigateBetweenPO, navigateBetweenBG } = parse(
      history.location.search
    );

    return {
      navigateBetweenPO,
      navigateBetweenBG,
      omitDefaultModifier: true,
      modifiers: [
        "withQuantityPrice",
        "withTotalQuantityPaid",
        "withTotalQuantityShipped",
        "withAssignedToBidGroup",
      ],
    };
  }

  componentDidMount() {
    const { specId, projectId, clientId, initSpecDetail } = this.props;

    initSpecDetail(specId, projectId, clientId, true, this.getParams());
  }

  componentDidUpdate({
    specDetailsChildrenDataComponent: prevSpecDetailsChildrenDataComponent,
    specDetailsComDataComponent: prevSpecDetailsComDataComponent,
    addSpecDetailsDataComponent: prevAddDefaultSpecDetailsDataComponent,
    specId: prevSpecId,
  }) {
    const {
      performFindRequest,
      specId,
      specDetailsChildrenDataComponent,
      specDetailsComDataComponent,
      addSpecDetailsDataComponent,
      projectId,
    } = this.props;

    if (specId !== prevSpecId) {
      performFindRequest(
        dataComponentId,
        specId,
        {
          $where: { projectId },
        },
        this.getParams()
      );
    }

    const callbacks = generateCallbacks(
      performFindRequest,
      specId,
      this.state,
      this.getParams()
    );

    processCreateRequestStatus(
      prevSpecDetailsChildrenDataComponent,
      specDetailsChildrenDataComponent,
      callbacks
    );

    processCreateRequestStatus(
      prevSpecDetailsComDataComponent,
      specDetailsComDataComponent,
      callbacks
    );

    processUpdateRequestStatus(
      prevSpecDetailsChildrenDataComponent,
      specDetailsChildrenDataComponent,
      callbacks
    );
    processUpdateRequestStatus(
      prevSpecDetailsComDataComponent,
      specDetailsComDataComponent,
      callbacks
    );

    processUpdateRequestStatus(
      prevAddDefaultSpecDetailsDataComponent,
      addSpecDetailsDataComponent,
      callbacks
    );

    processDeleteRequestStatus(
      prevSpecDetailsChildrenDataComponent,
      specDetailsChildrenDataComponent,
      callbacks
    );
    processDeleteRequestStatus(
      prevSpecDetailsComDataComponent,
      specDetailsComDataComponent,
      callbacks
    );
  }

  handleUpdateSpec = ({ vendorContacts, specDetails, ...values }) => {
    const { spec } = this.props;
    if (_isEmpty(values) || getQuantityErrorMessage(spec, values)) return;

    const onlySettings = _isEqual(Object.keys(values), ["requirements"]);
    const { specId, projectId, revisionValue } = this.props;
    this.props.updateSpec(
      specId,
      projectId,
      values,
      revisionValue.isActive && {
        update_po_revision: true,
        ...this.getParams(),
      },
      onlySettings ? dataComponentSettings : dataComponentId
    );
  };

  handleAddSpecDetails = (spec, previewOnly) => {
    const { performUpdateRequest } = this.props;
    performUpdateRequest(
      addSpecDetailsComponentId,
      spec.id,
      {},
      { previewOnly }
    );
    closeModalDialog();
  };

  openAddDetailModal = () => {
    const { spec, openModalDialog, projectId, clientId } = this.props;
    openAddDetailModal(
      spec,
      openDescription,
      addSpecDetailMenuItems,
      openModalDialog,
      { projectId, clientId, purchaseOrderId: spec.purchaseOrderId },
      this.handleAddSpecDetails
    );
  };

  handleEditDetailModal = () => {
    const { openModalDialog, spec, specId } = this.props;
    openModalDialog(
      ["Edit Spec Details", `${spec.customNumber} ${spec.description}`],
      "BulkEditSpecDetailsModal",
      { specId, purchaseOrderId: spec.purchaseOrderId, spec },
      false,
      true
    );
  };

  handleOpenNotesModal = () => {
    const { spec, openNotesModal } = this.props;
    openNotesModal(
      {
        projectId: spec.projectId,
        assignTo: generateValues([spec.purchaseOrder], [], [spec]),
      },
      { search: `"SPEC#${spec.customNumber}"` }
    );
  };

  openEditSpecDetailModal = specDetail => {
    const { spec, openModalDialog } = this.props;
    const parameters = {
      spec,
      specDetail,
      openModalDialog,
      purchaseOrderId: spec.purchaseOrderId,
    };

    const TypeToOpenMethodMap = {
      Description: () => openDescription(parameters),
      COM: () => openCOM(parameters),
      QCParBased: () => openPARBasedQuantityCalculation(parameters),
      QCCaseBased: () => openCaseBasedQuantityCalculation(parameters),
      Preview: () => openPreview(parameters),
    };

    TypeToOpenMethodMap[specDetail.type]();
  };

  handleViewPO = () => {
    const { spec, clientId, push } = this.props;
    push(
      `/clients/${clientId}/projects/${spec.projectId}/purchase-orders/${spec.purchaseOrderId}`
    );
  };

  handleOpenDeleteModal = () => {
    const {
      spec,
      openDeleteModalAction,
      clientId,
      projectId,
      backTo,
    } = this.props;
    openDeleteModalAction(dataComponentId, spec, clientId, projectId, {
      backToPO: backTo === "po",
    });
  };

  handleOpenDuplicateModal = () => {
    const { spec, openDuplicateModalAction, clientId, projectId } = this.props;
    openDuplicateModalAction(dataComponentId, spec, clientId, projectId, true);
  };

  getPageContext = memoizeOne((spec, clientId, projectId) => ({
    spec,
    clientId,
    projectId,
  }));

  render() {
    const {
      spec,
      clientId,
      loading,
      updateLoading,
      nextSpecId,
      lastSpecId,
      role,
      specId,
      projectId,
      backTo,
      bidGroupVendorId,
    } = this.props;

    const isVendorPortal = !!bidGroupVendorId;

    const projectContext = this.context;
    const isProjectClosed = getIsProjectClosed(projectContext);

    return (
      <LoaderContext.Provider value={{ loading }}>
        <LabeledTextContext.Provider
          value={
            isVendorPortal ||
            isPOChangeDisabled(spec?.purchaseOrder) ||
            isProjectClosed
          }
        >
          <PageContext.Provider
            value={this.getPageContext(spec, clientId, projectId)}
          >
            <SpecDetailContext.Provider
              value={{ onUpdateSpec: this.handleUpdateSpec }}
            >
              <SpecDetailPage
                nextSpecId={nextSpecId}
                lastSpecId={lastSpecId}
                backTo={backTo}
                spec={spec || {}}
                projectId={projectId}
                loading={loading}
                updateLoading={loading || updateLoading}
                onUpdateSpec={this.handleUpdateSpec}
                clientId={clientId}
                openAddDetailModal={this.openAddDetailModal}
                openEditSpecDetailModal={this.openEditSpecDetailModal}
                onOpenNotesModal={this.handleOpenNotesModal}
                onEditDetailModal={this.handleEditDetailModal}
                onViewPO={this.handleViewPO}
                onOpenDeleteSpec={this.handleOpenDeleteModal}
                onOpenDuplicateSpec={this.handleOpenDuplicateModal}
                role={role}
                revisionReference={`specs.${specId}`}
              />
            </SpecDetailContext.Provider>
          </PageContext.Provider>
        </LabeledTextContext.Provider>
      </LoaderContext.Provider>
    );
  }
}

SpecDetailContainer.propTypes = {
  openModalDialog: PropTypes.func.isRequired,
  isSelectCOM: PropTypes.bool,
  initDataComponent: PropTypes.func,
  layout: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool,
  updateLoading: PropTypes.bool,
  performRetrieveListRequest: PropTypes.func,
  performFindRequest: PropTypes.func,
  updateSpec: PropTypes.func,
  openNotesModal: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func,
  setAutoSaveComponentId: PropTypes.func,
  openDuplicateModalAction: PropTypes.func,
  openDeleteModalAction: PropTypes.func,
  initSpecDetail: PropTypes.func,
  specId: PropTypes.string,
  spec: propTypes.spec,
  clientId: PropTypes.string,
  projectId: PropTypes.string,
  nextSpecId: PropTypes.string,
  lastSpecId: PropTypes.string,
  specDetailsChildrenDataComponent: propTypes.dataComponent,
  specDetailsComDataComponent: propTypes.dataComponent,
  push: PropTypes.func,
  role: propTypes.userRole,
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
  performUpdateRequest: PropTypes.func,
  addSpecDetailsDataComponent: propTypes.dataComponent,
  bidGroupVendorId: PropTypes.string,
  history: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withPORevision(SpecDetailContainer, "SpecDetail", "purchaseOrder"))
);
