/* eslint-disable no-dupe-class-members */
import get from "lodash.get";

const toleranceMap = {
  freightWarehousing: 175,
  discount: 1,
};

export const roundInvoiceVal = value => {
  return Math.round(value * 100) / 100;
};

export default class DataRow {
  /**
   * @type {string}
   */
  #row;
  /**
   * @type {number}
   */
  #assetsPaid;
  /**
   * @type {number}
   */
  #depositsPaid;
  /**
   * @type {number}
   */
  #otherAssetsPaid;
  /**
   * @type {number}
   */
  #otherDepositsPaid;
  /**
   * @type {number}
   */
  #maximum;
  constructor(invoice, row) {
    this.#row = row;

    this.#maximum = get(invoice, `${row}Maximum`, 0);

    this.#assetsPaid = get(invoice, `${row}AssetsPaid`, 0);
    this.#depositsPaid = get(invoice, `${row}DepositsPaid`, 0);

    this.#otherAssetsPaid = get(invoice, `${row}OtherAssetsPaid`, 0);
    this.#otherDepositsPaid = get(invoice, `${row}OtherDepositsPaid`, 0);
  }

  get assetsPaid() {
    return roundInvoiceVal(this.#assetsPaid);
  }

  set assetsPaid(value) {
    this.#assetsPaid = value;
  }

  get depositsPaid() {
    return roundInvoiceVal(this.#depositsPaid);
  }

  set depositsPaid(value) {
    this.#depositsPaid = value;
  }

  get net() {
    return roundInvoiceVal(this.#assetsPaid + this.#depositsPaid);
  }

  get assetsToDate() {
    return roundInvoiceVal(this.#assetsPaid + this.#otherAssetsPaid);
  }

  get depositsToDate() {
    return roundInvoiceVal(this.#depositsPaid + this.#otherDepositsPaid);
  }

  get netToDate() {
    return roundInvoiceVal(this.assetsToDate + this.depositsToDate);
  }

  get notProcessed() {
    const notProcessed = this.rawNotProcessed;
    const notProcessedWithTolerance = this.#tolerance + notProcessed;

    if (
      notProcessedWithTolerance >= 0 &&
      notProcessedWithTolerance <= this.#tolerance
    ) {
      return 0;
    }

    return roundInvoiceVal(notProcessed);
  }

  get rawNotProcessed() {
    return roundInvoiceVal(this.#maximum - this.netToDate);
  }

  get #hasTolerance() {
    return Boolean(toleranceMap[this.#row]);
  }

  get #tolerance() {
    if (this.#hasTolerance) {
      return this.#maximum == 0 ||
        this.rawNotProcessed >= -toleranceMap[this.#row]
        ? toleranceMap[this.#row]
        : 0;
    }

    return 0;
  }

  get notProcessedAssetsPaid() {
    const tolerance = this.#hasTolerance ? toleranceMap[this.#row] : 0;
    return roundInvoiceVal(
      tolerance + this.#maximum - (this.netToDate - this.assetsPaid)
    );
  }

  get notProcessedDepositsPaid() {
    const tolerance = this.#hasTolerance ? toleranceMap[this.#row] : 0;
    return roundInvoiceVal(
      tolerance + this.#maximum - (this.netToDate - this.depositsPaid)
    );
  }
}
