import { inputs } from "../../../inputs/inputConfigs";

const path = "contact";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.firstName,
            name: `${path}.person.${inputs.firstName.name}`,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.lastName,
            name: `${path}.person.${inputs.lastName.name}`,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.phone,
            label: "Phone Number",
            name: `${path}.person.phoneNumber`,
            required: false,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Fax",
            name: `${path}.person.faxNumber`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Cell Number",
            name: `${path}.person.cellNumber`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Work Number",
            name: `${path}.person.workNumber`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.contactRole,
            name: `${path}.${inputs.contactRole.name}`,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.email,
            name: `${path}.person.${inputs.email.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.canAccessClientPortal,
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.status,
            name: `${path}.${inputs.status.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.clientContactType,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
