import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import Paper from "../../../mui/core/Paper";
import { ActionButtons } from "../../../forms";
import propTypes from "../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { dataComponentId } from "../SpecsContainer";
import {
  mapStateToProps,
  processRequestResponse,
} from "../CompleteSpecs/CompleteSpecsConfirmationContainer";

const Title = styled.div`
  padding: 24px 24px 24px 24px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 0px;

  ${Paper} {
    box-shadow: none;
    overflow-y: auto;
    max-height: 300px;
  }
`;

export const FlagAllowanceSpecs = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
  specs,
  showSnackNotificationAction,
  isAllowance,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      showSnackNotificationAction
    )
  );

  const onSend = useCallback(() => {
    const updatedSpecs = specs.map(spec => ({
      id: spec.id,
      isAllowance,
    }));
    performUpdateRequest(dataComponentId, updatedSpecs);
  }, [performUpdateRequest, specs, isAllowance]);

  return (
    <StyledPaper>
      <Title>
        Are you sure you want to {isAllowance ? "add" : "remove"} the allowance
        flag for {specs.length} Specs?
      </Title>
      <ActionButtons
        onSend={onSend}
        sendButtonText={
          isAllowance ? "Add Allowance Flag" : "Remove Allowance Flag"
        }
        isModal
        listeners={[dataComponent.dataComponentId]}
      />
    </StyledPaper>
  );
};

FlagAllowanceSpecs.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec),
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  isAllowance: PropTypes.bool.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlagAllowanceSpecs);
