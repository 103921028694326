import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import VendorDetailPage from "./VendorDetailPage";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
} from "../../../actions/dataComponentActions";
import Vendor from "../../../models/Vendor";
import VendorContact from "../../../models/VendorContact";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../actions/layoutActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";
import propTypes from "../../../constants/propTypes";

const dataComponentId = "VendorDetail";
export const vendorContactsDataComponentId = "VendorContactDataComponentId";

export class VendorDetailContainer extends Component {
  componentDidMount() {
    const { initDataComponent, performFindRequest, vendorId } = this.props;

    initDataComponent(
      dataComponentId,
      Vendor,
      ["parent", "category", "location"],
      "vendors"
    );
    performFindRequest(dataComponentId, vendorId);
    initDataComponent(
      vendorContactsDataComponentId,
      VendorContact,
      ["contact.person"],
      "vendor-contacts"
    );
    performRetrieveListRequest(vendorContactsDataComponentId, {
      pageSize: -1,
    });
  }

  handleEditVendor = () => {
    const { pushWithReturnUrl, vendorId } = this.props;
    pushWithReturnUrl(`/vendors/edit/${vendorId}`, "VENDOR DETAIL");
  };

  handleDeleteVendor = () => {
    const {
      vendor: { name: title, id },
    } = this.props;
    this.props.openModalDialog(
      `${title}`,
      "DeleteVendor",
      { id, title, goBackOnSuccess: true },
      true,
      false
    );
  };

  render() {
    const { vendor, route } = this.props;
    if (!vendor || !vendor.category) {
      return null;
    }

    return (
      <VendorDetailPage
        route={route}
        vendor={vendor}
        category={vendor.category}
        parentVendor={vendor.parent}
        onEditVendor={this.handleEditVendor}
        onDeleteVendor={this.handleDeleteVendor}
      />
    );
  }
}

VendorDetailContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func,
  openModalDialog: PropTypes.func,
  dataComponent: PropTypes.object,
  vendorId: PropTypes.string,
  vendor: PropTypes.object,
  route: propTypes.route,
};

export const mapDispatchToProps = {
  pushWithReturnUrl,
  initDataComponent,
  performFindRequest,
  openModalDialog,
};

export const mapStateToProps = (state, ownProps) => {
  const vendorId = ownProps.match.params.vendorId;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    vendorId,
    dataComponent,
    vendor: getBluechipResourceById(dataComponent, state, vendorId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorDetailContainer);
