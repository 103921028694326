import React, { useState } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";

import CreateVendorContactForm from "./CreateVendorContactForm";
import SelectFromAddressBookForm from "./SelectFromAddressBookForm";

export const parseContactStatus = contact => {
  if (Array.isArray(contact.contactStatus)) {
    contact.contactStatus = contact.contactStatus.join(",");
  }
};

const CreateVendorContactContainer = ({
  formikArrayHelpers,
  closeModalDialog,
  vendor,
  index,
  onCreateVendorContact,
  onUpdateVendorContact,
  toggleCreateNewContact,
  ignoreAddressBook,
  dataComponentId,
  formikPropsRef,
}) => {
  const validationSchema = () => {
    return BWModels.loadSchema("VendorContact").concat(
      Joi.object().keys({
        contact: BWModels.loadSchema("Contact").concat(
          Joi.object().keys({
            person: BWModels.loadSchema("Person"),
          })
        ),
      })
    );
  };
  const isEditing = typeof index !== "undefined";
  const [createNewContact, setCreateNewContact] = useState(ignoreAddressBook);
  const handleToggleCreateNewContact = () => {
    toggleCreateNewContact(!createNewContact);

    setCreateNewContact(!createNewContact);
  };
  const handleCreateContact = contact => {
    parseContactStatus(contact);
    onCreateVendorContact(contact, formikArrayHelpers);
  };
  const handleEditContact = contact => {
    parseContactStatus(contact);
    onUpdateVendorContact(contact, index, formikArrayHelpers);
  };
  if (createNewContact) {
    return (
      <CreateVendorContactForm
        ignoreAddressBook={ignoreAddressBook}
        vendor={vendor}
        isEditing={isEditing}
        onSubmit={isEditing ? handleEditContact : handleCreateContact}
        validationSchema={validationSchema()}
        toggleCreateNewContact={handleToggleCreateNewContact}
        formikPropsRef={formikPropsRef}
        dataComponentId={dataComponentId}
      />
    );
  } else {
    return (
      <SelectFromAddressBookForm
        vendor={vendor}
        onSubmit={handleCreateContact}
        validationSchema={validationSchema()}
        closeModalDialog={closeModalDialog}
        toggleCreateNewContact={handleToggleCreateNewContact}
      />
    );
  }
};

CreateVendorContactContainer.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  vendor: propTypes.vendor.isRequired,
  onCreateVendorContact: PropTypes.func,
  onUpdateVendorContact: PropTypes.func,
  toggleCreateNewContact: PropTypes.func,
  contact: propTypes.contact,
  index: PropTypes.string,
  ignoreAddressBook: PropTypes.bool,
  formikPropsRef: PropTypes.shape({}),
};
CreateVendorContactContainer.defaultProps = {
  ignoreAddressBook: false,
};

export default CreateVendorContactContainer;
