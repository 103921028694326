import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../actions/layoutActions";
import {
  performCreateRequest,
  setReload,
  initDataComponent,
} from "../../../actions/dataComponentActions";
import propTypes from "../../../constants/propTypes";
import ReviewSubmittals from "./ReviewSubmittals";
import CreateForm from "./CreateForm";
import ControlButtons from "../Notes/ControlButtons";
import SpecRequirement from "../../../models/SpecRequirement";
import SubmittalFile from "../../../models/SubmittalFile";
import {
  dataComponentId as requirementsDatacomponentId,
  submittalsDataComponentId,
} from "./RequirementsGrid/RequirementsGridContainer";
import withSelectedRowsAPI from "../../ui/BWGrid/withSelectedRowsAPI";
import { ShowBulkUploadSubmittalsContext } from "./ShowBulkUploadSubmittalsContext";

const dataComponentId = "ReviewSubmittals";

const CreateShipmentContainer = styled.div`
  height: 100%;
  form {
    height: 100%;
  }
`;

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`;

export const ReviewSubmittalsContainer = ({
  purchaseOrderId,
  dataComponent,
  initDataComponent,
}) => {
  const [selectedSpecs, setSelectedSpecs] = useState([]);
  const [showBulkUploadSubmittals, setShowBulkUploadSubmittals] = useState(
    false
  );

  useEffect(() => {
    initDataComponent(
      requirementsDatacomponentId,
      SpecRequirement,
      ["spec.specRequirements.requirement"],
      "spec-requirements",
      true
    );

    initDataComponent(
      submittalsDataComponentId,
      SubmittalFile,
      ["specRequirement.spec.specRequirements.requirement"],
      "submittal-files",
      true
    );
  }, [initDataComponent]);

  return (
    <ShowBulkUploadSubmittalsContext.Provider
      value={{ showBulkUploadSubmittals, setShowBulkUploadSubmittals }}
    >
      <CreateShipmentContainer>
        <Wrapper>
          <ReviewSubmittals
            purchaseOrderId={purchaseOrderId}
            dataComponent={dataComponent}
            setSelectedSpecs={setSelectedSpecs}
          />
          <CreateForm selectedSpecs={selectedSpecs} />
          <ControlButtons />
        </Wrapper>
      </CreateShipmentContainer>
    </ShowBulkUploadSubmittalsContext.Provider>
  );
};

ReviewSubmittalsContainer.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
  performCreateRequest: PropTypes.func,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  return {
    dataComponent,
  };
};

export const mapDispatchToProps = {
  performCreateRequest,
  closeModalDialog,
  showSnackNotificationAction,
  setReload,
  initDataComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSelectedRowsAPI(ReviewSubmittalsContainer));
