import React, { Fragment, useCallback, useMemo } from "react";
import get from "lodash.get";
import _omit from "lodash/omit";
import _omitBy from "lodash/omitBy";
import _isEmpty from "lodash/isEmpty";
import { Divider } from "@material-ui/core";

import { ActionButtons, FormGrid, FormikForm } from "../../../../forms";
import { inputs } from "../../../../inputs/inputConfigs";
import { GroupLabel } from "../../../../inputs/ProgramInput/components";
import { useWithSelectedRowContext } from "../../../../ui/BWGrid/withSelectedRows";

const getFields = (categories, areas, vendors, projectCurrencies) => ({
  groups: [
    {
      items: [
        {
          element: <GroupLabel>Update Selected Specs</GroupLabel>,
          grid: { xs: 12 },
        },
        {
          element: <Divider />,
          grid: { xs: 12 },
        },
        {
          input: {
            ..._omit(inputs.specCategory, "APIOptions"),
            name: "specCategory",
            options: categories,
            required: false,
            submitData: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ..._omit(inputs.area, "APIOptions"),
            name: "area",
            options: areas,
            required: false,
            submitData: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ..._omit(inputs.vendorAutocomplete, "APIOptions"),
            name: "vendor",
            options: vendors,
            required: false,
            submitData: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ..._omit(inputs.projectCurrency, "APIOptions"),
            name: "projectCurrency",
            options: projectCurrencies,
            required: false,
            submitData: true,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

export const UpdateSpecs = ({
  rows,
  setRows,
  setDisplayUpdateSpecs,
  categories,
  areas,
  vendors,
  projectCurrencies,
}) => {
  const { selectedIds } = useWithSelectedRowContext();

  const fields = useMemo(
    () => getFields(categories, areas, vendors, projectCurrencies),
    [areas, categories, projectCurrencies, vendors]
  );

  const handleCancel = useCallback(() => {
    setDisplayUpdateSpecs(false);
  }, [setDisplayUpdateSpecs]);

  const handleSubmit = useCallback(
    values => {
      const updatedValues = _omitBy(values, _isEmpty);
      Object.keys(updatedValues).forEach(key => {
        updatedValues[`${key}Id`] = get(updatedValues, `${key}.id`);
      });
      setRows(
        rows.map(row =>
          selectedIds.includes(row.id) ? { ...row, ...updatedValues } : row
        )
      );
      handleCancel();
    },
    [handleCancel, rows, selectedIds, setRows]
  );

  return (
    <FormikForm
      initialValues={{
        specCategory: null,
        area: null,
        vendor: null,
        projectCurrency: null,
      }}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Fragment>
          <FormGrid {...formikProps} fields={fields} />
          <ActionButtons
            onSend={formikProps.handleSubmit}
            onCancel={handleCancel}
            isModal
          />
        </Fragment>
      )}
    </FormikForm>
  );
};
