import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";

import { getDataComponent } from "../../../reducers/dataComponentReducer";
import { UserDetailsForm } from "../../layout/AppLayout/AccountSettings/UserDetailsForm";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import { updateUser, initUserComponent } from "../../../actions/usersActions";
import { updateUserDataComponentId } from "../../layout/AppLayout/AccountSettings/AccountSettings";
import {
  logout,
  sendVerificationCode,
  validateAuthentication,
} from "../../../actions/authActions";
import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../actions/layoutActions";

import {
  Container,
  Title,
  Page,
  AuthFormContainer,
  AuthFormWrapper,
} from "./components";
import SnackNotificationContainer from "../../ui/SnackNotifications/SnackNotificationContainer";
import VerifyCode from "./VerifyCode";
import { BWLink } from "../../ui/BWLink";

export const VerifyUserDetails = ({
  userData,
  actions,
  sendingVerificationCode,
}) => {
  const [sendToEmailOnly, setSendToEmailOnly] = useState(false);

  const children = sendToEmailOnly ? (
    <VerifyCode sendToEmailOnly />
  ) : (
    <Fragment>
      <UserDetailsForm userData={userData} actions={actions} forceVerify />
      <BWLink
        disabled={sendingVerificationCode}
        onClick={() => {
          setSendToEmailOnly(true);
          actions.sendVerificationCode({ sendToEmailOnly: true });
        }}
      >
        Send Code Via Email
      </BWLink>
    </Fragment>
  );

  return (
    <Fragment>
      <SnackNotificationContainer />
      <Page>
        <Container>
          <Title />
          <AuthFormContainer>
            <AuthFormWrapper>{children}</AuthFormWrapper>
          </AuthFormContainer>
        </Container>
      </Page>
    </Fragment>
  );
};

VerifyUserDetails.propTypes = {
  userData: PropTypes.object,
  actions: PropTypes.object,
  sendingVerificationCode: PropTypes.bool,
};

export const mapStateToProps = ({
  auth: {
    cellNumber,
    userId,
    email,
    name,
    profilePicture,
    sendingVerificationCode,
  },
  ...state
}) => {
  const dataComponent = getDataComponent(
    updateUserDataComponentId,
    state,
    REQUEST_TYPES.UPDATE
  );

  return {
    userData: {
      cellNumber,
      profilePicture,
      email,
      name,
      id: userId,
    },
    dataComponent,
    sendingVerificationCode,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        updateUser,
        initUserComponent,
        closeModalDialog,
        openModalDialog,
        showSnackNotificationAction,
        validateAuthentication,
        logout,
        push,
        sendVerificationCode,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUserDetails);
