import React, { useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import styled from "styled-components";
import Cancel from "@material-ui/icons/Cancel";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import Term from "../../../../models/Term";
import Paper from "../../../mui/core/Paper";
import BulkActions from "./BulkActions";
import { getOptionsFromSchema } from "../../../inputs/utils";
import LinesEllipsis from "../../../ui/LinesEllipsis";
import withSelectedRowsAPI from "../../../ui/BWGrid/withSelectedRowsAPI";
import propTypes from "../../../../constants/propTypes";
import {
  RelationColumn,
  relationFilter,
} from "../../Settings/Terms/GlobalTerms/TermPage";

const columns = ["ord", "content", "relation"];

const getRowMenu = onOpenDeleteModal => () => {
  const defaultOptions = [
    { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
  ];

  return defaultOptions;
};

const VerticalCenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const getColumnsOptions = () => ({
  ord: {
    title: "Order",
    bold: true,
    filter: true,
    filterOptions: {
      operator: "equal",
    },
    dragHandle: true,
    render: ({ displayOrder }) => displayOrder,
    parseSort: sort => {
      return { ...sort, columnName: "ord" };
    },
  },
  content: {
    title: "Description",
    fill: true,
    filter: true,
    render: ({ content }) => {
      return (
        content && (
          <VerticalCenterWrapper>
            <LinesEllipsis>{content}</LinesEllipsis>
          </VerticalCenterWrapper>
        )
      );
    },
  },
  relation: {
    title: "Relation",
    filter: "select",
    sortingEnabled: false,
    filterOptions: {
      options: getOptionsFromSchema("Term", "relation")(),
      operator: relationFilter,
    },
    render: RelationColumn,
  },
});

const isDeleteDisabled = selectedRows =>
  new Set(selectedRows.map(({ scopeId }) => scopeId)).size > 1;

const TermPage = ({
  dataComponent,
  onOpenDeleteModal,
  onOpenBulkDeleteModal,
  onReorder,
  additionalFilters,
  selectedRows,
  entity,
}) => {
  const columnOptions = getColumnsOptions();

  const filters = useMemo(() => {
    return {
      rootFilters: {
        $where: {
          id: {
            $in: entity.termIdSequence,
          },
          ...additionalFilters,
        },
      },
      params: {
        entityId: entity.id,
      },
    };
  }, [additionalFilters, entity]);

  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Term}
        apiRoute="terms"
        showSelectAll
        showSelectionColumn
        apiFilters={filters}
        includes={["scope"]}
        defaultSorting={[]}
        onReorder={onReorder}
        ignoreOptimizations
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} ${pluralize(
            "Term",
            dataComponent.totalRows
          )}`}
          headerOverride={
            <BulkActions
              dataComponent={dataComponent}
              onBulkDelete={onOpenBulkDeleteModal}
              isDeleteDisabled={isDeleteDisabled(selectedRows)}
            />
          }
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={getRowMenu(onOpenDeleteModal)}
        />
      </BWGridAPI>
    </Paper>
  );
};

TermPage.defaultProps = {
  additionalFilters: {},
};
TermPage.propTypes = {
  additionalFilters: PropTypes.shape({}),
  dataComponent: PropTypes.object.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  onOpenBulkDeleteModal: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  entity: propTypes.entity.isRequired,
  selectedRows: PropTypes.array,
};
export default withSelectedRowsAPI(TermPage);
