import React, { useEffect } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  getBluechipResourceById,
  getBluechipResources,
} from "../../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import Revision from "../../../../../models/Revision";
import propTypes from "../../../../../constants/propTypes";
import RevisionActivities from "./RevisionActivities";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import User from "../../../../../models/User";

const dataComponentId = "revisionsComponent";
const dataCurrencyId = "select-currencies";
const dataUsersId = "select-users";

const RevisionActivitiesContainer = ({
  revisionId,
  initDataComponent,
  performFindRequest,
  revision,
  performRetrieveListRequest,
  purchaseOrder,
  loading,
  changesMade,
}) => {
  useEffect(() => {
    if (!revisionId) return;
    initDataComponent(
      dataComponentId,
      Revision,
      ["revisionActivities.user.person"],
      "revisions"
    );
    initDataComponent("select-users", User, [], "users", undefined, "v2");
    performFindRequest(dataComponentId, revisionId);
    performRetrieveListRequest(dataCurrencyId, {
      sort: [{ columnName: "id", direction: "asc" }],
      pageSize: -1,
    });
    performRetrieveListRequest(dataUsersId, {
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
    });
  }, [
    initDataComponent,
    performFindRequest,
    performRetrieveListRequest,
    revisionId,
  ]);

  return (
    <RevisionActivities
      revision={revision}
      loading={loading}
      purchaseOrder={purchaseOrder}
      changesMade={changesMade}
    />
  );
};

RevisionActivitiesContainer.propTypes = {
  revisionId: PropTypes.string,
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  revision: propTypes.revision,
  purchaseOrder: propTypes.purchaseOrder,
  loading: PropTypes.bool,
  changesMade: PropTypes.number,
};

export const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const dataCurrencyComponent = getDataComponentFlattenedRequestState(
    dataCurrencyId,
    state
  );
  const loading = _get(dataComponent, "requestState.find.loading");
  return {
    dataComponent,
    currencies: getBluechipResources(dataCurrencyComponent, state),
    loading: loading !== undefined ? loading : true,
    revision: getBluechipResourceById(
      dataComponent,
      state,
      ownProps.revisionId
    ),
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RevisionActivitiesContainer)
);
