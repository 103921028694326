import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import { buildTextTypeProvider } from "../../../ui/Grid/filterDataTypeProviders";
import { useFlattenedDatacomponent } from "../../../hooks/useDatacomponent";
import PagingPaneWithStatusSwitch from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { genDetailTableComponents } from "../../../ui/BWGrid/genDetailTableComponents";
import Project from "../../../../models/Project";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import { columns, getColumnOptions } from "./gridProps";
import ShippingPayment from "../../../../models/ShippingPayment";
import ShippingPayments from "./ShippingPayments";
import { getDataComponentId } from "./ShippingPayments/ShippingPaymentsContainer";
import { UploadButton } from "./UploadButton";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import Summary from "./Summary";
import { Paper } from "../../../mui/core";
import { loadPayments, useInitComponents } from "./gridFunctions";
import { Actions } from "./Actions";

export const dataComponentId = "billing-shipping-payments-table";

export const Grid = ({
  loading,
  initDataComponent,
  performRetrieveListRequest,
  openModalDialog,
}) => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);

  useInitComponents(initDataComponent);

  const filteringDataTypeProviders = useMemo(
    () => [buildTextTypeProvider(["name", "number"])],
    []
  );
  const formatter = useCurrencyFormatter();

  const handleExpand = ({ id }) => {
    const dataComponentId = getDataComponentId(id);
    initDataComponent(
      dataComponentId,
      ShippingPayment,
      [],
      "shipping-payments",
      true,
      "v2"
    );
    loadPayments(performRetrieveListRequest, dataComponentId, id);
  };

  const GridHeaderComponent = useHeaderComponent({
    headerText: `${pluralize("Project", dataComponent.totalRows, true)}`,
  });
  const tableComponents = useMemo(
    () => ({
      PagingPanelTemplate: PagingPaneWithStatusSwitch,
      GridHeader: GridHeaderComponent,
      ...genDetailTableComponents({ colSpan: 7, prevColSpan: 0 }),
      DetailRowComponent: ShippingPayments,
    }),
    [GridHeaderComponent]
  );

  return (
    <Fragment>
      <Summary />
      <Paper>
        <BWGridAPI
          model={Project}
          apiRoute="projects"
          includes={["projectLead.person"]}
          defaultSorting={[{ columnName: "number", direction: "asc" }]}
          apiFilters={{
            rootFilters: {
              $where: {},
            },
            params: {
              modifiers: ["withShippingPaymentTotals"],
            },
          }}
          displayCollapseButton
          dataComponent={dataComponent}
          columnExtensions={[]}
          tableComponents={tableComponents}
          filteringDataTypeProviders={filteringDataTypeProviders}
          onExpand={handleExpand}
        >
          <GridHeader
            headerText={`${dataComponent.totalRows} Projects`}
            actions={[
              {
                actionOverride: (
                  <UploadButton
                    key={"upload-button"}
                    loading={loading}
                    openModalDialog={openModalDialog}
                  />
                ),
              },
            ]}
            headerOverride={<Actions dataComponent={dataComponent} />}
          />
          <GridTable
            columns={columns}
            columnOptions={getColumnOptions(formatter)}
          />
        </BWGridAPI>
      </Paper>
    </Fragment>
  );
};

Grid.propTypes = {
  loading: PropTypes.bool,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
};
