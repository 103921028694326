import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrid from "../../../forms/FormGrid";
import { FormikForm, ActionButtons } from "../../../forms";
import Joi from "joi-browser";
import BWModels from "benjaminwest-models";
import createConsultingCompanyContactFields from "./createConsultingCompanyContactFields";
import propTypes from "../../../../constants/propTypes";
import DuplicatePersonDialog from "../../../DuplicatePersonDialog";

const getValidationSchema = () =>
  BWModels.loadSchema("ConsultingCompanyContact").concat(
    Joi.object().keys({
      contact: BWModels.loadSchema("Contact").concat(
        Joi.object().keys({
          person: BWModels.loadSchema("Person"),
        })
      ),
    })
  );

const ConsultingCompanyContactForm = ({
  onSubmit,
  sendButtonText,
  consultingCompanyContact,
  dataComponentId,
}) => (
  <FormikForm
    initialValues={consultingCompanyContact}
    onSubmit={onSubmit}
    enableReinitialize={true}
    validationSchema={getValidationSchema()}
    ignoreValidationFieldNames={["name", "contactId"]}
  >
    {({ handleSubmit, values, ...formikProps }) => (
      <Fragment>
        <FormGrid
          values={values}
          fields={createConsultingCompanyContactFields}
          {...formikProps}
        />
        <DuplicatePersonDialog
          dataComponentId={dataComponentId}
          contactRelationId={consultingCompanyContact.consultingCompanyId}
        />
        <ActionButtons
          isModal={true}
          onSend={handleSubmit}
          sendButtonText={sendButtonText}
          listeners={[dataComponentId]}
        />
      </Fragment>
    )}
  </FormikForm>
);

ConsultingCompanyContactForm.propTypes = {
  onSubmit: PropTypes.func,
  sendButtonText: PropTypes.string,
  consultingCompanyContact: propTypes.consultingCompanyContact,
  dataComponentId: PropTypes.string.isRequired,
};

export default ConsultingCompanyContactForm;
