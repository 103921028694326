import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import _get from "lodash.get";
import AddCircle from "@material-ui/icons/AddCircle";
import memoizeOne from "memoize-one";
import BWModels from "benjaminwest-models";

import TagText from "../../ui/Tags/TagText";
import contactConstants from "../../../constants/Contact";
import VendorContact from "../../../models/VendorContact";
import { getContactStatus } from "./utils";
import BoldText from "../../ui/Typography/BoldText";
import Paper from "../../mui/core/Paper";

import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import { getVendorStatusOptions } from "./CreateVendorContact/CreateVendorContactForm/createVendorContactFields";

import { RowComponent } from "../../ui/BWGrid/helperComponents/EditableTextDetailRow/genDetailRowComponents/v2";
import { genDetailRowComponents } from "../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";

const TagsWrapper = styled.div`
  display: flex;
  max-width: 410px;
  flex-wrap: wrap;
`;

const ContactNumbers = styled.div`
  white-space: pre;
`;

export const ContactStatusPill = ({ row, vendor }) => {
  if (!row.contactStatus) return null;

  const tags = row.contactStatus.split(",").map(status => {
    const contactStatusInfo = contactConstants.vendorContactStatusMap[status];
    const contactStatus = getContactStatus(vendor, status);

    return (
      <TagText
        key={status}
        intent={
          contactStatusInfo && contactStatusInfo.intent
            ? contactStatusInfo.intent
            : ""
        }
        style={{
          width: "195px",
          marginRight: "10px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        {contactStatus}
      </TagText>
    );
  });

  return <TagsWrapper>{tags}</TagsWrapper>;
};
ContactStatusPill.propTypes = {
  row: PropTypes.object.isRequired,
  vendor: PropTypes.object,
};
export const RenderContactNameRow = row => {
  if (row.comments && row.comments.length > 0) {
    return (
      <Wrapper>
        <NameWrapper>{_get(row, "contact.name")}</NameWrapper>
      </Wrapper>
    );
  }
  return <BoldText>{_get(row, "contact.name")}</BoldText>;
};
RenderContactNameRow.propTypes = {
  row: PropTypes.object.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
Wrapper.displayName = "Wrapper";

const NameWrapper = styled(props => <BoldText {...props} />)`
  flex-grow: 0;
  padding: 0;
  margin: 0;
`;
NameWrapper.displayName = "NameWrapper";

const StyledIconButton = styled(props => <IconButton {...props} />)`
  height: 16px;
  width: 16px;
  padding: 0;
  color: #57abff;
  flex-grow: 0;
  margin-left: 8px;
`;
StyledIconButton.displayName = "StyledIconButton";

export const getRowMenu = (
  handleEditContact,
  handleDeleteContact,
  handleResendAccessEmail
) => row => {
  const options = [
    { text: "Edit", onClick: handleEditContact },
    { text: "Delete", onClick: handleDeleteContact },
  ];
  if (row.canAccessVendorPortal) {
    options.push(
      { separator: true },
      {
        text: "Resend Access Email",
        onClick: handleResendAccessEmail,
      }
    );
  }
  return options;
};

const columns = [
  "contact.name",
  "contact.jobTitle",
  "contactStatus",
  "isActive",
  "contact.phone",
  "contact.email",
];

const getColumnOptions = (statusMap, vendor) => {
  return {
    "contact.name": {
      title: "Name",
      bold: true,
      filter: true,
      render: row => RenderContactNameRow(row),
    },
    "contact.jobTitle": { title: "Job Title", filter: true },
    contactStatus: {
      title: "Role",
      filter: "select",
      filterOptions: {
        operator: "ilike",
        options: getVendorStatusOptions(vendor),
      },
      render: row => row.id && <ContactStatusPill row={row} vendor={vendor} />,
    },
    isActive: {
      title: "Status",
      filter: "select",
      filterOptions: {
        initialOption: true,
        options: [
          { id: true, name: "Active" },
          { id: false, name: "Inactive" },
        ],
      },
      render: row => {
        const isActive = _get(row, "isActive", false);
        const text = isActive ? "Active" : "Inactive";
        return row.id && <TagText>{text}</TagText>;
      },
    },
    "contact.phone": {
      title: "Contact Numbers",
      align: "left",
      render: row => {
        const numberProperties = [
          {
            key: "phoneNumber",
            prefix: "P",
          },
          {
            key: "faxNumber",
            prefix: "F",
          },
          {
            key: "cellNumber",
            prefix: "C",
          },
          {
            key: "workNumber",
            prefix: "W",
          },
        ];
        const contact = _get(row, "contact", {});
        return (
          <ContactNumbers>
            {numberProperties
              .filter(({ key }) => !!contact[key])
              .map(({ key, prefix }) => `${prefix}: ${contact[key]}`)
              .join(",\n")}
          </ContactNumbers>
        );
      },
    },
    "contact.email": { title: "Email" },
  };
};

const getTableComponents = memoizeOne(dataComponentId => {
  const detailRowComponents = genDetailRowComponents(
    "id",
    [
      {
        path: "comments",
        label: "Comments",
        placeholder: "Add Comment",
        isEmptyFn: Boolean,
      },
    ],
    BWModels.loadSchema("VendorContact"),
    dataComponentId,
    { prevSpan: 0, colSpan: 6, posSpan: 1 },
    {
      debounceDelay: 2000,
    }
  );
  return {
    ...detailRowComponents,
    RowComponent,
  };
});

const VendorContactsPage = ({
  dataComponent,
  handleAddContact,
  handleEditContact,
  handleDeleteContact,
  handleResendAccessEmail,
  vendorId,
  statusMap,
  vendor,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={VendorContact}
        includes={["contact.person"]}
        apiRoute={"vendor-contacts"}
        rootFilters={{
          $where: {
            vendorId: vendorId,
          },
        }}
        defaultSorting={[
          {
            columnName: "contact.name",
            direction: "asc",
          },
        ]}
        tableComponents={getTableComponents(dataComponent.dataComponentId)}
        alwaysDisplayDetailRow
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Contacts`}
          actions={[
            {
              text: "ADD CONTACT",
              icon: <AddCircle />,
              handler: handleAddContact,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={getColumnOptions(statusMap, vendor)}
          rowMenu={getRowMenu(
            handleEditContact,
            handleDeleteContact,
            handleResendAccessEmail
          )}
        />
      </BWGridAPI>
    </Paper>
  );
};

VendorContactsPage.propTypes = {
  dataComponent: PropTypes.shape({}),
  statusMap: PropTypes.shape({}),
  handleAddContact: PropTypes.func,
  handleEditContact: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  handleResendAccessEmail: PropTypes.func,
  vendor: PropTypes.shape({}),
  vendorId: PropTypes.string,
};

export default VendorContactsPage;
