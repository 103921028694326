import React, { Fragment } from "react";

import { cashflowFileField, projectBudgetFileField } from "../fundingFields";
import FormGrid from "../../../../../../forms/FormGrid";
import { TextWrapperWithLoading } from "../components";
import { useFormikContext } from "formik";

export const CashflowDocuments = () => {
  const formikProps = useFormikContext();

  const { values, errors } = formikProps;

  if (values.method == "Batch") {
    return null;
  }

  return (
    <Fragment>
      <TextWrapperWithLoading>
        {values.id
          ? "Cashflow Documents"
          : "Please upload your Cashflow file to provide documentation for this request."}
      </TextWrapperWithLoading>
      <FormGrid
        fields={cashflowFileField(values)}
        values={values}
        errors={errors}
        {...formikProps}
      />
      <TextWrapperWithLoading>
        {values.id
          ? null
          : "Please upload your Project Budget file to provide documentation for this request."}
      </TextWrapperWithLoading>
      <FormGrid
        fields={projectBudgetFileField(values)}
        values={values}
        errors={errors}
        {...formikProps}
      />
    </Fragment>
  );
};
