import React, { useEffect, useState } from "react";
import {
  initDataComponent,
  performFindRequest,
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  fetchData,
} from "../../../actions/dataComponentActions";
import { connect } from "react-redux";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import PropTypes from "prop-types";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../actions/layoutActions";
import AddressBookContainer from "./AddressBookContainer";
import {
  onCreateContact,
  onUpdateContact,
  onDeleteContact,
  processRequests,
} from "./AddressBookFunctions";
import Contact from "../../../models/Contact";

export const addressBookDataComponentId = "AddressBookContacts";
export const contactDataComponentId = "Contact";

const AddressBookComponent = props => {
  const {
    initDataComponent,
    performCreateRequest,
    performDeleteRequest,
    performUpdateRequest,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    dataComponent,
    dataComponentAddressBookContacts,
    contactDataComponent,
    addressBook,
    addressBookContacts,
  } = props;

  const [preDataComponent, setPreDataComponent] = useState(null);
  const [prevContactDataComponent, setPrevContactDataComponent] = useState(
    null
  );

  useEffect(() => setPreDataComponent(dataComponent), [dataComponent]);
  useEffect(() => setPrevContactDataComponent(contactDataComponent), [
    contactDataComponent,
  ]);

  useEffect(() => {
    processRequests(
      preDataComponent,
      dataComponent,
      addressBookDataComponentId,
      addressBook ? addressBook.id : null,
      {
        fetchData,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );
  }, [
    dataComponent,
    preDataComponent,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    addressBook,
  ]);
  useEffect(() => {
    processRequests(
      prevContactDataComponent,
      contactDataComponent,
      addressBookDataComponentId,
      addressBook ? addressBook.id : null,
      {
        fetchData,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );
  }, [
    prevContactDataComponent,
    contactDataComponent,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    addressBook,
  ]);

  useEffect(() => {
    initDataComponent(contactDataComponentId, Contact, [], "contacts");
  }, [initDataComponent]);

  const values = {
    addressBookContacts: addressBookContacts || [],
  };

  return (
    <AddressBookContainer
      addressBook={addressBook}
      dataComponent={dataComponentAddressBookContacts}
      values={values}
      onCreateContact={contact => {
        onCreateContact(
          addressBook.id,
          contact,
          addressBookDataComponentId,
          performCreateRequest
        );
      }}
      onDeleteContact={addressBookContactId =>
        onDeleteContact(
          addressBookContactId,
          addressBookDataComponentId,
          performDeleteRequest
        )
      }
      onUpdateContact={contact =>
        onUpdateContact(contact, contactDataComponentId, performUpdateRequest)
      }
    />
  );
};

AddressBookComponent.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  dataComponent: PropTypes.object.isRequired,
  dataComponentAddressBookContacts: PropTypes.object.isRequired,
  contactDataComponent: PropTypes.object.isRequired,
  addressBook: PropTypes.object.isRequired,
  addressBookContacts: PropTypes.array.isRequired,
};
AddressBookComponent.defaultProps = {
  addressBook: {},
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(addressBookDataComponentId, state);

  const dataComponentAddressBookContacts = getDataComponentFlattenedRequestState(
    addressBookDataComponentId,
    state
  );
  const addressBookContacts =
    getBluechipResources(dataComponentAddressBookContacts, state) || [];

  const contactDataComponent = getDataComponent(contactDataComponentId, state);
  return {
    role: state.auth.role,
    dataComponent,
    dataComponentAddressBookContacts,
    contactDataComponent,
    addressBookContacts,
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  fetchData,
  showSnackNotificationAction,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressBookComponent);
