import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import withPORevision from "../../../../withPORevision";
import { ActionButtons } from "../../../forms";
import Paper from "../../../mui/core/Paper";

const Title = styled.div`
  padding: 24px 24px 24px 24px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 0px;

  ${Paper} {
    box-shadow: none;
    overflow-y: auto;
    max-height: 300px;
  }
`;
export class FlagAllowanceSpec extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      dataComponentId,
      setReload,
    } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload(dataComponentId, true);
      },
      onError: this.handleUpdateRequestError,
    });
  }

  handleUpdateRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  onSend = () => {
    const {
      specId,
      performUpdateRequest,
      dataComponentId,
      isAllowance,
    } = this.props;
    const body = {
      isAllowance,
    };

    performUpdateRequest(dataComponentId, specId, body);
  };

  render() {
    const { spec, dataComponentId, isAllowance } = this.props;
    return (
      <StyledPaper>
        <Title>
          Are you sure you want to{" "}
          {isAllowance
            ? "add the allowance flag to"
            : "remove the allowance flag from"}{" "}
          {spec.customNumber} {spec.description}?
        </Title>
        <ActionButtons
          onSend={this.onSend}
          sendButtonText={
            isAllowance ? "Flag as Allowance" : "Remove Allowance Flag"
          }
          isModal
          listeners={[dataComponentId]}
        />
      </StyledPaper>
    );
  }
}

FlagAllowanceSpec.propTypes = {
  spec: propTypes.spec,
  isAllowance: PropTypes.bool.isRequired,
  specId: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  closeModalDialog,
  showSnackNotificationAction,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(FlagAllowanceSpec));
