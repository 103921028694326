import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import propTypes from "../../../../constants/propTypes";

import SpecsIndex from "../../Specs";
import { useAreaDetailPageContext } from "../areaDetailutils";
import Project from "../../../../models/Project";
import { dataComponentId } from "../../ProjectDetail/ProjectDetailContainer";

const SpecsGrid = withRouter(SpecsIndex);

const Specs = ({ project, actions }) => {
  const { projectId } = useAreaDetailPageContext();

  // Review note: This should be removed in future PRs after BW-3544 get merge.
  // TODO: Remove this actions and calls to it inside the Specs component once BW-3544
  useEffect(() => {
    actions.initDataComponent(
      dataComponentId,
      Project,
      [
        "property.[entity,location]",
        "projectMemberUsers.user.person",
        "projectMemberContacts.contact.person",
        "areas.[areaType,areaRooms]",
        "remitAddresses",
        "projectLead.person",
        "projectManager",
        "designer",
        "generalContractor",
        "otherConsultant",
        "office",
        "notesAuthor",
      ],
      "projects"
    );
    actions.performFindRequest(dataComponentId, projectId);
  }, [actions, projectId]);

  return <SpecsGrid project={project} />;
};

Specs.propTypes = {
  actions: PropTypes.shape({
    performFindRequest: PropTypes.func,
    initDataComponent: PropTypes.func,
  }).isRequired,
  projectId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  project: propTypes.project,
  links: PropTypes.shape({ last: PropTypes.string, next: PropTypes.string }),
};

export default Specs;
