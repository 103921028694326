import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import BoldText from "../../ui/Typography/BoldText";

const DescriptionWrapper = styled.div`
  display: flex;
`;

export const NonTaxableLabel = ({ isTaxExempt }) => {
  return isTaxExempt ? (
    <span
      style={{
        color: "#979ead",
        marginRight: "0.3em",
      }}
    >
      (NT){" "}
    </span>
  ) : (
    ""
  );
};
NonTaxableLabel.propTypes = {
  isTaxExempt: PropTypes.bool,
};

export const DescriptionColumn = ({ description, isTaxExempt }) => {
  return (
    <DescriptionWrapper>
      <NonTaxableLabel isTaxExempt={isTaxExempt} />
      <BoldText as="span" title={description}>
        {description}
      </BoldText>
    </DescriptionWrapper>
  );
};
DescriptionColumn.propTypes = {
  description: PropTypes.string,
  isTaxExempt: PropTypes.bool,
};

export const DescriptionFormatter = ({ row: spec }) => {
  return <DescriptionColumn {...spec} />;
};
DescriptionFormatter.propTypes = {
  row: PropTypes.shape({ name: PropTypes.string, msaClient: PropTypes.bool }),
};
export const DescriptionTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DescriptionFormatter}
    {...props}
    for={["description"]}
  />
);
