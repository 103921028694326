import React, { Component } from "react";
import PropTypes from "prop-types";

import Paper from "../mui/core/Paper";
import GridHeader from "../ui/BWGrid/GridHeader";
import GridTable from "../ui/BWGrid/GridTable";
import AddCircle from "@material-ui/icons/AddCircle";
import { BWGridLocal } from "../ui/BWGrid";
import styled from "styled-components";
import { LoaderContext } from "../ui/Loader";

export const AddCircleWrapper = styled(AddCircle)`
  min-width: 24px;
  min-height: 24px;
`;

export class GridLocalSection extends Component {
  static contextType = LoaderContext;

  get gridProps() {
    const {
      columns,
      columnsOptions,
      rowMenu,
      addButtonText,
      gridHeaderText,
      className,
      addClick,
      headerOverride,
      ...gridProps
    } = this.props;
    return gridProps;
  }

  render() {
    const {
      addClick,
      addButtonText,
      gridHeaderText,
      columns,
      columnsOptions,
      sorting,
      rowMenu,
      gridSubheaderText,
      hideHeader,
      headerActions,
      onRowClick,
      hideFooter = true,
      headerOverride,
    } = this.props;

    const headerActionsWithButton = addButtonText
      ? [
          ...headerActions,
          {
            text: addButtonText,
            icon: <AddCircleWrapper />,
            handler: addClick,
            disableIfProjectClosed: true,
          },
        ]
      : headerActions;

    const { loading } = this.context;

    if (hideHeader) {
      return (
        <Paper className={this.props.className}>
          <BWGridLocal isLoading={loading} {...this.gridProps}>
            <GridTable
              columns={columns}
              columnOptions={columnsOptions}
              sorting={sorting}
              rowMenu={rowMenu}
              onClick={onRowClick}
            />
          </BWGridLocal>
        </Paper>
      );
    }
    return (
      <BWGridLocal
        isLoading={loading}
        {...this.gridProps}
        hideFooter={hideFooter}
      >
        <GridHeader
          headerText={gridHeaderText}
          subheaderText={gridSubheaderText}
          actions={headerActionsWithButton}
          headerOverride={headerOverride}
        />
        <GridTable
          columns={columns}
          columnOptions={columnsOptions}
          rowMenu={rowMenu}
          onClick={onRowClick}
        />
      </BWGridLocal>
    );
  }
}

GridLocalSection.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsOptions: PropTypes.object.isRequired,
  rowMenu: PropTypes.any,
  addButtonText: PropTypes.string,
  gridHeaderText: PropTypes.string,
  className: PropTypes.string,
  addClick: PropTypes.func,
  gridSubheaderText: PropTypes.string,
  hideHeader: PropTypes.bool,
  headerActions: PropTypes.array,
  headerOverride: PropTypes.element,
};

GridLocalSection.defaultProps = {
  headerActions: [],
};
