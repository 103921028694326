import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { sendVerificationCode } from "../../../../actions/authActions";
import PrimaryActionButton from "../../../forms/ActionButtons/PrimaryActionButton";
import { useIsMounted } from "../../../hooks/useIsMounted";

const getLabel = login => (login ? "Resend" : "Verify");
const getCellNumber = (login, cellNumber) => (login ? undefined : cellNumber);

const isDisabled = (
  timeoutDisabled,
  validatingVerificationCode,
  login,
  cellNumber
) =>
  timeoutDisabled ||
  validatingVerificationCode ||
  (!login && isEmpty(cellNumber));

const VerifyButton = ({
  login,
  sendVerificationCode,
  sendingVerificationCode,
  cellNumber,
  validatingVerificationCode,
  sendToEmailOnly,
}) => {
  const [timeoutDisabled, setTimeoutDisabled] = useState(
    validatingVerificationCode
  );
  const isMounted = useIsMounted();

  const disabled = isDisabled(
    timeoutDisabled,
    validatingVerificationCode,
    login,
    cellNumber
  );

  const label = getLabel(login);
  return (
    <PrimaryActionButton
      onClick={() => {
        sendVerificationCode({
          cellNumber: getCellNumber(login, cellNumber),
          sendToEmailOnly,
          login,
        });
        setTimeoutDisabled(true);
        setTimeout(() => {
          if (isMounted()) {
            setTimeoutDisabled(false);
          }
        }, 10000);
      }}
      disabled={disabled}
      isLoading={sendingVerificationCode}
    >
      {label}
    </PrimaryActionButton>
  );
};

VerifyButton.propTypes = {
  login: PropTypes.bool,
  cellNumber: PropTypes.string,
  sendingVerificationCode: PropTypes.bool,
  sendVerificationCode: PropTypes.func.isRequired,
  validatingVerificationCode: PropTypes.bool,
  sendToEmailOnly: PropTypes.bool,
};

const mapStateToProps = state => {
  const {
    auth: { sendingVerificationCode, verificationCodeSent },
  } = state;

  return {
    sendingVerificationCode,
    verificationCodeSent,
  };
};

const mapDispatchToProps = {
  sendVerificationCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyButton);
