import React, { useMemo } from "react";
import BWModels from "benjaminwest-models";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import styled from "styled-components";

import { Content } from "../../layout/Content";
import MUIPaper from "../../mui/core/Paper";
import Project from "../../../models/Project";
import { useHeaderComponent } from "../../ui/BWGrid/GridHeader";
import GridAPI from "../../ui/Grid/GridAPI";
import {
  buildTextTypeProvider,
  buildStaticSelectTypeProvider,
  buildStaticMultiSelectTypeProvider,
} from "../../ui/Grid/filterDataTypeProviders";
import { RowMenuProvider } from "../../ui/Grid/utilComponents";
import {
  FavoriteTypeProvider,
  PropertyTypeProvider,
  ScopeTypeProvider,
  StatusTypeProvider,
  BillingStatusProvider,
  TeamTypeProvider,
} from "./dataTypeProviders";
import { StyledCell } from "../../ui/Grid/utilComponents/tableComponents/Cell";
import PagingPaneWithStatusSwitch from "../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { getColumns } from "./gridProps";
import { useListWorkScopes } from "../../hooks/useListWorkScopes";

const Paper = styled(MUIPaper)`
  ${StyledCell} {
    &:first-child {
      padding-left: 8px;
    }
  }
`;

export const statusFilter = ({ value }) => {
  return value ? { status: value } : {};
};

const Grid = ({
  selectedStatuses,
  dataComponent,
  title,
  getRowMenu,
  onRowClick,
}) => {
  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider(["name"]),
      buildStaticSelectTypeProvider(["status"]),
      buildStaticMultiSelectTypeProvider(["workScopes.id"]),
    ],
    []
  );

  const projectSchema = BWModels.loadSchema("Project");
  const statuses = projectSchema.__utils.statuses;

  const rowProps = useMemo(
    () => ({
      onClick: onRowClick,
      getRowTitle: ({ name }) => `Press to go to ${name} details`,
    }),
    [onRowClick]
  );

  const workScopes = useListWorkScopes();

  return (
    <Content>
      <Paper>
        <GridAPI
          apiRoute="projects"
          model={Project}
          includes={[
            "projectLead.person",
            "property.[location, entity.client.scope]",
            "projectMemberUsers.user.person",
            "projectMemberContacts.contact.person",
            "office.scope",
            "workScopes",
            "projectSubWorkScopes.workScopeSubWorkScope.[workScope,subWorkScope]",
          ]}
          defaultSorting={[
            { columnName: "isFavorite", direction: "asc" },
            { columnName: "number", direction: "desc" },
          ]}
          apiFilters={{
            rootFilters: {
              $where: {
                status: {
                  $in: selectedStatuses,
                },
              },
            },
          }}
          dataComponent={dataComponent}
          rowProps={rowProps}
          columns={getColumns({ getRowMenu, statuses, workScopes })}
          columnExtensions={[
            {
              columnName: "isFavorite",
            },
            { columnName: "team.name", sortingEnabled: false },
            { columnName: "workScopes.id", sortingEnabled: false },
          ]}
          tableComponents={{
            GridHeader: useHeaderComponent({
              headerText: pluralize(title, dataComponent.totalRows, true),
            }),
            PagingPanelTemplate: PagingPaneWithStatusSwitch,
          }}
          dataTypeProviders={[
            StatusTypeProvider,
            FavoriteTypeProvider,
            PropertyTypeProvider,
            TeamTypeProvider,
            ScopeTypeProvider,
            RowMenuProvider,
            BillingStatusProvider,
          ]}
          filteringDataTypeProviders={filteringDataTypeProviders}
        />
      </Paper>
    </Content>
  );
};

Grid.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string),
  getRowMenu: PropTypes.func,
  onRowClick: PropTypes.func,
  title: PropTypes.string,
};

export default Grid;
