import React, { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import BWModels from "benjaminwest-models";

import Grid from "./Grid";
import { useModalStateContext } from "../../../../layout/AppLayout/ModalDialog/withModalState";
import { ActionButtons, FormikForm } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";
import { shipToSiteDataComponentId } from "../ShipToSitePage";

export const newVendorContact = {
  contact: {
    person: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    jobTitle: "",
  },
};

const hasEmptyValues = contact => {
  const obj = { ...newVendorContact.contact, ...contact };
  return [
    "person.firstName",
    "person.lastName",
    "jobTitle",
    "person.email",
    "person.phoneNumber",
  ].some(key => _isEmpty(_get(obj, key)));
};

const isVendorContactEmpty = (
  { contact },
  Comparator = Array.prototype.every
) => {
  return Comparator.call(
    [
      "person.firstName",
      "person.lastName",
      "jobTitle",
      "person.email",
      "person.phoneNumber",
    ].map(key => _get(contact, key)),
    _isEmpty
  );
};

export const isCreateSiteDisabled = (
  { data, newVendorContact },
  nonEmptyContacts
) => {
  return (
    (nonEmptyContacts && !data.length) ||
    !isVendorContactEmpty(newVendorContact) ||
    data.some(vendorContact =>
      isVendorContactEmpty(vendorContact, Array.prototype.some)
    )
  );
};

const getAddNewVendorContact = updateContacts => (values, form) => {
  const contacts = [...values.data, values.newVendorContact];
  form.resetForm();
  form.setFieldValue("data", contacts);
  form.setFieldValue("newVendorContact", newVendorContact);
  updateContacts(contacts);
};

const buildHandleSubmit = ({
  addNewVendorContact,
  isAdding,
  showSnackNotificationAction,
  updateContacts,
}) => {
  return (values, form) => {
    updateContacts(values.data);
    if (!isAdding) return;
    if (hasEmptyValues(values.newVendorContact.contact)) return;
    const { error } = BWModels.loadSchema("Contact")
      .concat(
        BWModels.Joi.object().keys({
          person: BWModels.loadSchema("Person"),
        })
      )
      .validate(values.newVendorContact.contact);
    if (error) {
      const firstError = _get(error, "details.0.message");
      return showSnackNotificationAction(firstError);
    }
    addNewVendorContact(values, form);
  };
};

const CreateContactsContainer = ({
  isEditing,
  vendorContacts,
  showSnackNotificationAction,
  formikPropsRef,
  submitText,
  hideAddContact,
  handleSend,
  nonEmptyContacts,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const { setModalState, modalState = {} } = useModalStateContext();

  const initialValues = {
    data: modalState.contacts || [],
    newVendorContact: {
      contact: {
        person: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        },
        jobTitle: "",
      },
    },
  };

  const handleRemoveContact = useCallback((currentIndex, formik, values) => {
    formik.setFieldValue(
      "data",
      values.data.filter((_, index) => index !== currentIndex),
      false
    );
  }, []);

  const updateContacts = useCallback(
    contacts => {
      setModalState({
        ...modalState,
        contacts,
      });
    },
    [modalState, setModalState]
  );

  const addNewVendorContact = useCallback(
    getAddNewVendorContact(updateContacts),
    [updateContacts]
  );

  const handleSubmit = useCallback(
    buildHandleSubmit({
      addNewVendorContact,
      isAdding,
      showSnackNotificationAction,
      updateContacts,
    }),
    [addNewVendorContact, isAdding, showSnackNotificationAction, updateContacts]
  );

  const onSend = handleSend || formikPropsRef?.current.handleSubmit;

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      ignoreCache
    >
      {({ handleSubmit, values, ...formik }) => {
        return (
          <Fragment>
            <Grid
              values={values}
              isAdding={isAdding}
              setIsAdding={setIsAdding}
              formik={formik}
              hasEmptyValues={hasEmptyValues}
              handleSubmit={handleSubmit}
              handleRemoveContact={handleRemoveContact}
              vendorContacts={vendorContacts}
              isEditing={isEditing}
              showSnackNotificationAction={showSnackNotificationAction}
              hideAddContact={hideAddContact}
            />
            <ActionButtons
              sendButtonText={isEditing ? "Save" : `Create ${submitText}`}
              onSend={onSend}
              disabled={isCreateSiteDisabled(values, nonEmptyContacts)}
              listeners={[shipToSiteDataComponentId, "ProjectEdit"]}
              isModal
            />
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

CreateContactsContainer.propTypes = {
  isEditing: PropTypes.bool,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
  showSnackNotificationAction: PropTypes.func.isRequired,
  formikPropsRef: PropTypes.shape({}),
  submitText: PropTypes.string,
  hideAddContact: PropTypes.bool,
  handleSend: PropTypes.func,
  nonEmptyContacts: PropTypes.bool,
};

export default CreateContactsContainer;
