import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import Paper from "../../../../mui/core/Paper";
import { BWGridAPI, GridTable, GridRow } from "../../../../ui/BWGrid";
import QuotingActions from "./QuotingActions";
import Spec from "../../../../../models/Spec";
import VendorColumn from "../../../Specs/VendorColumn.js";
import { IconButton } from "@material-ui/core";
import {
  ExpandActionWrapper,
  ExpandedIcon,
  HeaderWrapper,
  Wrapper,
  TitleWrapper,
  HeaderLeft,
  ManageBidGroups,
  ManageBidGroupsSpan,
  StyledLocalOffer,
} from "../../components";
import withSelectedRowsAPI from "../../../../ui/BWGrid/withSelectedRowsAPI";
import { getSelectedProjectCurrencyId } from "../../BidGroupList/BidGroupTable/SpecsGrid";
import { getVendorName } from "../UnassignedOnPO/UnassignedSpecsGrid";
import { DescriptionColumn } from "../../../Specs/DescriptionColumn";

const columnOptions = (specCategories, vendors, areas, projectId) => ({
  customNumber: {
    title: "Spec Number",
    bold: true,
    filter: true,
    width: "400px",
  },
  description: { filter: true, fill: true, render: DescriptionColumn },
  "area.name": {
    title: "Area",
    width: "300px",
    filter: "select",
    filterOptions: {
      options: areas?.map(({ name }) => ({
        id: name,
        name,
      })),
    },
  },
  "specCategory.name": {
    title: "Category",
    filter: "select",
    filterOptions: {
      options: specCategories.map(({ name }) => ({
        id: name,
        name,
      })),
    },
  },
  "vendor.name": {
    title: "Vendor",
    width: "300px",
    filter: "select",
    filterOptions: {
      options: vendors?.map(vendor => ({
        id: vendor.name,
        name: getVendorName(vendor, projectId),
      })),
    },
    render: VendorColumn,
  },
  "projectCurrency.currency.name": {
    title: "Currency",
    fill: true,
    filter: true,
  },
  "unitOfMeasure.name": { title: "UOM", align: "right" },
  totalQuantityWithoutOverage: { title: "Qty", align: "right" },
});

const columns = [
  "customNumber",
  "description",
  "area.name",
  "specCategory.name",
  "vendor.name",
  "projectCurrency.currency.name",
  "unitOfMeasure.name",
  "totalQuantityWithoutOverage",
];

const getRootFilters = projectId => ({
  $where: {
    projectId: projectId,
    isAssignedToBidGroup: false,
    purchaseOrderId: {
      $exists: false,
    },
  },
});

const UnassignedSpecsGrid = ({
  dataComponent,
  projectId,
  onOpenManageBidsModal,
  onOpenCreateBidModal,
  specCategories,
  onOpenSpecDetail,
  vendors,
  areas,
  bidGroups,
  selectedRows,
  resetSelection,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleOpenSpecDetail = ({ id }) => {
    onOpenSpecDetail(id);
  };

  const tableComponents = useMemo(() => {
    if (isCollapsed) {
      return {
        TableFilterRow: () => null,
        HeaderRowComponent: () => () => null,
        BodyComponent: () => null,
      };
    }

    return {};
  }, [isCollapsed]);

  const selectedIds = selectedRows.map(({ id }) => id);

  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        hideFooter={isCollapsed}
        apiRoute="specs"
        model={Spec}
        includes={[
          "area",
          "project",
          "specCategory",
          "projectCurrency.currency",
          "vendor",
          "unitOfMeasure",
        ]}
        showSelectionColumn={true}
        showSelectAll={true}
        ignoreOptimizations
        tableComponents={tableComponents}
        defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
        isCollapsed={isCollapsed}
        apiFilters={{
          rootFilters: getRootFilters(projectId),
          params: {
            omitDefaultModifier: true,
            modifiers: ["withAssignedToBidGroup"],
          },
        }}
      >
        <GridRow>
          <HeaderWrapper>
            <Wrapper>
              <HeaderLeft>
                <TitleWrapper>{`${dataComponent.totalRows} Unassigned Specs`}</TitleWrapper>
              </HeaderLeft>

              {isCollapsed ? null : (
                <QuotingActions
                  dataComponent={dataComponent}
                  onOpenCreateBidModal={onOpenCreateBidModal}
                  triggerLabel={"Assign To"}
                  bidGroups={bidGroups}
                  selectedIds={selectedIds}
                  selectedProjectCurrencyId={getSelectedProjectCurrencyId(
                    selectedRows
                  )}
                  resetSelection={resetSelection}
                />
              )}
            </Wrapper>

            <ExpandActionWrapper>
              {isCollapsed ? null : (
                <ManageBidGroups onClick={onOpenManageBidsModal}>
                  <StyledLocalOffer />
                  <ManageBidGroupsSpan>MANAGE BID GROUPS</ManageBidGroupsSpan>
                </ManageBidGroups>
              )}
              <IconButton
                aria-label="show more"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <ExpandedIcon isExpanded={!isCollapsed} />
              </IconButton>
            </ExpandActionWrapper>
          </HeaderWrapper>
        </GridRow>
        <GridTable
          columns={columns}
          columnOptions={columnOptions(
            specCategories,
            vendors,
            areas,
            projectId
          )}
          onClick={handleOpenSpecDetail}
        />
      </BWGridAPI>
    </Paper>
  );
};

UnassignedSpecsGrid.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  onOpenManageBidsModal: PropTypes.func.isRequired,
  onOpenCreateBidModal: PropTypes.func.isRequired,
  apiRoute: PropTypes.string,
  model: PropTypes.func,
  includes: PropTypes.array,
  onOpenSpecDetail: PropTypes.func,
  role: propTypes.userRole,
  specCategories: PropTypes.array,
  vendors: PropTypes.array,
  areas: PropTypes.array,
  bidGroups: PropTypes.array,
  selectedRows: PropTypes.array,
  resetSelection: PropTypes.func.isRequired,
};

export default withSelectedRowsAPI(UnassignedSpecsGrid);
