import React, { useMemo } from "react";
import BWModels from "benjaminwest-models";
import AddCircle from "@material-ui/icons/AddCircle";
import get from "lodash.get";

import { genDetailRowComponentsV2 } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import { buildStaticSelectTypeProvider } from "../../../ui/Grid/filterDataTypeProviders";
import { isSpecReadOnly } from "./Grid";
import useDXGridWithRevisedItems from "./gridComponents/useDXGridWithRevisedItems";
import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";
import Area from "../../../../models/Area";

const areaDataComponent = {
  dataComponentId: "purchaseOrderAreasDataComponentId",
  model: Area,
  includes: [],
  apiRoute: "areas",
};

export const getRowMenu = (
  onOpenQuickEditModal,
  onRemoveSpec,
  isPOChangeDisabled
) => row => {
  if (row.__deleted__) return null;
  return [
    {
      text: "Quick Edit Spec",
      onClick: () => onOpenQuickEditModal(row),
    },
    {
      text: "Remove Spec",
      onClick: () => onRemoveSpec(row),
      disabled: isPOChangeDisabled,
      disableIfProjectClosed: true,
    },
  ];
};

const getColumns = ({
  onOpenQuickEditModal,
  onRemoveSpec,
  isPOChangeDisabled,
  areas,
}) => [
  {
    type: "rowMenu",
    getCellValue: getRowMenu(
      onOpenQuickEditModal,
      onRemoveSpec,
      isPOChangeDisabled
    ),
  },
  {
    name: "customNumber",
    title: "Spec Number",
    filter: "text",
    bold: true,
  },
  {
    name: "description",
    filter: "text",
    scope: "row",
    maxWidth: "14vw",
  },
  {
    name: "specCategory.name",
    title: "Type",
    filter: "text",
  },
  {
    name: "areaId",
    title: "Area",
    filter: "select",
    getCellValue: ({ area }) => get(area, "name"),
    filterOptions: {
      options: areas,
    },
  },
  { name: "unitOfMeasure.name", title: "UOM" },
  {
    name: "totalQuantityWithoutOverage",
    title: "Qty",
  },
  {
    title: "Left",
    name: "left",
  },
  {
    name: "totalQuantityShipped",
    title: "Shipped",
    getCellValue: ({ totalQuantityShipped }) => totalQuantityShipped,
  },
  { name: "price" },
  {
    name: "totalPriceWithoutOverage",
    title: "Total Price",
    sortingEnabled: false,
  },
];

export const useGridProps = ({
  purchaseOrderId,
  onOpenAddSpecModal,
  onGoToSpecDetails,
  dataComponent,
  onOpenQuickEditModal,
  onRemoveSpec,
  isPOChangeDisabled,
}) => {
  const rowProps = useMemo(
    () => ({
      onClick: row => onGoToSpecDetails(row.id),
      getRowTitle: ({ name }) => `Press to go to ${name} details`,
    }),
    [onGoToSpecDetails]
  );

  const detailRowProps = genDetailRowComponentsV2(
    "statusComment",
    "id",
    BWModels.loadSchema("Spec"),
    dataComponent.dataComponentId,
    7,
    2,
    { helperText: "Status Comment" },
    isSpecReadOnly
  );

  const gridActions = useMemo(() => {
    return [
      {
        text: "Add Spec",
        icon: <AddCircle />,
        handler: onOpenAddSpecModal,
        disableIfProjectClosed: true,
      },
    ];
  }, [onOpenAddSpecModal]);

  const filteringDataTypeProviders = useMemo(
    () => [buildStaticSelectTypeProvider(["areaId"])],
    []
  );

  const DXGrid = useDXGridWithRevisedItems();

  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [{ columnName: "name", direction: "desc" }],
      rootFilters: {
        $where: {
          "specs.purchaseOrderId": purchaseOrderId,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaseOrderId, dataComponent.reload]
  );

  const { data: areas } = usePerformRetrieveList(areaDataComponent, filters);

  const columns = useMemo(
    () =>
      getColumns({
        onOpenQuickEditModal,
        onRemoveSpec,
        isPOChangeDisabled,
        areas,
      }),
    [areas, isPOChangeDisabled, onOpenQuickEditModal, onRemoveSpec]
  );

  return {
    rowProps,
    detailRowProps,
    gridActions,
    filteringDataTypeProviders,
    DXGrid,
    columns,
  };
};
