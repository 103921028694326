import { inputs } from "../../../../components/inputs/inputConfigs";
import { isSuperAdmin, roleCan } from "../../../../utils/roleUtils";
import CheckboxWithError from "../../../inputs/CheckboxWithError";

const nonInternalRoleNames = ["Client Portal", "Vendor Portal"];

export const getNonInternalRoleIds = (userRoles = []) =>
  userRoles
    .filter(({ name }) => nonInternalRoleNames.includes(name))
    .map(({ id }) => id);

export const getHandleRoleChange = (setFieldValue, nonInternalRoleIds) => ({
  target: { value },
}) => {
  if (!nonInternalRoleIds.includes(value)) {
    return;
  }
  setFieldValue("teamId", null);
  setFieldValue("officeId", null);
};

export default (
  scopeId,
  role,
  userRole,
  { setFieldValue, values: { userRoleId } },
  userRoles
) => {
  const isUserRoleDisabled =
    role?.name !== "Super Admin" && isSuperAdmin(userRole);
  const nonInternalRoleIds = getNonInternalRoleIds(userRoles);
  const nonInternalRole = nonInternalRoleIds.includes(userRoleId);
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.firstName,
              name: "person.firstName",
              required: true,
              autoFocus: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.lastName,
              name: "person.lastName",
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: isUserRoleDisabled
              ? {
                  ...inputs.userRole,
                  isDisabled: true,
                  helperText: "You cannot update a Super Admin role",
                  submitData: true,
                  get options() {
                    return [userRole];
                  },
                }
              : {
                  ...inputs.userRole,
                  handleChange: getHandleRoleChange(
                    setFieldValue,
                    nonInternalRoleIds
                  ),
                },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.team,
              isDisabled: nonInternalRole,
              required: false,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.office,
              required: !nonInternalRole,
              isDisabled: nonInternalRole,
              APIOptions: {
                ...inputs.office.APIOptions,
                omitFilterScopeModels: true,
                rootFilters: {
                  $where: { "scope.id": scopeId },
                },
              },
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.email,
              name: "person.email",
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.phoneNumber,
              name: "person.phoneNumber",
            },
            grid: { xs: 6 },
          },
          roleCan(role, "manage-lock-users")
            ? {
                input: {
                  label: "Locked",
                  name: "locked",
                  InputComponent: CheckboxWithError,
                  formControlLabelProps: { labelPlacement: "start" },
                },
                grid: { xs: 6 },
              }
            : {},
        ],
      },
    ],
  };
};
