import { inputs } from "../../../../components/inputs/inputConfigs";

const fields = ({ vendorContactStatusInput }) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.firstName,
            name: "contact.person.firstName",
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.lastName,
            name: "contact.person.lastName",
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.phone,
            label: "Phone Number",
            name: "contact.person.phoneNumber",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.contactRole,
            required: true,
            name: "contact.jobTitle",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.email,
            name: "contact.person.email",
          },
          grid: { xs: 6 },
        },
        {
          input: vendorContactStatusInput,
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

function remapOption(option, replacers) {
  const nOption = {
    ...option,
  };
  Object.keys(replacers).forEach(term => {
    nOption.name = nOption.name.replace(`[${term}]`, replacers[term]);
  });
  return nOption;
}

export default () => {
  const replacers = { State: "Local" };
  const vendorContactStatusInput = {
    ...inputs.vendorContactStatus,
    options: inputs.vendorContactStatus.options.map(option =>
      remapOption(option, replacers)
    ),
  };

  return fields({ vendorContactStatusInput });
};
