import React, { useEffect, useMemo, useRef } from "react";
import BWModels from "benjaminwest-models";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import get from "lodash.get";

import GridAPI from "../../../../../ui/Grid/GridAPI";
import Spec from "../../../../../../models/Spec";
import { useFlattenedDatacomponent } from "../../../../../hooks/useDatacomponent";
import { specsSelectionGridId } from "../../../../../../actions/purchaseOrderSpecsActions";
import { genDetailRowComponentsV2 } from "../../../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import { useHeaderComponent } from "../../../../../ui/BWGrid/GridHeader";
import * as dataTypeProviders from "../../../../../ui/Grid/utilComponents/dataTypeProviders";
import { categoryTypeProvider } from "../../Grid";
import { GridWrapper } from "../components";

import PagingPaneWithButtons from "./PagingPaneWithButtons";
import {
  buildStaticSelectTypeProvider,
  buildTextTypeProvider,
} from "../../../../../ui/Grid/filterDataTypeProviders";
import useTableFilters from "./useTableFilters";
import Price from "../../../../../ui/Price";
import propTypes from "../../../../../../constants/propTypes";

export const RenderPrice = ({ projectCurrency, price }) => {
  return <Price number={price} currency={get(projectCurrency, "currency")} />;
};
RenderPrice.propTypes = {
  projectCurrency: propTypes.projectCurrency.isRequired,
  price: PropTypes.number.isRequired,
};

const getColumns = ({ filterOptions }) => {
  return [
    {
      name: "customNumber",
      title: "Number",
      bold: true,
      filter: true,
    },
    {
      name: "description",
      scope: "row",
      maxWidth: "14vw",
      filter: true,
    },
    {
      name: "specCategory.name",
      title: "Category",
      filter: "select",
      filterOptions: {
        options: filterOptions.categories,
      },
    },
    {
      name: "area.name",
      title: "Area",
      filter: "select",
      filterOptions: {
        options: filterOptions.areas,
      },
    },
    {
      name: "vendor.name",
      title: "Vendor",
      filter: "select",
      filterOptions: {
        options: filterOptions.vendors,
      },
    },
    {
      name: "projectCurrency.currency.name",
      title: "Currency",
      getCellValue: ({ projectCurrency }) =>
        get(projectCurrency, "currency.name"),
      filter: false,
    },
    {
      name: "price",
      getCellValue: RenderPrice,
    },
    {
      name: "baseQuantity",
      title: "Base Quantity",
    },
    {
      name: "atticStock",
      title: "Attic Stock",
    },
    {
      name: "overagePercent",
      title: "Overage %",
      getCellValue: ({ overagePercent }) =>
        overagePercent && `${overagePercent}%`,
    },
    { name: "overageValue", title: "Overage Value" },
    { name: "totalQuantity", title: "Qty" },
    {
      name: "unitOfMeasure.name",
      title: "UOM",
      filter: "select",
      filterOptions: {
        options: filterOptions.unitOfMeasures,
      },
    },
  ];
};

const SpecsGrid = ({ filters, submitTextFn, onSubmit }) => {
  const initialLoadingRef = useRef(true);
  const srcDataComponent = useFlattenedDatacomponent(specsSelectionGridId);

  useEffect(() => {
    if (srcDataComponent.loading) {
      initialLoadingRef.current = false;
    }
  }, [srcDataComponent.loading]);

  const dataComponent = useMemo(() => {
    if (!initialLoadingRef.current) {
      return srcDataComponent;
    }
    return { ...srcDataComponent, loading: true };
  }, [srcDataComponent]);

  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider(["customNumber", "description"]),
      buildStaticSelectTypeProvider([
        "specCategory.name",
        "area.name",
        "vendor.name",
        "unitOfMeasure",
      ]),
    ],
    []
  );

  const filterOptions = useTableFilters();

  const detailRowProps = genDetailRowComponentsV2(
    "forecastComment",
    "id",
    BWModels.loadSchema("Spec"),
    srcDataComponent.dataComponentId,
    8,
    3,
    { helperText: "Forecast Comment" },
    true
  );

  return (
    <GridWrapper>
      <GridAPI
        apiRoute="specs"
        model={Spec}
        defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
        apiFilters={{
          rootFilters: { $where: filters },
          params: {
            omitDefaultModifier: true,
            modifiers: ["withAssignedToBidGroup", "withQuantityPrice"],
          },
        }}
        showSelectAll
        showSelectionColumn
        includes={[
          "area",
          "specCategory",
          "purchaseOrder.vendor",
          "glCodes",
          "vendor",
          "unitOfMeasure",
          "projectCurrency.currency",
        ]}
        dataComponent={dataComponent}
        tableComponents={{
          GridHeader: useHeaderComponent({
            headerText: pluralize("Item", srcDataComponent.totalRows, true),
          }),
          ...detailRowProps.tableComponents,
          PagingPanelTemplate: PagingPaneWithButtons(submitTextFn, onSubmit),
        }}
        columns={getColumns({
          filterOptions,
        })}
        columnExtensions={[]}
        dxGridProps={detailRowProps.dxGridProps}
        dataTypeProviders={[
          dataTypeProviders.numberTypeBuilder(
            [
              "baseQuantity",
              "price",
              "atticStock",
              "overagePercent",
              "overageValue",
            ],
            true
          ),
          categoryTypeProvider,
        ]}
        filteringDataTypeProviders={filteringDataTypeProviders}
      />
    </GridWrapper>
  );
};

SpecsGrid.propTypes = {
  filters: PropTypes.shape({}),
  submitTextFn: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SpecsGrid;
