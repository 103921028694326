import PropTypes from "prop-types";
import { ContactsPage } from "../Contacts/ContactsPage";
import ClientContact from "../../../models/ClientContact";

export default class ClientContactsPage extends ContactsPage {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    ...ContactsPage.propTypes,
  };

  constructor(props) {
    super(props);
    this.columns.splice(3, 0, "contact.faxNumber");
  }

  get columnOptions() {
    const options = super.columnOptions;
    options["contact.phoneNumber"] = {
      title: "Phone Number",
      align: "right",
    };
    options["contact.faxNumber"] = {
      title: "Fax",
      align: "right",
    };
    return options;
  }
  get defaultSorting() {
    return [
      {
        columnName: "contact.firstName",
        direction: "asc",
      },
    ];
  }
  get rootFilters() {
    const { clientId } = this.props;
    return {
      $where: {
        clientId: clientId,
      },
      contact: true,
    };
  }

  get apiRoute() {
    return "client-contacts";
  }

  get model() {
    return ClientContact;
  }

  get includes() {
    return ["client", "contact.person"];
  }
}
