import _omit from "lodash/omit";
import { push } from "connected-react-router";
import get from "lodash.get";

import * as actions from "../constants/ActionTypes";
import { getDataComponent } from "../reducers/dataComponentReducer";
import { blueChipActions } from "../store/blueChipConfig";
import {
  fetchList,
  fetchItem,
  createItem,
  importFromFile,
  patchItem,
  deleteItem,
} from "./dataComponents";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { closeModalDialog, openModalDialog } from "./layoutActions";
import { getInitialPage } from "../components/pages/Login/LoginContainer";

export function initDataComponent(
  dataComponentId,
  model,
  includes,
  apiRoute,
  skipInitIfExists,
  dataComponentVersion = "v1"
) {
  return {
    type: actions.DATA_COMPONENT_INIT,
    dataComponentId,
    payload: { model, includes, apiRoute, dataComponentVersion },
    skipInitIfExists,
  };
}

export function destroyDataComponentResource(dataComponentId, requestType) {
  return {
    type: actions.DATA_COMPONENT_RESOURCE_DESTROY,
    dataComponentId,
    requestType,
  };
}

export function cloneDataComponent(
  srcDataComponentId,
  dstDataComponentId,
  skipInitIfExists,
  cloneRequestState = true
) {
  return {
    type: actions.DATA_COMPONENT_CLONE,
    dataComponentId: srcDataComponentId,
    payload: {
      dstDataComponentId,
      skipInitIfExists,
      cloneRequestState,
    },
  };
}

export function cloneDataComponentSetRequestState(
  srcDataComponentId,
  dstDataComponentId,
  requestState,
  skipInitIfExists
) {
  return async dispatch => {
    await dispatch({
      type: actions.DATA_COMPONENT_CLONE_SET_REQUEST_STATE,
      dataComponentId: srcDataComponentId,
      payload: {
        dstDataComponentId,
        requestState,
        skipInitIfExists,
      },
    });
  };
}

export function cloneDataComponentOverwriteProp(
  srcDataComponentId,
  dstDataComponentId,
  skipInitIfExists,
  cloneRequestState = true,
  dstDataComponentProps
) {
  return async dispatch => {
    await dispatch({
      type: actions.DATA_COMPONENT_CLONE_OVERWRITE_PROP,
      dataComponentId: srcDataComponentId,
      payload: {
        dstDataComponentId,
        skipInitIfExists,
        cloneRequestState,
        dstDataComponentProps,
      },
    });
  };
}

export function destroyDataComponent(dataComponentId) {
  return {
    type: actions.DATA_COMPONENT_DESTROY,
    dataComponentId,
  };
}

export function setPageNumberAction(dataComponentId, pageNumber) {
  return {
    type: actions.DATA_COMPONENT_SET_PAGE,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { pageNumber },
  };
}

function setPageSizeAction(dataComponentId, pageSize) {
  return {
    type: actions.DATA_COMPONENT_SET_PAGE_SIZE,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { pageSize },
  };
}

function setSortAction(dataComponentId, sort) {
  return {
    type: actions.DATA_COMPONENT_SET_SORT,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { sort },
  };
}

export function setReload(dataComponentId, reload) {
  return {
    type: actions.DATA_COMPONENT_SET_RELOAD,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { reload },
  };
}

function setSelectionAction(dataComponentId, selection) {
  return (dispatch, getState) => {
    const dataComponent = getDataComponent(dataComponentId, getState());
    const dataComponentVersion =
      (dataComponent && dataComponent.dataComponentVersion) || "v1";

    const selectionAction = {
      v1: setSelectionActionV1,
      v2: setSelectionActionV2,
    };

    dispatch(selectionAction[dataComponentVersion](dataComponentId, selection));
  };
}

function setSelectionActionV1(dataComponentId, selection) {
  return {
    type: actions.DATA_COMPONENT_SET_SELECTION,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { selection },
  };
}

function setSelectionActionV2(dataComponentId, selectedIds) {
  selectedIds = Array.isArray(selectedIds) ? selectedIds : [];
  return {
    type: actions.DATA_COMPONENT_SET_SELECTION_V2,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { selectedIds },
  };
}

export function reorder(dataComponentId, reorderInfo) {
  return {
    type: actions.DATA_COMPONENT_REORDER,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { reorderInfo },
  };
}

function setAPIFiltersAction(dataComponentId, filters) {
  return {
    type: actions.DATA_COMPONENT_SET_API_FILTERS,
    dataComponentId,
    requestType: REQUEST_TYPES.LIST,
    payload: { ...filters },
  };
}

export function updateResource(id, type, data) {
  return () => {
    blueChipActions.updateResource({
      id,
      type,
      attributes: data,
    });
  };
}

export function clearResources(resourceTypes) {
  return () => {
    blueChipActions.clearResources(resourceTypes);
  };
}

export function fetchDataRequest(dataComponentId, requestType) {
  return {
    type: actions.DATA_COMPONENT_REQUEST,
    dataComponentId,
    requestType,
  };
}

export function fetchDataSuccess(dataComponentId, requestType, payload) {
  return {
    type: actions.DATA_COMPONENT_SUCCESS,
    dataComponentId,
    requestType,
    payload,
  };
}

export function pushNewIndex(dataComponentId, rowIndex) {
  return (dispatch, getState) => {
    const state = getState();
    const currentState =
      state.dataComponents[dataComponentId].requestState[REQUEST_TYPES.LIST];
    dispatch({
      type: actions.DATA_COMPONENT_SUCCESS,
      dataComponentId,
      requestType: REQUEST_TYPES.LIST,
      payload: {
        totalRows: currentState.totalRows + rowIndex.length,
        rowIndex: [...currentState.rowIndex, ...rowIndex],
      },
    });
  };
}

export function fetchDataFailed(dataComponentId, requestType, error) {
  return {
    type: actions.DATA_COMPONENT_FAILURE,
    dataComponentId,
    requestType,
    payload: { ...error },
  };
}

export function setPage(dataComponentId, pageNumber) {
  return async dispatch => {
    dispatch(setPageNumberAction(dataComponentId, pageNumber));
  };
}

export function setPageSize(dataComponentId, pageSize, reload = true) {
  return async (dispatch, getState) => {
    const dataComponentState = getDataComponent(dataComponentId, getState());
    const { totalRows, pageNumber } = dataComponentState.requestState[
      REQUEST_TYPES.LIST
    ];
    dispatch(setPageSizeAction(dataComponentId, pageSize));

    const options = { pageSize };
    const lastPage = Math.ceil(pageSize / totalRows) - 1;
    if (lastPage < pageNumber) {
      dispatch(setPageNumberAction(dataComponentId, lastPage));
      options.pageNumber = lastPage;
    }

    if (reload) dispatch(setReload(dataComponentId, true));
  };
}

export function setSort(dataComponentId, sort) {
  return async dispatch => {
    dispatch(setSortAction(dataComponentId, sort));
    dispatch(setSelectionAction(dataComponentId, {}));
    dispatch(setReload(dataComponentId, true));
  };
}

export function setSelection(dataComponentId, selection) {
  return setSelectionAction(dataComponentId, selection);
}

export function performFindRequest(dataComponentId, id, rootFilters, params) {
  return async dispatch => {
    await dispatch(
      fetchData(dataComponentId, REQUEST_TYPES.FIND, {
        id,
        rootFilters,
        params,
      })
    );
  };
}

export function performDeleteRequest(dataComponentId, ids, params) {
  return async dispatch => {
    return await dispatch(
      fetchData(dataComponentId, REQUEST_TYPES.DELETE, { ids, params })
    );
  };
}

export function performCreateRequest(dataComponentId, body, params) {
  const isBulk = Array.isArray(body);
  const options = isBulk
    ? { items: body, isBulk }
    : { body: cleanUpBody(body), params };
  return async dispatch => {
    return await dispatch(
      fetchData(dataComponentId, REQUEST_TYPES.CREATE, options)
    );
  };
}

export const cleanUpBody = body => {
  return _omit(body, ["__isClicked__", "__expanded__"]);
};

export function performUpdateRequest(dataComponentId, ids, body, params) {
  const isBulk = Array.isArray(ids);
  const options = isBulk
    ? { items: ids, isBulk, params: body }
    : { id: ids, body: cleanUpBody(body), params };
  return async dispatch => {
    return dispatch(fetchData(dataComponentId, REQUEST_TYPES.UPDATE, options));
  };
}

export function performRetrieveListRequest(dataComponentId, filters) {
  return async dispatch => {
    dispatch(setAPIFiltersAction(dataComponentId, filters));
    await dispatch(fetchData(dataComponentId, REQUEST_TYPES.LIST, filters));
  };
}

export function performImportRequest(dataComponentId, body) {
  return async dispatch => {
    return await dispatch(
      fetchData(dataComponentId, REQUEST_TYPES.IMPORT, { body })
    );
  };
}

export function fetchData(
  dataComponentId,
  requestType = REQUEST_TYPES.LIST,
  options = {}
) {
  return async (dispatch, getState) => {
    const dataComponent = getDataComponent(dataComponentId, getState());

    if (!dataComponent.apiRoute) {
      const state = getState();
      dispatch(
        openModalDialog(
          "There was an issue while trying to prepare a request to server",
          "GeneralConfirmation",
          {
            title:
              "Click on Reload to refresh the page. Please contact support if the issue persists.",
            sendButtonText: "Reload",
            hideCancelButton: true,
            onSubmit: () => {
              dispatch(push(getInitialPage(get(state, "auth.role"))));
              dispatch(closeModalDialog());
              window.location.reload();
            },
          }
        )
      );
      return;
    }

    dispatch(fetchDataRequest(dataComponentId, requestType));

    try {
      const requestStatePayload = await performRequest(
        dataComponent,
        requestType,
        options
      );

      dispatch(
        fetchDataSuccess(dataComponentId, requestType, requestStatePayload)
      );
      return requestStatePayload;
    } catch (error) {
      dispatch(fetchDataFailed(dataComponentId, requestType, error));
      throw error;
    }
  };
}

const requestFunctions = {
  [REQUEST_TYPES.LIST]: fetchList,
  [REQUEST_TYPES.FIND]: fetchItem,
  [REQUEST_TYPES.DELETE]: deleteItem,
  [REQUEST_TYPES.CREATE]: createItem,
  [REQUEST_TYPES.IMPORT]: importFromFile,
  [REQUEST_TYPES.UPDATE]: patchItem,
};

async function performRequest(dataComponent, requestType, options) {
  const requestFn = requestFunctions[requestType];
  if (requestFn) return await requestFn(dataComponent, options);
}
