import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";

import propTypes from "../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../utils/dataComponentUtils";
import { getDataComponent } from "../../reducers/dataComponentReducer";
import DeleteConfirmation from "./DeleteConfirmation";

export class StandardDelete extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      dataComponentId,
      closeModalDialog,
      setReload,
      onSuccess,
    } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload(dataComponentId, true);
        onSuccess && onSuccess();
      },
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDelete = () => {
    const { id, performDeleteRequest, dataComponentId, params } = this.props;
    performDeleteRequest(dataComponentId, id, params);
  };

  render() {
    const { title, dataComponentId } = this.props;
    return (
      <DeleteConfirmation
        title={title}
        onDelete={get(this.props, "onDelete", this.handleDelete)}
        listeners={{ danger: [dataComponentId] }}
      />
    );
  }
}

StandardDelete.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  onDelete: PropTypes.func,
  performDeleteRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
  onSuccess: PropTypes.func,
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  setReload,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardDelete);
