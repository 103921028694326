import React from "react";

import { DashIcon } from "./components";
import Price from "../../../../ui/Price";
import { DescriptionColumn } from "../../../Specs/DescriptionColumn";

const formatPrice = attribute => spec => {
  const { projectCurrency = {} } = spec;
  const { currency, conversionRate = 1 } = projectCurrency;
  return (
    spec && (
      <Price number={spec[attribute] / conversionRate} currency={currency} />
    )
  );
};
export const columns = [
  "customNumber",
  "description",
  "area.name",
  "specCategory.name",
  "vendor.name",
  "unitOfMeasure.name",
  "totalQuantityWithoutOverage",
  "priceCents",
  "totalPriceWithoutOverage",
];

export const columnOptions = ({ specCategories, areas, vendors }) => ({
  customNumber: { title: "Spec Number", bold: true, filter: true },
  description: { fill: true, filter: true, render: DescriptionColumn },
  "vendor.name": {
    title: "Vendor",
    width: "300px",
    filter: "select",
    filterOptions: {
      options: vendors,
    },
    render: row => (row.vendor && row.vendor.name) || <DashIcon />,
  },
  "area.name": {
    title: "Area",
    width: "300px",
    filter: "select",
    filterOptions: {
      options: areas,
    },
    render: row => row.area && row.area.name,
  },
  "specCategory.name": {
    title: "Category",
    width: "300px",
    filter: "select",
    filterOptions: {
      options: specCategories,
    },
    render: row => row.specCategory && row.specCategory.name,
  },
  "unitOfMeasure.name": { title: "UOM" },
  totalQuantityWithoutOverage: { title: "QTY" },
  priceCents: {
    render: formatPrice("price"),
    title: "Selected Price",
    align: "right",
  },
  totalPriceWithoutOverage: {
    title: "Ext Price",
    render: formatPrice("totalPriceWithoutOverage"),
    align: "right",
  },
});
