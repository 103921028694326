import React, { Component } from "react";
import PropTypes from "prop-types";
import BoldText from "../../ui/Typography/BoldText";
import _get from "lodash.get";
import TagText from "../../ui/Tags/TagText";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import AddressBookContact from "../../../models/AddressBookContact";
import AddCircle from "@material-ui/icons/AddCircle";
import BWModels from "benjaminwest-models";
import contactConstants from "../../../constants/Contact";

export const getRowMenu = (handleEditClick, handleDeleteClick) => row => [
  { text: "Edit", onClick: () => handleEditClick(row, row.id) },
  { separator: true },
  {
    text: "Delete",
    onClick: () => handleDeleteClick(row, row.contact.name, row.id),
  },
];
export default class AddressBookSectionLayout extends Component {
  static propTypes = {
    dataComponent: PropTypes.object.isRequired,
    onOpenAddModal: PropTypes.func.isRequired,
    onOpenEditModal: PropTypes.func.isRequired,
    onOpenDeleteModal: PropTypes.func.isRequired,
    rows: PropTypes.array,
    addressBook: PropTypes.object.isRequired,
  };

  addButtonText = "Add Contact";
  gridHeaderText = "Address Book";

  render() {
    const {
      addressBook,
      onOpenAddModal,
      onOpenEditModal,
      onOpenDeleteModal,
    } = this.props;

    const columns = [
      "contact.name",
      "contact.jobTitle",
      "contact.status",
      "contact.phone",
      "contact.email",
    ];
    const statusMap = contactConstants.statusMap;
    const contactSchema = BWModels.loadSchema("Contact");
    const statuses = contactSchema.__utils.statuses;

    const columnsOptions = {
      "contact.name": {
        title: "Name",
        bold: true,
        render: row => <BoldText>{_get(row, "contact.name")}</BoldText>,
        filter: true,
      },
      "contact.jobTitle": {
        title: "Job Title",
        width: "88px",
        render: row => {
          return _get(row, "contact.jobTitle");
        },
      },
      "contact.status": {
        title: "Status",
        fill: true,
        render: row => {
          const status = _get(row, "contact.status");

          return <TagText>{_get(statusMap, `${status}.name`)}</TagText>;
        },
        filter: "select",
        filterOptions: {
          options: statuses,
        },
      },
      "contact.phone": {
        title: "Phone Number",
        align: "right",
      },
      "contact.email": {
        title: "Email",
        align: "right",
        render: row => {
          return _get(row, "contact.email");
        },
        filter: true,
      },
    };
    return (
      <BWGridAPI
        apiRoute="address-book-contacts"
        model={AddressBookContact}
        includes={["contact.person"]}
        dataComponent={this.props.dataComponent}
        defaultSorting={[
          {
            columnName: "contact.name",
            direction: "asc",
          },
        ]}
        apiFilters={{
          rootFilters: {
            $where: { addressBookId: addressBook.id },
          },
          sort: [{ columnName: "contact.name", direction: "asc" }],
        }}
      >
        <GridHeader
          headerText={`${this.gridHeaderText} - ${this.props.dataComponent.totalRows} Contacts`}
          actions={[
            {
              text: this.addButtonText,
              icon: <AddCircle />,
              handler: onOpenAddModal,
              disableIfProjectClosed: true,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnsOptions}
          rowMenu={getRowMenu(onOpenEditModal, onOpenDeleteModal)}
        />
      </BWGridAPI>
    );
  }
}
