import * as BID_TYPES from "../bidTypes";
import * as SPEC_TYPES from "../specTypes";
import * as CELL_TYPES from "./cellTypes";
import * as ROW_COLUMN_NAMES from "./rowColumnNames";

import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import {
  filterAnalysisRows,
  spreadBidGroupVendorSpecsWithComs,
} from "../Quotes/bidGroupVendorSpecUtils";
import {
  buildSpecRowForSpecOnly,
  buildSpecRowForCOM,
  buildSpecRowForAllInclusive,
  buildSpecRowEmpty,
  getZeroWhenFalsy,
} from "./analysisSpecRowFunctions";
import {
  buildExpandedCell,
  buildTotalCell,
  setDefaultValuesForVendorQuoteCell,
  getBackGroundColor,
} from "./analysisSpecCellFunctions";
import {
  calculateSubTotalMerchandiseCostForCOMS,
  calculateSubTotalMerchandiseCost,
} from "./analysisCalculations";
import { getDefaultProjectCurrency } from "../../../helpers";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

const initialBudgetBackground = "#EDF0F7";

export const getPlaceholderWhenFalsy = (value, compare) => {
  if (compare) {
    return compare ? compare : "--";
  }
  return value || "--";
};
const getDescriptionLabel = text => ({
  type: "label",
  value: text,
});
const formatLeadTime = bgVendor => {
  const from = bgVendor.leadTimesFrom || "-- ";
  const to = bgVendor.leadTimesTo || " --";
  return `${from}-${to} wks`;
};
const formatLeadTimeForSubmittal = bgVendor =>
  `${bgVendor.leadTimeForSubmittal || "--"} wks`;
const rowsByType = {
  subTotalMerchandise: {
    totalProductionQuantity: getDescriptionLabel("Subtotal Merchandise"),
  },
  potentialTariff: {
    totalProductionQuantity: getDescriptionLabel("Potential Tariff"),
  },
  miscellaneousCosts: {
    totalProductionQuantity: getDescriptionLabel("Miscellaneous Costs"),
  },
  estimatedFreight: {
    totalProductionQuantity: getDescriptionLabel(
      "Estimated Freight to Warehouse"
    ),
  },
  totalMerchandise: {
    totalProductionQuantity: getDescriptionLabel("Total (Excluding Tax)"),
  },
  notes: {
    totalProductionQuantity: getDescriptionLabel("Notes"),
  },
  terms: {
    totalProductionQuantity: getDescriptionLabel(
      "Terms (subject to credit approval)"
    ),
  },
  fob: {
    totalProductionQuantity: getDescriptionLabel("FOB"),
  },
  leadTimeForSubmittal: {
    totalProductionQuantity: getDescriptionLabel("Submittal Lead Time"),
  },
  leadTime: {
    totalProductionQuantity: getDescriptionLabel("Lead Time"),
  },
  factoryLocation: {
    totalProductionQuantity: getDescriptionLabel("Factory Location"),
  },
  quoteValidThrough: {
    totalProductionQuantity: getDescriptionLabel("Quote Valid Through"),
  },
  miscellaneous: {
    totalProductionQuantity: getDescriptionLabel("Miscellaneous"),
  },
  dropDeadDates: {
    totalProductionQuantity: getDescriptionLabel("Drop Dead Dates"),
  },
  footnotes: {
    totalProductionQuantity: getDescriptionLabel("Footnotes"),
  },
};
export const getCOMItemsForBidGroupVendor = bidGroupVendorSpecs => {
  const comItems = [];
  bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    const { specDetails = [] } = bidGroupVendorSpec.spec;
    specDetails.map(specDetail => {
      const { specDetailComs = [] } = specDetail;
      specDetailComs.map(specDetailCom => {
        comItems.push(specDetailCom.spec);
      });
    });
  });
  return comItems;
};
export const fillQuoteAttributes = (
  bgVendor,
  vendorSpec,
  specRow,
  vendorIndex,
  defaultProjectCurrency,
  allCOMItems,
  selectedCOMs,
  onCOMClicked,
  quoteIndex,
  openAlternateQuotesModal
) => {
  const { quoteProjectCurrency } = bgVendor;
  switch (bgVendor.bidType) {
    case BID_TYPES.COM_SPEC_ONLY: {
      if (specRow.description.type === SPEC_TYPES.COM) {
        buildSpecRowForCOM(
          bgVendor,
          specRow,
          vendorIndex,
          defaultProjectCurrency,
          vendorSpec,
          allCOMItems,
          selectedCOMs,
          onCOMClicked,
          quoteProjectCurrency
        );
      } else {
        buildSpecRowEmpty(specRow, vendorIndex, bgVendor);
      }
      break;
    }
    case BID_TYPES.ALL_INCLUSIVE: {
      buildSpecRowForAllInclusive(
        specRow,
        vendorIndex,
        defaultProjectCurrency,
        vendorSpec,
        allCOMItems,
        quoteIndex,
        bgVendor,
        openAlternateQuotesModal
      );
      break;
    }
    default: {
      buildSpecRowForSpecOnly(
        specRow,
        vendorIndex,
        defaultProjectCurrency,
        vendorSpec,
        selectedCOMs,
        quoteIndex,
        bgVendor,
        openAlternateQuotesModal
      );
    }
  }
};
export const sortSpecRows = specRows => {
  const specRowsArray = [];
  Object.values(specRows).forEach(specRow => {
    specRowsArray.push(specRow);
  });
  return specRowsArray.sort((a, b) => {
    return a.index < b.index ? -1 : 1;
  });
};
export const hasCOMs = (selectedCOMs, comItems) => {
  return comItems.reduce((accum, comItem) => {
    return accum && !!selectedCOMs[comItem.id];
  }, true);
};
const getRowDescriptionValue = row =>
  `${row.isTaxExempt ? "(NT) " : ""}${
    row.type === "COM" && row.awardedVendorName
      ? `${row.awardedVendorName} - ${row.description}`
      : row.description
  }`;
const getEmptyStringWhenCOM = (type, value) => (type !== "COM" ? value : "");
export const getSpecRowsById = (rows, bgVendors) => {
  const specRows = {};
  const defaultProjectCurrency = getDefaultProjectCurrencyFromBGVs(bgVendors);
  rows.map((row, index) => {
    const specRow = specRows[row.id];
    const unitOfMeasure = _get(row, "unitOfMeasure.name", "");
    if (!specRow || specRow.description.type === SPEC_TYPES.SPEC) {
      const designerQuantity =
        row.designerQuantity && row.unitOfMeasure
          ? `${row.designerQuantity}${unitOfMeasure}`
          : false;
      specRows[row.id] = {
        index,
        type: row.type,
        isParent: row.isParent,
        isLastCOM: row.isLastCOM,
        customNumber: { value: row.customNumber, type: row.type },
        description: {
          value: getRowDescriptionValue(row),
          type: row.type,
        },
        designerBudget: {
          value: getPlaceholderWhenFalsy(
            row.designerBudget * _get(row, "projectCurrency.conversionRate")
          ),
          type: CELL_TYPES.BOLD,
          backGroundColor: initialBudgetBackground,
          projectCurrency: defaultProjectCurrency,
        },
        unitOfMeasure: row.unitOfMeasure,
        designerQuantity: {
          value: getPlaceholderWhenFalsy(designerQuantity),
          backGroundColor: initialBudgetBackground,
          projectCurrency: defaultProjectCurrency,
        },
        designerExtendedCost: {
          value: getPlaceholderWhenFalsy(
            row.designerQuantity *
              row.designerBudget *
              _get(row, "projectCurrency.conversionRate")
          ),
          type: CELL_TYPES.BOLD,
          backGroundColor: initialBudgetBackground,
          projectCurrency: defaultProjectCurrency,
        },
        totalSampleQuantity: {
          value: getEmptyStringWhenCOM(row.type, row.sampleQuantity),
          type: row.type,
        },
        totalProductionQuantity: {
          value: getEmptyStringWhenCOM(
            row.type,
            row.totalQuantityWithoutOverage
          ),
          type: row.type,
        },
      };
    }
  });
  bgVendors.forEach((bgVendor, vendorIndex) => {
    rows.forEach(row => {
      /** Sample columns */
      specRows[row.id][`quote_${vendorIndex}_sampleCOMQuantity`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
      specRows[row.id][`quote_${vendorIndex}_extendedSampleCOMQuantity`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
      specRows[row.id][`quote_${vendorIndex}_samplePricing`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
        projectCurrency: defaultProjectCurrency,
      };
      specRows[row.id][`quote_${vendorIndex}_extendedSamplePricing`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
      /**
       * Production columns
       */
      specRows[row.id][`quote_${vendorIndex}_productionCOMQuantity`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
      specRows[row.id][`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
      specRows[row.id][`quote_${vendorIndex}_productionPricing`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
        projectCurrency: defaultProjectCurrency,
      };
      specRows[row.id][`quote_${vendorIndex}_extendedProductionPricing`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
        projectCurrency: defaultProjectCurrency,
      };
      specRows[row.id][`quote_${vendorIndex}_selectCOM`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
      specRows[row.id][`quote_${vendorIndex}_chevron`] = {
        value: " ",
        backGroundColor: getBackGroundColor(bgVendor),
      };
    });
  });
  return specRows;
};
export const buildSpecRows = (
  bgVendors,
  rows,
  specRowsById,
  comItems,
  selectedCOMs,
  onCOMClicked,
  openAlternateQuotesModal
) => {
  const defaultProjectCurrency = getDefaultProjectCurrencyFromBGVs(bgVendors);
  bgVendors.forEach((bgVendor, index) => {
    const quoteIndex = 0;
    const { bidGroupVendorSpecs = [] } = bgVendor;
    const expandedBidGroupVendorSpecs = filterAnalysisRows(
      spreadBidGroupVendorSpecsWithComs(bidGroupVendorSpecs)
    );
    rows.map(row => {
      const bidGroupVendorSpec = expandedBidGroupVendorSpecs.find(
        expandedBidGroupVendorSpec =>
          expandedBidGroupVendorSpec.specId === row.id
      );
      if (bidGroupVendorSpec) {
        fillQuoteAttributes(
          bgVendor,
          bidGroupVendorSpec,
          specRowsById[bidGroupVendorSpec.specId],
          index,
          defaultProjectCurrency,
          comItems,
          selectedCOMs,
          onCOMClicked,
          quoteIndex,
          openAlternateQuotesModal
        );
      }
    });
  });
};
// eslint-disable-next-line max-lines-per-function
const setDefaultValuesForInitialBudgetColumn = rowsByType => {
  rowsByType.miscellaneousCosts[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.subTotalMerchandise[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.miscellaneousCosts[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.subTotalMerchandise[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.miscellaneousCosts[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.subTotalMerchandise[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    type: CELL_TYPES.BOLD,
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.potentialTariff[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.potentialTariff[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.potentialTariff[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.totalMerchandise[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: " ",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.totalMerchandise[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.totalMerchandise[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    type: CELL_TYPES.BOLD,
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.notes[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.notes[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.notes[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.terms[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.terms[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.terms[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.fob[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.fob[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.fob[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.factoryLocation[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.factoryLocation[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.factoryLocation[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.leadTime[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.leadTimeForSubmittal[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.leadTime[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.leadTime[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.estimatedFreight[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.estimatedFreight[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.estimatedFreight[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.quoteValidThrough[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.quoteValidThrough[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.quoteValidThrough[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.miscellaneous[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.miscellaneous[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.miscellaneous[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.dropDeadDates[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.dropDeadDates[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.dropDeadDates[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.footnotes[ROW_COLUMN_NAMES.DESIGNER_BUDGET] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.footnotes[ROW_COLUMN_NAMES.DESIGNER_QUANTITY] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
  rowsByType.footnotes[ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST] = {
    value: "",
    backGroundColor: initialBudgetBackground,
  };
};

export const getDefaultProjectCurrencyFromBGVs = bgVendors => {
  if (bgVendors && bgVendors.length) {
    return getDefaultProjectCurrency(
      bgVendors[0].bidGroup.project.projectCurrencies
    );
  }
  return null;
};

const getTotalsColumn = (vendorIndex, isCOMVisible, isSampleColumnsVisible) => {
  if (isCOMVisible) {
    if (isSampleColumnsVisible) return `quote_${vendorIndex}_sampleCOMQuantity`;
    return `quote_${vendorIndex}_productionCOMQuantity`;
  }
  if (!isCOMVisible) {
    if (isSampleColumnsVisible) return `quote_${vendorIndex}_samplePricing`;
    return `quote_${vendorIndex}_productionPricing`;
  }
};

const getPotentialTariffValue = (bgVendor, value) =>
  bgVendor.potentialTariffType === "$"
    ? bgVendor.potentialTariffTotal
    : (bgVendor.potentialTariff / 100) * value;

// eslint-disable-next-line max-lines-per-function
export const formatVendorsToSpecRows = (
  rows,
  bgVendors,
  paymentTerms,
  comItems,
  selectedCOMs,
  onCOMClicked,
  openAlternateQuotesModal,
  isCOMVisible,
  isSampleColumnsVisible
) => {
  const specRowsById = getSpecRowsById(rows, bgVendors);
  buildSpecRows(
    bgVendors,
    rows,
    specRowsById,
    comItems,
    selectedCOMs,
    onCOMClicked,
    openAlternateQuotesModal,
    isCOMVisible
  );
  const defaultProjectCurrency = getDefaultProjectCurrencyFromBGVs(bgVendors);
  const initialBudgetTotal = Object.values(rows).reduce((accum, spec) => {
    return (
      accum +
      spec.designerBudget *
        spec.designerQuantity *
        _get(spec, "projectCurrency.conversionRate")
    );
  }, 0);

  setDefaultValuesForInitialBudgetColumn(rowsByType);
  rowsByType.subTotalMerchandise[
    ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST
  ].value = initialBudgetTotal;
  rowsByType.totalMerchandise[
    ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST
  ].value = initialBudgetTotal;
  rowsByType.subTotalMerchandise[
    ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST
  ].projectCurrency = defaultProjectCurrency;
  rowsByType.totalMerchandise[
    ROW_COLUMN_NAMES.DESIGNER_EXTENDED_COST
  ].projectCurrency = defaultProjectCurrency;
  /* eslint-disable max-lines-per-function */
  /* eslint-disable-next-line complexity */
  bgVendors.forEach((bgVendor, vendorIndex) => {
    const { bidGroupVendorSpecs = [] } = bgVendor;

    const estimatedFreightToWarehouse =
      bgVendor.estimatedFreightToWarehouse || 0;
    /**
     * Ext. Sample Pricing
     */
    const subTotalMerchandiseSampleSpecs = calculateSubTotalMerchandiseCost(
      bidGroupVendorSpecs,
      true
    );
    const subTotalMerchandiseSampleCOMS = calculateSubTotalMerchandiseCostForCOMS(
      bidGroupVendorSpecs,
      selectedCOMs,
      true
    );
    const subTotalMerchandiseSample =
      subTotalMerchandiseSampleSpecs + subTotalMerchandiseSampleCOMS;

    const potentialTariffSample = getPotentialTariffValue(
      bgVendor,
      subTotalMerchandiseSample
    );

    const subTotalMerchandiseSampleTotal =
      subTotalMerchandiseSample +
      potentialTariffSample +
      estimatedFreightToWarehouse +
      bgVendor.miscellaneousCost;

    /**
     * Ext. Production Pricing
     */
    const subTotalMerchandiseProductionSpecs = calculateSubTotalMerchandiseCost(
      bidGroupVendorSpecs,
      false
    );
    const subTotalMerchandiseProductionCOMS = calculateSubTotalMerchandiseCostForCOMS(
      bidGroupVendorSpecs,
      selectedCOMs,
      false
    );
    const subTotalMerchandiseProduction =
      subTotalMerchandiseProductionSpecs + subTotalMerchandiseProductionCOMS;
    const potentialTariffProduction = getPotentialTariffValue(
      bgVendor,
      subTotalMerchandiseProduction
    );
    const subTotalMerchandiseProductionTotal =
      subTotalMerchandiseProduction +
      potentialTariffProduction +
      estimatedFreightToWarehouse +
      bgVendor.miscellaneousCost;

    setDefaultValuesForVendorQuoteCell(rowsByType, bgVendor, vendorIndex);
    const totalsColumn = getTotalsColumn(
      vendorIndex,
      isCOMVisible,
      isSampleColumnsVisible
    );
    if (bgVendor.bidType === BID_TYPES.ALL_INCLUSIVE) {
      buildTotalCell(
        rowsByType,
        "subTotalMerchandise",
        bgVendor,
        vendorIndex,
        defaultProjectCurrency,
        getZeroWhenFalsy(subTotalMerchandiseSample),
        getZeroWhenFalsy(subTotalMerchandiseSample)
      );
    } else {
      buildTotalCell(
        rowsByType,
        "subTotalMerchandise",
        bgVendor,
        vendorIndex,
        defaultProjectCurrency,
        getZeroWhenFalsy(subTotalMerchandiseSample),
        getZeroWhenFalsy(subTotalMerchandiseProduction)
      );
    }
    buildTotalCell(
      rowsByType,
      "potentialTariff",
      bgVendor,
      vendorIndex,
      defaultProjectCurrency,
      getZeroWhenFalsy(potentialTariffSample),
      getZeroWhenFalsy(potentialTariffProduction)
    );
    buildTotalCell(
      rowsByType,
      "miscellaneousCosts",
      bgVendor,
      vendorIndex,
      defaultProjectCurrency,
      getZeroWhenFalsy(bgVendor.miscellaneousCost),
      getZeroWhenFalsy(bgVendor.miscellaneousCost)
    );
    buildTotalCell(
      rowsByType,
      "estimatedFreight",
      bgVendor,
      vendorIndex,
      defaultProjectCurrency,
      getZeroWhenFalsy(estimatedFreightToWarehouse),
      getZeroWhenFalsy(estimatedFreightToWarehouse)
    );
    if (bgVendor.bidType === BID_TYPES.ALL_INCLUSIVE) {
      buildTotalCell(
        rowsByType,
        "totalMerchandise",
        bgVendor,
        vendorIndex,
        defaultProjectCurrency,
        getZeroWhenFalsy(subTotalMerchandiseSampleTotal),
        getZeroWhenFalsy(subTotalMerchandiseProductionTotal)
      );
    } else {
      buildTotalCell(
        rowsByType,
        "totalMerchandise",
        bgVendor,
        vendorIndex,
        defaultProjectCurrency,
        getZeroWhenFalsy(
          subTotalMerchandiseSampleTotal,
          subTotalMerchandiseSampleTotal
        ),
        getZeroWhenFalsy(
          subTotalMerchandiseProductionTotal,
          subTotalMerchandiseProductionTotal
        )
      );
    }
    /**
     * General
     */
    rowsByType.notes[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(bgVendor.notes)
    );
    rowsByType.terms[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(
        _get(
          paymentTerms.find(({ id }) => id == bgVendor.paymentTermId),
          "content"
        )
      )
    );
    rowsByType.fob[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(bgVendor.fob)
    );
    rowsByType.factoryLocation[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(bgVendor.factoryLocation)
    );
    rowsByType.leadTimeForSubmittal[totalsColumn] = buildExpandedCell(
      bgVendor,
      formatLeadTimeForSubmittal(bgVendor)
    );
    rowsByType.leadTime[totalsColumn] = buildExpandedCell(
      bgVendor,
      formatLeadTime(bgVendor)
    );
    rowsByType.quoteValidThrough[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(
        bgVendor.quoteValidUntil
          ? moment(bgVendor.quoteValidUntil).format(PRIMARY_DATE_FORMAT)
          : null
      )
    );
    rowsByType.miscellaneous[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(bgVendor.miscellaneous)
    );
    rowsByType.dropDeadDates[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(bgVendor.dropDeadDates)
    );
    rowsByType.footnotes[totalsColumn] = buildExpandedCell(
      bgVendor,
      getPlaceholderWhenFalsy(
        "* This is only an estimation as the spec hasn't been awarded yet."
      ),
      {
        fontStyle: "italic",
      }
    );
  });
  /* eslint-enable max-lines-per-function */
  const specRows = [];
  sortSpecRows(specRowsById).forEach(specRow => {
    specRows.push(specRow);
  });
  const totalRows = [];
  totalRows.push(
    rowsByType.subTotalMerchandise,
    rowsByType.potentialTariff,
    rowsByType.miscellaneousCosts,
    rowsByType.estimatedFreight,
    rowsByType.totalMerchandise,
    rowsByType.notes,
    rowsByType.terms,
    rowsByType.fob,
    rowsByType.factoryLocation,
    rowsByType.leadTimeForSubmittal,
    rowsByType.leadTime,
    rowsByType.quoteValidThrough,
    rowsByType.miscellaneous,
    rowsByType.dropDeadDates,
    rowsByType.footnotes
  );
  return {
    specRows,
    totalRows,
    count: specRowsById.length,
    isCOMVisible,
    isSampleColumnsVisible,
  };
};

const markAsAlternateQuote = (sequenceIndex, bidGroupVendor) => {
  if (sequenceIndex > 1) {
    bidGroupVendor.vendor.name += ` #${sequenceIndex}`;
    bidGroupVendor.id += ` -${sequenceIndex}`;
    bidGroupVendor.isAlternateQuote = true;
  }
};

const cloneNewBidGroupIfNecessary = (
  quote,
  quoteSequenceIndex,
  bidGroupVendorAlternateDictionary,
  bidGroupVendor
) => {
  if (quote.sequenceIndex > quoteSequenceIndex) {
    bidGroupVendorAlternateDictionary[bidGroupVendor.id].push(
      _cloneDeep(bidGroupVendor)
    );
    quoteSequenceIndex = quote.sequenceIndex;
  }

  return quoteSequenceIndex;
};

export const getBidGroupVendorsAlternate = bidGroupVendors => {
  const newBidGroupVendors = [];
  const bidGroupVendorAlternateDictionary = {};

  bidGroupVendors.forEach(bidGroupVendor => {
    let quoteSequenceIndex = 0;
    bidGroupVendorAlternateDictionary[bidGroupVendor.id] = [];
    bidGroupVendor.bidGroupVendorSpecs?.forEach(bidGroupVendorSpec => {
      bidGroupVendorSpec.quotes?.forEach(quote => {
        quoteSequenceIndex = cloneNewBidGroupIfNecessary(
          quote,
          quoteSequenceIndex,
          bidGroupVendorAlternateDictionary,
          bidGroupVendor
        );
      });
    });
  });

  for (const newBidGruopVendorsId in bidGroupVendorAlternateDictionary) {
    let sequenceIndex = 1;
    bidGroupVendorAlternateDictionary[newBidGruopVendorsId].forEach(
      bidGroupVendor => {
        bidGroupVendor.bidGroupVendorSpecs?.forEach(bidGroupVendorSpec => {
          bidGroupVendorSpec.quotes = bidGroupVendorSpec.quotes?.filter(
            quote => quote.sequenceIndex == sequenceIndex
          );
        });

        markAsAlternateQuote(sequenceIndex, bidGroupVendor);
        newBidGroupVendors.push(bidGroupVendor);
        sequenceIndex++;
      }
    );
  }

  newBidGroupVendors.forEach(bidGroupVendor => {
    bidGroupVendor.bidGroupVendorSpecs = bidGroupVendor.bidGroupVendorSpecs?.filter(
      bidGroupVendorSpec => bidGroupVendorSpec.quotes?.length > 0
    );
  });

  return newBidGroupVendors;
};
