import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { connect as formikConnect } from "formik";

import AddContactMenu from "./AddContactMenu";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../../reducers/dataComponentReducer";
import {
  getBluechipResources,
  getBluechipResourceById,
} from "../../../../../../utils/bluechipUtils";
import {
  performUpdateRequest,
  performCreateRequest,
} from "../../../../../../actions/dataComponentActions";
import {
  createPOContact,
  getDataComponentId,
  poContactdataComponentId,
  initContactsDataComponent,
  loadVendorContacts,
} from "../../../../../../actions/purchaseOrdersContactActions";

import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import {
  openModalDialog,
  closeModalDialog,
  setModalDialogTitle,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import * as DATA_COMPONENTS from "../../../../../../constants/DataComponents";
import {
  addressBookType,
  updateModalDialogTitle,
} from "../../../../VendorContacts/VendorContactsContainer";
import { onCreateVendorContact } from "../../../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";
import { bindActionCreators } from "redux";

const baseDCId = "AddPurchaseContactMenu";
const specDataComponentId = "SpecDetail";

export const getSelectedContacts = (purchaseOrderContacts, projectContacts) => {
  return purchaseOrderContacts
    .map(poContact => poContact.vendorContact || poContact.consultingContact)
    .concat(projectContacts);
};

const getContactsDataComponentId = type => {
  return `${baseDCId}-${type}`;
};

const mapStateToProps = (
  state,
  {
    purchaseOrderContacts,
    projectContacts,
    type,
    match: {
      params: { specId },
    },
  }
) => {
  const dataComponentId = getContactsDataComponentId(type);
  const contactsDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const specDataComponent = getDataComponentFlattenedRequestState(
    specDataComponentId,
    state
  );
  const createRequestStatus = getDataComponentFlattenedRequestState(
    getDataComponentId(type),
    state,
    REQUEST_TYPES.CREATE
  );

  const vendorContactDataComponent = getDataComponent(dataComponentId, state);
  const spec = getBluechipResourceById(specDataComponent, state, specId);

  const dataComponentAddressBooks = getDataComponentFlattenedRequestState(
    DATA_COMPONENTS.ADDRESS_BOOKS_SELECT,
    state
  );
  const addressBooks =
    getBluechipResources(dataComponentAddressBooks, state) || [];

  const addressBook =
    addressBooks.find(addressBook => addressBook.type === addressBookType) ||
    {};

  const selectedContacts = getSelectedContacts(
    purchaseOrderContacts,
    projectContacts
  );

  return {
    loading: contactsDataComponent.loading,
    addressBook,
    vendorContacts: getBluechipResources(contactsDataComponent, state) || [],
    spec,
    isCreating: createRequestStatus.loading,
    selectedContacts,
    vendorContactDataComponent,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { vendor, match, type, formik } = ownProps;
  const dataComponentId = getContactsDataComponentId(type);
  const purchaseOrderId =
    match.params.poId || _get(ownProps, "purchaseOrder.id");

  const { setFieldValue, values: po } = formik;

  const {
    match: {
      params: { specId },
    },
  } = ownProps;

  const {
    performCreateRequest: boundPerformCreateRequest,
    closeModalDialog: boundCloseModalDialog,
    setModalDialogTitle: boundSetModalDialogTitle,
    showSnackNotificationAction: boundShowSnackNotificationAction,
  } = bindActionCreators(
    {
      performCreateRequest,
      closeModalDialog,
      setModalDialogTitle,
      showSnackNotificationAction,
    },
    dispatch
  );

  return {
    showSnackNotificationAction: boundShowSnackNotificationAction,
    closeModalDialog: boundCloseModalDialog,
    loadVendorContacts: () =>
      dispatch(loadVendorContacts(dataComponentId, type, vendor.id)),
    initDataComponent: () =>
      dispatch(initContactsDataComponent(dataComponentId, type)),
    updateSpecVendorContacts: vendorContacts => {
      dispatch(
        performUpdateRequest(specDataComponentId, specId, {
          vendorContacts,
        })
      );
    },
    createPOContact: vendorContactId =>
      dispatch(
        createPOContact(
          poContactdataComponentId,
          purchaseOrderId,
          vendorContactId,
          type,
          setFieldValue,
          po.poContacts
        )
      ),
    openCreateContactDialog: (addressBook, formikPropsRef) => {
      const handleCreate = vendorContact =>
        onCreateVendorContact(
          vendorContact,
          addressBook,
          dataComponentId,
          boundPerformCreateRequest
        );

      const handleToggle = showNewContact => {
        updateModalDialogTitle(showNewContact, boundSetModalDialogTitle);
      };

      dispatch(
        openModalDialog(
          "Create Contact",
          "CreateVendorContact",
          {
            vendor,
            gridComponentId: dataComponentId,
            dataComponentId,
            onCreateVendorContact: handleCreate,
            toggleCreateNewContact: handleToggle,
            formikPropsRef,
          },
          true
        )
      );
    },
  };
};

export default withRouter(
  formikConnect(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(props =>
      props.vendor && props.vendor.id && props.addressBook.id ? (
        <AddContactMenu {...props} />
      ) : null
    )
  )
);
