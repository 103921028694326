import React, { useState } from "react";
import PropTypes from "prop-types";

import ActionButtons from "../../../../../../forms/ActionButtons";
import { Fragment } from "react";
import { Dialog, Typography } from "@material-ui/core";
import ModalTitle from "../../../../../../layout/AppLayout/ModalDialog/ModalTitle";
import { useFormikContext } from "formik";

const SubmitButton = ({
  handleSubmit,
  dataComponentId,
  loading,
  hideCancelButton,
  onCancel,
  sendButtonText,
}) => {
  const [isOpen, setOpen] = useState(false);
  const {
    values: { useTaxPayment, other },
  } = useFormikContext();

  if (!handleSubmit) {
    return null;
  }

  const onSend = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    handleSubmit();
    closeDialog();
  };

  const confirmationMessage = getConfirmationMessage({ useTaxPayment, other });

  return (
    <Fragment>
      <ActionButtons
        onSend={onSend}
        isModal={true}
        sendButtonText={sendButtonText}
        listeners={[dataComponentId]}
        hideCancelButton={loading || hideCancelButton}
        onCancel={onCancel}
      />
      <Dialog open={isOpen} disablePortal style={{ zIndex: 1299 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ModalTitle
            title={"Review Settings"}
            closeModalDialog={closeDialog}
            width={"auto"}
            isShowCloseIcon
          />
          <Typography style={{ padding: "24px" }}>
            {confirmationMessage}
          </Typography>
          <ActionButtons
            onSend={onSubmit}
            isModal={true}
            sendButtonText={"Confirm"}
            listeners={[dataComponentId]}
            onCancel={closeDialog}
          />
        </div>
      </Dialog>
    </Fragment>
  );
};
SubmitButton.propTypes = {
  handleSubmit: PropTypes.func,
  dataComponentId: PropTypes.string,
  loading: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  sendButtonText: PropTypes.string,
};

const getConfirmationMessage = ({ useTaxPayment, other }) => {
  if (useTaxPayment && other) {
    return "Are you sure about Issuing Funding Request with Use Tax Payment and Other options?";
  } else if (useTaxPayment) {
    return "Are you sure about Issuing Funding Request with Use Tax Payment option?";
  } else if (other) {
    return "Are you sure about Issuing Funding Request with Other option?";
  } else {
    return "Are you sure about Issuing Funding Request without Use Tax Payment or Other options?";
  }
};

export default SubmitButton;
