import React, { Component } from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import memoizeOne from "memoize-one";
import BWModels from "benjaminwest-models";
import _isEmpty from "lodash/isEmpty";
import { get } from "lodash";
import styled from "styled-components";

import Paper from "../../mui/core/Paper";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import VendorColumn from "./VendorColumn";
import SpecActions from "./SpecActions";
import propTypes from "../../../constants/propTypes";
import {
  PriceColumn,
  ExtPriceColumn,
  CustomNumberColumn,
  DesignerBudgetColumn,
  OverageColumn,
  RFQNumber,
  PONumber,
  SwitchBox,
  SwitchLabel,
  SpecCategoryColumn,
  getSpecBidGroup,
} from "./components";
import { DescriptionColumn } from "./DescriptionColumn";
import { roleCan } from "../../../utils/roleUtils";
import { genDetailRowComponents } from "../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";
import { ArrayText } from "../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow/DetailComponent/inputComponents";
import { Switch } from "../../mui/core";
import BWCell from "../../ui/BWGrid/gridInternalComponents/BWCell";
import BWTableRow from "../../ui/BWGrid/gridInternalComponents/BWTableRow";
import { isPOChangeDisabled } from "../PurchaseOrderDetail/utils";

const StyledCell = styled(BWCell)`
  border-bottom: none;
`;
const StyledTableRow = styled(BWTableRow)`
  background-color: ${({ greyBackground }) =>
    greyBackground ? "rgba(0, 0, 0, 0.05) !important" : "initial !important"};
`;
export const CustomCell = props => {
  return <StyledCell {...props} />;
};
export const CustomRow = props => {
  return (
    <StyledTableRow
      {...props}
      greyBackground={props.row.isIncomplete || props.row.isAllowance}
    />
  );
};

const rfqNumberFilter = ({ value }) => ({
  $or: [
    { "purchaseOrder.bidGroup.id": value },
    {
      "bidGroups.id": {
        $in: [value],
      },
    },
  ],
});

const columns = [
  "customNumber",
  "description",
  "specCategory.name",
  "area.name",
  "vendor.name",
  "projectCurrency.currency.name",
  "designerBudget",
  "designerQuantity",
  "priceCents",
  "totalQuantityWithoutOverage",
  "atticStock",
  "overagePercent",
  "unitOfMeasure.name",
  "totalForecast",
  "rfqNumber",
  "purchaseOrder.sequenceIndex",
];

const columnOptions = (rfqs, pos, onGoToPO, onGoToBidGroup) => {
  return {
    customNumber: {
      title: "Spec Number",
      bold: true,
      filter: true,
      render: CustomNumberColumn,
    },
    description: {
      filter: true,
      width: "300px",
      render: DescriptionColumn,
    },
    "specCategory.name": {
      title: "Category",
      filter: true,
      render: SpecCategoryColumn,
    },
    "area.name": { title: "Area", filter: true },
    "vendor.name": {
      title: "Vendor",
      fill: true,
      render: VendorColumn,
      filter: true,
    },
    "projectCurrency.currency.name": {
      title: "Currency",
      fill: true,
      filter: true,
    },
    "unitOfMeasure.name": { title: "UOM", align: "right" },
    totalQuantityWithoutOverage: {
      title: "Qty",
    },
    priceCents: {
      title: "Price",
      render: PriceColumn,
    },
    totalForecast: {
      title: "Ext Price",
      render: ExtPriceColumn,
      sortingEnabled: false,
    },
    designerBudget: {
      title: "Designer Budget",
      render: DesignerBudgetColumn,
    },
    atticStock: {
      title: "Attic Stock",
    },
    designerQuantity: {
      title: "Designer Qty",
    },
    overagePercent: {
      title: "Overage",
      render: OverageColumn,
    },
    "purchaseOrder.sequenceIndex": {
      title: "PO Number",
      render: PONumber(onGoToPO),
      filter: "select",
      filterOptions: {
        operator: ({ value }) =>
          value != -1
            ? {
                ["purchaseOrder.id"]: value,
              }
            : { purchaseOrderId: { $exists: false } },
        options: [
          {
            id: -1,
            name: "None",
          },
          ...pos.map(({ id, sequenceIndex }) => ({
            id,
            name: String(sequenceIndex).padStart(3, "0"),
          })),
        ],
      },
    },
    rfqNumber: {
      title: "RFQ Number",
      render: RFQNumber(onGoToBidGroup),
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: rfqs.map(({ id, sequenceIndex }) => ({
          id,
          name: String(sequenceIndex).padStart(3, "0"),
        })),
        operator: rfqNumberFilter,
      },
    },
  };
};

const getPOOption = (row, goToPO) => {
  if (row.purchaseOrderId) {
    return {
      text: `View PO #${row.purchaseOrder?.number}`,
      onClick: () => goToPO(row.purchaseOrderId),
    };
  }
  return {
    text: "View PO",
    disabled: true,
  };
};

export const isSpecDeleteDisabled = spec =>
  Boolean(get(spec, "purchaseOrderId")) || Boolean(getSpecBidGroup(spec));

export const getRowMenu = ({
  onEditSpec,
  onOpenDuplicateSpecModal,
  onOpenDeleteSpecModal,
  onOpenIncompleteSpecModal,
  onOpenCompleteSpecModal,
  onOpenFlagAllowanceSpec,
  onGoToPO,
}) => row => {
  const isUpdateDisabled = isPOChangeDisabled(get(row, "purchaseOrder"));

  const isDeleteDisabled = isSpecDeleteDisabled(row);
  return [
    getPOOption(row, onGoToPO),
    { text: "Edit Spec", onClick: () => onEditSpec(row) },
    {
      text: "Duplicate",
      onClick: () => onOpenDuplicateSpecModal(row),
      disableIfProjectClosed: true,
    },
    { separator: true },
    {
      text: "Flag as Incomplete",
      onClick: () => onOpenIncompleteSpecModal(row),
      disabled: row.isIncomplete || isUpdateDisabled,
      disableIfProjectClosed: true,
    },
    {
      text: "Remove Incomplete Flag",
      onClick: () => onOpenCompleteSpecModal(row),
      disabled: !row.isIncomplete || isUpdateDisabled,
      disableIfProjectClosed: true,
    },
    {
      text: "Flag as Allowance",
      onClick: () => onOpenFlagAllowanceSpec(row, true),
      disabled: row.isAllowance || isUpdateDisabled,
      disableIfProjectClosed: true,
    },
    {
      text: "Remove Allowance Flag",
      onClick: () => onOpenFlagAllowanceSpec(row, false),
      disabled: !row.isAllowance || isUpdateDisabled,
      disableIfProjectClosed: true,
    },
    { separator: true },
    {
      text: "Delete",
      onClick: () => onOpenDeleteSpecModal(row),
      disabled: isDeleteDisabled,
      disableIfProjectClosed: true,
    },
  ];
};

export default class SpecsPage extends Component {
  state = {
    inCompleteSpecsOnly: false,
    displayComments: true,
    inAllowanceSpecsOnly: false,
  };

  createButton() {
    return roleCan(this.props.role, "project-detail-specs-add-spec")
      ? [
          {
            text: "Create Spec",
            icon: <AddCircle />,
            handler: this.props.onOpenAddSpec,
            disableIfProjectClosed: true,
          },
        ]
      : [];
  }

  bulkButton() {
    return roleCan(this.props.role, "project-detail-specs-bulk-upload")
      ? [
          {
            text: "IMPORT SPECS",
            icon: <AddCircle />,
            handler: this.props.onOpenImportSpecModal,
            disableIfProjectClosed: true,
          },
        ]
      : [];
  }

  getTableComponents = memoizeOne((dataComponentId, displayComments) => {
    if (!displayComments) return {};
    const detailRowComponents = genDetailRowComponents(
      "id",
      [
        {
          path: "forecastComment",
          label: "Forecast Comment",
          placeholder: "Add Forecast Comment",
          isEmptyFn: Boolean,
        },
        {
          path: "glCodes",
          label: "GL Codes",
          disabled: true,
          isEmptyFn: _isEmpty,
          alwaysVisible: false,
          InputComponent: ArrayText,
          inputComponentProps: {
            itemLabelPath: "description",
          },
        },
      ],
      BWModels.loadSchema("Spec"),
      dataComponentId,
      { prevSpan: 3, colSpan: 15, posSpan: 1 }
    );
    return {
      ...detailRowComponents,
      CellComponent: CustomCell,
      RowComponent: CustomRow,
    };
  });
  handleToggleAllowanceSpecs = () => {
    this.setState({
      displayComments: this.state.displayComments,
      inCompleteSpecsOnly: this.state.inCompleteSpecsOnly,
      inAllowanceSpecsOnly: !this.state.inAllowanceSpecsOnly,
    });
  };
  handleToggleIncompleteSpecs = () => {
    this.setState({
      displayComments: this.state.displayComments,
      inCompleteSpecsOnly: !this.state.inCompleteSpecsOnly,
      inAllowanceSpecsOnly: this.state.inAllowanceSpecsOnly,
    });
  };
  handleToggleComments = () => {
    this.setState({
      displayComments: !this.state.displayComments,
      inCompleteSpecsOnly: this.state.inCompleteSpecsOnly,
      inAllowanceSpecsOnly: this.state.inAllowanceSpecsOnly,
    });
  };

  render() {
    const {
      dataComponent,
      projectId,
      areaId,
      onOpenBulkDeleteModal,
      onOpenDuplicateSpecs,
      onOpenCopySpecModal,
      onEditSpec,
      onOpenDuplicateSpecModal,
      onOpenDeleteSpecModal,
      onOpenIncompleteSpecModal,
      onOpenCompleteSpecModal,
      onOpenIncompleteSpecsModal,
      onOpenCompleteSpecsModal,
      onOpenMoveSpecsModal,
      onOpenFlagAllowanceSpecs,
      onOpenFlagAllowanceSpec,
      onGoToPO,
      onGoToBidGroup,
      onRowClick,
      rfqs,
      pos,
    } = this.props;

    const whereParams = {
      projectId,
      areaId,
    };
    if (this.state.inCompleteSpecsOnly) {
      whereParams["isIncomplete"] = true;
    }
    if (this.state.inAllowanceSpecsOnly) {
      whereParams["isAllowance"] = true;
    }
    return (
      <Paper>
        <BWGridAPI
          {...this.props}
          showSelectionColumn={true}
          showSelectAll={true}
          defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
          apiFilters={{
            rootFilters: {
              $where: whereParams,
            },
            params: {
              omitDefaultModifier: true,
              modifiers: ["withQuantityPrice"],
            },
          }}
          additionalSorting={[{ columnName: "id", direction: "asc" }]}
          tableComponents={this.getTableComponents(
            dataComponent.dataComponentId,
            this.state.displayComments
          )}
          alwaysDisplayDetailRow
        >
          <GridHeader
            headerText={`${dataComponent.totalRows} Specs`}
            headerOverride={
              <SpecActions
                onOpenBulkDeleteModal={onOpenBulkDeleteModal}
                onOpenDuplicateSpecs={onOpenDuplicateSpecs}
                onOpenCopySpecModal={onOpenCopySpecModal}
                onOpenIncompleteSpecModal={onOpenIncompleteSpecsModal}
                onOpenCompleteSpecModal={onOpenCompleteSpecsModal}
                onOpenMoveSpecsModal={onOpenMoveSpecsModal}
                onOpenFlagAllowanceSpecs={onOpenFlagAllowanceSpecs}
                dataComponent={dataComponent}
              />
            }
            actions={[...this.bulkButton(), ...this.createButton()]}
          >
            <SwitchBox>
              <SwitchLabel
                hide={
                  !dataComponent.totalRows && !this.state.inAllowanceSpecsOnly
                }
                control={
                  <Switch
                    checked={this.state.inAllowanceSpecsOnly}
                    onChange={this.handleToggleAllowanceSpecs}
                    color="primary"
                  />
                }
                label="Show Allowance Only"
              />
              <SwitchLabel
                hide={
                  !dataComponent.totalRows && !this.state.inCompleteSpecsOnly
                }
                control={
                  <Switch
                    checked={this.state.inCompleteSpecsOnly}
                    onChange={this.handleToggleIncompleteSpecs}
                    color="primary"
                  />
                }
                label="Show Incomplete Only"
              />
              <SwitchLabel
                hide={!dataComponent.totalRows}
                control={
                  <Switch
                    checked={this.state.displayComments}
                    onChange={this.handleToggleComments}
                    color="primary"
                  />
                }
                label="Expand Info"
              />
            </SwitchBox>
          </GridHeader>
          <GridTable
            columns={columns}
            columnOptions={columnOptions(rfqs, pos, onGoToPO, onGoToBidGroup)}
            onClick={onRowClick}
            rowMenu={getRowMenu({
              onEditSpec,
              onOpenDuplicateSpecModal,
              onOpenDeleteSpecModal,
              onOpenIncompleteSpecModal,
              onOpenCompleteSpecModal,
              onOpenFlagAllowanceSpec,
              onGoToPO,
            })}
          />
        </BWGridAPI>
      </Paper>
    );
  }
}

SpecsPage.propTypes = {
  dataComponent: propTypes.dataComponent.isRequired,
  isModalOpen: PropTypes.bool,
  onOpenAddSpec: PropTypes.func,
  onOpenImportSpecModal: PropTypes.func,
  onOpenBulkDeleteModal: PropTypes.func,
  onOpenDuplicateSpecs: PropTypes.func,
  onOpenCopySpecModal: PropTypes.func,
  onEditSpec: PropTypes.func,
  onOpenDuplicateSpecModal: PropTypes.func,
  onOpenDeleteSpecModal: PropTypes.func,
  onOpenIncompleteSpecModal: PropTypes.func,
  onOpenCompleteSpecModal: PropTypes.func,
  onOpenIncompleteSpecsModal: PropTypes.func,
  onOpenCompleteSpecsModal: PropTypes.func,
  onOpenMoveSpecsModal: PropTypes.func,
  onOpenFlagAllowanceSpecs: PropTypes.func,
  onOpenFlagAllowanceSpec: PropTypes.func,
  onGoToPO: PropTypes.func,
  onGoToBidGroup: PropTypes.func,
  onRowClick: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  areaId: PropTypes.string,
  rfqs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  pos: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};
