import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { CreateAreaType } from "./CreateAreaType";
import {
  performCreateRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";
import { areaTypeDataComponentId } from "../AreaTypeContainer";
import { useCreateProcessRequests } from "../../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../../utils/formValidationUtils";
import propTypes from "../../../../../../constants/propTypes";

const CreateAreaTypeContainer = ({
  dataComponent,
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Area Type created successfully.");
      setReload(areaTypeDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleCreateAreaType = values => {
    performCreateRequest(areaTypeDataComponentId, values);
  };

  return (
    <CreateAreaType
      handleCreateAreaType={handleCreateAreaType}
      closeModalDialog={closeModalDialog}
    />
  );
};

CreateAreaTypeContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  performCreateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(areaTypeDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAreaTypeContainer);
