import {
  fetchDataFailed,
  fetchDataRequest,
  fetchDataSuccess,
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
} from "./dataComponentActions";
import { getCurrentBluechipResourcesForRequestType } from "../utils/bluechipUtils";
import { dataComponentId as poDataComponentId } from "../components/pages/PurchaseOrderDetail/connect";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import ConsultingCompanyContact from "../models/ConsultingCompanyContact";
import VendorContact from "../models/VendorContact";
import PurchaseOrderContact from "../models/PurchaseOrderContact";

export const poContactdataComponentId = "POContacts";
export const freightContactdataComponentId = "ProjectFreightContacts";
export const designerContactdataComponentId = "DesignerContacts";

const dcIdsMap = {
  vendor: poContactdataComponentId,
  designer: designerContactdataComponentId,
  freight: freightContactdataComponentId,
};

export function getDataComponentId(type) {
  return dcIdsMap[type] || dcIdsMap.freight;
}

export function createPOContact(
  dataComponentId,
  purchaseOrderId,
  contactId,
  contactType = "vendor",
  setFieldValue,
  currentContacts = []
) {
  return async (dispatch, getState) => {
    dispatch(fetchDataRequest(poDataComponentId, REQUEST_TYPES.UPDATE));
    try {
      const relationKeys = {
        designer: "consultingContactId",
        projectSite: "projectSiteContactId",
      };
      const relationKey = relationKeys[contactType] || "vendorContactId";
      const response = await dispatch(
        performCreateRequest(dataComponentId, {
          [relationKey]: contactId,
          purchaseOrderId,
          contactType,
        })
      );

      dispatch(
        fetchDataSuccess(poDataComponentId, REQUEST_TYPES.UPDATE, response)
      );
      const resource = getCurrentBluechipResourcesForRequestType(
        dataComponentId,
        getState(),
        REQUEST_TYPES.CREATE
      );
      setFieldValue("poContacts", [resource, ...currentContacts]);
    } catch (error) {
      dispatch(fetchDataFailed(poDataComponentId, REQUEST_TYPES.UPDATE, error));
      throw error;
    }
  };
}

export const initContactsDataComponent = (dataComponentId, type) => {
  return dispatch => {
    if (type === "designer") {
      return dispatch(
        initDataComponent(
          dataComponentId,
          ConsultingCompanyContact,
          ["contact.person"],
          "consulting-company-contacts",
          false,
          "v2"
        )
      );
    }

    return dispatch(
      initDataComponent(
        dataComponentId,
        VendorContact,
        ["contact.person"],
        "vendor-contacts",
        false,
        "v2"
      )
    );
  };
};

export const loadVendorContacts = (dataComponentId, type, id) => {
  return dispatch => {
    if (type === "designer") {
      return dispatch(
        performRetrieveListRequest(dataComponentId, {
          rootFilters: {
            $where: { consultingCompanyId: id },
          },
          pageSize: -1,
          sort: [{ columnName: "contact.firstName", order: "asc" }],
        })
      );
    }

    return dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: { vendorId: id, isActive: true },
        },
        pageSize: -1,
        sort: [{ columnName: "contact.firstName", order: "asc" }],
      })
    );
  };
};

export function fetchPurchaseOrderContacts(purchaseOrderId) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        poContactdataComponentId,
        PurchaseOrderContact,
        [
          "vendorContact.contact.person",
          "consultingContact.contact.person",
          "projectSiteContact.contact.person",
        ],
        "purchase-orders-contacts",
        undefined,
        "v2"
      )
    );
    dispatch(
      await performRetrieveListRequest(poContactdataComponentId, {
        rootFilters: {
          $where: { purchaseOrderId },
        },
      })
    );
  };
}
