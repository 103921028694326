import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BulkDeleteConfirmation from "./BulkDeleteConfirmation";
import { closeModalDialog } from "../../actions/layoutActions";
import propTypes from "../../constants/propTypes";
import {
  performDeleteRequest,
  setReload,
} from "../../actions/dataComponentActions";

import { getDataComponent } from "../../reducers/dataComponentReducer";
import { processDeleteRequestStatus } from "../../utils/dataComponentUtils";

const processRequestResponse = (
  dataComponentId,
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  onSuccess
) => {
  processDeleteRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      onSuccess && onSuccess();
    },
  });
};

export const BulkDeleteConfirmationContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  onDelete,
  performDeleteRequest,
  selectedIds,
  dataComponentId,
  reloadDataComponentId = dataComponentId,
  params,
  message,
  onSuccess,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      reloadDataComponentId,
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      onSuccess
    );
  }, [
    reloadDataComponentId,
    prevDataComponent,
    dataComponent,
    closeModalDialog,
    setReload,
    onSuccess,
  ]);

  const handleDelete = useCallback(
    () => performDeleteRequest(dataComponentId, selectedIds, params),
    [performDeleteRequest, selectedIds, dataComponentId, params]
  );

  return (
    <BulkDeleteConfirmation
      count={selectedIds.length}
      message={message}
      onDelete={onDelete || handleDelete}
      listeners={{ danger: [dataComponentId] }}
    />
  );
};

BulkDeleteConfirmationContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  dataComponentId: PropTypes.string.isRequired,
  message: PropTypes.string,
  reloadDataComponentId: PropTypes.string,
  closeModalDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  performDeleteRequest: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setReload: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
  onSuccess: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performDeleteRequest,
  setReload,
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkDeleteConfirmationContainer);
