import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import _get from "lodash/get";

import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";

import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import { connect } from "react-redux";
import {
  initDataComponent,
  destroyDataComponent,
  performCreateRequest,
  performDeleteRequest,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
  fetchData,
} from "../../../../actions/dataComponentActions";
import {
  showSnackNotificationAction,
  openModalDialog,
  closeModalDialog,
  setAutoSaveComponentId,
} from "../../../../actions/layoutActions";
import BidGroupPurchaseOrder from "../../../../models/BidGroupPurchaseOrder";
import BidGroupVendorSpec from "../../../../models/BidGroupVendorSpec";
import BidGroupVendorSpecQuote from "../../../../models/BidGroupVendorSpecQuote";
import PurchaseOrder from "../../../../models/PurchaseOrder";

import BidGroupTable from "./BidGroupTable";
import {
  getBluechipResourceById,
  getBluechipResources,
} from "../../../../utils/bluechipUtils";

import QuoteEmptyState from "./PurchaseOrders/QuoteEmptyState";
import PurchaseOrderTable from "./PurchaseOrders/PurchaseOrderTable";
import BidGroup from "../../../../models/BidGroup";
import {
  onCreateBidGroupPurchaseOrder,
  onCreateAlternateQuote,
  onUpdatePurchaseOrderStatus,
  onAwardSpec,
  onUnAwardSpec,
  onUpdateNote,
  processRequests,
  processRequestsForPurchaseOrder,
  initBidGroupVendorDataComponent,
  initSpecDataComponent,
  processRequestForBidGroupVendorSpecQuotes,
  processRequestsForSpecs,
} from "./QuoteFunctions";
import * as DATA_COMPONENTS from "../../../../constants/DataComponents";
import { ContentWrapper } from "../../PurchaseOrderDetail/components";
import { revertFromRevision } from "../../../../actions/purchaseOrdersActions";
import SpecTable from "./SpecTable/SpecTable";
import { QuoteViewByContext } from "./ViewByControl/withQuoteViewByControl";
import {
  bidGroupVendorSpecDataComponentId,
  dataComponentVendorId,
  specDataComponentId,
} from "../../../../actions/bidGroupActions";
import pluralize from "pluralize";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";

export const dataComponentId = "PurchaseOrdersGrid";
export const bidGroupDataComponentId = "BidGroupDataComponent";
export const bidGroupVendorDataComponentId = "BidGroupVendorDataComponent";
export const bidGroupPurchaseOrderDataComponentId =
  "BidGroupPurchaseOrderDataComponent";
export const purchaseOrderDataComponentId = "PurchaseOrderDataComponent";
export const bidGroupVendorSpecQuoteComponentId =
  "BidGroupVendorSpecQuoteDataComponent";
export const vendorCategoriesDataComponentId =
  DATA_COMPONENTS.VENDOR_CATEGORIES_SELECT;
export const specCategoriesDataComponentId =
  DATA_COMPONENTS.SPEC_CATEGORIES_SELECT;

export const getSpecVendorGroups = (bidGroup, bidGroupVendors) => {
  const results = {};
  bidGroupVendors.map(bidGroupVendor => {
    const { bidGroupVendorSpecs = [] } = bidGroupVendor;
    bidGroupVendorSpecs.map(bidGroupVendorSpec => {
      results[bidGroupVendorSpec.spec.id] = bidGroupVendorSpec.spec;
    });
  });
  const specVendorGroups = [];
  Object.values(results).map(bidGroupSpec => {
    const specVendor = {
      spec: bidGroupSpec,
      vendors: [],
    };
    bidGroupVendors.map(bidGroupVendor => {
      const { bidGroupVendorSpecs = [] } = bidGroupVendor;
      bidGroupVendorSpecs.map(bidGroupVendorSpec => {
        if (bidGroupVendorSpec.spec.id === bidGroupSpec.id) {
          specVendor.vendors.push(bidGroupVendor);
        }
      });
    });
    specVendorGroups.push(specVendor);
  });
  return specVendorGroups;
};
export const createBidGroupPurchaseOrder = (
  bidGroupVendor,
  purchaseOrder,
  dataComponentId,
  performCreateRequest,
  setSnackMessage
) => {
  setSnackMessage("Purchase Order successfully created");
  onCreateBidGroupPurchaseOrder(
    bidGroupVendor.bidGroupId,
    purchaseOrder,
    dataComponentId,
    performCreateRequest
  );
};
export const createAddToPurchaseOrder = (
  bidGroup,
  performUpdateRequest,
  performCreateRequest,
  setSnackMessage
) => (purchaseOrderId, specs) => {
  setSnackMessage("Specs added to Purchase Order successfully.");
  performUpdateRequest(
    specDataComponentId,
    specs.map(spec => ({ ...spec, purchaseOrderId }))
  );
  if (!bidGroup.purchaseOrders.find(({ id }) => id === purchaseOrderId)) {
    performCreateRequest(bidGroupPurchaseOrderDataComponentId, {
      bidGroupId: bidGroup.id,
      purchaseOrderId,
    });
  }
};
export const updateNote = (
  bidGroupVendorSpecQuote,
  dataComponentId,
  performUpdateRequest,
  setSnackMessage
) => {
  setSnackMessage("Spec note successfully updated.");
  onUpdateNote(
    bidGroupVendorSpecQuote,
    bidGroupVendorSpecQuoteComponentId,
    performUpdateRequest
  );
};
export const awardSpec = (awardSpecAction, bidGroupVendorRef) => (
  specs,
  dataComponentId,
  setSnackMessage
) => {
  setSnackMessage("Spec successfully awarded.");
  onAwardSpec(specs, dataComponentId, awardSpecAction, bidGroupVendorRef);
};
export const unAwardSpec = (unawardSpecAction, bidGroupVendorRef) => (
  specs,
  dataComponentId,
  setSnackMessage
) => {
  setSnackMessage("Spec successfully un-awarded.");
  onUnAwardSpec(specs, dataComponentId, unawardSpecAction, bidGroupVendorRef);
};
export const createAlternateQuote = (
  bidGroupVendorSpecId,
  notes,
  quoteItems,
  dataComponentId,
  performCreateRequest,
  setSnackMessage
) => {
  setSnackMessage("Alternate quote successfully created");
  onCreateAlternateQuote(
    bidGroupVendorSpecId,
    notes,
    quoteItems,
    dataComponentId,
    performCreateRequest
  );
};

const updateBidGroupVendorSpecs = (
  performRetrieveListRequest,
  bidGroupId
) => () => {
  performRetrieveListRequest(bidGroupVendorDataComponentId, {
    rootFilters: {
      $where: { bidGroupId: bidGroupId },
    },
    pageSize: -1,
  });
};

// eslint-disable-next-line max-lines-per-function,complexity
export const QuotesPage = ({
  match: { params },
  initDataComponent,
  destroyDataComponent,
  performCreateRequest,
  performUpdateRequest,
  performFindRequest,
  performRetrieveListRequest,
  fetchData,
  showSnackNotificationAction,
  openModalDialog,
  closeModalDialog,
  loading,
  loadingSpecs,
  bidGroup,
  bidGroupVendors,
  vendorCategories,
  specCategories,
  bidGroupPurchaseOrdersDataComponent,
  bidGroupVendorSpecQuoteComponent,
  specDataComponent,
  purchaseOrderDataComponent,
  revertFromRevision,
  pushWithReturnUrl,
  setAutoSaveComponentId,
  updateVendorSpecDataComponent,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(null);
  const { currentView } = useContext(QuoteViewByContext);
  const [
    prevPurchaseOrderDataComponent,
    setPrevPurchaseOrderDataComponent,
  ] = useState(null);
  const [prevSpecDataComponent, setPrevSpecDataComponent] = useState(null);
  const [
    prevBidGroupVendorSpecQuoteComponent,
    setPrevBidGroupVendorSpecQuoteComponent,
  ] = useState(null);
  const [snackMessage, setSnackMessage] = useState("");

  const reloadBidGroupVendorSpecs = updateBidGroupVendorSpecs(
    performRetrieveListRequest,
    params.bidGroupId
  );

  useUpdateProcessRequests(updateVendorSpecDataComponent, {
    onSuccess: () => {
      reloadBidGroupVendorSpecs();
    },
  });

  useEffect(() => setPrevDataComponent(bidGroupPurchaseOrdersDataComponent), [
    bidGroupPurchaseOrdersDataComponent,
  ]);
  useEffect(() => {
    processRequests(
      prevDataComponent,
      bidGroupPurchaseOrdersDataComponent,
      dataComponentId,
      params.bidGroupId,
      {
        fetchData,
        showSnackNotificationAction,
        closeModalDialog,
        snackMessage,
        reloadBidGroupVendorSpecs,
      }
    );
  }, [
    bidGroupPurchaseOrdersDataComponent,
    prevDataComponent,
    fetchData,
    params.bidGroupId,
    showSnackNotificationAction,
    closeModalDialog,
    snackMessage,
    performRetrieveListRequest,
    reloadBidGroupVendorSpecs,
  ]);

  useEffect(
    () => setPrevPurchaseOrderDataComponent(purchaseOrderDataComponent),
    [purchaseOrderDataComponent]
  );
  useEffect(() => {
    processRequestsForPurchaseOrder(
      prevPurchaseOrderDataComponent,
      purchaseOrderDataComponent,
      purchaseOrderDataComponentId,
      {
        fetchData,
        showSnackNotificationAction,
        snackMessage: "Purchase Order status successfully updated",
        closeModalDialog,
        reloadBidGroupVendorSpecs,
      }
    );
  }, [
    purchaseOrderDataComponent,
    prevPurchaseOrderDataComponent,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    performRetrieveListRequest,
    params.bidGroupId,
    reloadBidGroupVendorSpecs,
  ]);

  useEffect(() => setPrevSpecDataComponent(specDataComponent), [
    specDataComponent,
  ]);
  useEffect(() => {
    processRequestsForSpecs(
      prevSpecDataComponent,
      specDataComponent,
      specDataComponentId,
      {
        fetchData,
        showSnackNotificationAction,
        snackMessage,
        closeModalDialog,
        performFindRequest,
        bidGroupId: params.bidGroupId,
        projectId: params.projectId,
      }
    );
  }, [
    specDataComponent,
    prevSpecDataComponent,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    snackMessage,
    params,
    performFindRequest,
  ]);
  useEffect(
    () =>
      setPrevBidGroupVendorSpecQuoteComponent(bidGroupVendorSpecQuoteComponent),
    [bidGroupVendorSpecQuoteComponent]
  );
  useEffect(() => {
    processRequestForBidGroupVendorSpecQuotes(
      prevBidGroupVendorSpecQuoteComponent,
      bidGroupVendorSpecQuoteComponent,
      bidGroupVendorDataComponentId,
      params.bidGroupId,
      {
        fetchData,
        showSnackNotificationAction,
        closeModalDialog,
        snackMessage,
      }
    );
  }, [
    bidGroupVendorSpecQuoteComponent,
    prevBidGroupVendorSpecQuoteComponent,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    params.bidGroupId,
    snackMessage,
  ]);

  useEffect(() => {
    initDataComponent(
      dataComponentId,
      BidGroup,
      [
        "purchaseOrders",
        "purchaseOrders.[project, vendor, projectCurrency.[currency]]",
      ],
      "bid-groups"
    );
    initBidGroupVendorDataComponent(initDataComponent, destroyDataComponent);
    initSpecDataComponent(initDataComponent);

    performFindRequest(dataComponentId, params.bidGroupId);

    performRetrieveListRequest(bidGroupVendorDataComponentId, {
      rootFilters: {
        $where: { bidGroupId: params.bidGroupId },
      },
      pageSize: -1,
    });
  }, [
    initDataComponent,
    params.projectId,
    params.bidGroupId,
    performFindRequest,
    performRetrieveListRequest,
    destroyDataComponent,
  ]);

  useEffect(() => {
    initDataComponent(
      bidGroupPurchaseOrderDataComponentId,
      BidGroupPurchaseOrder,
      [],
      "bid-group-purchase-orders"
    );
  }, [initDataComponent]);

  useEffect(() => {
    initDataComponent(
      bidGroupVendorSpecDataComponentId,
      BidGroupVendorSpec,
      [
        "quotes.[quoteItems]",
        "spec.[unitOfMeasure,specDetails.[specDetailComs.spec]]",
      ],
      "bid-group-vendor-specs",
      false,
      "v2"
    );
    setAutoSaveComponentId(bidGroupVendorSpecDataComponentId);
  }, [initDataComponent, setAutoSaveComponentId]);
  useEffect(() => {
    initDataComponent(
      purchaseOrderDataComponentId,
      PurchaseOrder,
      [
        "shipAddress.location",
        "approvalFiles.[file, user]",
        "vendor.location",
        "project.[remitAddresses, freightVendor, designer.location, projectMemberContacts.contact]",
        "commodities",
        "currentRevision.revisionActivities",
        "revisions",
      ],
      "purchase-orders"
    );
  }, [initDataComponent]);
  useEffect(() => {
    initDataComponent(
      bidGroupVendorSpecQuoteComponentId,
      BidGroupVendorSpecQuote,
      ["quoteItems"],
      "bid-group-vendor-spec-quotes"
    );
  }, [initDataComponent]);
  const openAddToPurchaseOrderModal = (bidGroupVendor, bidGroupVendorSpecs) => {
    openModalDialog(
      `Add to Purchase Order`,
      "CreateBidGroupPurchaseOrderContainer",
      {
        projectId: params.projectId,
        bidGroupVendor: bidGroupVendor,
        bidGroupVendorSpecs: bidGroupVendorSpecs,
        title: bidGroupVendor.vendor.name,
        index: bidGroupVendor.id,
        onCreateBidGroupPurchaseOrder: createBidGroupPurchaseOrder,
        onAddToPurchaseOrder: createAddToPurchaseOrder(
          bidGroup,
          performUpdateRequest,
          performCreateRequest,
          setSnackMessage
        ),
        dataComponentId: bidGroupPurchaseOrderDataComponentId,
        performCreateRequest: performCreateRequest,
        setSnackMessage: setSnackMessage,
      },
      false,
      false,
      {
        subtitle: bidGroupVendor.vendor.name,
        width: "800px",
      }
    );
  };
  const openAwardSpecModal = (
    bidGroupVendor,
    bidGroupVendorSpecs,
    awardSpecAction,
    bidGroupVendorRef
  ) => {
    openModalDialog(
      `Award ${pluralize("Spec", bidGroupVendorSpecs.length)} to ${
        bidGroupVendor.vendor.name
      }`,
      "AwardSpecContainer",
      {
        bidGroupVendor: bidGroupVendor,
        bidGroupVendorSpecs: bidGroupVendorSpecs,
        onAwardSpec: awardSpec(awardSpecAction, bidGroupVendorRef),
        dataComponentId: specDataComponentId,
        setSnackMessage: setSnackMessage,
      },
      false,
      true,
      {
        subtitle: ``,
        width: "800px",
      }
    );
  };
  const openUnawardSpecModal = (
    bidGroupVendor,
    bidGroupVendorSpecs,
    unawardSpecAction,
    bidGroupVendorRef
  ) => {
    openModalDialog(
      `Unaward ${pluralize("Spec", bidGroupVendorSpecs.length)} from ${
        bidGroupVendor.vendor.name
      }`,
      "UnawardSpecContainer",
      {
        bidGroupVendor: bidGroupVendor,
        bidGroupVendorSpecs: bidGroupVendorSpecs,
        onUnAwardSpec: unAwardSpec(unawardSpecAction, bidGroupVendorRef),
        dataComponentId: specDataComponentId,
        setSnackMessage: setSnackMessage,
      },
      false,
      true,
      {
        subtitle: ``,
        width: "800px",
      }
    );
  };
  const openCreateAlternateQuoteModal = (row, bidGroupVendor) => {
    openModalDialog(
      `Create Alternate Quote`,
      "CreateAlternateQuoteContainer",
      {
        bidGroupVendorSpec: row,
        bidGroupVendor,
        index: row.bidGroupVendorSpecId,
        createAlternateQuote,
        dataComponentId: bidGroupVendorSpecQuoteComponentId,
        performCreateRequest: performCreateRequest,
        setSnackMessage: setSnackMessage,
      },
      false,
      false,
      {
        subtitle: `${row.spec.customNumber} ${row.spec.description}`,
        width: "800px",
      }
    );
  };
  const openQuoteNotesModal = (bidGroupVendor, row) => {
    const subtitle = `${row.spec.customNumber} ${row.spec.description}`;

    openModalDialog(
      `Note for ${_get(bidGroupVendor, "vendor.name")}`,
      "QuotesNotesContainer",
      {
        bidGroupVendorSpecQuote: row.quote,
        dataComponentId: bidGroupVendorSpecQuoteComponentId,
        performUpdateRequest,
        onUpdateNote: updateNote,
        setSnackMessage: setSnackMessage,
      },
      true,
      true,
      {
        subtitle,
      }
    );
  };
  const goToSpecDetail = bidGroupSpec => {
    pushWithReturnUrl(
      `/clients/${params.clientId}/projects/${params.projectId}/specs/${bidGroupSpec.spec.id}`,
      `BG #${bidGroup.number} - ${bidGroup.name}`,
      { navigateBetweenBG: -1 }
    );
  };
  const goToPurchaseOrderDetail = po => {
    pushWithReturnUrl(
      `/clients/${params.clientId}/projects/${
        params.projectId
      }/purchase-orders/${po?.id || po}`,
      `BG #${bidGroup.number} - ${bidGroup.name}`
    );
  };
  const handleOpenEditQuote = bidGroupVendor => {
    openModalDialog(
      "Edit Quote Details",
      "QuoteEdit",
      { bidGroupVendor },
      false,
      true,
      {
        subtitle: _get(bidGroupVendor, "vendor.name"),
      }
    );
  };
  const handleOpenApprovalManagerClick = purchaseOrder => {
    openModalDialog(
      "Approval Manager",
      "ApprovalManager",
      { purchaseOrder: purchaseOrder },
      false,
      true,
      { components: { Content: ContentWrapper } }
    );
  };
  const handleCancelPurchaseOrder = purchaseOrder => {
    const title = `${purchaseOrder.number} ${purchaseOrder.vendor.name}`;

    openModalDialog(
      ["Cancel Purchase Order", title],
      "CancelPO",
      {
        dataComponentId: purchaseOrderDataComponentId,
        projectId: params.projectId,
        id: purchaseOrder.id,
        clientId: params.clientId,
        title,
        status: purchaseOrder.status,
      },
      false
    );
  };
  const handleUpdatePurchaseOrderStatus = (purchaseOrder, status) => {
    onUpdatePurchaseOrderStatus(
      purchaseOrder,
      status,
      purchaseOrderDataComponentId,
      performUpdateRequest
    );
  };
  const handleRevertPurchaseOrder = (purchaseOrder, additionalParams) => {
    revertFromRevision(
      purchaseOrder.id,
      purchaseOrder.currentRevisionId,
      additionalParams
    );
  };
  const purchaseOrders = bidGroup.purchaseOrders || [];
  const sortedBidGroupVendors = [...bidGroupVendors].sort(
    (bidGroupVendorA, bidGroupVendorB) => {
      return bidGroupVendorA.vendor.name < bidGroupVendorB.vendor.name ? -1 : 1;
    }
  );
  if (currentView === "Specs") {
    const specVendorGroups = getSpecVendorGroups(bidGroup, bidGroupVendors);
    return (
      <React.Fragment>
        {specVendorGroups.map((specVendors, index) => (
          <SpecTable
            key={index}
            loading={loading}
            specVendors={specVendors}
            vendorCategories={vendorCategories}
          />
        ))}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <QuoteEmptyState
        visible={
          !loading &&
          (purchaseOrders.length === 0 || bidGroupVendors.length === 0)
        }
        messageType={bidGroupVendors.length === 0 ? "vendors" : "po"}
      />
      {purchaseOrders.length > 0 && (
        <PurchaseOrderTable
          loading={loading}
          goToPurchaseOrderDetail={goToPurchaseOrderDetail}
          onHandleOpenApprovalManagerClick={handleOpenApprovalManagerClick}
          onHandleCancelPurchaseOrder={handleCancelPurchaseOrder}
          onUpdatePurchaseOrderStatus={handleUpdatePurchaseOrderStatus}
          onHandleRevertPurchaseOrder={handleRevertPurchaseOrder}
          purchaseOrders={purchaseOrders}
        />
      )}
      {sortedBidGroupVendors.map(bidGroupVendor => (
        <BidGroupTable
          key={bidGroupVendor.id}
          onOpenEditQuote={handleOpenEditQuote}
          bidGroupVendor={bidGroupVendor}
          projectId={bidGroupVendor.bidGroup.projectId}
          loading={loadingSpecs}
          specCategories={specCategories}
          bidGroupVendorSpecDataComponentId={bidGroupVendorSpecDataComponentId}
          addToPurchaseOrder={openAddToPurchaseOrderModal}
          createAlternateQuote={openCreateAlternateQuoteModal}
          onOpenAlternateQuoteNotesModal={openQuoteNotesModal}
          onOpenAwardSpecModal={openAwardSpecModal}
          onOpenUnawardSpecModal={openUnawardSpecModal}
          goToSpecDetail={goToSpecDetail}
          vendorCategories={vendorCategories}
          goToPurchaseOrderDetail={goToPurchaseOrderDetail}
        />
      ))}
    </React.Fragment>
  );
};

QuotesPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  bidGroupVendorDataComponent: PropTypes.object.isRequired,
  bidGroupPurchaseOrdersDataComponent: PropTypes.object.isRequired,
  bidGroupVendorSpecQuoteComponent: PropTypes.object.isRequired,
  specDataComponent: PropTypes.object.isRequired,
  purchaseOrderDataComponent: PropTypes.object.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  destroyDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func,
  performRetrieveListRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  revertFromRevision: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingSpecs: PropTypes.bool,
  bidGroup: propTypes.bidGroup,
  bidGroupVendors: PropTypes.arrayOf(propTypes.bidGroupVendor),
  vendorCategories: PropTypes.arrayOf(propTypes.vendorCategory),
  specCategories: PropTypes.arrayOf(propTypes.specCategory),
  match: PropTypes.object.isRequired,
  pushWithReturnUrl: PropTypes.func,
  fetchData: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  updateVendorSpecDataComponent: propTypes.dataComponent,
};

const defaultBidGroupVendors = [
  {
    id: "0",
    bidGroup: {},
    bidGroupVendorSpecs: Array(10).fill({
      spec: {},
      quotes: [
        {
          quoteItems: [{}],
        },
      ],
    }),
    vendor: {},
  },
];

export const mapStateToProps = (
  state,
  {
    match: {
      params: { bidGroupId },
    },
  }
) => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const bidGroupVendorDataComponent = getDataComponentFlattenedRequestState(
    bidGroupVendorDataComponentId,
    state
  );

  const bidGroup =
    getBluechipResourceById(dataComponent, state, bidGroupId) || {};

  const bidGroupVendors =
    getBluechipResources(bidGroupVendorDataComponent, state) ||
    defaultBidGroupVendors;

  const bidGroupPurchaseOrdersDataComponent = getDataComponent(
    bidGroupPurchaseOrderDataComponentId,
    state
  );
  const specDataComponent = getDataComponent(specDataComponentId, state);
  const purchaseOrderDataComponent = getDataComponent(
    purchaseOrderDataComponentId,
    state
  );
  const bidGroupVendorSpecsDataComponent = getDataComponentFlattenedRequestState(
    bidGroupVendorSpecDataComponentId,
    state
  );
  const bidGroupVendorSpecQuoteComponent = getDataComponent(
    bidGroupVendorSpecQuoteComponentId,
    state
  );
  const vendorCategoriesDataComponent = getDataComponentFlattenedRequestState(
    vendorCategoriesDataComponentId,
    state
  );
  const vendorCategories =
    getBluechipResources(vendorCategoriesDataComponent, state) || [];

  const specCategoriesDataComponent = getDataComponentFlattenedRequestState(
    specCategoriesDataComponentId,
    state
  );
  const specCategories =
    getBluechipResources(specCategoriesDataComponent, state) || [];

  const updateVendorSpecDataComponent = getDataComponent(
    dataComponentVendorId,
    state,
    REQUEST_TYPES.UPDATE
  );

  return {
    dataComponent,
    bidGroupVendorDataComponent,
    bidGroup,
    bidGroupVendors,
    bidGroupPurchaseOrdersDataComponent,
    bidGroupVendorSpecQuoteComponent,
    specDataComponent,
    purchaseOrderDataComponent,
    vendorCategories,
    specCategories,
    updateVendorSpecDataComponent,
    loading: bidGroupVendorDataComponent.loading,
    loadingSpecs:
      bidGroupVendorSpecsDataComponent.loading ||
      bidGroupVendorDataComponent.loading,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  destroyDataComponent,
  performRetrieveListRequest,
  performFindRequest,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  fetchData,
  showSnackNotificationAction,
  openModalDialog,
  closeModalDialog,
  pushWithReturnUrl,
  revertFromRevision,
  setAutoSaveComponentId,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuotesPage)
);
