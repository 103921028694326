import React from "react";
import styled from "styled-components";

import propTypes from "../../../../../../constants/propTypes";
import RegularText from "../../../../../ui/Typography/RegularText";

const Text = styled(RegularText)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
`;

export const Column = styled.div`
  display: flex;
  width: ${({ width }) => width}%;
  max-width: ${({ width }) => width}%;
  min-width: ${({ width }) => width}%;
  padding: 0 8px;
  box-sizing: border-box;
`;
export const Row = styled.div`
  display: flex;
  flex: 1;
`;

const Option = ({ vendorContact }) => {
  const contact = vendorContact.contact;
  return (
    <Row>
      <Column width={40} className="main-column">
        <Text>
          {contact.person.firstName} {contact.person.lastName}
        </Text>
      </Column>
      <Column width={20}>
        <Text intent="light">{contact.jobTitle}</Text>
      </Column>
      <Column width={40}>
        <Text intent="light">{contact.person.email}</Text>
      </Column>
    </Row>
  );
};

Option.propTypes = { vendorContact: propTypes.vendorContact };

export default Option;
