import React from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { validateVerificationCode } from "../../../actions/authActions";
import { VerifyCode as Verify } from "../../layout/AppLayout/VerifyCellPhoneNumber/VerifyCode";
import VerifyButton from "../../layout/AppLayout/VerifyCellPhoneNumber/VerifyButton";
import { getAttr } from "../ForgotPassword/ForgotPasswordContainer";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  padding: 0 24px 24px;
`;

const Message = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
`;

const VerifyCode = ({
  sendingVerificationCode,
  validatingVerificationCode,
  cellNumber,
  sendToEmailOnly,
  ...props
}) => {
  const loading = sendingVerificationCode || validatingVerificationCode;

  const Component = loading ? <CircularProgress /> : <Verify {...props} />;

  const targetDevice = sendToEmailOnly
    ? "email"
    : "email and cell phone number";

  return (
    <Wrapper>
      <Message>
        Enter the login code that you received recently on your {targetDevice}.
      </Message>
      {Component}
      <ButtonWrapper>
        <VerifyButton login sendToEmailOnly={sendToEmailOnly} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export const mapStateToProps = state => {
  const {
    auth: {
      sendingVerificationCode,
      verificationCodeSent,
      verificationCodeValidated,
      validatingVerificationCode,
      cellNumber,
    },
    router,
  } = state;

  return {
    login: true,
    callbackUrl: getAttr(router.location.query, "callback"),
    sendingVerificationCode,
    verificationCodeSent,
    verificationCodeValidated,
    validatingVerificationCode,
    cellNumber,
  };
};

const mapDispatchToProps = {
  validateVerificationCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);
