import { SPEC_TYPE } from "../bidGroupVendorSpecUtils";
import _pick from "lodash/pick";
import _cloneDeep from "lodash/cloneDeep";

const attributes = [
  "quoteProjectCurrencyId",
  "vat",
  "leadTimesTo",
  "leadTimesFrom",
  "leadTimeForSubmittal",
  "dropDeadDates",
  "factoryLocation",
  "quoteProvider",
  "fob",
  "miscellaneousCost",
  "estimatedFreightToWarehouse",
  "paymentTermId",
  "quoteDate",
  "miscellaneous",
  "notes",
];

const buildPurchaseOrder = (bidGroupVendorSpecs, bidGroupVendor, specs) => {
  const poTotal = bidGroupVendorSpecs.reduce(
    (previous, { extendedProductionPricing }) =>
      previous + extendedProductionPricing,
    0
  );
  const potentialTariff =
    bidGroupVendor.potentialTariffType === "%"
      ? (bidGroupVendor.potentialTariff / 100) * poTotal
      : bidGroupVendor.potentialTariff;
  return {
    ..._pick(bidGroupVendor, attributes),
    projectId: bidGroupVendor.bidGroup.projectId,
    vendorId: bidGroupVendor.vendorId,
    projectCurrencyId: bidGroupVendor.quoteProjectCurrencyId,
    quoteEstimatedShipDate: bidGroupVendor.estimatedShipDate || undefined,
    specs: specs,
    potentialTariff,
  };
};

const buildSpecs = ({ bidGroupVendorSpecs, gridRows, bidGroupVendor }) => {
  return bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    const gridRow = gridRows.find(
      gridRow => gridRow.specId === bidGroupVendorSpec.spec.id
    );
    const spec = {
      id: bidGroupVendorSpec.spec.id,
      projectCurrencyId: bidGroupVendor.quoteProjectCurrencyId,
      baseQuantity: bidGroupVendorSpec.spec.baseQuantity,
      atticStock: bidGroupVendorSpec.spec.atticStock,
      overagePercent: bidGroupVendorSpec.overagePercent,
    };
    if (gridRow.type === SPEC_TYPE) {
      spec.priceCents = gridRow.price * 100;
    }
    const { specDetails = [] } = bidGroupVendorSpec.spec;
    const updatedSpecDetails = [];
    specDetails.map(specDetail => {
      const clonedSpecDetail = _cloneDeep(specDetail);
      const { specDetailComs = [] } = clonedSpecDetail;
      specDetailComs.forEach(specDetailCom => {
        delete specDetailCom.spec;
      });
      updatedSpecDetails.push(clonedSpecDetail);
    });
    if (updatedSpecDetails.length > 0) {
      spec.specDetails = updatedSpecDetails;
    }
    return spec;
  });
};

export const handleAddToPurchaseOrder = (
  bidGroupVendor,
  bidGroupVendorSpecs,
  gridRows,
  onCreateBidGroupPurchaseOrder,
  dataComponentId,
  performCreateRequest,
  setSnackMessage,
  purchaseOrderId,
  onAddToPurchaseOrder
) => {
  const specs = buildSpecs({ bidGroupVendorSpecs, gridRows, bidGroupVendor });
  const purchaseOrder = buildPurchaseOrder(
    bidGroupVendorSpecs,
    bidGroupVendor,
    specs
  );
  if (purchaseOrderId == "0") {
    onCreateBidGroupPurchaseOrder(
      bidGroupVendor,
      purchaseOrder,
      dataComponentId,
      performCreateRequest,
      setSnackMessage
    );
    return;
  }
  onAddToPurchaseOrder(purchaseOrderId, specs);
};
export const generateGridRows = bidGroupVendorSpecs => {
  const rows = [];
  bidGroupVendorSpecs.forEach(bidGroupVendorSpec => {
    rows.push({
      id: bidGroupVendorSpec.id,
      specId: bidGroupVendorSpec.spec.id,
      customNumber: bidGroupVendorSpec.spec.customNumber,
      description: bidGroupVendorSpec.spec.description,
      unitOfMeasure: bidGroupVendorSpec.spec.unitOfMeasure,
      baseQuantity: bidGroupVendorSpec.spec.baseQuantity,
      atticStock: bidGroupVendorSpec.spec.atticStock,
      price: bidGroupVendorSpec.productionPricing,
      extendedPrice:
        bidGroupVendorSpec.spec.totalQuantityWithoutOverage *
        bidGroupVendorSpec.spec.price,
      type: SPEC_TYPE,
    });
  });
  return rows;
};
export const calculateTotal = rows => {
  return rows.reduce((accum, row) => {
    return accum + row.price * row.quantity;
  }, 0);
};
