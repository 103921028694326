import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";
import { openModalDialog } from "../../../../actions/layoutActions";
import useActions from "../../../hooks/useActions";
import { SpecDetailContext } from "../SpecDetailContainer";
import { Content } from "./ReviewSubmittals/components";
import { usePrevious } from "../../../hooks/usePrevious";

export const RequirementsSectionActions = ({ rows, specId }) => {
  const actions = useActions({ openModalDialog });
  const { selectedIds, resetSelection } = useWithSelectedRowContext();
  const { onUpdateSpec } = useContext(SpecDetailContext);
  const prevSpecId = usePrevious(specId);

  useEffect(() => {
    if (prevSpecId != specId) {
      resetSelection();
    }
  }, [prevSpecId, resetSelection, specId]);

  const selectedRows = rows.filter(row => selectedIds.includes(row.id));

  const handleClick = useCallback(() => {
    actions.openModalDialog(
      "Upload Files",
      "UploadFiles",
      {
        selectedSpecRequirementIds: selectedIds,
        onUpdateSpec,
      },
      false,
      true,
      {
        scrollDisabled: true,
        components: { Content },
        width: "auto",
        subtitle: selectedRows
          .map(({ requirement: { name } }) => name)
          .join(", "),
      }
    );
  }, [actions, onUpdateSpec, selectedIds, selectedRows]);

  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: "Upload Files",
          onClick: handleClick,
          disabled: !selectedIds.length,
        },
      ]}
    />
  );
};

RequirementsSectionActions.propTypes = {
  rows: PropTypes.array,
  specId: PropTypes.string,
};
