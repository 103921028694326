import React, {
  useState,
  memo,
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";

import BWGrid from "./BWGrid";
import GridHeader from "./GridHeader";
import { WithSelectedRowsContext } from "./withSelectedRows";
import Paper from "../../mui/core/Paper";
import { WithPaginationContext } from "../../../withPagination";
import { LoaderContext } from "../Loader";
import { getRows } from "./gridAPIFunctions";
import { operationBuildersLocal } from "./filterFunctions";
import { createDeepEqualSelector } from "../../../utils/selectors";

const createSetPage = (setCurrentPage, setPage, setIsLoading, id) => page => {
  setCurrentPage(page);
  setPage(page, id);
  setIsLoading(true);
};

const createSetPageSize = (
  setCurrentPageSize,
  setPageSize,
  setIsLoading,
  id
) => pageSize => {
  setCurrentPageSize(pageSize);
  setPageSize(pageSize, id);
  setIsLoading(true);
};

const getInitialPageSize = (gridConfig, pageSize) => {
  if (gridConfig.pageSize === 0) return 0;
  return pageSize;
};

const createSetCurrentDefault = (
  currentDefault,
  defaultSelection,
  setSelection,
  setCurrentdefault,
  actualRows,
  selectionRows
) => () => {
  if (
    selectionRows.length > 0 &&
    actualRows.length > 0 &&
    !_isEqual(currentDefault, defaultSelection)
  ) {
    setSelection(defaultSelection);
    setCurrentdefault(defaultSelection);
  }
};

const usePaginationcontext = (gridConfig, id) => {
  const {
    isDefault,
    getSort,
    setSort,
    getPage,
    setPage,
    getPageSize,
    setPageSize,
  } = useContext(WithPaginationContext);

  const initialPageSize = useMemo(
    () => getInitialPageSize(gridConfig, getPageSize(id)),
    [getPageSize, gridConfig, id]
  );
  const [currentPage, setCurrentPage] = useState(getPage(id));
  const [currentSort, setCurrentSort] = useState(getSort(id));
  const [currentPageSize, setCurrentPageSize] = useState(initialPageSize);

  const page = isDefault ? currentPage : getPage(id);

  return useMemo(
    () => ({
      currentPage: page,
      setCurrentPage,
      currentPageSize,
      setCurrentPageSize,
      currentSort,
      setPageSize,
      setPage,
      setCurrentSort: (sort, id) => {
        setCurrentSort(sort);
        setSort(sort, id);
      },
    }),
    [
      page,
      setCurrentPage,
      currentPageSize,
      setCurrentPageSize,
      currentSort,
      setPageSize,
      setPage,
      setSort,
    ]
  );
};

const useLoading = (isLoading, setIsLoading, loading) => {
  const loadingValue = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  );

  useEffect(() => {
    if (isLoading !== loading) {
      return setIsLoading(loading);
    }
  }, [setIsLoading, isLoading, loading]);

  return loadingValue;
};

const createSelector = () =>
  createDeepEqualSelector(
    (_, { value }) => value,
    value => value
  );

const useSelector = (attr, value, omitSelector) => {
  const selectors = useRef({
    sorting: createSelector(),
    gridConfig: createSelector(),
    rows: createSelector(),
  });

  if (omitSelector) {
    return value;
  }

  return selectors.current[attr](null, { value });
};

const buildChildrenComponent = (children, totalRows) => () => {
  if (!Array.isArray(children)) return children;
  return children.map((child, key) => {
    const isHeader = child.type.displayName === GridHeader.displayName;
    if (isHeader && child.props.withCounter) {
      return React.cloneElement(child, {
        headerText: pluralize(child.props.headerText, totalRows, true),
        key: key,
      });
    }
    return child;
  });
};

const buildUpdateActualRows = (
  actualRows,
  setRows,
  prev,
  setIsLoading
) => () => {
  if (prev.current.actualRows !== actualRows) {
    prev.current.actualRows = actualRows;
    setRows(actualRows);
    setTimeout(() => {
      setIsLoading(false);
    }, 150);
  }
};

const buildUpdateTotalRows = (
  allRows,
  setAllRows,
  setTotalRows,
  prev
) => () => {
  if (prev.current.actualRows !== allRows) {
    prev.current.actualRows = allRows;
    setAllRows(allRows);
    setTotalRows(allRows && allRows.length);
  }
};

const usePrevSelected = (selection, onChangeSelected, selectedIds) => {
  const [prevSelected, setPrevSelected] = useState(selection);
  useEffect(() => {
    if (!_isEqual(prevSelected, selection)) {
      onChangeSelected(selection, selectedIds);
      setPrevSelected(selection);
    }
  }, [selection, onChangeSelected, selectedIds, prevSelected]);
  return [prevSelected, setPrevSelected];
};

function updatePageOnFiltering(actualRows, rows, handleSetPage, currentPage) {
  return () => {
    if (
      _get(actualRows, "length", 0) === 0 &&
      _get(rows, "length", 0) &&
      currentPage !== 0
    ) {
      handleSetPage(0);
    }
  };
}

const getHandleFiltersChange = (handleSetPage, setFilters) => {
  return _debounce((filters, omitSetPage) => {
    setFilters(filters);
    if (!omitSetPage) {
      handleSetPage(0);
    }
  }, 800);
};

// eslint-disable-next-line max-lines-per-function
const BWGridLocal = ({
  className,
  children,
  id,
  onChangeSelected,
  isLoading: loading,
  noBorder,
  noBottomMargin,
  defaultSelection,
  omitSelector,
  ...gridProps
}) => {
  const {
    setSelection,
    selection,
    setRows,
    setAllRows,
    setTotalRows,
    selectedIds,
    rows: selectionRows,
  } = useContext(WithSelectedRowsContext);
  const prev = useRef({});
  const rows = useSelector("rows", gridProps.rows, omitSelector);
  const gridConfig = useSelector("gridConfig", gridProps.gridConfig);
  const sorting = useSelector("sorting", gridProps.sorting);
  usePrevSelected(selection, onChangeSelected, selectedIds);
  const {
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    currentSort,
    setPageSize,
    setPage,
    setCurrentSort,
  } = usePaginationcontext(gridConfig, id);

  const [filters, setFilters] = useState(gridProps.filters);
  const [isLoading, setIsLoading] = useState();
  const [currentDefault, setCurrentdefault] = useState({});

  const { pageRows: actualRows, rows: allRows } = useMemo(
    () =>
      getRows(rows, id, {
        sort: currentSort,
        currentPage,
        pageSize: currentPageSize,
        filters,
      }),
    [rows, id, currentSort, currentPage, currentPageSize, filters]
  );

  useEffect(
    createSetCurrentDefault(
      currentDefault,
      defaultSelection,
      setSelection,
      setCurrentdefault,
      actualRows,
      selectionRows
    ),
    [currentDefault, defaultSelection, setSelection]
  );

  const loadingValue = useLoading(isLoading, setIsLoading, loading);

  useEffect(() => {
    if (!currentSort) {
      setCurrentSort(sorting, id);
    }
  }, [currentSort, sorting, id, setCurrentSort]);

  useEffect(buildUpdateActualRows(actualRows, setRows, prev, setIsLoading), [
    actualRows,
    setRows,
  ]);

  useEffect(buildUpdateTotalRows(allRows, setAllRows, setTotalRows, prev), [
    allRows,
    setAllRows,
    setTotalRows,
  ]);

  const handleSetPage = useCallback(
    createSetPage(setCurrentPage, setPage, setIsLoading, id),
    [id, setPage]
  );

  useEffect(
    updatePageOnFiltering(actualRows, rows, handleSetPage, currentPage),
    [actualRows, rows, handleSetPage, currentPage]
  );

  const handleSetPageSize = useCallback(
    createSetPageSize(setCurrentPageSize, setPageSize, setIsLoading, id),
    [id, setPageSize]
  );

  const handleSetSort = useCallback(
    sort => {
      setIsLoading(true);
      setCurrentSort(sort, id);
    },
    [id, setCurrentSort]
  );

  const handleFiltersChange = useCallback(
    getHandleFiltersChange(handleSetPage, setFilters, filters),
    [handleSetPage, filters]
  );

  const handleSelection = useCallback(
    selection => setSelection(selection, currentPage),
    [currentPage, setSelection]
  );

  const gridConfigMemo = useMemo(
    () => ({
      ...gridConfig,
      selection: selection,
      pageNumber: currentPage,
      sort: currentSort,
      totalRows: allRows.length,
      defaultSelection: defaultSelection,
    }),
    [
      gridConfig,
      selection,
      currentPage,
      currentSort,
      allRows.length,
      defaultSelection,
    ]
  );

  const childrenComponent = useMemo(
    buildChildrenComponent(children, allRows.length),
    [allRows.length, children]
  );

  return (
    <Paper
      className={className}
      noBorder={noBorder}
      noBottomMargin={noBottomMargin}
    >
      <LoaderContext.Provider value={loadingValue}>
        <BWGrid
          {...gridProps}
          id={id}
          rows={actualRows}
          setSelection={handleSelection}
          setPage={handleSetPage}
          setPageSize={handleSetPageSize}
          onFiltersChange={handleFiltersChange}
          setSort={handleSetSort}
          isLoading={isLoading}
          pageSize={currentPageSize}
          gridConfig={gridConfigMemo}
          filteringBuilders={operationBuildersLocal}
          ignoreFilterQueryParams
        >
          {childrenComponent}
        </BWGrid>
      </LoaderContext.Provider>
    </Paper>
  );
};

BWGridLocal.propTypes = {
  gridConfig: PropTypes.shape({}),
  rows: PropTypes.array,
  showSnackNotificationAction: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
};

BWGridLocal.defaultProps = {
  onChangeSelected: () => {},
  isLoading: false,
  actions: [],
  selection: {},
  sorting: [],
  noBottomMargin: false,
};

export default memo(BWGridLocal);
