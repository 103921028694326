import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";

import ActionButtons from "../forms/ActionButtons";
import { RelatedRecords } from "./RelatedRecords";
import { closeModalDialog, openModalDialog } from "../../actions/layoutActions";
import useActions from "../hooks/useActions";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const AffectedRecordsDialog = ({
  personId,
  contactRelationId,
  isUserForm,
}) => {
  const [sendDisabled, setSendDisabled] = useState(true);

  const actions = useActions({
    openModalDialog,
    closeModalDialog,
  });

  const handleClose = () => {
    actions.closeModalDialog();
  };

  return (
    <Content>
      <Typography>
        The following related records were affected by this update:
      </Typography>
      <RelatedRecords
        personId={personId}
        contactRelationId={contactRelationId}
        isUserForm={isUserForm}
        setSendDisabled={setSendDisabled}
        observationElement={
          <p style={{ fontStyle: "italic" }}>* This record.</p>
        }
      />
      <ActionButtons
        onCancel={handleClose}
        disabled={sendDisabled}
        cancelButtonText="CLOSE"
      />
    </Content>
  );
};
AffectedRecordsDialog.propTypes = {
  personId: PropTypes.string.isRequired,
  contactRelationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUserForm: PropTypes.bool,
};
