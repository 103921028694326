import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flag from "@material-ui/icons/Flag";
import Remove from "@material-ui/icons/Remove";
import _get from "lodash/get";

import Price from "../../ui/Price";
import { FormControlLabel } from "../../mui/core";
import BoldText from "../../ui/Typography/BoldText";
import { getSpecCategoryLabel } from "../../../models/SpecCategory";
import propTypes from "../../../constants/propTypes";

export const FlagIcon = styled(Flag)`
  color: #ff9100;
  font-size: 12px;
  line-height: 20px;
  width: 12px;
  height: 12px;
`;
export const PurpleFlagIcon = styled(Flag)`
  color: #ae7ccd;
  font-size: 12px;
  line-height: 20px;
  width: 12px;
  height: 12px;
`;
export const DashIcon = styled(Remove)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 20px;
  width: 14px;
  height: 14px;
`;

export const CustomNumberColumn = ({
  customNumber,
  isIncomplete,
  isAllowance,
}) => {
  return (
    <BoldText as="span" title={customNumber}>
      {customNumber}
      {isIncomplete && <FlagIcon />}
      {isAllowance && <PurpleFlagIcon />}
    </BoldText>
  );
};
CustomNumberColumn.propTypes = {
  customNumber: PropTypes.string,
  isIncomplete: PropTypes.bool,
  isAllowance: PropTypes.bool,
};

export const PriceColumn = ({ price, projectCurrency = {} }) => (
  <Price number={price} currency={projectCurrency.currency} />
);
PriceColumn.propTypes = {
  price: PropTypes.number,
  projectCurrency: PropTypes.object,
};

export const SpecCategoryColumn = ({ specCategory = {} }) => {
  return getSpecCategoryLabel(specCategory);
};
SpecCategoryColumn.propTypes = {
  specCategory: propTypes.specCategory,
};

export const DesignerBudgetColumn = ({ designerBudget, projectCurrency }) => {
  return typeof designerBudget === "number" ? (
    <Price number={designerBudget} currency={projectCurrency.currency} />
  ) : (
    ""
  );
};

DesignerBudgetColumn.propTypes = {
  designerBudget: PropTypes.number,
  projectCurrency: PropTypes.object,
};

export const OverageColumn = ({ overagePercent }) => {
  return typeof overagePercent === "number" ? `${overagePercent}%` : "";
};

export const ExtPriceColumn = ({ totalPrice, projectCurrency = {} }) => {
  const { currency } = projectCurrency;
  return <Price number={totalPrice} currency={currency} />;
};

ExtPriceColumn.propTypes = {
  totalPrice: PropTypes.number,
  projectCurrency: PropTypes.object,
};

export const getSpecBidGroup = ({ purchaseOrder, bidGroups = [] }) => {
  let [bidGroup] = bidGroups;

  if (!bidGroup) {
    bidGroup = _get(purchaseOrder, "bidGroup");
  }
  return bidGroup;
};

export const RFQNumber = onClick =>
  // eslint-disable-next-line react/display-name, react/prop-types
  function({ purchaseOrder, bidGroups = [] }) {
    const bidGroup = getSpecBidGroup({ purchaseOrder, bidGroups });

    let rfqNumber = _get(bidGroup, "sequenceIndex");
    rfqNumber = rfqNumber ? String(rfqNumber).padStart(3, "0") : "";

    const bidGroupId = _get(bidGroup, "id");

    if (!bidGroupId) {
      return null;
    }

    return (
      <StyledSpan
        onClick={event => {
          onClick(bidGroupId);
          event.stopPropagation();
        }}
      >
        {rfqNumber}
      </StyledSpan>
    );
  };

const StyledSpan = styled.span`
  color: #57abff;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
  z-index: 2;
  padding: 10px 10px 10px 0;
`;

export const PONumber = (onClick, readOnly) =>
  // eslint-disable-next-line react/display-name, react/prop-types
  function({ purchaseOrder }) {
    const poNumber = _get(purchaseOrder, "sequenceIndex")
      ? String(_get(purchaseOrder, "sequenceIndex")).padStart(3, "0")
      : "";

    if (readOnly) return poNumber;
    return (
      <StyledSpan
        onClick={event => {
          onClick(purchaseOrder.id);
          event.stopPropagation();
        }}
      >
        {poNumber}
      </StyledSpan>
    );
  };

const ModalContent = styled.div`
  background-color: rgb(255, 255, 255);
  width: 90vw;
`;

const SpecsDeleteModalContent = styled.div`
  background-color: rgb(255, 255, 255);
  width: 700px;
`;
export const ContentWrapper = props => <ModalContent {...props} />;
export const SpecsDeleteWrapper = props => (
  <SpecsDeleteModalContent {...props} />
);
export const SwitchBox = styled.div`
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 1px;
    background: #c4c4c4;
    margin-top: 8px;
    height: 24px;
    right: 0;
  }
`;
export const SwitchLabel = styled(FormControlLabel)`
  padding-right: 24px;
  margin-right: 0;
  display: ${({ hide }) => (hide ? "none" : "auto")};
`;
