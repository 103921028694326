import inputs from "../../../../inputs/inputConfigs/submittals";

export default disableSelectFile => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.submittalFile,
            disabled: disableSelectFile,
            ignoreLabeledText: true,
            inputFileProps: { disabled: disableSelectFile, isForm: true },
          },
          grid: { xs: 12 },
        },
        {
          input: { ...inputs.submittalCreatedAt, ignoreLabeledText: true },
          grid: { xs: 6 },
        },
        {
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.submittalDescription,
            label: "Received",
            name: "receivedDescription",
            ignoreLabeledText: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.submittalDescription,
            label: "Approved",
            name: "approvedDescription",
            ignoreLabeledText: true,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});
