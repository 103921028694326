import React from "react";
import styled from "styled-components";
import { DashIcon } from "../../Specs/components";
import { DescriptionColumn } from "../../Specs/DescriptionColumn";
import { SelectedPrice, StyledEdit } from "./components";
import Price from "../../../ui/Price";
import PropTypes from "prop-types";
import { currencyToNumber } from "../../../inputs/utils";
import { inputs } from "../../../inputs/inputConfigs";
import memoizeOne from "memoize-one";
import { editableCellCreator } from "../../../ui/BWGrid/EditableCellAPI";
import { SelectionCell, RowComponent } from "./tableComponents";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || "flex-start"};
  > svg {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
`;

export function renderPriceColumn(row) {
  const { projectCurrency = {}, priceLocal } = row;
  const { currency } = projectCurrency;

  if (row.__deleted__ || row.totalQuantityApprovedToPay > 0) {
    return <Price number={priceLocal} currency={currency} />;
  }
  return <SelectedPrice price={priceLocal} currency={currency} />;
}

export const ExtPriceColumn = ({
  totalPriceWithoutOverage,
  projectCurrency = {},
}) => {
  const { currency, conversionRate } = projectCurrency;
  return totalPriceWithoutOverage ? (
    <Price
      number={totalPriceWithoutOverage / conversionRate}
      currency={currency}
    />
  ) : (
    <DashIcon />
  );
};

ExtPriceColumn.propTypes = {
  totalPriceWithoutOverage: PropTypes.number,
  projectCurrency: PropTypes.object,
};

export const columns = [
  "customNumber",
  "description",
  "specCategory.name",
  "unitOfMeasure.name",
  "totalQuantityWithoutOverage",
  "priceCents",
  "totalPriceWithoutOverageLocal",
];

export const columnOptions = {
  customNumber: { title: "Spec Number", bold: true },
  description: { fill: true, render: DescriptionColumn },
  "specCategory.name": { title: "Category", width: "300px" },
  "unitOfMeasure.name": { title: "UOM" },
  totalQuantityWithoutOverage: { title: "QTY", sortingEnabled: false },
  priceCents: {
    title: "Selected Price",
    sortingEnabled: false,
    align: "right",
    render: renderPriceColumn,
    editable: true,
    disabled: spec => spec.totalQuantityApprovedToPay > 0,
    editableOptions: {
      formatValue: row => row.priceLocal,
      beforeSave: value => currencyToNumber(value) * 100,
      afterSave: (value, row) => (row.priceLocal = value / 100),
    },
  },
  totalPriceWithoutOverageLocal: {
    title: "Ext Price",
    render: ExtPriceColumn,
    align: "right",
    sortingEnabled: false,
  },
};

export const getRootFilters = purchaseOrder => ({
  $where: {
    purchaseOrderId: purchaseOrder.id,
  },
});

const rowInputs = currency => ({
  priceCents: {
    ...inputs.priceCents,
    name: "priceCents",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    inputProps: {
      currency: currency,
    },
  },
});

const getUpdateParams = memoizeOne(isRevisionActive => {
  if (isRevisionActive) {
    return {
      update_po_revision: true,
    };
  }
});

export const getTableComponents = memoizeOne(
  (dataComponentId, revisionIsActive, currency, isClosedProject) => ({
    CellComponent: editableCellCreator(
      rowInputs(currency, isClosedProject),
      dataComponentId,
      getUpdateParams(revisionIsActive),
      (column, tableRow) => {
        const disabled =
          columnOptions[column.name].editable &&
          columnOptions[column.name].disabled(tableRow.row);
        return !isClosedProject && !tableRow?.row?.__deleted__ && !disabled;
      }
    ),
    RowComponent,
    SelectionCell,
  })
);
