import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { get, isEmpty, omit, pickBy, set, cloneDeep } from "lodash";

import InvoiceDetailPage from "./InvoiceDetailPage";
import {
  openModalDialog,
  setAutoSaveComponentId,
  setAutoSaveQueueId,
} from "../../../actions/layoutActions";
import { LoaderContext } from "../../ui/Loader";
import {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../actions/dataComponentActions";
import { generateAndDownloadReport } from "../../../actions/reportsActions";
import { openNotesModal } from "../../../actions/notesActions";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import propTypes from "../../../constants/propTypes";
import PurchaseOrder from "../../../models/PurchaseOrder";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import Invoice from "../../../models/Invoice";
import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";
import {
  getDirtyFields,
  handleRequestError,
} from "../../../utils/formValidationUtils";
import { LabeledTextContext } from "../../../withPORevision";
import { reloadDataComponent } from "../../../actions/initializeStoreActions";
import { createQueueProcess } from "../../../actions/queuesActions";
import { getRowIdFieldNameObj } from "./Specs/SpecsList";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";
import * as QUEUE_STATUSES from "../../../constants/queue";

export const dataComponentId = "InvoiceDetail";

// eslint-disable-next-line no-unused-vars
const categoryCurrencyFields = [
  "merchandise",
  "overage",
  "tax",
  "accruedUseTax",
  "freightWarehousing",
  "install",
  "otherCost",
  "discount",
  "total",
];

// eslint-disable-next-line no-unused-vars
const currencyFields = [
  "AssetsPaid",
  "AssetsToDate",
  "DepositsPaid",
  "DepositsToDate",
  "Net",
  "NotProcessed",
  "Maximum",
  "Total",
];

export const processRequestChanges = (
  { prevDataComponent, dataComponent },
  { backRoute, shouldRedirectOnSuccess, afterUpdate, formikProps },
  { push, setShouldRedirect, performFindRequest, invoiceId, projectId, invoice }
) => () => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      if (afterUpdate) {
        afterUpdate(invoice);
      }
      if (shouldRedirectOnSuccess) {
        setShouldRedirect(false);
        push(backRoute);
      }
      if (dataComponent.dataComponentId == dataComponentId)
        performFindRequest(dataComponentId, invoiceId, {
          $where: { "purchaseOrder.projectId": projectId },
        });
    },
    onError: error => {
      handleRequestError(error, formikProps);
      setShouldRedirect(false);
    },
  });
};

const invoiceSpecsQueue = "updateInvoiceSpec";
const updateInvoiceSpecs = (
  invoiceSpecsValues,
  initialValues,
  createQueueProcess
) => {
  if (!isEmpty(invoiceSpecsValues)) {
    const objects = [];

    Object.keys(invoiceSpecsValues).forEach(key => {
      const { id, fieldName } = getRowIdFieldNameObj(key);
      const value = invoiceSpecsValues[key];
      const valueObj = {};
      valueObj[fieldName] = value;
      objects.push({ id, ...valueObj });
    });

    createQueueProcess("bulk-create", invoiceSpecsQueue, {
      objects: objects,
      modelType: "InvoiceSpec",
    });
  }
};

const parseValues = values =>
  pickBy(
    values,
    (value, key) => !key.startsWith("quantity") && !key.startsWith("total")
  );

export const buildHandleUpdateInvoice = (
  invoice,
  invoiceId,
  formikProps,
  setFormikProps,
  performUpdateRequest,
  createQueueProcess,
  setAfterUpdate
) => (
  { glCodes, afterSubmit, ...values },
  { dirty, initialValues, isValid, ...formikProps },
  forceUpdate
) => {
  if (!forceUpdate && (!isValid || !dirty)) {
    formikProps.setStatus("submitted");
    formikProps.setErrors({ ...formikProps.errors });
    return;
  }
  if (glCodes) {
    values.glCodes = glCodes.map(({ id }) => ({ id }));
  }

  const parsedValues = parseValues(values);

  const invoiceValues = getDirtyFields({
    dirty: dirty || forceUpdate,
    values: parsedValues,
    initialValues,
  });

  setAfterUpdate(() => afterSubmit);
  setFormikProps(formikProps);
  performUpdateRequest(
    dataComponentId,
    invoiceId,
    omit(invoiceValues, ["invoiceSpecs"])
  );

  const invoiceSpecsValues = pickBy(values, (value, key) =>
    key.startsWith("quantity")
  );
  updateInvoiceSpecs(invoiceSpecsValues, initialValues, createQueueProcess);
};

const shouldLockAllChanges = (invoice, isProjectClosed) =>
  !!get(invoice, "purchaseOrder.revisionStatus") ||
  get(invoice, "purchaseOrder.status") !== "Issued" ||
  isProjectClosed;

const isReadOnly = (invoice, allChangesLocked) =>
  allChangesLocked ||
  !!invoice?.checkNumber ||
  get(invoice, "purchaseOrder.status") !== "Issued" ||
  get(invoice, "isVoid") ||
  !isEmpty(
    get(invoice, "fundings", []).filter(({ status }) => status != "Void")
  );

const InvoiceDetailContainer = ({
  dataComponent,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  push,
  openNotesModal,
  setAutoSaveComponentId,
  setAutoSaveQueueId,
  invoiceId,
  nextInvoiceId,
  lastInvoiceId,
  clientId,
  projectId,
  loading,
  invoice,
  createQueueProcess,
  generateAndDownloadReport,
}) => {
  const backRoute = `/clients/${clientId}/projects/${projectId}/accounting/invoices`;

  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [shouldRedirectOnSuccess, setShouldRedirect] = useState(false);
  const [formikProps, setFormikProps] = useState(false);
  const [afterUpdate, setAfterUpdate] = useState();

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(
    processRequestChanges(
      { prevDataComponent, dataComponent },
      { backRoute, shouldRedirectOnSuccess, afterUpdate, formikProps },
      {
        push,
        setShouldRedirect,
        performFindRequest,
        invoiceId,
        projectId,
        invoice,
      }
    ),
    [
      prevDataComponent,
      dataComponent,
      backRoute,
      formikProps,
      push,
      afterUpdate,
    ]
  );

  useEffect(() => {
    initDataComponent(
      dataComponentId,
      Invoice,
      [
        "invoiceSpecs(withModify).spec.[shipments, glCodes, unitOfMeasure]",
        "purchaseOrder.[project, vendor.location, poContacts.vendorContact.contact, currentRevision, invoices, projectCurrency.currency]",
        "remitAddress.[location, pointOfContacts.contact]",
        "fundings",
      ],
      "invoices"
    );

    performFindRequest(dataComponentId, invoiceId, {
      $where: { "purchaseOrder.projectId": projectId },
    });
    setAutoSaveComponentId(dataComponentId);
    setAutoSaveQueueId(invoiceSpecsQueue);
  }, [
    initDataComponent,
    performFindRequest,
    setAutoSaveComponentId,
    invoiceId,
    projectId,
    setAutoSaveQueueId,
  ]);

  const handleUpdateInvoice = useCallback(
    buildHandleUpdateInvoice(
      invoice,
      invoiceId,
      formikProps,
      setFormikProps,
      performUpdateRequest,
      createQueueProcess,
      setAfterUpdate
    ),
    [performUpdateRequest, invoiceId, invoice]
  );

  const handleOpenNotesModal = useCallback(() => {
    openNotesModal(
      { projectId, purchaseOrderId: invoice.purchaseOrderId },
      { search: `"INV#${invoice.number}"` }
    );
  }, [invoice, openNotesModal, projectId]);

  const isProjectClosed = useIsProjectClosed();
  const allChangesLocked = shouldLockAllChanges(invoice, isProjectClosed);
  const readOnly = isReadOnly(invoice, allChangesLocked);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <LabeledTextContext.Provider value={readOnly}>
        <InvoiceDetailPage
          invoice={invoice}
          projectId={projectId}
          invoiceId={invoiceId}
          clientId={clientId}
          onUpdateInvoice={handleUpdateInvoice}
          onOpenNotesModal={handleOpenNotesModal}
          generateAndDownloadReport={generateAndDownloadReport}
          dataComponentId={dataComponentId}
          allChangesLocked={allChangesLocked}
          nextItemRoute={
            nextInvoiceId &&
            `/clients/${clientId}/projects/${projectId}/invoices/${nextInvoiceId}`
          }
          lastItemRoute={
            lastInvoiceId &&
            `/clients/${clientId}/projects/${projectId}/invoices/${lastInvoiceId}`
          }
        />
      </LabeledTextContext.Provider>
    </LoaderContext.Provider>
  );
};

InvoiceDetailContainer.propTypes = {
  initDataComponent: PropTypes.func,
  loading: PropTypes.bool,
  performFindRequest: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  openModalDialog: PropTypes.func,
  openNotesModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func,
  setAutoSaveQueueId: PropTypes.func,
  invoiceId: PropTypes.string,
  nextInvoiceId: PropTypes.string,
  lastInvoiceId: PropTypes.string,
  clientId: PropTypes.string,
  projectId: PropTypes.string,
  invoice: propTypes.invoice,
  dataComponent: propTypes.dataComponent,
  reloadDataComponent: PropTypes.func.isRequired,
  createQueueProcess: PropTypes.func.isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  const { invoiceId, clientId, projectId } = ownProps.match.params;
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const queue = state.queues[invoiceSpecsQueue];

  const invoice = getBluechipResourceById(
    flattenedDataComponent,
    state,
    invoiceId
  );
  const po = invoice
    ? PurchaseOrder.query(state.resources)
        .includes([
          "project",
          "vendor.location",
          "poContacts.vendorContact.contact.person",
          "currentRevision",
          "invoices",
          "projectCurrency.currency",
        ])
        .where({ id: [invoice.purchaseOrderId] })
        .toObjects()
    : undefined;

  const loading =
    getDataComponentFlattenedRequestState(
      dataComponentId,
      state,
      REQUEST_TYPES.UPDATE
    ).loading ||
    [QUEUE_STATUSES.QUEUE_PROCESSING, QUEUE_STATUSES.QUEUE_REQUESTNG].includes(
      get(queue, "status", false)
    );

  const dataComponent = cloneDeep(getDataComponent(dataComponentId, state));

  set(dataComponent, "requestState.update.loading", loading);

  return {
    invoiceId,
    clientId,
    projectId,
    dataComponent,
    invoice: invoice ? { ...invoice, purchaseOrder: po[0] } : undefined,
    nextInvoiceId: get(flattenedDataComponent, "links.next.meta.id"),
    lastInvoiceId: get(flattenedDataComponent, "links.last.meta.id"),
    loading: loading || flattenedDataComponent.loading,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  performRetrieveListRequest,
  openModalDialog,
  setAutoSaveComponentId,
  setAutoSaveQueueId,
  openNotesModal,
  push,
  reloadDataComponent,
  createQueueProcess,
  generateAndDownloadReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetailContainer);
