import { push } from "connected-react-router";
import queryString from "query-string";

import * as REQUEST_TYPES from "../constants/ActionTypes";
import AuthService from "../services/AuthService";
import { showSnackNotificationAction, openModalDialog } from "./layoutActions";
import { initializeStore } from "./initializeStoreActions";
import { getInitialPage } from "../components/pages/Login/LoginContainer";
import { getMyIp, getMyLocation } from "../services/utils";

const service = new AuthService();

const closeErrorDialogAction = () => ({
  type: REQUEST_TYPES.CLOSE_ERROR_DIALOG,
});

const verifyEmailRequestAction = () => ({
  type: REQUEST_TYPES.VERIFY_EMAIL_REQUEST,
});

const verifyEmailSuccessAction = isPasswordDefined => ({
  type: REQUEST_TYPES.VERIFY_EMAIL_SUCCESS,
  payload: {
    isPasswordDefined,
  },
});

const verifyEmailFailureAction = error => ({
  type: REQUEST_TYPES.VERIFY_EMAIL_FAILURE,
  payload: error,
  error: true,
});

const authenticationRequestAction = () => ({
  type: REQUEST_TYPES.AUTHENTICATION_REQUEST,
});

const authenticationSuccessAction = (
  token,
  userId,
  name,
  email,
  cellNumber,
  phoneNumber,
  role,
  office,
  noteLastVisited,
  hasUnreadNotes,
  profilePicture,
  favoriteClientIds,
  favoriteProjectIds,
  favoriteConsultingCompanyIds,
  dashboardShowInClosing,
  dashboardShowClosed,
  projectsShowInClosing,
  projectsShowClosed,
  dashboardGridPreferences,
  isCellNumberVerified
) => ({
  type: REQUEST_TYPES.AUTHENTICATION_SUCCESS,
  payload: {
    token,
    userId,
    name,
    profilePicture,
    cellNumber,
    phoneNumber,
    email,
    role,
    office,
    noteLastVisited,
    hasUnreadNotes,
    favoriteClientIds,
    favoriteProjectIds,
    favoriteConsultingCompanyIds,
    dashboardShowInClosing,
    dashboardShowClosed,
    projectsShowInClosing,
    projectsShowClosed,
    dashboardGridPreferences,
    isCellNumberVerified,
  },
});

const authenticationUpdateAction = updatedInfo => ({
  type: REQUEST_TYPES.AUTHENTICATION_UPDATE,
  payload: updatedInfo,
});

const authenticationFailureAction = error => {
  return {
    type: REQUEST_TYPES.AUTHENTICATION_FAILURE,
    payload: error,
    error: true,
  };
};

const sendResetPasswordEmailRequestAction = () => ({
  type: REQUEST_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST,
});

const sendResetPasswordEmailSuccessAction = () => ({
  type: REQUEST_TYPES.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
});

const sendResetPasswordEmailFailureAction = error => ({
  type: REQUEST_TYPES.SEND_PASSWORD_RESET_EMAIL_FAILURE,
  payload: error,
  error: true,
});

const updatePasswordRequestAction = () => ({
  type: REQUEST_TYPES.UPDATE_PASSWORD_REQUEST,
});

const updatePasswordSuccessAction = () => ({
  type: REQUEST_TYPES.UPDATE_PASSWORD_SUCCESS,
});

const updatePasswordFailureAction = error => ({
  type: REQUEST_TYPES.UPDATE_PASSWORD_FAILURE,
  payload: error,
  error: true,
});

const sendVerificationCodeRequestAction = () => ({
  type: REQUEST_TYPES.SEND_VERIFICATION_CODE,
  payload: {
    verificationCodeSent: false,
    sendingVerificationCode: true,
  },
});

const sendVerificationCodeSuccessAction = () => ({
  type: REQUEST_TYPES.SEND_VERIFICATION_CODE,
  payload: {
    verificationCodeSent: true,
    sendingVerificationCode: false,
  },
});

const sendVerificationCodeFailureAction = error => ({
  type: REQUEST_TYPES.SEND_VERIFICATION_CODE_FAILURE,
  payload: error,
  error: true,
});

const validateVerificationCodeRequestAction = () => ({
  type: REQUEST_TYPES.VALIDATE_VERIFICATION_CODE,
  payload: {
    verificationCodeValidated: false,
    validatingVerificationCode: true,
  },
});

const validateVerificationCodeSuccessAction = () => ({
  type: REQUEST_TYPES.VALIDATE_VERIFICATION_CODE,
  payload: {
    verificationCodeValidated: true,
    validatingVerificationCode: false,
  },
});

const validateVerificationCodeFailureAction = error => ({
  type: REQUEST_TYPES.VALIDATE_VERIFICATION_CODE_FAILURE,
  payload: error,
  error: true,
});

const logoutRequestAction = () => ({
  type: REQUEST_TYPES.LOGOUT_REQUEST,
});

const logoutSuccessAction = (token, id, name, email) => ({
  type: REQUEST_TYPES.LOGOUT_SUCCESS,
  payload: {
    token,
    id,
    name,
    email,
  },
});

const logoutFailureAction = error => {
  return {
    type: REQUEST_TYPES.LOGOUT_FAILURE,
    payload: error,
    error: true,
  };
};

export function validateAuthentication(shouldInitializeStore = true) {
  return async dispatch => {
    try {
      const token = service.getToken();
      if (!token) {
        return dispatch(logout(true));
      }

      dispatch(authenticationRequestAction());

      const {
        id,
        name,
        email,
        cellNumber,
        phoneNumber,
        profilePicture,
        noteLastVisited,
        hasUnreadNotes,
        favoriteClientIds,
        favoriteProjectIds,
        favoriteConsultingCompanyIds,
        userRole,
        office,
        dashboardShowInClosing,
        dashboardShowClosed,
        projectsShowInClosing,
        projectsShowClosed,
        dashboardGridPreferences,
        isCellNumberVerified,
      } = await service.getAuthenticatedUserInformation(token);

      if (shouldInitializeStore) {
        dispatch(initializeStore());
      }

      dispatch(
        authenticationSuccessAction(
          token,
          id,
          name,
          email,
          cellNumber,
          phoneNumber,
          userRole,
          office,
          noteLastVisited,
          hasUnreadNotes,
          profilePicture,
          favoriteClientIds,
          favoriteProjectIds,
          favoriteConsultingCompanyIds,
          dashboardShowInClosing,
          dashboardShowClosed,
          projectsShowInClosing,
          projectsShowClosed,
          dashboardGridPreferences,
          isCellNumberVerified
        )
      );
    } catch (error) {
      dispatch(authenticationFailureAction(error));
      dispatch(showSnackNotificationAction(error.message));
    }
  };
}

export function updateAuthenticatedUser(data) {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const token = service.getToken();
      dispatch(authenticationUpdateAction(data));
      const updatedUser = await service.patch(`/users/${auth.userId}`, data, {
        includes: JSON.stringify([
          "profilePicture",
          "userRole.permissions",
          "office.scope",
        ]),
      });

      const {
        id,
        name,
        email,
        phoneNumber,
        profilePicture,
        noteLastVisited,
        hasUnreadNotes,
        favoriteClientIds,
        favoriteProjectIds,
        favoriteConsultingCompanyIds,
        userRole,
        office,
      } = updatedUser;

      dispatch(
        authenticationUpdateAction({
          token,
          id,
          name,
          email,
          phoneNumber,
          userRole,
          office,
          noteLastVisited,
          hasUnreadNotes,
          profilePicture,
          favoriteClientIds,
          favoriteProjectIds,
          favoriteConsultingCompanyIds,
        })
      );
    } catch (error) {
      dispatch(showSnackNotificationAction(error.message));
    }
  };
}

export function closeErrorDialog() {
  return closeErrorDialogAction();
}

export function logout(useCallbackUrl) {
  return async dispatch => {
    try {
      const token = service.getToken();
      const hasCallback = Object.hasOwn(
        queryString.parse(location.search),
        "callback"
      );
      if (!token && hasCallback) return;

      const redirect = useCallbackUrl
        ? `/?callback=${encodeURIComponent(
            `${location.pathname}${location.search}`
          )}`
        : "/";
      dispatch(logoutRequestAction());
      service.logout();
      dispatch(logoutSuccessAction());
      dispatch(push(redirect));
    } catch (error) {
      dispatch(logoutFailureAction(error));
    }
  };
}

export function verifyEmail(email, callbackUrl) {
  return async dispatch => {
    try {
      dispatch(verifyEmailRequestAction());
      const { isPasswordDefined } = await service.verifyEmail(email);
      dispatch(verifyEmailSuccessAction(isPasswordDefined));

      if (!isPasswordDefined) {
        dispatch(
          push(
            `/forgotPassword?email=${encodeURIComponent(
              email
            )}&callback=${callbackUrl ?? ""}`
          )
        );
      }
    } catch (error) {
      dispatch(verifyEmailFailureAction(error));
    }
  };
}

export function login(email, password, callbackUrl) {
  return async dispatch => {
    try {
      dispatch(authenticationRequestAction());

      const {
        token,
        sendToEmailOnly,
        forceVerifyUserDetails,
        twoFactorVerified,
      } = await service.login(email, password);
      service.storeToken(token);
      sessionStorage.setItem("invoices_grid_pageSize", 25);
      const {
        id,
        name,
        noteLastVisited,
        hasUnreadNotes,
        cellNumber,
        phoneNumber,
        profilePicture,
        favoriteClientIds,
        favoriteProjectIds,
        favoriteConsultingCompanyIds,
        userRole,
        office,
        dashboardShowInClosing,
        dashboardShowClosed,
        projectsShowInClosing,
        projectsShowClosed,
        dashboardGridPreferences,
        isCellNumberVerified,
      } = await service.getAuthenticatedUserInformation(token);

      dispatch(
        authenticationSuccessAction(
          token,
          id,
          name,
          email,
          cellNumber,
          phoneNumber,
          userRole,
          office,
          noteLastVisited,
          hasUnreadNotes,
          profilePicture,
          favoriteClientIds,
          favoriteProjectIds,
          favoriteConsultingCompanyIds,
          dashboardShowInClosing,
          dashboardShowClosed,
          projectsShowInClosing,
          projectsShowClosed,
          dashboardGridPreferences,
          isCellNumberVerified
        )
      );

      if (forceVerifyUserDetails) {
        dispatch(push("/account-settings"));
      } else if (twoFactorVerified) {
        dispatch(initializeStore());
        dispatch(push(getInitialPage(userRole, callbackUrl)));
      } else {
        dispatch(sendVerificationCode({ sendToEmailOnly, login: true }));
      }
    } catch (error) {
      dispatch(authenticationFailureAction(error));
    }
  };
}

export function sendPasswordResetEmail(email, callbackUrl, resetPassword) {
  return async dispatch => {
    dispatch(sendResetPasswordEmailRequestAction());
    try {
      await service.post("/auth/password/", { email });

      const snackNotificationMessage = `A password reset message was sent to ${email} if that email is associated to a valid account.`;
      dispatch(showSnackNotificationAction(snackNotificationMessage));
      dispatch(sendResetPasswordEmailSuccessAction());
      dispatch(
        push(
          `/set-password?email=${email}&callback=${callbackUrl}&resetPassword=${resetPassword}`
        )
      );
    } catch (error) {
      dispatch(
        showSnackNotificationAction(
          `A password reset message could not be sent to ${email}.`
        )
      );
      dispatch(sendResetPasswordEmailFailureAction(error));
    }
  };
}

export function updatePassword(verificationCode, password, email, callbackUrl) {
  return async dispatch => {
    dispatch(updatePasswordRequestAction());
    try {
      const ip = await getMyIp();
      const location = await getMyLocation(ip);
      const { token } = await service.post("/user/passwordUpdate", {
        verificationCode,
        password,
        email,
        ip,
        location,
      });

      service.storeToken(token);
      const {
        id,
        name,
        noteLastVisited,
        hasUnreadNotes,
        cellNumber,
        phoneNumber,
        profilePicture,
        favoriteClientIds,
        favoriteProjectIds,
        favoriteConsultingCompanyIds,
        userRole,
        office,
        dashboardShowInClosing,
        dashboardShowClosed,
        projectsShowInClosing,
        projectsShowClosed,
        dashboardGridPreferences,
        isCellNumberVerified,
      } = await service.getAuthenticatedUserInformation(token);
      dispatch(
        authenticationSuccessAction(
          token,
          id,
          name,
          email,
          cellNumber,
          phoneNumber,
          userRole,
          office,
          noteLastVisited,
          hasUnreadNotes,
          profilePicture,
          favoriteClientIds,
          favoriteProjectIds,
          favoriteConsultingCompanyIds,
          dashboardShowInClosing,
          dashboardShowClosed,
          projectsShowInClosing,
          projectsShowClosed,
          dashboardGridPreferences,
          isCellNumberVerified
        )
      );
      dispatch(initializeStore());
      dispatch(push(getInitialPage(userRole, callbackUrl)));
      const successMessage = `Your password was updated successfully.`;
      dispatch(showSnackNotificationAction(successMessage));
      dispatch(updatePasswordSuccessAction());
    } catch (error) {
      const errorMessage = `Password could not be updated. Please take into account that the verification code received in your email is valid for 1 hour only.`;
      dispatch(showSnackNotificationAction(errorMessage));
      dispatch(updatePasswordFailureAction(error));
    }
  };
}

export function sendVerificationCode({ cellNumber, sendToEmailOnly }) {
  return async dispatch => {
    dispatch(sendVerificationCodeRequestAction());
    const sendSMSOnly = Boolean(cellNumber);
    const target = sendToEmailOnly ? "email" : "email and cell phone number";
    const targetMsg = sendToEmailOnly
      ? "registered email"
      : "given cell phone number and registered email";
    try {
      await service.post("/auth/verification-code", {
        cellNumber,
        sendToEmailOnly,
      });

      if (sendSMSOnly) {
        const successMessage = `A verification code was sent to the registered cell phone number. Never share the code with anyone. Only use it on RPM2.`;
        dispatch(showSnackNotificationAction(successMessage));
      } else if (sendToEmailOnly) {
        const successMessage = `A verification code was sent to the registered email. Never share the code with anyone. Only use it on RPM2.`;
        dispatch(showSnackNotificationAction(successMessage));
      } else {
        const successMessage = `A verification code was sent to the registered email and cell phone number. Never share the code with anyone. Only use it on RPM2.`;
        dispatch(showSnackNotificationAction(successMessage));
      }

      dispatch(sendVerificationCodeSuccessAction());
    } catch (error) {
      const errorMessage = `A verification code could not be sent to the ${targetMsg}. Please make sure you entered a valid ${target}.`;
      dispatch(showSnackNotificationAction(errorMessage));
      dispatch(sendVerificationCodeFailureAction(error));
    }
  };
}

export function validateVerificationCode(code, login, callbackUrl) {
  return async dispatch => {
    dispatch(validateVerificationCodeRequestAction());
    try {
      const ip = await getMyIp();
      const location = await getMyLocation(ip);
      const { user, token } = await service.post("/auth/verify-code", {
        code,
        ip,
        location,
      });

      service.storeToken(token);

      const { isCellNumberVerified, cellNumber } = user;

      if (!login) {
        const successMessage = `The verification code was validated successfully.`;
        dispatch(showSnackNotificationAction(successMessage));
      }
      dispatch(validateVerificationCodeSuccessAction());
      dispatch(
        authenticationUpdateAction({
          isCellNumberVerified,
          cellNumber,
        })
      );

      if (login) {
        dispatch(initializeStore());
        dispatch(push(getInitialPage(user.userRole, callbackUrl)));
      }
    } catch (error) {
      const errorMessage = `The verification code couldn't be validated.`;
      dispatch(showSnackNotificationAction(errorMessage));
      dispatch(validateVerificationCodeFailureAction(error));
    }
  };
}

export function openAccountSettingsModal(name, email) {
  return openModalDialog(
    ["Edit Profile", name, email],
    "AccountSettings",
    {},
    false,
    false,
    { isShowCloseIcon: true }
  );
}

function buildExpireSessionAction() {
  let endSessionTimeout;
  const A_MINUTE = 60 * 1000;

  return ttl => {
    const timeout = ttl <= 2 * A_MINUTE ? ttl - A_MINUTE : ttl - 2 * A_MINUTE;
    return async dispatch => {
      clearTimeout(endSessionTimeout);
      endSessionTimeout = setTimeout(
        () => dispatch(openModalDialog(false, "InactivityDialog", {})),
        timeout
      );
    };
  };
}

export function switchToClientPortal() {
  return async dispatch => {
    try {
      const [[role], [switchToClientPortalPermission]] = await Promise.all([
        service.get("/user-roles", {
          includes: JSON.stringify(["permissions"]),
          filter: JSON.stringify({
            $where: { name: "Client Portal" },
          }),
        }),
        service.get("/permissions", {
          filter: JSON.stringify({
            $where: { name: "switch-to-client-portal" },
          }),
        }),
      ]);

      dispatch(
        authenticationUpdateAction({
          role: {
            ...role,
            permissions: [
              ...role.permissions,
              { ...switchToClientPortalPermission, active: true },
            ],
          },
        })
      );

      dispatch(push(getInitialPage(role)));
    } catch (error) {
      dispatch(
        showSnackNotificationAction(
          "There was an error while trying to switch portals"
        )
      );
    }
  };
}

export function switchBackToOriginalPortal() {
  return async dispatch => {
    try {
      const token = service.getToken();

      const { userRole } = await service.getAuthenticatedUserInformation(token);

      dispatch(
        authenticationUpdateAction({
          role: userRole,
        })
      );

      dispatch(push(getInitialPage(userRole)));
    } catch (error) {
      dispatch(
        showSnackNotificationAction(
          "There was an error while trying to switch portals"
        )
      );
    }
  };
}

export const displayExpireSessionModal = buildExpireSessionAction();
