import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import get from "lodash.get";
import { map } from "lodash";

import EmptyState from "./EmptyState";
import propTypes from "../../../constants/propTypes";
import RequirementsGrid from "./RequirementsGrid";
import ModalTitle from "../../layout/AppLayout/ModalDialog/ModalTitle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  background-color: #ffffff;
`;

const getDefaultSpecTitle = selectedSpecs => {
  if (isEmpty(selectedSpecs)) {
    return "No Spec Selected";
  }
  return selectedSpecs.length == 1
    ? `${get(selectedSpecs, "0.customNumber")} ${get(
        selectedSpecs,
        "0.description"
      )}`
    : `${selectedSpecs.length} Specs Selected`;
};

const CreateForm = ({ selectedSpecs }) => {
  const [specTitle, setTitle] = useState(getDefaultSpecTitle(selectedSpecs));

  useEffect(() => {
    setTitle(getDefaultSpecTitle(selectedSpecs));
  }, [selectedSpecs]);

  const handleTitleChange = useCallback(
    specRequirement => {
      const defaultSpecTitle = getDefaultSpecTitle(selectedSpecs);
      setTitle(
        specRequirement
          ? `${defaultSpecTitle} - ${specRequirement.requirement.name}`
          : defaultSpecTitle
      );
    },
    [selectedSpecs]
  );

  const specIds = useMemo(() => map(selectedSpecs, "id"), [selectedSpecs]);

  return (
    <Wrapper>
      <ModalTitle title={["Review Submittals", specTitle]} />
      {selectedSpecs.length ? (
        <RequirementsGrid
          specIds={specIds}
          onTitleChange={handleTitleChange}
          setTitle={setTitle}
        />
      ) : (
        <EmptyState />
      )}
    </Wrapper>
  );
};

CreateForm.propTypes = {
  selectedSpecs: PropTypes.arrayOf(propTypes.spec),
};

export default CreateForm;
