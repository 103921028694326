import React from "react";
import BoldText from "../../../../ui/Typography/BoldText";
import RegularText from "../../../../ui/Typography/RegularText";
import { SPEC_TYPE } from "../../Quotes/bidGroupVendorSpecUtils";
import { NonTaxableLabel } from "../../../Specs/DescriptionColumn";

export Indicator from "./Indicator";

export const CustomNumber = row => {
  return <BoldText>{row.customNumber}</BoldText>;
};

export const Description = row => {
  if (row.isParent) {
    return (
      <BoldText>
        <NonTaxableLabel isTaxExempt={row.isTaxExempt} />
        {row.description}
      </BoldText>
    );
  }
  return (
    <RegularText intent={row.type === SPEC_TYPE ? "main" : "light"}>
      <NonTaxableLabel isTaxExempt={row.isTaxExempt} />
      {row.description}
    </RegularText>
  );
};

export const Category = row => {
  return (
    <RegularText intent={row.isParent ? "main" : "light"}>
      {row.specCategory}
    </RegularText>
  );
};

export const VendorName = row => {
  return row.vendorName;
};
