import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TermPage from "./TermPage";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { dataComponentId as entityDataComponentId } from "../EntityDetailContainer";

export const dataComponentId = "EntityTermsGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  setReload
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload(dataComponentId, true);
    },
  });
};

const updateTermsMsg =
  "Please update the Entity Terms to view the recent Terms changes on the latest Project Documents";

const TermContainer = ({
  dataComponent,
  flattenedDataComponent,
  openModalDialog,
  setReload,
  additionalFilters,
  showSnackNotificationAction,
  closeModalDialog,
  performUpdateRequest,
  entity,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);
  useEffect(() => {
    processRequestResponse(prevDataComponent, dataComponent, setReload);
  });

  const handleUpdate = useCallback(
    termIdSequence => {
      performUpdateRequest(entityDataComponentId, entity.id, {
        termIdSequence,
      });
      showSnackNotificationAction(updateTermsMsg);
    },
    [entity, performUpdateRequest, showSnackNotificationAction]
  );

  const handleDelete = useCallback(
    ids => () => {
      entity.termIdSequence = entity.termIdSequence.filter(
        id => !ids.includes(id)
      );
      handleUpdate(entity.termIdSequence);
      closeModalDialog();
    },
    [closeModalDialog, entity.termIdSequence, handleUpdate]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, content }) => {
      const title =
        content.length > 30 ? `${content.substr(0, 30)}...` : content;
      openModalDialog(
        `Delete ${title}`,
        "StandardDelete",
        {
          id,
          title,
          dataComponentId,
          params: {},
          onDelete: handleDelete([id]),
        },
        false
      );
    },
    [handleDelete, openModalDialog]
  );

  const handleOpenBulkDeleteModal = useCallback(() => {
    openModalDialog(
      "Delete Terms",
      "StandardBulkDeleteConfirmation",
      {
        dataComponentId,
        selectedIds: flattenedDataComponent.selectedIds,
        params: {},
        onDelete: handleDelete(flattenedDataComponent.selectedIds),
      },
      false
    );
  }, [flattenedDataComponent.selectedIds, openModalDialog, handleDelete]);

  const handleReorder = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex == newIndex) {
        return;
      }

      const termIdSequence = [...entity.termIdSequence];
      const termId = termIdSequence[oldIndex];
      termIdSequence.splice(oldIndex, 1);
      termIdSequence.splice(newIndex, 0, termId);

      handleUpdate(termIdSequence);
    },
    [entity, handleUpdate]
  );

  return (
    <TermPage
      dataComponent={flattenedDataComponent}
      additionalFilters={additionalFilters}
      onOpenDeleteModal={handleOpenDeleteModal}
      onOpenBulkDeleteModal={handleOpenBulkDeleteModal}
      onReorder={handleReorder}
      entity={entity}
    />
  );
};

TermContainer.propTypes = {
  additionalFilters: PropTypes.shape({}),
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  entity: propTypes.entity.isRequired,
};

export const mapStateToProps = state => {
  return {
    flattenedDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    dataComponent: getDataComponent(entityDataComponentId, state),
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  setReload,
  showSnackNotificationAction,
  performUpdateRequest,
  closeModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermContainer);
