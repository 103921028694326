import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { get, isUndefined } from "lodash";
import { CircularProgress } from "@material-ui/core";

import { useFlattenedDatacomponent } from "../../../hooks/useDatacomponent";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import Spec from "../../../../models/Spec";
import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";
import Revision from "../../../../models/Revision";
import { usePrevious } from "../../../hooks/usePrevious";
import { DescriptionColumn } from "../../Specs/DescriptionColumn";
export const revisionsDataComponentDefinition = {
  dataComponentId: "downloadPurchaseOrderPDFRevisions",
  model: Revision,
  includes: ["snapshot"],
  apiRoute: "revisions",
};

export const purchaseOrderPDFSpecsDataComponentId =
  "purchaseOrderPDFSpecsDataComponentId";

const columns = ["customNumber", "description"];
const columnOptions = {
  customNumber: {
    title: "Number",
    bold: true,
  },
  description: {
    render: DescriptionColumn,
  },
};

export const SpecsGrid = ({ purchaseOrderId, revisionId }) => {
  const dataComponent = useFlattenedDatacomponent(
    purchaseOrderPDFSpecsDataComponentId
  );

  const filters = useMemo(
    () => ({
      sort: [{ columnName: "version", direction: "desc" }],
      rootFilters: {
        $where: revisionId ? { id: revisionId } : { purchaseOrderId },
      },
    }),
    [purchaseOrderId, revisionId]
  );

  const { data, isLoading } = usePerformRetrieveList(
    revisionsDataComponentDefinition,
    filters
  );

  const prevLoading = usePrevious(isLoading);

  if (isUndefined(prevLoading) || isLoading) {
    return (
      <div
        style={{
          width: "560px",
          height: "75vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const [revision] = data;

  const specIds = Object.keys(get(revision, "snapshot.json.specs", {}));

  const $where = revisionId
    ? {
        id: {
          $in: specIds,
        },
      }
    : {
        purchaseOrderId,
      };

  return (
    <BWGridAPI
      dataComponent={dataComponent}
      model={Spec}
      includes={[]}
      apiRoute="specs"
      apiFilters={{
        rootFilters: {
          $where,
        },
        params: {
          omitDefaultModifier: true,
        },
      }}
      defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
      showSelectionColumn
      showSelectAll
    >
      <GridHeader headerText={"Add Spec Files"} />
      <GridTable columns={columns} columnOptions={columnOptions} />
    </BWGridAPI>
  );
};
SpecsGrid.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  revisionId: PropTypes.string,
};
