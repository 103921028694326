import React, { useCallback, useMemo, useState } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import Project from "../../../../models/Project";
import { inputs } from "../../../inputs/inputConfigs";
import propTypes from "../../../../constants/propTypes";
import importTemplateFile from "./importTemplateFile.xls";

const getAPIOptions = (projectId, scopeId) => ({
  model: Project,
  route: "projects",
  rootFilters: {
    $where: {
      id: {
        $notEqual: projectId,
      },
      "property.entity.client.scopeId": scopeId,
    },
  },
  fetchOnlyOnce: true,
  sort: [{ columnName: "name", direction: "asc" }],
  fields: ["projects.id", "number", "name"],
  params: { omitDefaultModifier: true },
});

export const getProjectName = ({ number, name } = {}) => {
  if (!number && !name) {
    return "";
  }
  return [number, name].filter(prop => !!prop).join(" - ");
};

import {
  FormWrapper,
  SelectWrapper,
  DownloadLink,
  StyledFormControl,
} from "./components";
import DragDropInputFile from "../../../inputs/DragDrop/DragDropInputFile";
import { useModalControl } from "../../../inputs/DragDrop/DragDropInputFile/useModalControl";
import useScope from "../../../hooks/useScope";

const ImportForm = ({
  projectId,
  targetProjectId,
  onSetTargetProjectId,
  onUploadSpecs,
  isFromProjects,
}) => {
  const [fileName, setFileName] = useState("");

  const { open, onOpen, onClose } = useModalControl();

  const handleOpenFilePicker = useCallback(() => {
    onOpen();
    return onSetTargetProjectId("-1");
  }, [onOpen, onSetTargetProjectId]);

  const onFileChange = useCallback(
    ({ target }) => {
      const file = _get(target, "files.0");
      if (file) {
        onUploadSpecs(file);
        setFileName(file.name);
      }
      onClose();
    },
    [onClose, onUploadSpecs]
  );

  const { id: scopeId } = useScope();

  const APIOptions = useMemo(() => getAPIOptions(projectId, scopeId), [
    projectId,
    scopeId,
  ]);

  const handleChange = useCallback(
    ({ target }) => {
      const id = _get(target, "value", "-1");
      onSetTargetProjectId(id);
    },
    [onSetTargetProjectId]
  );

  const { InputComponent, ...selectProps } = inputs.project;
  return (
    <FormWrapper>
      <SelectWrapper>
        {isFromProjects ? (
          <InputComponent
            {...selectProps}
            dataComponentId="select-projects-import"
            label="Select Project"
            labelProperty={getProjectName}
            apiFilterLabelProperty="name"
            required={false}
            isAutocomplete
            isSingleAutocomplete
            onChange={handleChange}
            value={targetProjectId}
            APIOptions={APIOptions}
          />
        ) : (
          <StyledFormControl>
            <InputLabel shrink>Select File</InputLabel>
            <Input
              name="input-import-file"
              placeholder={"Choose File"}
              readOnly
              onClick={handleOpenFilePicker}
              fullWidth
              value={fileName}
            />
            <FormHelperText>
              Download
              <DownloadLink href={importTemplateFile} download>
                Spec Index Template
              </DownloadLink>
            </FormHelperText>
          </StyledFormControl>
        )}
        <DragDropInputFile
          open={open}
          onClose={onClose}
          onChange={onFileChange}
          accept=".xls,.xlsx"
        />
      </SelectWrapper>
    </FormWrapper>
  );
};

ImportForm.propTypes = {
  defaultAreaId: PropTypes.string,
  isFromProjects: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
  targetProjectId: PropTypes.string.isRequired,
  onSetTargetProjectId: PropTypes.func.isRequired,
};

export default ImportForm;
