import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";

const Option = styled.div`
  min-width: 175px;
`;

export const BulkActions = ({
  dataComponent,
  onBulkDelete,
  isDeleteDisabled,
}) => {
  const disabled =
    isDeleteDisabled || (dataComponent.selectedIds || []).length === 0;

  const options = [
    {
      content: <Option>Delete</Option>,
      onClick: onBulkDelete,
      disabled,
    },
  ];
  return <DropdownMenu buttonText="Actions" options={options} />;
};

BulkActions.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
  isDeleteDisabled: PropTypes.bool,
};

export default BulkActions;
