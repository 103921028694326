import React, { Component } from "react";
import { connect } from "react-redux";
import VendorContactsPage from "./VendorContactsPage";
import PropTypes from "prop-types";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  performRetrieveListRequest,
  fetchData,
} from "../../../actions/dataComponentActions";
import {
  openModalDialog,
  closeModalDialog,
  setModalDialogTitle,
  showSnackNotificationAction,
  setAutoSaveComponentId,
} from "../../../actions/layoutActions";
import { resendAccessEmail } from "../../../actions/contactActions";

import contactConstants from "../../../constants/Contact";
import { getBluechipResources } from "../../../utils/bluechipUtils";

import {
  onCreateVendorContact,
  onUpdateVendorContact,
  onDeleteVendorContact,
  processRequests,
} from "./CreateVendorContact/CreateVendorContactFunctions";

import VendorContact from "../../../models/VendorContact";
import * as DATA_COMPONENTS from "../../../constants/DataComponents";

const dataComponentId = "VendorContactsDataComponentId";

export const vendorContactsDataComponentId = "VendorContactsDataComponentId";

export const addressBookType = "Vendor";

export const createNewContactModalHeader = "Create Contact";
export const selectFromAddressBookModalHeader = "Add Contact";
export const updateModalDialogTitle = (showNewContact, setModalDialogTitle) => {
  if (showNewContact) {
    setModalDialogTitle(createNewContactModalHeader);
  } else {
    setModalDialogTitle(selectFromAddressBookModalHeader);
  }
};
export class VendorContactsContainer extends Component {
  constructor(props) {
    super(props);

    this.toggleCreateNewContact = this.toggleCreateNewContact.bind(this);
    this.formikPropsRef = React.createRef(null);
  }

  static propTypes = {
    vendorId: PropTypes.string.isRequired,
    addressBook: PropTypes.object.isRequired,
    dataComponent: PropTypes.object.isRequired,
    vendorContactDataComponent: PropTypes.object.isRequired,
    refreshContactsTable: PropTypes.func,
    isModalOpen: PropTypes.bool,
    openModalDialog: PropTypes.func,
    resendAccessEmail: PropTypes.func,
    setModalDialogTitle: PropTypes.func.isRequired,
    ignoreAddressBook: PropTypes.bool,
  };

  state = {
    customInfo: {
      vendor: { location: {} },
      gridComponentId: vendorContactsDataComponentId,
    },
  };

  componentDidMount() {
    const { vendor, initDataComponent, setAutoSaveComponentId } = this.props;
    initDataComponent(
      vendorContactsDataComponentId,
      VendorContact,
      [],
      "vendor-contacts"
    );

    setAutoSaveComponentId(vendorContactsDataComponentId);

    this.setState({
      customInfo: { vendor, gridComponentId: vendorContactsDataComponentId },
    });
  }
  componentDidUpdate({ vendorContactDataComponent: prevDataComponent }) {
    const {
      vendorContactDataComponent,
      performRetrieveListRequest,
      showSnackNotificationAction,
      closeModalDialog,
    } = this.props;

    switch (prevDataComponent.dataComponentId) {
      case vendorContactsDataComponentId: {
        processRequests(
          prevDataComponent,
          vendorContactDataComponent,
          vendorContactsDataComponentId,
          {
            performRetrieveListRequest,
            showSnackNotificationAction,
            closeModalDialog,
            formikProps: this.formikPropsRef.current,
          }
        );
        break;
      }
    }
  }
  handleAddContact = () => {
    const {
      openModalDialog,
      performCreateRequest,
      addressBook,
      ignoreAddressBook,
    } = this.props;
    const { customInfo } = this.state;
    const handleCreate = vendorContact =>
      onCreateVendorContact(
        vendorContact,
        addressBook,
        vendorContactsDataComponentId,
        performCreateRequest
      );

    openModalDialog(
      ignoreAddressBook
        ? createNewContactModalHeader
        : selectFromAddressBookModalHeader,
      "CreateVendorContact",
      {
        ...customInfo,
        ignoreAddressBook,
        dataComponentId: vendorContactsDataComponentId,
        toggleCreateNewContact: this.toggleCreateNewContact,
        onCreateVendorContact: handleCreate,
        formikPropsRef: this.formikPropsRef,
      },
      true
    );
  };

  handleEditContact = vendorContact => {
    const {
      openModalDialog,
      performUpdateRequest,
      ignoreAddressBook,
    } = this.props;
    const { customInfo } = this.state;
    const handleUpdate = vendorContact =>
      onUpdateVendorContact(
        vendorContact,
        vendorContactsDataComponentId,
        performUpdateRequest
      );

    openModalDialog(
      "Edit Contact",
      "EditVendorContact",
      {
        vendorContact,
        ignoreAddressBook,
        vendorContactId: vendorContact.id,
        ...customInfo,
        onUpdateVendorContact: handleUpdate,
        formikPropsRef: this.formikPropsRef,
      },
      true
    );
  };

  handleDeleteContact = vendorContact => {
    const { openModalDialog, performDeleteRequest } = this.props;
    const { firstName, lastName } = vendorContact.contact;
    const title = `${firstName} ${lastName}`;

    const handleDelete = vendorContactId =>
      onDeleteVendorContact(
        vendorContactId,
        vendorContactsDataComponentId,
        performDeleteRequest
      );

    openModalDialog(
      `Delete ${title}`,
      "DeleteVendorContact",
      {
        title,
        vendorContactId: vendorContact.id,
        onDeleteVendorContact: handleDelete,
      },
      true,
      false
    );
  };

  handleResendAccessEmail = ({ contact }) => {
    this.props.resendAccessEmail(contact, "Vendor");
  };

  toggleCreateNewContact(showNewContact) {
    const { setModalDialogTitle } = this.props;
    updateModalDialogTitle(showNewContact, setModalDialogTitle);
  }

  render() {
    return (
      <VendorContactsPage
        {...this.props}
        dataComponentId={dataComponentId}
        vendor={this.state.customInfo.vendor}
        statusMap={contactConstants.statusMap}
        handleAddContact={this.handleAddContact}
        handleEditContact={vendorContact =>
          this.handleEditContact(vendorContact)
        }
        handleDeleteContact={vendorContact =>
          this.handleDeleteContact(vendorContact)
        }
        handleResendAccessEmail={vendorContact =>
          this.handleResendAccessEmail(vendorContact)
        }
      />
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const vendorContactDataComponent = getDataComponent(
    vendorContactsDataComponentId,
    state
  );

  const dataComponentAddressBooks = getDataComponentFlattenedRequestState(
    DATA_COMPONENTS.ADDRESS_BOOKS_SELECT,
    state
  );
  const addressBooks =
    getBluechipResources(dataComponentAddressBooks, state) || [];

  const addressBook =
    addressBooks.find(addressBook => addressBook.type === addressBookType) ||
    {};

  return {
    vendorId: ownProps.match.params.vendorId,
    addressBook,
    dataComponent: getDataComponentFlattenedRequestState(
      vendorContactsDataComponentId,
      state
    ),
    vendorContactDataComponent: vendorContactDataComponent,
    isModalOpen: state.layout.modalDialog.isOpen,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  performRetrieveListRequest,
  fetchData,
  openModalDialog,
  closeModalDialog,
  resendAccessEmail,
  setModalDialogTitle,
  showSnackNotificationAction,
  setAutoSaveComponentId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorContactsContainer);
