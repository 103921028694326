import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import styled from "styled-components";
import _get from "lodash/get";
import { Tooltip } from "@material-ui/core";
import Remove from "@material-ui/icons/Remove";

import ScheduleColumn from "./ScheduleColumn";
import POStatusTag from "../../../ui/POStatusTag";
import { getAddressName } from "../../../inputs/inputConfigs/purchaseOrderDetails";
import Date from "../../../ui/BWGrid/columns/Date";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";
import { statusFilters } from "../../Quoting/filterOptions";

export const Column = styled(({ status, bold, ...props }) => (
  <span {...props} />
))`
  opacity: ${({ status }) => (status === "Canceled" ? "0.5" : null)};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

export const POPropTypes = {
  status: PropTypes.string.isRequired,
  lastStatusOn: PropTypes.instanceOf(Date),
  approvedAt: PropTypes.string,
  followUpDate: PropTypes.string,
  estimatedShipDate: PropTypes.string,
  estimatedDeliveryDate: PropTypes.string,
  isRevised: PropTypes.bool,
};

export const columns = [
  "number",
  "status",
  "vendor.name",
  "needsFollowUp",
  "shipmentStatus",
  "bidGroup.id",
  "followUpDate",
  "shipAddress.location",
  "estimatedShipDate",
  "estimatedDeliveryDate",
];

const parseDate = date =>
  date ? moment(date).format(PRIMARY_DATE_FORMAT) : "00/00/00";
export const DashIcon = styled(Remove)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 20px;
`;

export const StatusColumn = ({ id, ...purchaseOrder }) => {
  return (
    <POStatusTag
      purchaseOrder={purchaseOrder}
      displayNeedsFollowUp={false}
      displayDelivered={false}
      spaceBetween={false}
      marginRight={true}
    />
  );
};

StatusColumn.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...POPropTypes,
};

export const ShipmentStatusColumn = ({
  status,
  shipmentsCount,
  shipmentsWithoutDelivery,
}) => {
  if (status === "Canceled") return "";
  if (shipmentsCount === 0) return "--";
  return shipmentsWithoutDelivery === 0 ? "Delivered" : "Undelivered";
};

ShipmentStatusColumn.propTypes = POPropTypes;

export function shipmentStatusFilter({ value }) {
  switch (value) {
    case "delivered":
      return {
        shipmentsWithoutDelivery: 0,
        shipmentsCount: { $gt: 0 },
      };
    case "not-delivered":
      return {
        $or: [
          {
            shipmentsWithoutDelivery: {
              $gt: 0,
            },
            shipmentsCount: { $gt: 0 },
          },
          { shipmentsCount: 0 },
        ],
      };
    default:
      return {};
  }
}

export function purchaseOrderStatusFilter({ value }) {
  return statusFilters[value];
}

export const EstimatedShipDateColumn = ({ status, estimatedShipDate }) => {
  return (
    status && <Column status={status}>{parseDate(estimatedShipDate)}</Column>
  );
};

export const EstimatedDeliveryDateColumn = ({
  status,
  estimatedDeliveryDate,
}) =>
  status && <Column status={status}>{parseDate(estimatedDeliveryDate)}</Column>;

export const RFQNumber = ({ bidGroup }) => _get(bidGroup, "rfqNumber");

export const displayEarliestDate = (row, fieldName) => {
  const shipmentEstimatedDate = row[fieldName];
  if (!shipmentEstimatedDate) {
    return <DashIcon />;
  }
  return <Date date={shipmentEstimatedDate} format={PRIMARY_DATE_FORMAT} />;
};

const getShipTo = (shipAddress, shipVendorAddress) => {
  if (shipAddress) {
    return getAddressName(shipAddress);
  }
  if (shipVendorAddress) {
    return _get(shipVendorAddress.vendor, "name");
  }
  return "";
};

export const ShipTo = ({ shipAddress, shipVendorAddress }) => {
  const shipTo = getShipTo(shipAddress, shipVendorAddress);

  return (
    <Tooltip title={shipTo}>
      <span>{shipTo}</span>
    </Tooltip>
  );
};
ShipTo.propTypes = {
  shipAddress: PropTypes.shape({}),
  shipVendorAddress: PropTypes.shape({}),
};

export const columnOptions = rfqs => {
  return {
    number: {
      title: "PO Number",
      filter: true,
      filterOptions: {
        operator: ({ value }) => ({
          number: {
            $integerContains: `%${value}%`,
          },
          // For some reason, this speeds up performance when filtering by number alone
          $or: [
            {
              $and: {
                shipmentsWithoutDelivery: 0,
                shipmentsCount: { $gt: 0 },
              },
            },
            {
              $or: [
                {
                  shipmentsWithoutDelivery: {
                    $gt: 0,
                  },
                  shipmentsCount: { $gt: 0 },
                },
                { shipmentsCount: 0 },
              ],
            },
          ],
        }),
      },
      render: ({ status, number }) =>
        number && (
          <Column status={status} bold>
            {number}
          </Column>
        ),
    },
    status: {
      render: StatusColumn,
      filter: "select",
      filterOptions: {
        operator: purchaseOrderStatusFilter,
        options: Object.keys(statusFilters).map(key => ({
          id: key,
          name: key,
        })),
      },
    },
    shipmentStatus: {
      render: ShipmentStatusColumn,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        operator: shipmentStatusFilter,
        options: [
          { id: "delivered", name: "Delivered" },
          { id: "not-delivered", name: "Undelivered" },
        ],
      },
    },
    "vendor.name": {
      title: "Vendor",
      filter: true,
      width: "150px",
      render: ({ status, vendor }) => {
        const name = _get(vendor, "name", "");
        return (
          vendor && (
            <Column status={status}>
              <Tooltip title={name}>
                <span>{name}</span>
              </Tooltip>
            </Column>
          )
        );
      },
    },
    needsFollowUp: {
      title: "Schedule",
      render: ScheduleColumn,
      filter: "select",
      filterOptions: {
        options: [
          { id: true, name: "Needs Follow Up" },
          { id: false, name: "On Schedule" },
        ],
      },
    },
    "bidGroup.id": {
      title: "RFQ Number",
      render: RFQNumber,
      fill: true,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: rfqs.map(({ id, rfqNumber }) => ({ id, name: rfqNumber })),
      },
    },
    followUpDate: {
      render: row => displayEarliestDate(row, "followUpDate"),
      filter: "picker",
    },
    "shipAddress.location": {
      title: "Ship To",
      sortingEnabled: false,
      filter: true,
      width: "100px",
      filterOptions: {
        operator: ({ value }) => ({
          $or: [
            {
              "shipAddress.name": {
                $ilike: `%${value}%`,
              },
            },
            {
              "shipAddress.description": {
                $ilike: `%${value}%`,
              },
            },
            {
              "shipVendorAddress.vendor.name": {
                $ilike: `%${value}%`,
              },
            },
          ],
        }),
      },
      render: ShipTo,
    },
    estimatedShipDate: {
      title: "Estimated Ship",
      align: "right",
      render: row => displayEarliestDate(row, "shipmentsEstimatedShipDate"),
    },
    estimatedDeliveryDate: {
      title: "Estimated Delivery",
      align: "right",
      render: row => displayEarliestDate(row, "shipmentsEstimatedDeliveryDate"),
    },
  };
};

export const getRootFilters = memoize(projectId => {
  return {
    $where: {
      projectId,
    },
  };
});
