import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import FormGrid from "../../../../components/forms/FormGrid";
import userSettingsFields from "../CreateUser/createUserFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import propTypes from "../../../../constants/propTypes";
import { Button } from "../../../mui/core";
import { getCustomValidator } from "../CreateUser/CreateUserForm";
import DuplicatePersonDialog from "../../../DuplicatePersonDialog";

export const DeleteButton = styled(Button)`
  color: rgba(0, 0, 0, 0.54);
`;

export default class UserSettingsForm extends Component {
  static propTypes = {
    saveUser: PropTypes.func.isRequired,
    user: propTypes.user.isRequired,
    office: propTypes.office,
    userRole: propTypes.userRole.isRequired,
    dataComponentId: PropTypes.string.isRequired,
    role: propTypes.userRole,
    userRoles: PropTypes.array,
  };

  render() {
    const {
      saveUser,
      user,
      office,
      userRole,
      dataComponentId,
      role,
      userRoles,
    } = this.props;

    return (
      <FormikForm
        initialValues={user}
        onSubmit={saveUser}
        validationSchema={getCustomValidator(userRoles)}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={userSettingsFields(
                office?.scopeId,
                role,
                userRole,
                {
                  values,
                  ...formikProps,
                },
                userRoles
              )}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <DuplicatePersonDialog
              dataComponentId={dataComponentId}
              isUserForm
            />
            <ActionButtons
              onSend={handleSubmit}
              isModal={true}
              onDanger={null}
              dangerButtonText="DELETE USER"
              listeners={[dataComponentId]}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}
