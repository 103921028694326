import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import { haveActiveRequests } from "../../../../utils/dataComponentUtils";
import {
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { saveSubmittal } from "../../../../actions/submittalActions";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import {
  dataComponentId,
  specsDataComponentId,
  submittalsDataComponentId,
} from "./RequirementsGridContainer";

export const mapStateToProps = (state, { specIds }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const submittalsDatacomponent = getDataComponent(
    submittalsDataComponentId,
    state
  );
  const specsDataComponent = getDataComponentFlattenedRequestState(
    specsDataComponentId,
    state
  );
  const specs = getBluechipResources(specsDataComponent, state, specIds) || [];
  const loading = haveActiveRequests([
    dataComponent,
    submittalsDatacomponent,
    getDataComponent(specsDataComponentId, state),
  ]);
  return {
    dataComponent,
    submittalsDatacomponent,
    loading,
    specs,
  };
};

export const mapDispatchToProps = {
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  setReload,
  saveSubmittal,
  showSnackNotificationAction,
};
