import React from "react";
import BWModels from "benjaminwest-models";

import CurrencyInput from "../CurrencyInput";

import ProgramInput from "../ProgramInput";
import DecimalInput from "../DecimalInput";
import TextInputWithError from "../TextInputWithError";
import InputAdornment from "@material-ui/core/InputAdornment";
import SelectWithError, {
  SelectWithError as RawSelectWithError,
} from "../SelectWithError";
import CheckboxWithError from "../CheckboxWithError";
import DatePickerWithError from "../DatePickerWithError";
import DropdownMenu from "../../layout/AppLayout/DropdownMenu";
import projects from "./projects";
import area from "./area";
import quote from "./quote";
import specDetails from "./specDetails";
import purchaseOrderDetails, {
  filterByStartsWith,
} from "./purchaseOrderDetails";
import shipment from "./shipment";
import note from "./note";
import invoice from "./invoice";
import glCode from "./glCode";
import userRole from "./userRole";
import discount from "./discount";
import funding from "./funding";
import team from "./team";
import rfq from "./rfq";
import Search from "@material-ui/icons/Search";
import FilePickerWithError from "../FilePickerWithError";
import SpecCategory, {
  getSpecCategoryLabel,
} from "../../../models/SpecCategory";
import Vendor from "../../../models/Vendor";
import Property from "../../../models/Property";
import UnitOfMeasure from "../../../models/UnitOfMeasure";
import {
  FLAGS_SELECT,
  PROGRAMS_SELECT,
  BRANDS_SELECT,
} from "../../../constants/DataComponents";
import Entity from "../../../models/Entity";
import PurchaseOrder from "../../../models/PurchaseOrder";
import AreaType from "../../../models/AreaType";
import Team from "../../../models/Team";
import UserRole from "../../../models/UserRole";
import Office from "../../../models/Office";
import Spec from "../../../models/Spec";
import Area from "../../../models/Area";
import ProjectCurrency from "../../../models/ProjectCurrency";
import Scope from "../../../models/Scope";
import fee from "./fee";
import PhoneInput from "../PhoneInput";
import GLCode from "../../../models/GLCode";
import Product from "../../../models/Product";

export const handleCurrencyInputChange = (callback, name) => event => {
  callback(event, name);
};

export const inputs = {
  ...invoice,
  ...note,
  ...projects,
  ...area,
  ...specDetails,
  ...purchaseOrderDetails,
  ...shipment,
  ...glCode,
  ...userRole,
  ...discount,
  ...fee,
  ...funding,
  ...team,
  ...rfq,
  quote,
  programs: {
    InputComponent: ProgramInput,
    name: "programs",
    label: "Programs",
    fullWidth: true,
  },
  baseName: {
    InputComponent: TextInputWithError,
    name: "name",
    label: "",
    fullWidth: true,
    autoFocus: true,
  },
  name: {
    InputComponent: TextInputWithError,
    label: "Name",
    name: "name",
    required: true,
    fullWidth: true,
  },
  abbreviation: {
    InputComponent: TextInputWithError,
    label: "Abbreviation",
    name: "abbreviation",
    required: true,
    fullWidth: true,
    limit: 30,
    limitType: "length",
  },
  description: {
    InputComponent: TextInputWithError,
    label: "Description",
    name: "description",
    fullWidth: true,
    multiline: true,
    rows: 2,
    limit: 255,
    limitType: "length",
    avoidDebounce: true,
  },
  remitAddressLabel: {
    InputComponent: TextInputWithError,
    label: "Remit Address Label",
    name: "label",
    required: true,
    fullWidth: true,
    autoFocus: true,
  },
  locationName: {
    InputComponent: TextInputWithError,
    label: "Location Name",
    name: "name",
    required: true,
    fullWidth: true,
  },
  userRole: {
    InputComponent: SelectWithError,
    label: "Role",
    name: "userRoleId",
    dataComponentId: "select-userRoles",
    required: true,
    fullWidth: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: UserRole,
      route: "user-roles",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      pageSize: -1,
    },
    valueProperty: "id",
    labelProperty: "name",
  },
  contactRole: {
    InputComponent: TextInputWithError,
    label: "Job Title",
    name: "jobTitle",
    fullWidth: true,
  },
  team: {
    InputComponent: SelectWithError,
    label: "Team",
    name: "teamId",
    dataComponentId: "select-teams",
    required: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: Team,
      route: "teams",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
    valueProperty: "id",
    labelProperty: "name",
    fullWidth: true,
  },
  email: {
    name: "email",
    InputComponent: TextInputWithError,
    label: "Email",
    required: true,
    fullWidth: true,
  },
  // User model has 'phone_number', Contact model has 'phone'
  phoneNumber: {
    InputComponent: TextInputWithError,
    label: "Phone",
    name: "phoneNumber",
    fullWidth: true,
  },
  cellNumber: {
    InputComponent: TextInputWithError,
    label: "Phone",
    name: "cellNumber",
    fullWidth: true,
    InputProps: {
      inputComponent: PhoneInput,
    },
    inputProps: {
      placeholder: "+15555555555",
    },
  },
  phone: {
    InputComponent: TextInputWithError,
    label: "Phone",
    name: "phone",
    required: true,
    fullWidth: true,
  },
  fax: {
    InputComponent: TextInputWithError,
    label: "Fax",
    name: "fax",
    fullWidth: true,
  },
  office: {
    InputComponent: SelectWithError,
    label: "Office",
    name: "officeId",
    dataComponentId: "select-offices",
    required: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: Office,
      route: "offices",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["offices.id", "name", "scopeId"],
    },
    valueProperty: "id",
    labelProperty: "name",
    fullWidth: true,
  },
  location: {
    InputComponent: TextInputWithError,
    label: "Address 1",
    name: "location.address",
    fullWidth: true,
  },
  locationAddress2: {
    InputComponent: TextInputWithError,
    label: "Address 2",
    name: "location.address2",
    fullWidth: true,
  },
  locationCountry: {
    InputComponent: TextInputWithError,
    label: "Country",
    name: "location.country",
    fullWidth: true,
  },
  locationState: {
    InputComponent: TextInputWithError,
    label: "State",
    name: "location.state",
    fullWidth: true,
  },
  locationCity: {
    InputComponent: TextInputWithError,
    label: "City",
    name: "location.city",
    fullWidth: true,
  },
  locationPostalCode: {
    InputComponent: TextInputWithError,
    label: "Zip Code",
    name: "location.zip",
    fullWidth: true,
  },
  address: {
    InputComponent: TextInputWithError,
    label: "Address 1",
    name: "address",
    fullWidth: true,
  },
  address2: {
    InputComponent: TextInputWithError,
    label: "Address 2",
    name: "address2",
    fullWidth: true,
  },
  country: {
    InputComponent: SelectWithError,
    label: "Country",
    name: "country",
    dataComponentId: "select-countries",
    required: true,
    fullWidth: true,
  },
  state: {
    InputComponent: SelectWithError,
    label: "State",
    name: "state",
    dataComponentId: "select-states",
    required: true,
    fullWidth: true,
  },
  city: {
    InputComponent: TextInputWithError,
    label: "City",
    name: "city",
    fullWidth: true,
  },
  postalCode: {
    InputComponent: TextInputWithError,
    label: "Postal Code",
    name: "postalCode",
    fullWidth: true,
  },
  federalId: {
    InputComponent: TextInputWithError,
    label: "Federal ID",
    name: "federalId",
    fullWidth: true,
  },
  vendorName: {
    InputComponent: TextInputWithError,
    label: "Common Vendor Name",
    name: "name",
    autoFocus: true,
    fullWidth: true,
  },
  poName: {
    InputComponent: TextInputWithError,
    label: "PO Vendor Name",
    name: "poName",
    fullWidth: true,
  },
  w9Name: {
    InputComponent: TextInputWithError,
    label: "W9 Vendor Name",
    name: "w9Name",
    fullWidth: true,
  },
  contactStatus: {
    InputComponent: RawSelectWithError,
    label: "Status",
    name: "status",
    displayEmpty: false,
    get options() {
      /* istanbul ignore next */
      const contactSchema = BWModels.loadSchema("Contact");
      /* istanbul ignore next */
      return contactSchema.__utils.statuses;
    },
    fullWidth: true,
  },
  vendorContactStatus: {
    InputComponent: RawSelectWithError,
    label: "Role",
    name: "contactStatus",
    nullAsEmpty: true,
    get options() {
      /* istanbul ignore next */
      const contactSchema = BWModels.loadSchema("VendorContact");
      /* istanbul ignore next */
      return contactSchema.__utils.contactStatus;
    },
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
  },
  website: {
    InputComponent: TextInputWithError,
    label: "Website",
    name: "website",
    fullWidth: true,
  },
  vendorCategoryId: {
    InputComponent: SelectWithError,
    label: "Category",
    name: "categoryId",
    isSingleAutocomplete: true,
    isAutocomplete: true,
    dataComponentId: "select-vendorCategories",
    fullWidth: true,
  },
  vendorStatus: {
    InputComponent: RawSelectWithError,
    label: "Status",
    name: "status",
    displayEmpty: false,
    isAutocompleteMulti: true,
    isAutocomplete: true,
    SelectProps: {
      multiple: true,
      renderValue: selected => {
        const vendorSchema = BWModels.loadSchema("Vendor").__utils.statuses;
        return selected
          .map(id => vendorSchema.find(option => option.id === id).name)
          .join(", ");
      },
    },
    get options() {
      /* istanbul ignore next */
      const vendorSchema = BWModels.loadSchema("Vendor");
      /* istanbul ignore next */
      return vendorSchema.__utils.statuses;
    },
    fullWidth: true,
  },
  products: {
    label: "Products",
    InputComponent: SelectWithError,
    name: "products",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
    valueProperty: "id",
    labelProperty: "name",
    dataComponentId: "select-vendor-products",
    APIOptions: {
      model: Product,
      route: "products",
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["products.id", "name"],
    },
  },
  certifications: {
    label: "Certifications",
    InputComponent: SelectWithError,
    name: "certifications",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
    valueProperty: "id",
    labelProperty: "name",
    dataComponentId: "select-vendor-certifications",
    APIOptions: {
      model: Product,
      route: "certifications",
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["certifications.id", "name"],
    },
  },
  unitOfMeasure: {
    InputComponent: SelectWithError,
    name: "unitOfMeasureId",
    label: "UOM",
    fullWidth: true,
    displayEmpty: true,
    nullAsEmpty: true,
    filterOption: filterByStartsWith,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    dataComponentId: "select-unit-of-measure",
    APIOptions: {
      model: UnitOfMeasure,
      route: "unit-of-measures",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["unit_of_measures.id", "name"],
    },
    valueProperty: "id",
    labelProperty: "name",
  },
  baseQuantity: {
    InputComponent: TextInputWithError,
    name: "baseQuantity",
    label: "Base Quantity",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { prefix: "" },
    },
  },
  atticStock: {
    InputComponent: TextInputWithError,
    name: "atticStock",
    label: "Attic Stock",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { prefix: "" },
    },
  },
  overagePercent: {
    InputComponent: TextInputWithError,
    name: "overagePercent",
    label: "Overage",
    fullWidth: true,
    labeledTextProps: {
      endAdornment: "%",
    },
    InputProps: {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    },
  },
  overageValue: {
    InputComponent: TextInputWithError,
    name: "overageValue",
    label: "Overage Value",
    fullWidth: true,
    disabled: true,
    InputProps: {
      disableUnderline: true,
    },
  },
  totalQuantity: {
    InputComponent: TextInputWithError,
    name: "totalQuantity",
    label: "Total Quantity",
    fullWidth: true,
    disabled: true,
    InputProps: {
      disableUnderline: true,
    },
  },
  designerBudget: {
    InputComponent: TextInputWithError,
    name: "designerBudget",
    label: "Designer Budget",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  designerQuantity: {
    InputComponent: TextInputWithError,
    name: "designerQuantity",
    label: "Designer Quantity",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { prefix: "" },
    },
  },
  clientBudgetCents: {
    InputComponent: TextInputWithError,
    name: "clientBudgetCents",
    label: "Client Budget",
    fullWidth: true,
  },
  priceCents: {
    InputComponent: TextInputWithError,
    name: "priceCents",
    label: "Price",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  price: {
    name: "price",
    InputComponent: TextInputWithError,
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  firstName: {
    InputComponent: TextInputWithError,
    name: "firstName",
    label: "First Name",
    fullWidth: true,
  },
  lastName: {
    InputComponent: TextInputWithError,
    name: "lastName",
    label: "Last Name",
    fullWidth: true,
  },
  role: {
    name: "role",
    InputComponent: TextInputWithError,
    label: "Role",
    fullWidth: true,
  },
  specDescription: {
    InputComponent: TextInputWithError,
    name: "description",
    label: "Description",
    multiline: true,
    rows: 2,
    fullWidth: true,
    required: true,
  },
  customNumber: {
    InputComponent: TextInputWithError,
    name: "customNumber",
    label: "Spec Number",
    fullWidth: true,
    required: true,
  },
  statusComment: {
    InputComponent: TextInputWithError,
    label: "Quick Notes",
    name: "statusComment",
    fullWidth: true,
    multiline: true,
    rows: 2,
    resize: "vertical",
    ignoreLabeledText: true,
    avoidOnChange: true,
  },
  title: {
    InputComponent: TextInputWithError,
    name: "title",
    label: "Title",
    fullWidth: true,
  },
  search: {
    InputComponent: TextInputWithError,
    name: "search",
    label: "Search",
    fullWidth: true,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      ),
    },
  },
  requiresApproval: {
    name: "requiresApproval",
    InputComponent: CheckboxWithError,
    label: "Requires Approval",
    ignoreCaption: true,
  },
  status: {
    name: "status",
    InputComponent: CheckboxWithError,
    label: "Inactive",
    checkValues: {
      check: "inact",
      uncheck: "act",
    },
    ignoreCaption: true,
  },
  canAccessVendorPortal: {
    name: "canAccessVendorPortal",
    InputComponent: CheckboxWithError,
    label: "Allow Vendor Portal Access",
    ignoreCaption: true,
  },
  contactIsActiveForVendor: {
    name: "isActive",
    InputComponent: CheckboxWithError,
    label: "Active",
    ignoreCaption: true,
  },
  canAccessClientPortal: {
    name: "canAccessClientPortal",
    InputComponent: CheckboxWithError,
    label: "Client Portal Access",
    ignoreCaption: true,
  },
  saveAndCreateAnother: {
    label: "Save and Create Another",
    name: "saveAndCreateAnother",
    InputComponent: CheckboxWithError,
  },
  isPlaceholder: {
    label: "Incomplete Spec",
    name: "isIncomplete",
    InputComponent: CheckboxWithError,
    ignoreCaption: true,
  },
  isAllowance: {
    label: "Allowance Spec",
    name: "isAllowance",
    InputComponent: CheckboxWithError,
    ignoreCaption: true,
  },
  nonTaxableCheck: {
    label: "Non-Taxable Spec",
    name: "isTaxExempt",
    InputComponent: CheckboxWithError,
    ignoreCaption: true,
    useSwitch: true,
    formControlLabelProps: { labelPlacement: "start" },
  },
  propertyId: {
    InputComponent: SelectWithError,
    label: "Property",
    name: "propertyId",
    dataComponentId: "select-properties",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    APIOptions: {
      model: Property,
      route: "properties",
      includes: ["location", "entity"],
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["properties.id", "name"],
    },
    valueProperty: "id",
    labelProperty: "name",
    fullWidth: true,
    required: true,
  },
  client: {
    InputComponent: SelectWithError,
    label: "Client",
    name: "clientId",
    dataComponentId: "select-clients",
    fullWidth: true,
  },
  moreActions: {
    InputComponent: DropdownMenu,
    label: "",
    name: "moreActions",
    dataComponentId: "needEntity",
    buttonText: "More actions",
    fullWidth: true,
    ignoreCaption: true,
  },
  entity: {
    InputComponent: SelectWithError,
    label: "Entity",
    name: "entityId",
    dataComponentId: "select-entities",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    APIOptions: {
      model: Entity,
      route: "entities",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
    valueProperty: "id",
    labelProperty: "name",
    fullWidth: true,
  },
  specCategory: {
    InputComponent: SelectWithError,
    label: "Category",
    name: "specCategoryId",
    isSingleAutocomplete: true,
    isAutocomplete: true,
    dataComponentId: "select-specCategories",
    APIOptions: {
      model: SpecCategory,
      route: "spec-categories",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      params: {
        modifiers: ["withLinked"],
      },
      fields: ["spec_categories.id", "name", "key", "isActive"],
    },
    fullWidth: true,
    valueProperty: "id",
    apiFilterLabelProperty: "name",
    labelProperty: getSpecCategoryLabel,
    isOptionDisabled: ({ isActive }) => !isActive,
    required: true,
  },
  vendorAutocomplete: {
    isSingleAutocomplete: true,
    InputComponent: SelectWithError,
    label: "Vendor",
    name: "vendorId",
    isAutocomplete: true,
    displayEmpty: true,
    nullAsEmpty: true,
    dataComponentId: "select-vendors",
    isOptionDisabled: Vendor.isVendorDisabled,
    APIOptions: {
      model: Vendor,
      route: "vendors",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["vendors.id", "name", "status", "scopeId"],
    },
    fullWidth: true,
    valueProperty: "id",
    apiFilterLabelProperty: "name",
    labelProperty: Vendor.labelProperty,
  },
  area: {
    InputComponent: SelectWithError,
    label: "Area",
    name: "areaId",
    fullWidth: true,
    required: true,
    dataComponentId: "select-areas",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    valueProperty: "id",
    labelProperty: "name",
    APIOptions: {
      model: Area,
      route: "areas",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["areas.id", "name"],
    },
  },
  bidGroup: {
    InputComponent: SelectWithError,
    label: "Spec Bid Group",
    name: "bidGroupId",
    dataComponentId: "select-bidGroups",
    fullWidth: true,
  },
  startDate: {
    InputComponent: DatePickerWithError,
    label: "Start Date",
    name: "startDate",
    disablePast: true,
    fullWidth: true,
  },
  currency: {
    InputComponent: SelectWithError,
    label: "Currency",
    name: "currencyId",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    nullAsEmpty: true,
    displayEmpty: true,
    dataComponentId: "select-currencies",
    fullWidth: true,
  },
  conversionRate: {
    InputComponent: TextInputWithError,
    label: "Conversion Rate",
    name: "conversionRate",
    InputProps: {
      inputComponent: DecimalInput,
    },
    inputProps: {
      decimalScale: 6,
      allowNegative: false,
    },
    fullWidth: true,
    required: true,
  },
  projectCurrency: {
    InputComponent: SelectWithError,
    label: "Currency",
    name: "projectCurrencyId",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    dataComponentId: "select-project-currencies",
    fullWidth: true,
    valueProperty: "id",
    labelProperty: "currency.name",
    required: true,
    APIOptions: {
      model: ProjectCurrency,
      route: "project-currencies",
      pageSize: -1,
      includes: ["currency"],
      sort: [{ columnName: "currency.name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
  },
  projectService: {
    InputComponent: SelectWithError,
    label: "Service",
    name: "serviceId",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    dataComponentId: "select-services",
    fullWidth: true,
    nullAsEmpty: true,
    displayEmpty: true,
  },
  projectType: {
    InputComponent: SelectWithError,
    label: "Project Type",
    name: "projectTypeId",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    dataComponentId: "select-project-types",
    fullWidth: true,
    nullAsEmpty: true,
    displayEmpty: true,
  },
  customDate: (name, label = "Date") => ({
    InputComponent: DatePickerWithError,
    label,
    name,
    disablePast: true,
    fullWidth: true,
  }),
  endDate: {
    InputComponent: DatePickerWithError,
    label: "End Date",
    name: "endDate",
    fullWidth: true,
    required: true,
  },
  estimatedShipDate: {
    InputComponent: DatePickerWithError,
    label: "Estimated Ship",
    name: "estimatedShipDate",
    fullWidth: true,
  },
  estimatedDeliveryDate: {
    InputComponent: DatePickerWithError,
    label: "Estimated Delivery",
    name: "estimatedDeliveryDate",
    fullWidth: true,
  },
  areaType: {
    InputComponent: SelectWithError,
    label: "Type",
    name: "areaType",
    submitData: true,
    dataComponentId: "select-areaTypes",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    fullWidth: true,
    valueProperty: "id",
    labelProperty: "name",
    APIOptions: {
      model: AreaType,
      route: "area-types",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      omitDestroyDataComponent: true,
    },
  },
  consultingCompanyType: {
    InputComponent: RawSelectWithError,
    label: "Type",
    name: "type",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    get options() {
      const consultingCompanySchema = BWModels.loadSchema("ConsultingCompany");
      return consultingCompanySchema.__utils.types;
    },
    fullWidth: true,
    required: true,
  },
  msaClient: {
    name: "msaClient",
    InputComponent: CheckboxWithError,
    label: "MSA Client",
    checkValues: {
      check: true,
      uncheck: false,
    },
  },
  draftClient: {
    name: "isDraft",
    InputComponent: CheckboxWithError,
    label: "Draft Client",
    checkValues: {
      check: true,
      uncheck: false,
    },
  },
  notes: {
    InputComponent: TextInputWithError,
    label: "Notes",
    name: "notes",
    fullWidth: true,
  },
  vendorId: {
    InputComponent: SelectWithError,
    label: "Select Vendor",
    name: "vendorId",
    dataComponentId: "select-vendors",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    fullWidth: true,
    isOptionDisabled: Vendor.isVendorDisabled,
    apiFilterLabelProperty: "name",
    labelProperty: Vendor.labelProperty,
  },
  projectReportCategoryId: {
    name: "projectReportCategoryId",
    default: "All",
    fullWidth: true,
  },
  reportStartDate: {
    InputComponent: DatePickerWithError,
    name: "startDate",
    fullWidth: true,
  },
  reportEndDate: {
    InputComponent: DatePickerWithError,
    name: "endDate",
    fullWidth: true,
  },
  contingencyType: {
    InputComponent: RawSelectWithError,
    label: "Type",
    name: "contingencyType",
    get options() {
      const contingency = BWModels.loadSchema("Contingency");
      return contingency.__utils.contingencyTypes;
    },
    fullWidth: true,
  },
  contingencyUnit: {
    InputComponent: RawSelectWithError,
    label: "Unit",
    name: "unit",
    get options() {
      const contingency = BWModels.loadSchema("Contingency");
      return contingency.__utils.units;
    },
    fullWidth: true,
  },
  contingencyValue: {
    InputComponent: TextInputWithError,
    label: "Value",
    name: "value",
    fullWidth: true,
  },
  importFile: {
    InputComponent: FilePickerWithError,
    name: "file",
    label: "Select File",
    fullWidth: true,
    inputFileProps: {
      accept: "text/csv",
    },
    helperText: "Provide a valid .csv file",
  },
  glCodes: {
    label: "Add GL Code",
    InputComponent: SelectWithError,
    name: "glCodes",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
    valueProperty: "id",
    labelProperty: "description",
    APIOptions: {
      model: GLCode,
      route: "gl-codes",
      pageSize: -1,
      sort: [{ columnName: "description", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["gl_codes.id", "description"],
    },
  },
  commodities: {
    label: "Select Commodities",
    InputComponent: SelectWithError,
    name: "commodities",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
    valueProperty: "id",
    labelProperty: "name",
    menuPlacement: "top",
  },
  brandId: {
    InputComponent: SelectWithError,
    label: "Brand",
    name: "brandId",
    dataComponentId: BRANDS_SELECT,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    fullWidth: true,
    displayEmpty: true,
    nullAsEmpty: true,
    styleMenuOption: true,
    labelProperty: "name",
    required: true,
  },
  programId: {
    InputComponent: SelectWithError,
    label: "Program",
    name: "programId",
    nullAsEmpty: true,
    displayEmpty: true,
    fullWidth: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    dataComponentId: PROGRAMS_SELECT,
    labelProperty: "name",
    styleMenuOption: true,
  },
  flagId: {
    InputComponent: SelectWithError,
    label: "Flag",
    name: "flagId",
    nullAsEmpty: true,
    displayEmpty: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    dataComponentId: FLAGS_SELECT,
    fullWidth: true,
    styleMenuOption: true,
    labelProperty: "name",
    required: true,
  },
  profilePicture: {
    InputComponent: FilePickerWithError,
    name: "profilePicture",
    label: "Select Image",
    helperText: "Click to browse",
    fullWidth: true,
    useFileWrapper: true,
    inputFileProps: {
      accept: ".jpg,.png,.jpeg",
      isForm: true,
    },
  },
  clientContactType: {
    InputComponent: SelectWithError,
    label: "Contact Type",
    name: "contactType",
    get options() {
      return BWModels.loadSchema("ClientContact").__utils.clientContactType;
    },
    fullWidth: true,
  },
  specIds: {
    label: "Spec Number",
    InputComponent: SelectWithError,
    dataComponentId: "specs-datacomponent-quoting",
    name: "specIds",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    valueProperty: "id",
    labelProperty: "customNumber",
    APIOptions: {
      model: Spec,
      route: "specs",
      pageSize: -1,
      sort: [{ columnName: "customNumber", direction: "asc" }],
      fetchOnlyOnce: true,
      params: {
        omitDefaultModifier: true,
      },
      fields: ["specs.id", "customNumber"],
    },
  },
  vendorIds: {
    label: "Vendor",
    InputComponent: SelectWithError,
    dataComponentId: "vendors-datacomponent-quoting",
    name: "vendorIds",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    valueProperty: "id",
    apiFilterLabelProperty: "name",
    labelProperty: Vendor.labelProperty,
    isOptionDisabled: Vendor.isVendorDisabled,
    APIOptions: {
      model: Vendor,
      route: "vendors",
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["vendors.id", "name", "status", "scopeId"],
    },
  },
  awardedTo: {
    label: "Awarded To",
    InputComponent: SelectWithError,
    dataComponentId: "awarded-datacomponent-quoting",
    name: "awardedIds",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    valueProperty: "id",
    apiFilterLabelProperty: "name",
    labelProperty: Vendor.labelProperty,
    isOptionDisabled: Vendor.isVendorDisabled,
    APIOptions: {
      model: Vendor,
      route: "vendors",
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["vendors.id", "name", "status", "scopeId"],
    },
  },
  poIds: {
    label: "PO Number",
    InputComponent: SelectWithError,
    dataComponentId: "purchase_order-datacomponent-quoting",
    name: "poIds",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    valueProperty: "id",
    labelProperty: "number",
    pageSize: -1,
    APIOptions: {
      model: PurchaseOrder,
      route: "purchase-orders",
      fields: ["purchase_orders.id", "number"],
      pageSize: -1,
      sort: [{ columnName: "sequenceIndex", direction: "asc" }],
      fetchOnlyOnce: true,
      params: { omitDefaultModifier: true, modifiers: ["withOnlyNumber"] },
    },
  },
  areaQuoteIds: {
    label: "Area",
    InputComponent: SelectWithError,
    dataComponentId: "area-datacomponent-quoting",
    name: "areaQuoteIds",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    valueProperty: "id",
    labelProperty: "name",
    pageSize: -1,
    APIOptions: {
      model: Area,
      route: "areas",
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
      fetchOnlyOnce: true,
      fields: ["areas.id", "name"],
    },
  },
  scopeId: {
    InputComponent: SelectWithError,
    label: "Scope",
    name: "scopeId",
    dataComponentId: "select-scopes",
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: Scope,
      route: "scopes",
      sort: [{ columnName: "id", direction: "asc" }],
      pageSize: -1,
      fetchOnlyOnce: true,
      omitDestroyDataComponent: true,
    },
    fullWidth: true,
    required: true,
    valueProperty: "id",
    labelProperty: "name",
  },
  shipmentStatus: {
    InputComponent: RawSelectWithError,
    label: "Shipment Status",
    name: "shipmentStatus",
    displayEmpty: true,
    options: [
      { id: "delivered", name: "Delivered" },
      { id: "not-delivered", name: "Undelivered" },
    ],
    fullWidth: true,
  },
};
