import queryString from "query-string";

import * as Sentry from "@sentry/react";

import AuthService from "../services/AuthService";
import * as actions from "../constants/ActionTypes";
import { filtersToGetParams } from "./dataComponents/utils";
import { createQueueProcess, parseQueryParams } from "./queuesActions";

const authService = new AuthService(false);

export const generateReportRequestAction = reportReference => ({
  type: actions.GENERATE_REPORT_REQUEST,
  payload: { reportReference },
});

export const generateReportSuccessAction = reportReference => ({
  type: actions.GENERATE_REPORT_SUCCESS,
  payload: { reportReference },
});

export const generateReportFailureAction = reportReference => ({
  type: actions.GENERATE_REPORT_FAILURE,
  payload: { reportReference },
});

export const downloadFile = (data, filename) => {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  a.href = window.URL.createObjectURL(data);
  a.setAttribute("download", filename);

  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

const parseReportParamsToQuery = reportParams =>
  Object.keys(reportParams).reduce((accum, key) => {
    const value = reportParams[key];
    accum[key] =
      value && value.constructor === Object ? filtersToGetParams(value) : value;
    return accum;
  }, {});

const getFileName = disposition => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) return matches[1].replace(/['"]/g, "");
};

const getPath = ({ reportType, reportId, queries } = {}) => {
  if (reportType === "queryReport") {
    return "/generate-query-report";
  }

  return reportId && queries ? "/generate-custom-report" : "/generate-report";
};

export const mimeTypes = {
  pdf: "application/pdf",
  csv: "text/csv",
  xls: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const handleFile = (showPreview, blob, fileName) => {
  if (showPreview) {
    window.open(URL.createObjectURL(blob));
  } else {
    downloadFile(blob, fileName);
  }
};

export const handleFileResponse = async (response, showPreview) => {
  if (response.status === 200) {
    const blob = await response.blob();
    const dispositionHeader = response.headers.get("Content-Disposition");
    const fileName = getFileName(dispositionHeader);
    handleFile(showPreview, blob, fileName);
  }
};

export const handleQueueFileResponse = async (
  { returnvalue: { reportData, reportUrl, fileName } },
  showPreview
) => {
  if (reportUrl) {
    window.open(reportUrl);
    return;
  }

  const blob = new Blob([Buffer.from(reportData)], {
    type: "application/pdf",
  });
  handleFile(showPreview, blob, fileName);
};

export const queueReportGenerator = async ({
  reportReference,
  params,
  showPreview,
  dispatch,
  getState,
  queueName,
}) => {
  const start = performance.now();
  await dispatch(createQueueProcess(queueName, reportReference, params));
  const { queues } = getState();
  const reportQueue = queues[reportReference];
  handleQueueFileResponse(reportQueue, showPreview);

  {
    const end = performance.now();
    const message = `${JSON.stringify({
      reportReference,
      ...params,
    })} took too long (${end - start}ms).`;
    Sentry.captureException(new Error(message));
    // eslint-disable-next-line no-console
    console.warn(message);
  }
};

export const reportGenerator = async ({
  templateName,
  reportParams,
  format,
  reportReference,
  params,
  showPreview,
  dispatch,
  getState,
  queueGenerator,
}) => {
  if (queueGenerator) {
    return await queueReportGenerator({
      reportReference,
      params,
      showPreview,
      dispatch,
      getState,
      queueName:
        format === "pdf" ? "generate-pdf-report" : "generate-custom-report",
    });
  }

  const response = await authService.post(
    `${getPath(reportParams)}?${queryString.stringify(
      parseQueryParams({
        ...reportParams,
        templateName,
      })
    )}`,
    params,
    {},
    {
      Accept: mimeTypes[format],
    },
    {
      returnRawResponse: true,
    }
  );

  await handleFileResponse(response, showPreview);
};

export const generateAndDownloadReport = ({
  format = "csv",
  showPreview = false,
  templateName,
  revisionId,
  referenceId = "",
  ...reportParams
}) => {
  return async (dispatch, getState) => {
    const revisionReference = revisionId
      ? `-revision-${revisionId}`
      : referenceId;
    const reportReference = `${templateName}-${format}-${showPreview}${revisionReference}`;
    dispatch(generateReportRequestAction(reportReference));

    const query = parseReportParamsToQuery(reportParams);
    const params = { templateName, revisionId, ...query };

    const queueGenerator =
      ["queryReport", "customReport"].includes(reportParams.reportType) ||
      (format === "pdf" && process.env.QUEUE_PDF_GENERATOR === "true");
    try {
      await reportGenerator({
        templateName,
        reportParams,
        format,
        reportReference,
        params,
        showPreview,
        dispatch,
        getState,
        queueGenerator,
      });

      dispatch(generateReportSuccessAction(reportReference));
    } catch (error) {
      dispatch(generateReportFailureAction(reportReference));
    }
  };
};
